import React from 'react'
import { Layout } from 'antd'
import Navbar from '../../components/Menu/Navbar'
import { Helmet } from 'react-helmet'
import Scroll from '../../components/scrollButton'

const { Header } = Layout

export const LandingLayout = ({ children }) => {
  return (
    <Layout
      id="components-layout-demo-top"
      className="layout"
      style={{ backgroundColor: '#fff' }}
    >
      {/* freshworks script tag */}
      <Helmet>
        <script src="//fw-cdn.com/988138/2065725.js" chat="false"></script>
      </Helmet>
      <script src="//fw-cdn.com/988138/2065725.js" chat="false"></script>

      {/* <!-- Tracking tag. Place in the <body> section of webpage --> */}
      {/* <!-- Privacy policy at http://tag.brandcdn.com/privacy --> */}
      <Scroll showBelow={250} />
      <script
        type="text/javascript"
        src="//tag.brandcdn.com/autoscript/nofloodflorida_vfdwsk1vmtzvwgc9/No_Flood_Florida.js"
      ></script>

      {/* paypal script tag */}
      <script
        src="https://www.paypal.com/sdk/js?client-id=AUw335aYTdPn42p9F8YWUB3_YPELNcFoVM0RVeSaFXNM8hswPI3cIfF3rtnsQu009tOGXbOKLF5id5O2&vault=true&intent=subscription"
        data-sdk-integration-source="button-factory"
      ></script>

      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.20/esri/themes/light/main.css"
        />
      </Helmet>
      <script src="https://js.arcgis.com/4.20/"></script>

      <Header style={{ height: 'fit-content' }}>
        <Navbar />
      </Header>

      {children}
    </Layout>
  )
}
