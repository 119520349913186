import React, { useState } from 'react'
// import { Line } from '@ant-design/charts'
import './styles.css'
// import '../index.css'
import { Typography } from 'antd'

const { Title, Paragraph, Text } = Typography

const BarGraphDrainageClass = props => {
  // ExcDrain={ExcDrain}
  // ModWellDrain={ModWellDrain}
  // PoorDrain={PoorDrain}
  // SomeExcDrain={SomeExcDrain}
  // SomePoorDrain={SomePoorDrain}
  // VeryPoorDrain={VeryPoorDrain}
  // WellDrain={WellDrain}

  // populate chart details for flood frequency
  let chartSource2 = []

  chartSource2 = [
    {
      val1: 'Excessive',
      cost1: props.ExcDrain,
      costNum1: props.ExcDrain,
      color1: '#B3DACB',

      val2: 'Somewhat Excessive',
      cost2: props.SomeExcDrain,
      costNum2: props.SomeExcDrain,
      color2: '#66B596',

      val3: 'Well',
      cost3: props.WellDrain,
      costNum3: props.WellDrain,
      color3: '#F9E299',

      val4: 'Moderate',
      cost4: props.ModWellDrain,
      costNum4: props.ModWellDrain,
      color4: '#F5D466',

      val5: 'Somewhat Poor',
      cost5: props.SomePoorDrain,
      costNum5: props.SomePoorDrain,
      color5: '#F5D466',

      val6: 'Poor',
      cost6: props.PoorDrain,
      costNum6: props.PoorDrain,
      color6: '#E3A5A1',

      val7: 'Very Poor',
      cost7: props.VeryPoorDrain,
      costNum7: props.VeryPoorDrain,
      color7: '#CD615A'
    }
  ]

  // console.log(chartSource2)

  return (
    <main>
      <strong>Drainage Class</strong>
      <div>
        <br />
        <table
          id="DrainageClassChart"
          class="charts-css column show-primary-axis show-5-secondary-axes data-spacing-5 labels-align-center"
        >
          <thead>
            <tr>
              <th scope="col"> Year </th> <th scope="col"> Progress </th>
            </tr>
          </thead>{' '}
          <tbody>
            <tr>
              <th scope="row"> {chartSource2[0]?.val1} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource2[0]?.costNum1} / (100) )`,
                  '--color': `${chartSource2[0]?.color1}`
                }}
              >
                <Choose>
                  <When condition={chartSource2[0]?.cost1 != 0}>
                    <span class="data"> {chartSource2[0]?.cost1} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource2[0]?.cost1} % Excessive.
                    </span>
                  </When>
                </Choose>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource2[0]?.val2} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource2[0]?.costNum2} / (100) )`,
                  '--color': `${chartSource2[0]?.color2}`
                }}
              >
                <Choose>
                  <When condition={chartSource2[0]?.cost2 != 0}>
                    <span class="data"> {chartSource2[0]?.cost2} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource2[0]?.cost2} % Somewhat Excessive.
                    </span>
                  </When>
                </Choose>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource2[0]?.val3} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource2[0]?.costNum3} / (100) )`,
                  '--color': `${chartSource2[0]?.color3}`
                }}
              >
                <Choose>
                  <When condition={chartSource2[0]?.cost3 != 0}>
                    <span class="data"> {chartSource2[0]?.cost3} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource2[0]?.cost3} % Well.
                    </span>
                  </When>
                </Choose>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource2[0]?.val4} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource2[0]?.costNum4} / (100) )`,
                  '--color': `${chartSource2[0]?.color4}`
                }}
              >
                <Choose>
                  <When condition={chartSource2[0]?.cost4 != 0}>
                    <span class="data"> {chartSource2[0]?.cost4} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource2[0]?.cost4} % Moderate.
                    </span>
                  </When>
                </Choose>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource2[0]?.val5} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource2[0]?.costNum5} / (100) )`,
                  '--color': `${chartSource2[0]?.color5}`
                }}
              >
                <Choose>
                  <When condition={chartSource2[0]?.cost5 != 0}>
                    <span class="data"> {chartSource2[0]?.cost5} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource2[0]?.cost5} % Somewhat Poor.
                    </span>
                  </When>
                </Choose>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource2[0]?.val6} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource2[0]?.costNum6} / (100 ) )`,
                  '--color': `${chartSource2[0]?.color6}`
                }}
              >
                <Choose>
                  <When condition={chartSource2[0]?.cost6 != 0}>
                    <span class="data"> {chartSource2[0]?.cost6} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource2[0]?.cost6} % Poor.
                    </span>
                  </When>
                </Choose>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource2[0]?.val7} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource2[0]?.costNum7} / (100) )`,
                  '--color': `${chartSource2[0]?.color7}`
                }}
              >
                <Choose>
                  <When condition={chartSource2[0]?.cost7 != 0}>
                    <span class="data"> {chartSource2[0]?.cost7} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource2[0]?.cost7} % Very Poor.
                    </span>
                  </When>
                </Choose>
              </td>
            </tr>{' '}
          </tbody>
        </table>
        {/* <br /> */}
        <div
          class="primary-axis"
          style={{
            textAlign: 'center',
            padding: '0px 0px 20px 0px'
          }}
        >
          {' '}
          <i>Drainage Capacity (Excessive to Very Poor)</i>{' '}
        </div>
      </div>
    </main>
  )
}

export default BarGraphDrainageClass
