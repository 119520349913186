import { useState, useEffect } from 'react'
import firebase from 'gatsby-plugin-firebase'

const getDownloadURL = async (county, nffid) => {
  return new Promise((resolve, reject) => {
    const storage = firebase.storage()
    const gsReference = storage.refFromURL(`gs://lomas/Florida/${county}/LOMA_${nffid}.jpg`)

    gsReference
      .getDownloadURL()
      .then(url => {
        console.log('resolved url:', url)
        resolve(url)
      })
      .catch(error => {
        console.log('Error: ', error)
      })
  })
}

export const useDomains = () => {
  const [pubDomain] = useState('https://flood-score.firebaseapp.com/search-eligibility')
  const [devDomain] = useState('http://localhost:3000/search-eligibility')
  return { pubDomain, devDomain }
}

export const useGetPropertyData = (county, address) => {
  const [docData, setDocData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [id, setId] = useState(null)

  useEffect(() => {
    console.log('getting property data for county:', county)
    console.log('getting property data for address:', address)
    firebase.firestore()
      .collection('properties').doc('Florida')
      .collection('counties').doc(county)
      .collection('properties').where('FULL_ADD', '==', address)
      .get()
      .then(function (querySnapshot) {
        console.log('querySnapshot:', querySnapshot)
        querySnapshot.forEach(function (doc) { // we can assume it is only one
          console.log('doc:', doc)
          const { id } = doc
          doc.ref.get().then(doc => {
            console.log('setting doc data:', doc.data())
            setDocData(doc.data())
            setLoading(false)
            setId(id)
          })
        })
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error)
      })

    // firebase.doFirestoreAddressRefGet(county, address).then(properties => {
    //   const propertyDoc = properties[0]
    //   const { id } = propertyDoc
    //   console.log('in useGetPropertyData hook, propertyDoc:', propertyDoc)
    //   propertyDoc.get().then(doc => {
    //     setDocData(doc.data())
    //     setLoading(false)
    //     setId(id)
    //   })
    // })
  }, [address, firebase])

  if (docData !== null || typeof docData !== 'undefined') {
    console.log(docData)
    return { id, docData, loading }
  }
}

export const useGetImg = (county, address) => {
  console.log('useGetImg')
  console.log('county:', county)
  console.log('address:', address)
  const [imgUrlData, setImgUrlData] = useState(null)
  const [imgLoading, setImgLoading] = useState(true)

  useEffect(() => {
    firebase.firestore()
      .collection('properties').doc('Florida')
      .collection('counties').doc(county)
      .collection('properties').where('FULL_ADD', '==', address)
      .get()
      .then(function (querySnapshot) {
        const documents = []
        querySnapshot.forEach(function (doc) { // we can assume it is only one
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data())
          const { id } = doc
          if (typeof id !== 'undefined') {
            getDownloadURL(county, id).then(url => {
              if (url !== null || url !== undefined) {
                setImgUrlData(url)
                setImgLoading(false)
              }
            })
          }
        })
        return documents
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error)
      })

    // firebase.doFirestoreAddressRefGet(county, address).then(properties => {
    //   const propertyDoc = properties[0]
    //   const { id } = propertyDoc

    //   if (typeof id !== 'undefined') {
    //     firebase.getDownloadURL(county, id).then(url => {
    //       if (url !== null || url !== undefined) {
    //         setImgUrlData(url)
    //         setImgLoading(false)
    //       }
    //     })
    //   }
    // })
  }, [imgUrlData, firebase])

  return { imgUrlData, imgLoading }
}
