import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import firebase from 'gatsby-plugin-firebase'
import { useDomains } from './eligibility-hooks'
import { Link, navigate } from 'gatsby'
import { AuthContext } from '../../context/auth'
// import '../../pages/index.css'

/* Styles */
import { Row, Col, Typography, Button } from 'antd'
import { ParallaxContainer } from './styled-eligibility'

/* Components */
import AutoSuggest from '../../components/AutoSuggest/AutoSuggest'
import Screening from './Screening/Screening'
import Recommendation from './Recommendation/Recommendation'

import ContactUsForm from './ContactUsForm'
import ElegibilityContainer from './ElegibilityContainer'
import { BoldOutlined } from '@ant-design/icons'

const { Title, Paragraph } = Typography

/**
 * Controls the 'flow' of displaying the screeening and various recommendations
 */
const Eligibility = props => {
  const { pubDomain, devDomain } = useDomains()
  // const [showSearch, setShowSearch] = useState(true)
  const [showScreening, setShowScreening] = useState(false)
  const [showRecommendation, setShowRecommendation] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState('')
  const [selectedCounty, setSelectedCounty] = useState(null)
  const [showContactModal, setShowContactModal] = useState(false)

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setSelectedAddress(suggestion)
  }

  function handleCloseContactForm() {
    setShowContactModal(false)
  }
  function handleShowContactForm() {
    setShowContactModal(true)
  }

  function updateParentCountyState(county) {
    setSelectedCounty(county)
  }

  const handleProceedButton = address => {
    setSelectedAddress(address)
    // setShowSearch(false)
    setShowScreening(true)
  }

  /**
   * Render the recommendations
   */
  if (showRecommendation) {
    return (
      <Recommendation
        county={selectedCounty}
        address={selectedAddress}
        setShowRecommendation={setShowRecommendation}
        setShowScreening={setShowScreening}
      />
    )
  }

  /**
   * Show the screening form if this value is 'true'
   * Check if the user has filled out the screening form previously in their
   * browser's session.
   *
   * Skip to recommendations if they're information is cache'd
   */
  if (showScreening) {
    // console.log('selected county: ', selectedCounty)
    // console.log('selected address: ', selectedAddress)

    const name = window.sessionStorage.getItem('name')
    const email = window.sessionStorage.getItem('email')
    const phone = window.sessionStorage.getItem('phone')
    const cacheExists = name !== null || email !== null || phone !== null

    /**
     * If the screening form data exists in the cache, proceed to the
     * recommendations.
     */
    if (cacheExists) {
      setShowRecommendation(true)
      // Add to firebase
      // const timestamp = new Date().toLocaleString('en-US', {
      //   timeZone: 'America/New_York'
      // })
      // const screeningFormData = { name, email, phone, timestamp }
      // const screeningFormMessageObj = {
      //   to: 'info@nofloodflorida.com',
      //   template: {
      //     name: 'screeningFormTemplate',
      //     data: {
      //       address: selectedAddress,
      //       ...screeningFormData
      //     }
      //   }
      // }
      // // firebase.doFirestoreAdd('screeningForm', screeningFormMessageObj)
      // firebase
      //   .firestore()
      //   .collection('screeningForm')
      //   .add(screeningFormMessageObj)
      //   .then(() => {
      //     console.log('success')
      //   })
      //   .catch(error => console.log('Error adding doucment', error))
    } else {
      return (
        <Screening
          selected={selectedAddress}
          selectedCounty={selectedCounty}
          setShowRecommendation={setShowRecommendation}
          setShowScreening={setShowScreening}
        />
      )
    }
  }

  /* Render IFrame Landing */
  if (!showScreening) {
    return (
      <IFrameLanding
        onSuggestionSelected={onSuggestionSelected}
        updateParentCountyState={updateParentCountyState}
        firebase={firebase}
        handleProceedButton={handleProceedButton}
        selectedCounty={selectedCounty}
        showContactModal={showContactModal}
        handleShowContactForm={handleShowContactForm}
        handleCloseContactForm={handleCloseContactForm}
      />
    )
  }
}

const IFrameLanding = props => {
  IFrameLanding.propTypes = {
    onSuggestionSelected: PropTypes.func,
    updateParentCountyState: PropTypes.func,
    firebase: PropTypes.object,
    handleProceedButton: PropTypes.func,
    showContactModal: PropTypes.func,
    handleShowContactForm: PropTypes.func,
    handleCloseContactForm: PropTypes.func
  }
  const {
    onSuggestionSelected,
    updateParentCountyState,
    firebase,
    handleProceedButton,
    showContactModal,
    handleShowContactForm,
    handleCloseContactForm
  } = props

  const { user } = useContext(AuthContext)

  const handleLogout = async () => {
    await firebase.auth().signOut()
    navigate('/app/login')
  }

  return (
    <ElegibilityContainer>
      <ParallaxContainer>
        <Row span={24} gutter={[10, 10]}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Title level={2} style={{ color: '#fff' }}>
              Best Available Technology <br /> & Flood Risk Models
            </Title>
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Title
              level={5}
              style={{
                color: '#fff',
                margin: '0 auto',
                maxWidth: '680px',
                fontWeight: '400'
              }}
            >
              Over 300k Florida Residents have a wrong Flood Zone designation.
              Let's take the first step together to fix this and help you save
              on your flood insurance. Guaranteed.
            </Title>
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Title level={4} style={{ color: '#c7ae4a', textAlign: 'center' }}>
              Find out for FREE if your property is eligible.
            </Title>
          </Col>
        </Row>

        <AutoSuggestRow span={24}>
          <Col span={24} style={{ padding: '0', margin: '0 auto' }}>
            <AutoSuggest
              autocomplete="off"
              theme={autosuggestTheme}
              countySelectStyles={countySelectStyles}
              updateParentCountyState={updateParentCountyState}
              onSuggestionSelected={onSuggestionSelected}
              inputProps={{ id: 'eligibilityAddressSuggest' }}
              firebase={firebase}
              showProceedButton={true}
              handleProceedButton={handleProceedButton}
              showStats
            />
          </Col>
        </AutoSuggestRow>

        <Row span={24} style={{ padding: '5px', marginTop: '10px' }}>
          <Col span={24}>
            <Paragraph
              style={{
                margin: '0 auto',
                maxWidth: '640px',
                textAlign: 'center',
                color: '#fff'
              }}
            >
              Can’t find your property?{' '}
              <u
                style={{
                  color: '#007bff',
                  cursor: 'pointer',
                  textDecoration: 'none'
                }}
                onClick={handleShowContactForm}
              >
                Contact us
              </u>{' '}
              for a custom screening.{' '}
            </Paragraph>
          </Col>

          <ContactUsForm
            show={showContactModal}
            handleClose={handleCloseContactForm}
          />
        </Row>

        <Row span={24} style={{ marginTop: '5px' }}>
          <Col span={24}>
            <Paragraph
              style={{
                margin: '0 auto',
                maxWidth: '640px',
                textAlign: 'center',
                color: '#fff'
              }}
            >
              Need an Elevation Certificate?
              <br />{' '}
              {/* <Link to="/elevation-certificate" style={{ color: '#007bff' }}>
                Click Here
              </Link>{' '} */}
              <Link to="/elevation-certificate">
                <Button
                  type="primary"
                  className="box-shadow"
                  shape="round"
                  size="small"
                  style={{
                    background: '#1c79c7',
                    borderColor: '#1c79c7'
                  }}
                >
                  Click Here
                </Button>
              </Link>
            </Paragraph>
          </Col>
        </Row>
        <Row span={24} style={{ marginTop: '5px' }}>
          <Col span={24}>
            <Paragraph
              style={{
                margin: '0 auto',
                maxWidth: '640px',
                textAlign: 'center',
                color: '#fff'
              }}
            >
              Need Flood Insurance?
              <br />{' '}
              {/* <Link to="/flood-insurance-quote" style={{ color: '#007bff' }}>
                Click Here
              </Link>{' '} */}
              <Link to="/flood-insurance">
                <Button
                  type="primary"
                  className="box-shadow"
                  shape="round"
                  size="small"
                  style={{
                    background: '#1c79c7',
                    borderColor: '#1c79c7'
                  }}
                >
                  Click Here
                </Button>
              </Link>
              <br />{' '}
            </Paragraph>
          </Col>
        </Row>

        <div>
          {!user ? (
            <>
              <Row span={24} style={{ marginTop: '5px' }}>
                <Col span={24}>
                  <Paragraph
                    style={{
                      margin: '0 auto',
                      maxWidth: '640px',
                      textAlign: 'center',
                      color: '#fff'
                    }}
                  >
                    Register or Login to access exclusive flood risk tools!
                    <br />{' '}
                    <Link to="/sign-in/register" style={{ color: '#55B96A' }}>
                      Register
                    </Link>{' '}
                    <br />{' '}
                    <Link to="/sign-in/login" style={{ color: '#55B96A' }}>
                      Login
                    </Link>{' '}
                  </Paragraph>
                </Col>
              </Row>
            </>
          ) : (
            <Row span={24} style={{ marginTop: '5px' }}>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Link to="/app/profile" style={{ color: '#55B96A' }}>
                  Dashboard
                </Link>{' '}
                <Button
                  type="text"
                  size="large"
                  onClick={handleLogout}
                  style={{ color: '#55B96A', textDecoration: BoldOutlined }}
                >
                  Logout
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </ParallaxContainer>
    </ElegibilityContainer>
  )
}

export default Eligibility

const AutoSuggestRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
`

const autosuggestTheme = {
  container: {
    width: '100%',
    color: '#666666',
    position: 'relative',
    top: '6px'
  },

  containerOpen: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    zIndex: '999',
    position: 'relative',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '0px',
    borderBottomRadius: '0px'
  },

  input: {
    borderRadius: '4px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    color: '#666666',
    width: '100%',
    padding: '10px 15px 10px 15px',
    height: '3rem',
    border: 'none'
  },

  inputOpen: {
    fontWeight: '500',
    color: '#666666',
    padding: '10px 15px 10px 15px',
    border: 'none'
  },

  inputFocused: {
    outline: 'none'
  },

  suggestionsContainer: {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    maxHeight: '300px',
    overflow: 'auto',
    margin: '0',
    padding: '0',
    zIndex: '999',
    textAlign: 'left',
    background: '#FAFAFA'
  },

  /**
   * Position absolute here will not push the containers underneath down.
   */
  suggestionsContainerOpen: {
    width: '100%',
    /* overflow: 'scroll', */
    zIndex: '999',
    backgroundColor: '#fff',
    position: 'absolute'
  },

  suggestionsList: {
    listStyleType: 'none',
    borderTop: '1px solid #eee',
    paddingRight: '40px',
    /* overflow: 'scroll', */
    paddingBottom: '10px',
    marginBottom: '0'
  },

  suggestion: {
    paddingTop: '4px',
    paddingBottom: '4px',
    cursor: 'pointer'
  },

  suggestionHighlighted: {
    backgroundColor: '#eeeeee'
  },

  suggestionFirst: {},
  sectionContainer: {},
  sectionContainerFirst: {},
  sectionTitle: {}
}

const countySelectStyles = {
  borderRadius: '4px !important',
  boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  position: 'relative',
  margin: '0 auto',
  width: '100%',
  maxWidth: '250px',
  textAlign: 'left'
}
