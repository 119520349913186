import { loadModules } from 'esri-loader'

export function loadMap(
  element,
  position,
  countyName,
  zoomLevel,
  fixedZoom,
  searchFunction
) {
  return loadModules(
    [
      'esri/Map',
      'esri/views/MapView',
      'esri/config',
      'esri/layers/FeatureLayer',
      'esri/layers/MapImageLayer',
      'esri/widgets/Search',
      'esri/Graphic',
      'esri/layers/GraphicsLayer',
      'esri/widgets/LayerList',
      // 'esri/widgets/Print',
      'esri/core/watchUtils',
      'esri/widgets/Expand',
      // 'esri/widgets/Editor',
      'esri/widgets/BasemapToggle'
    ],
    {
      css: true
    }
  ).then(
    ([
      Map,
      MapView,
      esriConfig,
      FeatureLayer,
      MapImageLayer,
      Search,
      Graphic,
      GraphicsLayer,
      LayerList,
      // Print,
      watchUtils,
      Expand,
      // Editor,
      BasemapToggle
    ]) => {
      if (!element) {
        // component or app was likely destroyed
        return
      }
      if (!position) {
        // component or app was likely destroyed
        return
      }

      esriConfig.apiKey = process.env.GATSBY_ESRI_API_KEY;

      // FEMA floodplain popup and labels
      const FEMA_FldPlnLabel =
        '$feature.FLD_ZONE + ",   " + $feature.STATIC_BFE'

      const labelClassFloodplains = {
        // autocasts as new LabelClass()
        symbol: {
          type: 'text', // autocasts as new TextSymbol()
          color: 'white',
          haloColor: [0, 0, 0],
          haloSize: 2,
          font: {
            family: 'Montserrat Medium',
            style: 'italic',
            size: 12,
            weight: 'bold'
          }
        },
        labelPlacement: 'above-center',
        labelExpressionInfo: {
          expression: FEMA_FldPlnLabel
        }
      }

      // FEMA floodplain popup and labels
      const FEMA_LOMA_Label =
        '"LOMA  " + $feature.CASENUMBER + ",  " +  Month($feature.DATEENDED) + "/" + Day($feature.DATEENDED) + "/" + Year($feature.DATEENDED)'

      const labelClassLOMA = {
        // autocasts as new LabelClass()
        symbol: {
          type: 'text', // autocasts as new TextSymbol()
          color: 'purple',
          haloColor: [255, 255, 255],
          haloSize: 2,
          font: {
            family: 'Montserrat Medium',
            style: 'italic',
            size: 12,
            weight: 'bold'
          }
        },
        labelPlacement: 'above-center',
        labelExpressionInfo: {
          expression: FEMA_LOMA_Label
        }
      }

      // FEMA FIRM labels
      const FEMA_FIRM_Lbl =
        '$feature.FIRM_PAN + ",   " + Year($feature.EFF_DATE)'

      const labelClassFIRM = {
        // autocasts as new LabelClass()
        symbol: {
          type: 'text', // autocasts as new TextSymbol()
          color: 'white',
          haloColor: [0, 0, 0],
          haloSize: 2,
          font: {
            family: 'Montserrat Medium',
            style: 'italic',
            size: 12,
            weight: 'bold'
          }
        },
        labelPlacement: 'above-center',
        labelExpressionInfo: {
          expression: FEMA_FIRM_Lbl
        }
      }

      // FEMA BFE Line labels
      const FEMA_BFE_Lbl = '$feature.ELEV + "  ft"'

      const labelClassBFELine = {
        // autocasts as new LabelClass()
        symbol: {
          type: 'text', // autocasts as new TextSymbol()
          color: 'LawnGreen',
          haloColor: [0, 0, 0],
          haloSize: 2,
          font: {
            family: 'Montserrat Medium',
            style: 'italic',
            size: 16,
            weight: 'bold'
          }
        },
        labelPlacement: 'above-center',
        labelExpressionInfo: {
          expression: FEMA_BFE_Lbl
        }
      }

      // create the Map
      const map = new Map({
        basemap: 'satellite',
        showLabels: false
      })

      // show the map at the element
      let view = new MapView({
        map,
        container: element,
        //   center: [-118.80543, 34.027],
        // center: [-82.118615, 28.011979],
        center: [position.x, position.y],
        zoom: zoomLevel,
        showLabels: false
      })

      // label template for FEMA floodplains
      const templateFloodplains = {
        // autocasts as new PopupTemplate()
        title: 'FEMA Floodplain',
        content: [
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'FLD_ZONE',
                label: 'FEMA Zone'
              },
              {
                fieldName: 'ZONE_SUBTY',
                label: 'Subtype'
              },
              {
                fieldName: 'STATIC_BFE',
                label: 'BFE'
              },
              {
                fieldName: 'V_DATUM',
                label: 'Datum'
              }
            ]
          }
        ]
      }

      // label template for LOMA Point Features
      const templateLOMAPoint = {
        // autocasts as new PopupTemplate()
        title: 'FEMA LOMAs',
        content: [
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'CASENUMBER',
                label: 'Case Number'
              },
              {
                fieldName: 'PROJECTNAME',
                label: 'Project Name'
              },
              {
                fieldName: 'DATEENDED',
                label: 'LOMA Date'
              },
              {
                fieldName: 'REVAL_STAT',
                label: 'LOMA Revalidation'
              }
            ]
          }
        ]
      }

      // label template for LOMA Point Features
      const templateNFFData = {
        // autocasts as new PopupTemplate()
        title: 'NFF Structure Data',
        content: [
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'PROP_ADD',
                label: 'Address'
              },
              {
                fieldName: 'PROP_CITY',
                label: 'City'
              },
              {
                fieldName: 'FEMA_ZONE',
                label: 'FEMA Zone'
              },
              {
                fieldName: 'FEMA_BFE',
                label: 'FEMA BFE'
              },
              {
                fieldName: 'FEMA_EFF_DT',
                label: 'FEMA Effective Date'
              },
              {
                fieldName: 'SGE',
                label: 'Ground Elevation'
              },
              {
                fieldName: 'LOMA',
                label: 'LOMA Recommendation'
              }
            ]
          }
        ]
      }

      // label template for FEMA BFE Lines
      const templateBFELine = {
        // autocasts as new PopupTemplate()
        title: 'FEMA BFE Lines',
        content: [
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'ELEV',
                label: 'Elevation'
              },
              {
                fieldName: 'V_DATUM',
                label: 'Datum'
              }
            ]
          }
        ]
      }

      const templateParcels = {
        title: 'Parcel Information',
        content: [
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'PHY_ADDR1',
                label: 'Address'
              },
              {
                fieldName: 'ACT_YR_BLT',
                label: 'Year Built'
              },
              {
                fieldName: 'OWN_NAME',
                label: 'Owner Name'
              }
            ]
          }
        ]
      }

      const templateDistrictFldPlns = {
        title: 'SWFWMD Best Available',
        content: [
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'FEMA_ZONE',
                label: 'FEMA Zone'
              },
              {
                fieldName: 'WATERSHED',
                label: 'Watershed'
              },
              {
                fieldName: 'COUNTY',
                label: 'County'
              }
            ]
          }
        ]
      }
      // label template for FEMA FIRM Panel
      const templateFIRMPANEL = {
        // autocasts as new PopupTemplate()
        title: 'FIRM Panel',
        content: [
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'FIRM_PAN',
                label: 'Panel Num'
              },
              {
                fieldName: 'EFF_DATE',
                label: 'Effective Date'
              }
            ]
          }
        ]
      }

      // label template for FEMA floodplains
      const templateSoils = {
        // autocasts as new PopupTemplate()
        title: 'SSURGO Soils',
        content: [
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'hydgrpdcd',
                label: 'Hydrologic Group'
              },
              {
                fieldName: 'drclassdcd',
                label: 'Drainage Class'
              },
              {
                fieldName: 'flodfreqdc',
                label: 'Flood Frequency'
              }
            ]
          }
        ]
      }

      let countyCode = 0
      //pointing to the DOT Parcels dataset use if statement to relate the number to the name of the county that is passed in
      if (countyName == 'Alachua') {
        countyCode = 1
      } else if (countyName == 'Baker') {
        countyCode = 2
      } else if (countyName == 'Bay') {
        countyCode = 3
      } else if (countyName == 'Bradford') {
        countyCode = 4
      } else if (countyName == 'Brevard') {
        countyCode = 5
      } else if (countyName == 'Broward') {
        countyCode = 6
      } else if (countyName == 'Calhoun') {
        countyCode = 7
      } else if (countyName == 'Charlotte') {
        countyCode = 8
      } else if (countyName == 'Citrus') {
        countyCode = 9
      } else if (countyName == 'Clay') {
        countyCode = 10
      } else if (countyName == 'Collier') {
        countyCode = 11
      } else if (countyName == 'Columbia') {
        countyCode = 12
      } else if (countyName == 'MiamiDade') {
        countyCode = 13
      } else if (countyName == 'Desoto') {
        countyCode = 14
      } else if (countyName == 'Dixie') {
        countyCode = 15
      } else if (countyName == 'Duval') {
        countyCode = 16
      } else if (countyName == 'Escambia') {
        countyCode = 17
      } else if (countyName == 'Flagler') {
        countyCode = 18
      } else if (countyName == 'Franklin') {
        countyCode = 19
      } else if (countyName == 'Gadsden') {
        countyCode = 20
      } else if (countyName == 'Gilchrist') {
        countyCode = 21
      } else if (countyName == 'Glades') {
        countyCode = 22
      } else if (countyName == 'Gulf') {
        countyCode = 23
      } else if (countyName == 'Hamilton') {
        countyCode = 24
      } else if (countyName == 'Hardee') {
        countyCode = 25
      } else if (countyName == 'Hendry') {
        countyCode = 26
      } else if (countyName == 'Hernando') {
        countyCode = 27
      } else if (countyName == 'Highlands') {
        countyCode = 28
      } else if (countyName == 'Hillsborough') {
        countyCode = 29
      } else if (countyName == 'Holmes') {
        countyCode = 30
      } else if (countyName == 'IndianRiver') {
        countyCode = 31
      } else if (countyName == 'Jackson') {
        countyCode = 32
      } else if (countyName == 'Jefferson') {
        countyCode = 33
      } else if (countyName == 'Lafayette') {
        countyCode = 34
      } else if (countyName == 'Lake') {
        countyCode = 35
      } else if (countyName == 'Lee') {
        countyCode = 36
      } else if (countyName == 'Leon') {
        countyCode = 37
      } else if (countyName == 'Levy') {
        countyCode = 38
      } else if (countyName == 'Liberty') {
        countyCode = 39
      } else if (countyName == 'Madison') {
        countyCode = 40
      } else if (countyName == 'Manatee') {
        countyCode = 41
      } else if (countyName == 'Marion') {
        countyCode = 42
      } else if (countyName == 'Martin') {
        countyCode = 43
      } else if (countyName == 'Monroe') {
        countyCode = 44
      } else if (countyName == 'Nassau') {
        countyCode = 45
      } else if (countyName == 'Okaloosa') {
        countyCode = 46
      } else if (countyName == 'Okeechobee') {
        countyCode = 47
      } else if (countyName == 'Orange') {
        countyCode = 48
      } else if (countyName == 'Osceola') {
        countyCode = 49
      } else if (countyName == 'PalmBeach') {
        countyCode = 50
      } else if (countyName == 'Pasco') {
        countyCode = 51
      } else if (countyName == 'Pinellas') {
        countyCode = 52
      } else if (countyName == 'Polk') {
        countyCode = 53
      } else if (countyName == 'Putnam') {
        countyCode = 54
      } else if (countyName == 'SantaRosa') {
        countyCode = 55
      } else if (countyName == 'Sarasota') {
        countyCode = 56
      } else if (countyName == 'Seminole') {
        countyCode = 57
      } else if (countyName == 'StJohns') {
        countyCode = 58
      } else if (countyName == 'StLucie') {
        countyCode = 59
      } else if (countyName == 'Sumter') {
        countyCode = 60
      } else if (countyName == 'Suwannee') {
        countyCode = 61
      } else if (countyName == 'Taylor') {
        countyCode = 62
      } else if (countyName == 'Union') {
        countyCode = 63
      } else if (countyName == 'Volusia') {
        countyCode = 64
      } else if (countyName == 'Wakulla') {
        countyCode = 65
      } else if (countyName == 'Walton') {
        countyCode = 66
      } else if (countyName == 'Washington') {
        countyCode = 67
      }

      // pointing to the FEMA Effective dataset
      const FEMALayer = new FeatureLayer({
        url:
          'https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer/28',
        // showLabels: false,
        popupTemplate: templateFloodplains,
        labelingInfo: [labelClassFloodplains],
        title: 'FEMA Effective'
      })

      map.add(FEMALayer)

      //pointing to the FEMA LOMA dataset
      const FEMALOMALayer = new FeatureLayer({
        url:
          'https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer/34',
        // showLabels: false,
        popupTemplate: templateLOMAPoint,
        labelingInfo: [labelClassLOMA],
        title: 'FEMA LOMAs'
      })
      FEMALOMALayer.minScale = 20000
      map.add(FEMALOMALayer)

      //pointing to the DFIRM Panel Effective dataset
      const FIRMLayer = new FeatureLayer({
        url:
          'https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer/3',
        showLabels: false,
        popupTemplate: templateFIRMPANEL,
        labelingInfo: [labelClassFIRM],
        listMode: 'hide'
      })

      map.add(FIRMLayer)

      const BFELineRender = {
        type: 'simple',
        symbol: {
          type: 'simple-line',
          outline: { style: 'none' },
          color: [0, 255, 0], // lime green
          width: 3
        }
      }

      //pointing to the FEMA BFE Line dataset
      const FEMABFELineLayer = new FeatureLayer({
        url:
          'https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer/16',
        // showLabels: false,
        renderer: BFELineRender,
        popupTemplate: templateBFELine,
        labelingInfo: [labelClassBFELine],
        title: 'FEMA BFE Lines',
        listMode: 'hide'
      })
      FEMABFELineLayer.minScale = 20000
      map.add(FEMABFELineLayer)

      //applying best available floodplain render from SWFWMD
      const fldRender = {
        type: 'simple',
        symbol: {
          type: 'simple-fill',
          outline: { style: 'none' },
          color: [227, 67, 241, 0.25]
        }
      }

      const BAFldLayer = new FeatureLayer({
        url:
          'https://www25.swfwmd.state.fl.us/arcgis11/rest/services/prjFloodplainWatersheds/FloodplainBndsNoCache/MapServer/0',
        renderer: fldRender,
        title: 'Best Available Data',
        popupTemplate: templateDistrictFldPlns
        // listMode: 'hide'
      })
      BAFldLayer.minScale = 20000
      map.add(BAFldLayer)

      // const SoilsLayer = new FeatureLayer({
      //   url:
      //     'https://services8.arcgis.com/2AaC1LfGYnmR2D3k/arcgis/rest/services/usgs_soils_july_2020/FeatureServer/0',
      //   // renderer: fldRender,
      //   title: 'SSURGO Soils',
      //   popupTemplate: templateSoils,
      //   // listMode: 'hide'
      //   visible: false
      // })
      // SoilsLayer.minScale = 20000
      // map.add(SoilsLayer)

      // // NHD flowline features
      // const NHDLayer = new FeatureLayer({
      //   url:
      //     'https://hydro.nationalmap.gov/arcgis/rest/services/NHDPlus_HR/MapServer/7',
      //   // renderer: fldRender,
      //   title: 'NHD Flowlines'
      //   // popupTemplate: templateDistrictFldPlns
      //   // listMode: 'hide'
      // })
      // NHDLayer.minScale = 20000
      // map.add(NHDLayer)

      let NHDLayer = new MapImageLayer({
        url:
          'https://hydro.nationalmap.gov/arcgis/rest/services/NHDPlus_HR/MapServer',
        title: 'NHD Features',
        listMode: 'hide',
        sublayers: [
          {
            id: 2,
            title: 'NHD Network ',
            visible: true
          }
        ]
      })
      NHDLayer.minScale = 20000
      map.add(NHDLayer)

      // symbol for default structure points, grey, N/A LOMA
      const LOMA_N_A = {
        type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
        size: 5,
        color: [0, 0, 0],
        outline: null
      }
      const LOMA_Low = {
        type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
        size: 5,
        color: [255, 255, 0],
        outline: null
      }
      const LOMA_Medium = {
        type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
        size: 5,
        color: [255, 195, 77],
        outline: null
      }
      const LOMA_High = {
        type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
        size: 5,
        color: [255, 25, 25],
        outline: null
      }

      const NFF_Struc_Renderer = {
        type: 'unique-value', // autocasts as new UniqueValueRenderer()
        // legendOptions: {
        //   title: "Freeway type"
        // },
        defaultSymbol: LOMA_N_A,
        defaultLabel: 'Not Applicable',
        field: 'LOMA',
        uniqueValueInfos: [
          {
            value: 1, // code for Low LOMA recommendation, yellow
            symbol: LOMA_Low,
            label: 'Low'
          },
          {
            value: 2, // code for Medium LOMA recommendation, orange
            symbol: LOMA_Medium,
            label: 'Medium'
          },
          {
            value: 3, // code for High LOMA recommendation, red
            symbol: LOMA_High,
            label: 'High'
          }
        ]
      }

      //pointing to NFF structures feature layer
      const NFF_Struc_Pts = new FeatureLayer({
        url:
          'https://services8.arcgis.com/2AaC1LfGYnmR2D3k/arcgis/rest/services/nff_esri_uploadgdb/FeatureServer/0',
        renderer: NFF_Struc_Renderer,
        popupTemplate: templateNFFData,
        title: 'NFF Data'
        // listMode: 'hide'
      })
      NFF_Struc_Pts.minScale = 20000
      map.add(NFF_Struc_Pts)

      //map.add(layer);
      // //editor for adding comments to hosted feature layer, temporary info now
      // const editLayer = new FeatureLayer({
      //   url:
      //     'https://services8.arcgis.com/2AaC1LfGYnmR2D3k/arcgis/rest/services/geotech_data/FeatureServer/0',
      //   listMode: 'hide'
      // })

      // map.add(editLayer)

      // var editor = new Editor({
      //   view: view,
      //   container: document.createElement('div'),
      //   layer: editLayer,
      //   fieldConfig: [
      //     // Specify which fields to configure
      //     {
      //       name: 'Kv',
      //       label: 'Kv ft/day'
      //     },
      //     {
      //       name: 'Kh',
      //       label: 'Kh ft/day'
      //     }
      //   ],
      //   enabled: true, // default is true, set to false to disable editing functionality
      //   addEnabled: true, // default is true, set to false to disable the ability to add a new feature
      //   updateEnabled: false, // default is true, set to false to disable the ability to edit an existing feature
      //   deleteEnabled: false // default is true, set to false to disable the ability to delete features
      // })

      const ParcelLayer = new FeatureLayer({
        url: `https://gis.fdot.gov/arcgis/rest/services/Parcels/MapServer/${countyCode}`,
        // showLabels: false,
        popupTemplate: templateParcels,
        // labelingInfo: [labelClassFloodplains],
        listMode: 'hide'
      })
      ParcelLayer.minScale = 6000
      map.add(ParcelLayer)

      //applying building render
      const buildingRender = {
        type: 'simple',
        symbol: {
          type: 'simple-fill',
          style: 'none',
          outline: { width: 1.5, color: [238, 233, 191, 1] }
        }
      }

      // adding building feature layer from Microsoft
      const BuildingLayer = new FeatureLayer({
        url:
          'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/MSBFP2/FeatureServer/0',
        renderer: buildingRender,
        listMode: 'hide',
        title: 'Building Structures'
      })
      BuildingLayer.minScale = 6000
      map.add(BuildingLayer)

      // use graphics layer to add a point based on the user entered, geocoded address
      const graphicsLayer = new GraphicsLayer({ listMode: 'hide' })
      map.add(graphicsLayer)

      const addressPoint = {
        //Create a point based on the georeference of the user selected address
        type: 'point',
        longitude: position.x,
        latitude: position.y
      }
      const simpleMarkerSymbol = {
        type: 'simple-marker',
        color: [255, 69, 0], // Orange
        outline: {
          color: [255, 255, 255], // White
          width: 1
        }
      }

      const pointGraphic = new Graphic({
        geometry: addressPoint,
        symbol: simpleMarkerSymbol
      })
      graphicsLayer.add(pointGraphic)

      // start adding and customizing the various widgets related to the map
      // adding search bar functionality

      if (searchFunction == true) {
        const search = new Search({
          //Add Search widget
          view: view,
          popupEnabled: false
        })
        view.ui.add(search, 'top-right') //Add to the map
      }

      const toggle = new BasemapToggle({
        view: view,
        nextBasemap: 'topo'
      })
      view.ui.add(toggle, 'top-right') //Add to the map

      var layerList = new LayerList({
        view: view,
        container: document.createElement('div')
      })

      // //print widget
      // var print = new Print({
      //   view: view,
      //   container: document.createElement('div')
      // })

      // Create an Expand instance and set the content property to the DOM node of the basemap gallery widget
      // Use an Esri icon font to represent the content inside of the Expand widget
      var bgExpand,
        bgExpand2,
        // bgExpand3,
        // expandHandle1,
        expandHandle2
      // expandHandle3

      // bgExpand = new Expand({
      //   view: view,
      //   content: print.container,
      //   expandIconClass: 'esri-icon-printer',
      //   mode: 'floating'
      // })

      bgExpand2 = new Expand({
        view: view,
        content: layerList.container,
        expandIconClass: 'esri-icon-layers',
        mode: 'floating'
      })

      // bgExpand3 = new Expand({
      //   view: view,
      //   content: editor.container,
      //   expandIconClass: 'esri-icon-edit',
      //   mode: 'floating'
      // })

      // expandHandle1 = watchUtils.pausable(bgExpand, 'expanded', function (
      //   newValue,
      //   oldValue
      // ) {
      //   if (newValue === true) {
      //     expandHandle1.pause()
      //     setTimeout(function () {
      //       expandHandle2.resume()
      //     }, 100)
      //   } else {
      //     expandHandle1.resume()
      //   }
      //   if (bgExpand2.expanded) {
      //     bgExpand2.collapse()
      //   }
      // })

      expandHandle2 = watchUtils.pausable(bgExpand2, 'expanded', function (
        newValue,
        oldValue
      ) {
        if (newValue === true) {
          expandHandle2.pause()
          setTimeout(function () {
            // expandHandle1.resume()
          }, 100)
        } else {
          expandHandle2.resume()
        }
        // if (bgExpand.expanded) {
        //   bgExpand.collapse()
        // }
      })

      // expandHandle3 = watchUtils.pausable(bgExpand3, 'expanded', function (
      //   newValue,
      //   oldValue
      // ) {
      //   if (newValue === true) {
      //     expandHandle3.pause()
      //     setTimeout(function () {
      //       expandHandle2.resume()
      //     }, 100)
      //   } else {
      //     expandHandle3.resume()
      //   }
      //   if (bgExpand.expanded) {
      //     bgExpand.collapse()
      //   }
      // })

      // Add the expand instance to the ui
      // view.ui.add(bgExpand, 'top-left')
      view.ui.add(bgExpand2, 'top-left')
      // view.ui.add(bgExpand3, 'top-left')

      if (fixedZoom === true) {
        view.when(disableZooming)
        function disableZooming(view) {
          view.popup.dockEnabled = true

          // Removes the zoom action on the popup
          view.popup.actions = []

          // stops propagation of default behavior when an event fires
          function stopEvtPropagation(event) {
            event.stopPropagation()
          }

          // exlude the zoom widget from the default UI
          view.ui.components = ['attribution']

          // disable mouse wheel scroll zooming on the view
          view.on('mouse-wheel', stopEvtPropagation)

          // disable zooming via double-click on the view
          view.on('double-click', stopEvtPropagation)

          // disable zooming out via double-click + Control on the view
          view.on('double-click', ['Control'], stopEvtPropagation)

          // disables pinch-zoom and panning on the view
          view.on('drag', stopEvtPropagation)

          // disable the view's zoom box to prevent the Shift + drag
          // and Shift + Control + drag zoom gestures.
          view.on('drag', ['Shift'], stopEvtPropagation)
          view.on('drag', ['Shift', 'Control'], stopEvtPropagation)

          // prevents zooming with the + and - keys
          view.on('key-down', event => {
            const prohibitedKeys = [
              '+',
              '-',
              'Shift',
              '_',
              '=',
              'ArrowUp',
              'ArrowDown',
              'ArrowRight',
              'ArrowLeft'
            ]
            const keyPressed = event.key
            if (prohibitedKeys.indexOf(keyPressed) !== -1) {
              event.stopPropagation()
            }
          })
        }
      }
      // wait for the view to load TODO: may not need this?
      return view.when(() => {
        // return a reference to the view
        return view
      })
    }
  )
}
