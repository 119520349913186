import React from 'react'
import styled from 'styled-components'
import { Link as ScrollLink } from 'react-scroll'
import { Button, Typography, Row, Col, Grid, Divider } from 'antd'
import {
  FormOutlined,
  FileOutlined,
} from '@ant-design/icons'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import { Wrapper, Container } from '../../styled-components/styled-components'
import { Footer } from '../../components/Footer/Footer'
import PageHeader from '../../components/PageHeader'
import OrderSectionLand from './components/OrderSectionLandDueDiligence'
import SeoNoIndex from '../../components/SeoNoIndex'

const { Title, Paragraph } = Typography
const { useBreakpoint } = Grid

const LandDueDiligence = () => {
  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '30px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '50px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  return (
    <LandingLayout>
      <SeoNoIndex />

      <PageHeader title="Land Due Diligence" />
      <Wrapper>
        <Container style={{ padding: '20px 10px 10px 10px' }}>
          <Row gutter={[25, 10]}>
            <Paragraph>
              No Flood Florida has created a comprehensive report which
              highlights a variety of different geospatial considerations when
              looking into purchasing a piece of land. We refer to this report
              as the Land Due Diligence Report and it can be created for any
              property throughout Florida. The report includes information
              related to{' '}
              <ScrollLink
                activeClass="active"
                to="zoning-section"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
              >
                Zoning
              </ScrollLink>
              ,{' '}
              <ScrollLink
                activeClass="active"
                to="topography-section"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
              >
                Topography
              </ScrollLink>
              {/* <ScrollLink
                activeClass="active"
                to="imagery-section"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
              >
                Latest/Historical Imagery
              </ScrollLink> */}
              ,{' '}
              <ScrollLink
                activeClass="active"
                to="wetland-section"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
              >
                National Wetland and Flowline features
              </ScrollLink>
              ,{' '}
              <ScrollLink
                activeClass="active"
                to="fema-floodplain-section"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
              >
                FEMA Effective Floodplains
              </ScrollLink>
              ,{' '}
              <ScrollLink
                activeClass="active"
                to="soils-section"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
              >
                USDA Soils
              </ScrollLink>
              ,{' '}
              <ScrollLink
                activeClass="active"
                to="landuse-section"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
              >
                Landuse Classifications
              </ScrollLink>
              {/* ,{' '}
              <ScrollLink
                activeClass="active"
                to="parcel-analysis-section"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
              >
                Parcel Area and Dimension Analysis
              </ScrollLink> */}
              , and{' '}
              <ScrollLink
                activeClass="active"
                to="utilities-section"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
              >
                Utilities
              </ScrollLink>
              {/* , and other{' '}
              <ScrollLink
                activeClass="active"
                to="other-key-terms-section"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
              >
                Important Key Terms
              </ScrollLink> */}
              .
            </Paragraph>
            {/* <Row gutter={[25, 10]}> */}
              {' '}
              {/* <Col xl={12} lg={12} md={12} sm={24} xs={24}> */}
                <Paragraph>
                  Each report component is first presented with a general
                  description related to the specific component and then a
                  unique analysis for the property of interest.
                </Paragraph>
              {/* </Col> */}
              {/* <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Row align="center">
                  <Paragraph>
                    The cost of the land due diligence report is
                  </Paragraph>
                </Row>
                <Row align="center">
                  <Title
                    level={2}
                    style={{
                      backgroundColor: '#ffefd5',
                      padding: '2px 20px 2px 20px',
                      color: '#262626'
                    }}
                  >
                    $200
                  </Title>
                </Row>
              </Col> */}
            {/* </Row> */}

            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <Row
                gutter={[20, 20]}
                style={{
                  justifyContent: 'space-around',
                  padding: '0px 0px 0px 0px'
                }}
              >
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <ScrollLink
                    activeClass="active"
                    to="order-land-report"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <Button
                      block
                      size="large"
                      type="primary"
                      shape="round"
                      icon={<FormOutlined />}
                      style={{ width: '80%' }}
                    >
                      Order Report
                    </Button>
                  </ScrollLink>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://firebasestorage.googleapis.com/v0/b/flood-score.appspot.com/o/LandDueDiligence%2FFinal_PinehurstDrPondCir.pdf?alt=media&token=13668e54-9315-4381-8fc1-32f796649bb0"
                  >
                    <Button
                      block
                      size="large"
                      type="primary"
                      shape="round"
                      icon={<FileOutlined />}
                      style={{ width: '80%' }}
                    >
                      Sample Report
                    </Button>
                  </a>
                </Col>
              </Row>
            </Col>
            <Divider />
            <Col span={24}>
              <Title level={3}>Report Components</Title>
            </Col>
            <Col span={24} id="zoning-section">
              <Title level={5}>Zoning Verification</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                Local municipalities (cities/counties) often divide their
                communities into different areas or zones. Each zone category
                typically dictates what type of development can occur. For
                example, a zone might be designated as single use where
                residential type buildings can be constructed. The zone
                designation might also indicate the lot size or the building
                scale that is allowed at that location.
              </Paragraph>
            </Col>
            <Col span={24} id="topography-section">
              <Title level={5}>Topography and Terrain</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                No Flood Florida relies on the most up-to-date digital terrain
                information that is derived from LiDAR technology. For a given
                property of interest, we will display the digital terrain model
                along with 1-ft contours to help better understand the slope of
                the land.
              </Paragraph>
            </Col>

            {/* <Col span={24} id="imagery-section">
              <Title level={5}>Current and Historical Imagery</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                The most up-to-date aerial imagery along with specific
                historical imagery is provided in this component. Often, we are
                able to report imagery from the past several decades, including
                1970, 1980, 1990, 2000, and 2020.
              </Paragraph>
            </Col> */}
            <Col span={24} id="wetland-section">
              <Title level={5}>National Wetland and Flowline Features</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                Wetland and flowline data are incorporated into the Land Due
                Diligence Report to help better understand potential hazards
                when developing a piece of land. Often, wetlands are protected
                from development pressures.
              </Paragraph>
            </Col>
            <Col span={24} id="fema-floodplain-section">
              <Title level={5}>FEMA Effective Floodplains</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                The current FEMA Effective Floodplain maps are used to help
                determine development capabilities and potential issues with
                mitigation. Additionally, the presence of the high-risk
                floodplain on a property could trigger flood insurance
                requirements. Below is a brief review of the different flood
                zone designations:
                <ul>
                  <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                    <b style={{ color: '#C41E3A' }}>High Risk Zones</b>
                    <ul>
                      <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                        <b>Zone A</b> – These areas are similar to the 100-year
                        floodplain, with the exception of not having an
                        engineering model establishing the Base Flood Elevation.
                        Homes in these areas are typically required to purchase
                        flood insurance.
                      </li>
                      <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                        <b>Zone AE</b> – These areas are subject to inundation
                        by the 100-year storm event. Base Flood Elevations
                        (BFEs) derived from detailed hydraulic analyses are
                        established. Mandatory flood insurance purchase
                        requirements and floodplain management standards apply.
                        No Flood Florida further breaks down the Zone AE
                        floodplains into <b>AE, LEVEL POOL</b> and{' '}
                        <b>AE, RIVERINE</b>. An AE, LEVEL POOL flood zone
                        indicates a none-flowing system, while the AE, RIVERINE
                        designation defines flood zones that are associated with
                        flowing systems.
                      </li>
                      <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                        <b>Zone VE</b> – These areas are subject to inundation
                        by the 100-year storm event with additional hazards due
                        to storm-induced <b>velocity wave action</b>. Base Flood
                        Elevations (BFEs) derived from detailed hydraulic
                        analyses are established. Mandatory flood insurance
                        purchase requirements and floodplain management
                        standards apply.
                      </li>
                    </ul>
                  </li>
                  <li style={{ paddingBottom: '5px', paddingTop: '0px' }}>
                    <b style={{ color: '#F28C28' }}>Moderate Risk Zones</b>
                    <ul>
                      <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                        <b>Zone X, shaded</b> – These areas are subject to
                        inundation during what is called the 500-year storm. The
                        500-year storm has a 0.2 % chance of happening in any
                        given year. This happens less often than the 100-year
                        storm. Homes in these areas typically are not required
                        to purchase flood insurance. No Flood Florida defines
                        this zone as{' '}
                        <b>X, 0.2 PCT ANNUAL CHANCE FLOOD HAZARD</b> with a BFE
                        of None.
                      </li>
                    </ul>
                  </li>
                  <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                    <b style={{ color: '#388E8E' }}>Low Risk Zones</b>
                    <ul>
                      <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                        <b>Zone X</b> – The areas of minimal flood hazard, which
                        are the areas outside the SFHA and higher than the
                        elevation of the 0.2-percent-annual-chance flood (500
                        year flood), are labeled Zone C or Zone X (unshaded). No
                        Flood Florida defines this zone as{' '}
                        <b>X, AREA OF MINIMAL FLOOD HAZARD</b> with a BFE of
                        None.
                      </li>
                    </ul>
                  </li>
                </ul>
              </Paragraph>
            </Col>
            <Col span={24} id="soils-section">
              <Title level={5}>USDA Soils</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                Soils data were downloaded from the United States Department of
                Agriculture (USDA) Soil Survey website. Through additional
                processing, No Flood Florida is able to report on the hydrologic
                soil class, the frequency of flooding, and the ability of the
                surrounding soils to infiltrate water.
              </Paragraph>
            </Col>
            <Col span={24} id="landuse-section">
              <Title level={5}>Landuse Classifications</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                Landuse data are often produced by local/state agencies. These
                geospatial features convey the type of landuse for a given area.
                Some examples might be Residential, Industrial, Commercial, etc.
              </Paragraph>
            </Col>
            {/* <Col span={24} id="parcel-analysis-section">
              <Title level={5}>Parcel Area and Dimension Analysis</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                Through GIS techniques we are able to analyze your property of
                interest (dimensions/area) along with providing a statistical
                review of the properties in your surrounding area.
              </Paragraph>
            </Col> */}
            <Col span={24} id="utilities-section">
              <Title level={5}>Utilities</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                Often, local communities and governments provide general
                coverages for utilities that No Flood Florida can visualize on a
                map. The utility coverages often define various municipality
                services like water and sewer.
              </Paragraph>
            </Col>
            {/* <Col span={24} id="other-key-terms-section">
              <Title level={5}>Other Key Terms</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                Additional key terms will be added in this section.
              </Paragraph>
            </Col> */}
          </Row>
        </Container>
      </Wrapper>
      <OrderSectionLand />
      <Footer />
    </LandingLayout>
  )
}

export default LandDueDiligence

const InsuranceProcessBoxes = styled.h3`
  position: relative;
  z-index: 2;
  // font-size: 1.5rem;
  margin-right: 20px;
  margin-left: 20px;
  //   margin-top: 6px;
  padding: 2px;
  // padding-top: 50px;
  background: PapayaWhip;
  border: none;
  border-radius: 3px;
`
