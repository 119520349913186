import React from 'react'
import { FaCheck } from 'react-icons/fa'
import LikelihoodAnalysis from '../LikelihoodAnalysis/LikelihoodAnalysis.js'
import { BulletLabel } from '../StyledComponents'
import { Row, Col, Typography } from 'antd'

const { Text } = Typography

const InfoBox = props => {
  const {
    LOMARating,
    selectedAddress,
    propertyData,
    suggestion,
    basisOfDetermination
  } = props

  return (
    <Row gutter={[0, 30]}>
      <Col span={24}>
        <FaCheck />
        <BulletLabel>Property Address:</BulletLabel>
        <span style={{ fontWeight: '500' }}>{selectedAddress}</span>
      </Col>
      <Col span={24}>
        <FaCheck />
        <BulletLabel>FEMA Flood Zone:</BulletLabel>
        <Text style={{ color: '#fff' }}>{propertyData.FEMA_ZONE}</Text>
      </Col>
      <Col span={24}>
        <FaCheck />
        <LikelihoodAnalysis
          propertyData={propertyData}
          LOMARating={LOMARating}
          suggestion={suggestion}
        />
      </Col>
      <Col span={24}>
        <Row style={{ marginBottom: '20px' }}>
          <FaCheck />
          <BulletLabel>Basis of this determination:</BulletLabel>
        </Row>
        <Row gutter={[0, 10]}>
          {basisOfDetermination.map((item, idx) => (
            <Col key={idx} span={24} offset={1}>
              <FaCheck /> <span>{item.info}</span>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default InfoBox
