import React, { useState } from 'react'
// import { Line } from '@ant-design/charts'
import './styles.css'
// import '../index.css'
import { Typography } from 'antd'

const { Title, Paragraph, Text } = Typography

const LineGraphLowRisk = props => {
  // console.log(props.femaZone)
  // console.log(props.replacementCost)

  // use this section to make if/then/else statements related to PRP policies
  let chartSource = []

  if (props.replacementCost > 25000 && props.replacementCost <= 50000) {
    chartSource = [
      {
        val1: '$ 50,000',
        cost1: '$ 271',
        costNum1: 271,
        color1: 'orange',

        val2: '$ 75,000',
        cost2: '$ 316',
        costNum2: 316,
        color2: '#ade6d8',

        val3: '$ 100,000',
        cost3: '$ 349',
        costNum3: 349,
        color3: '#ade6d8',

        val4: '$ 125,000',
        cost4: '$ 366',
        costNum4: 366,
        color4: '#ade6d8'
      }
    ]
  } else if (props.replacementCost > 50000 && props.replacementCost <= 75000) {
    chartSource = [
      {
        val1: '$ 50,000',
        cost1: '$ 271',
        costNum1: 271,
        color1: '#ade6d8',

        val2: '$ 75,000',
        cost2: '$ 316',
        costNum2: 316,
        color2: 'orange',

        val3: '$ 100,000',
        cost3: '$ 349',
        costNum3: 349,
        color3: '#ade6d8',

        val4: '$ 125,000',
        cost4: '$ 366',
        costNum4: 366,
        color4: '#ade6d8'
      }
    ]
  } else if (props.replacementCost > 75000 && props.replacementCost <= 100000) {
    chartSource = [
      {
        val1: '$ 50,000',
        cost1: '$ 271',
        costNum1: 271,
        color1: '#ade6d8',

        val2: '$ 75,000',
        cost2: '$ 316',
        costNum2: 316,
        color2: '#ade6d8',

        val3: '$ 100,000',
        cost3: '$ 349',
        costNum3: 349,
        color3: 'orange',

        val4: '$ 125,000',
        cost4: '$ 366',
        costNum4: 366,
        color4: '#ade6d8'
      }
    ]
  } else if (
    props.replacementCost > 100000 &&
    props.replacementCost <= 125000
  ) {
    chartSource = [
      {
        val1: '$ 50,000',
        cost1: '$ 271',
        costNum1: 271,
        color1: '#ade6d8',

        val2: '$ 75,000',
        cost2: '$ 316',
        costNum2: 316,
        color2: '#ade6d8',

        val3: '$ 100,000',
        cost3: '$ 349',
        costNum3: 349,
        color3: '#ade6d8',

        val4: '$ 125,000',
        cost4: '$ 366',
        costNum4: 366,
        color4: 'orange'
      }
    ]
  } else if (
    props.replacementCost > 125000 &&
    props.replacementCost <= 130000
  ) {
    chartSource = [
      {
        val1: '$ 125,000',
        cost1: '$ 366',
        costNum1: 366,
        color1: 'orange',

        val2: '$ 150,000',
        cost2: '$ 389',
        costNum2: 389,
        color2: '#ade6d8',

        val3: '$ 200,000',
        cost3: '$ 425',
        costNum3: 425,
        color3: '#ade6d8',

        val4: '$ 250,000',
        cost4: '$ 451',
        costNum4: 451,
        color4: '#ade6d8'
      }
    ]
  } else if (
    props.replacementCost > 130000 &&
    props.replacementCost <= 160000
  ) {
    chartSource = [
      {
        val1: '$ 125,000',
        cost1: '$ 366',
        costNum1: 366,
        color1: '#ade6d8',

        val2: '$ 150,000',
        cost2: '$ 389',
        costNum2: 389,
        color2: 'orange',

        val3: '$ 200,000',
        cost3: '$ 425',
        costNum3: 425,
        color3: '#ade6d8',

        val4: '$ 250,000',
        cost4: '$ 451',
        costNum4: 451,
        color4: '#ade6d8'
      }
    ]
  } else if (
    props.replacementCost > 160000 &&
    props.replacementCost <= 220000
  ) {
    chartSource = [
      {
        val1: '$ 125,000',
        cost1: '$ 366',
        costNum1: 366,
        color1: '#ade6d8',

        val2: '$ 150,000',
        cost2: '$ 389',
        costNum2: 389,
        color2: '#ade6d8',

        val3: '$ 200,000',
        cost3: '$ 425',
        costNum3: 425,
        color3: 'orange',

        val4: '$ 250,000',
        cost4: '$ 451',
        costNum4: 451,
        color4: '#ade6d8'
      }
    ]
  } else if (props.replacementCost > 220000) {
    chartSource = [
      {
        val1: '$ 125,000',
        cost1: '$ 366',
        costNum1: 366,
        color1: '#ade6d8',

        val2: '$ 150,000',
        cost2: '$ 389',
        costNum2: 389,
        color2: '#ade6d8',

        val3: '$ 200,000',
        cost3: '$ 425',
        costNum3: 425,
        color3: '#ade6d8',

        val4: '$ 250,000',
        cost4: '$ 451',
        costNum4: 451,
        color4: 'orange'
      }
    ]
  }

  // console.log(chartSource)

  return (
    <main>
      <h3>Flood Insurance Premium ($/year)</h3>

      <div>
        <table
          id="lowRiskChart"
          class="charts-css column show-labels show-primary-axis show-5-secondary-axes data-spacing-10 labels-align-center"
        >
          <thead>
            <tr>
              <th scope="col"> Year </th> <th scope="col"> Progress </th>
            </tr>
          </thead>{' '}
          <tbody>
            <tr>
              <th scope="row"> {chartSource[0]?.val1} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum1} / (${chartSource[0]?.costNum4} + 50 ) )`,
                  '--color': `${chartSource[0]?.color1}`
                }}
              >
                <span class="data"> {chartSource[0]?.cost1} </span>
                <span class="tooltip">
                  {' '}
                  Cost for {chartSource[0]?.val1} replacement value.
                </span>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource[0]?.val2} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum2} / (${chartSource[0]?.costNum4} + 50 ) )`,
                  '--color': `${chartSource[0]?.color2}`
                }}
              >
                <span class="data"> {chartSource[0]?.cost2} </span>
                <span class="tooltip">
                  {' '}
                  Cost for {chartSource[0]?.val2} replacement value.
                </span>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource[0]?.val3} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum3} / (${chartSource[0]?.costNum4} + 50  ) )`,
                  '--color': `${chartSource[0]?.color3}`
                }}
              >
                <span class="data"> {chartSource[0]?.cost3} </span>
                <span class="tooltip">
                  {' '}
                  Cost for {chartSource[0]?.val3} replacement value.
                </span>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource[0]?.val4} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum4} / (${chartSource[0]?.costNum4} + 50 ) )`,
                  '--color': `${chartSource[0]?.color4}`
                }}
              >
                <span class="data"> {chartSource[0]?.cost4} </span>
                <span class="tooltip">
                  {' '}
                  Cost for {chartSource[0]?.val4} replacement value.
                </span>
              </td>
            </tr>{' '}
          </tbody>
        </table>
        <div class="primary-axis">
          {' '}
          <i>Building Replacement Cost ($)</i>{' '}
        </div>
      </div>
    </main>
  )
}

export default LineGraphLowRisk
