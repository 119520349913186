import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import firebase from 'gatsby-plugin-firebase'
import { useDomains } from '../eligibility-hooks'
import { Form, Input, Button, Typography, Select } from 'antd'
import './screening-styles.css'
import { ParallaxContainer } from '../styled-eligibility'
import ElegibilityContainer from '../ElegibilityContainer'
import { normalizePhoneNumberInput } from '../../../utils/form/normalize'

const { Title } = Typography

const Screening = props => {
  Screening.propTypes = {
    selected: PropTypes.object,
    setShowRecommendation: PropTypes.func,
    setShowScreening: PropTypes.func
  }
  // const { pubDomain, devDomain } = useDomains()
  const {
    selected,
    selectedCounty,
    setShowRecommendation,
    setShowScreening
  } = props

  const [exists] = useState(false)
  const [floodRiskData, setFloodRiskData] = useState(null)

  // console.log('PROPS => ', props)

  /**
   * Check if cache exist in the session storage. If it exists, we don't need
   * them to refill out the form. Set setShowRecommendation to true skip to
   * proceed to recommendations.
   */
  useEffect(() => {
    const name = window.sessionStorage.getItem('name')
    const email = window.sessionStorage.getItem('email')
    const phone = window.sessionStorage.getItem('phone')
    const cacheExists = name !== null || email !== null || phone !== null
    if (cacheExists) {
      setShowScreening(false)
      setShowRecommendation(true)
    }
  })

  /**
   * Fetch the flood risk data when the component mounts
   */
  useEffect(() => {
    if (selectedCounty) {
      firebase
        .firestore()
        .collection(`properties/Florida/counties/${selectedCounty}/properties`)
        .where('FULL_ADD', '==', selected)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            if (doc) {
              const data = doc.data()
              setFloodRiskData(data)
            }
          })
        })
    }
  }, [selectedCounty])

  /**
   * Handle form submit
   */
  const handleOnSubmit = values => {
    if (floodRiskData) {
      const { phoneNumber, email, name, insuranceQuote } = values

      const phone = phoneNumber
      const timestamp = new Date().toLocaleString('en-US', {
        timeZone: 'America/New_York'
      })

      const screeningFormData = {
        name,
        email,
        phone,
        insuranceQuote,
        timestamp
      }

      const screeningFormPayload = {
        to: screeningFormData.email,
        bcc: [
          'info@nofloodflorida.com',
          'sophia@nofloodflorida.com',
          'nofloodflorida+7t5d67e@nofloodflorida-org.myfreshworks.com'
        ],
        template: {
          name: 'screeningFormTemplate',
          data: {
            address: selected,
            ...screeningFormData,
            floodRiskData
          }
        }
      }

      console.log('screening form payload: ', screeningFormPayload)

      firebase
        .firestore()
        .collection('screeningForm')
        .add(screeningFormPayload)
        .then(res => {
          // Add the name and email to session so they don't have to re-submit form.
          window.sessionStorage.setItem('name', `${name}`)
          window.sessionStorage.setItem('email', `${email}`)
          window.sessionStorage.setItem('phone', `${phone}`)

          console.log('res id: ', res.id)

          const payloadWithId = {
            id: res.id,
            ...screeningFormPayload
          }

          console.log('payload: ', payloadWithId)
          // Add Update the document with it's document ID
          firebase
            .firestore()
            .collection('screeningForm')
            .doc(res.id)
            .set(payloadWithId)
            .then(() => {
              setShowRecommendation(true)
              setShowScreening(false)
            })
        })
        .catch(error => console.log('Error adding document:', error))
    }
  }

  if (exists) {
    return 'Loading recommendation.'
  }

  return (
    <ElegibilityContainer>
      <ParallaxContainer>
        <div style={{ maxWidth: '450px' }}>
          <Title level={3} style={{ color: 'white', textAlign: 'center' }}>
            You are one step away from getting your{' '}
            <b style={{ color: '#fff' }}>FREE</b> screening results!
          </Title>
        </div>

        <Form
          id="screeningForm"
          action="javascript:void(0);"
          onFinish={handleOnSubmit}
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input your name.' }]}
          >
            <Input placeholder="Name" size="large" name="name" />
          </Form.Item>

          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input a valid email address.'
              }
            ]}
          >
            <Input
              placeholder="Email Address"
              size="large"
              name="email"
              onInput={e =>
                (e.target.value = ('' + e.target.value).toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item
            normalize={(value, prevValue, prevValues) =>
              normalizePhoneNumberInput(value, prevValue)
            }
            label="Phone Number"
            name="phoneNumber"
            rules={[
              { required: true, message: 'Please input your phone number.' }
            ]}
          >
            <Input placeholder="(xxx) xxx-xxxx" size="large" name="phone" />
          </Form.Item>

          <Form.Item
            label={
              <a
                target="_blank"
                href="/flood-insurance"
              >
                Interested in Flood Insurance? (Click to Learn More)
              </a>
            }
            name="insuranceQuote"
            rules={[{ required: true, message: 'Please select Yes or No.' }]}
          >
            <Select>
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              block
              size="large"
              style={{
                color: 'black',
                fontWeight: 'bold',
                background: '#c7ae4a',
                border: 'none'
              }}
            >
              Submit
            </Button>
            <br />
            <br />
            <div
              style={{
                width: '100%',
                margin: '0 auto',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                type="text"
                size="small"
                onClick={() => setShowScreening(false)}
                style={{ color: 'white' }}
              >
                BACK
              </Button>
            </div>
          </Form.Item>
        </Form>
      </ParallaxContainer>
    </ElegibilityContainer>
  )
}

export default Screening
