import React, { useState, useRef } from 'react'
import firebase from 'gatsby-plugin-firebase'
import {
  Form,
  Input,
  Button,
  Typography,
  Select,
  Upload,
  Radio,
  Divider,
  message
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import {
  Wrapper,
  Container
} from '../../../styled-components/styled-components'
import { normalizePhoneNumberInput } from '../../../utils/form/normalize'
import './orderForm.css'
import SuccessModal from '../../../components/SuccessModal'
import { Link } from 'gatsby'

const { Title } = Typography
const { Option } = Select

const doUploadFile = (fileToUpload, folder) => {
  return new Promise((resolve, reject) => {
    let progress = 0

    // File or Blob named nameOfFile.jpg
    const { originFileObj } = fileToUpload
    const file = originFileObj

    // Create the file metadata with the given file type
    const { type } = file
    const metadata = { contentType: type }

    // Create a reference to 'images/nameOfFile.jpg'
    const storageRef = firebase.storage().ref()

    // Upload file and metadata to the object 'images/nameOfFile.jpg'
    const path = folder + '/'
    storageRef
      .child(path + `${Date.now()}-${file.name}`)
      .put(file, metadata)
      .then(uploadTaskSnapshot => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        progress =
          (uploadTaskSnapshot.bytesTransferred /
            uploadTaskSnapshot.totalBytes) *
          100
        console.log('Upload is ' + progress + '% done')

        // console.log('uploadTaskSnapshot: ', uploadTaskSnapshot);
        // Upload completed successfully, now we can get the download URL
        uploadTaskSnapshot.ref.getDownloadURL().then(downloadUrl => {
          resolve(downloadUrl)
          // downloadURL = downloadUrl
          // resolve({ downloadURL, progress })
        })
      })
  })
}

const OrderForm = () => {
  const formRef = useRef(null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleOnSubmit = values => {
    values.timestamp = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York'
    })
    const { elevationCertificate } = values

    if (elevationCertificate) {
      const promises = []
      elevationCertificate.forEach(certificate => {
        promises.push(doUploadFile(certificate, 'orderForm'))
      })
      Promise.all(promises).then(downloadUrls => {
        console.log('downloadUrls:', downloadUrls)
        delete values.elevationCertificate // replacing with downloadUrls
        values.downloadUrls = downloadUrls
        submitDataToFirestore(values)
      })
    } else {
      delete values.elevationCertificate
      values.downloadUrls = []
      submitDataToFirestore(values)
    }
  }

  const submitDataToFirestore = values => {
    // replace all undefined values with '' to make firestore happy
    const convertedString = JSON.stringify(values, function (key, value) {
      return value === undefined ? '' : value
    })
    const updatedValues = JSON.parse(convertedString)
    const quoteSubmitObject = {
      to: updatedValues.email,
      bcc: [
        'info@nofloodflorida.com',
        'sophia@nofloodflorida.com',
        'nofloodflorida+7t5d67e@nofloodflorida-org.myfreshworks.com'
      ],
      template: {
        name: 'uploadQuoteTemplate',
        data: {
          ...updatedValues
        }
      }
    }

    firebase
      .firestore()
      .collection('orderForm')
      .add(quoteSubmitObject)
      .then(() => {
        setIsModalVisible(true)
        formRef.current.resetFields()
      })
      .catch(error => console.log('Error adding document:', error))
  }

  const normFile = e => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }
  const handleOk = () => setIsModalVisible(false)
  const handleCancel = () => setIsModalVisible(false)

  return (
    <Wrapper>
      <Container>
        <Title level={3} style={{ textAlign: 'center' }}>
          LOMA Flood Risk Package Request
        </Title>
        <SuccessModal
          content="Form Submitted Successfully! Please check your email and/or spam folder for a confirmation."
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          handleOk={handleOk}
        />
        <Form
          onFinish={handleOnSubmit}
          ref={formRef}
          id="order-form"
          className="box-shadow round"
          layout="vertical"
          initialValues={{ singleFamily: 'yes', foundation: 'slab on grade' }}
        >
          <Form.Item
            label="Contact Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input your name.'
              }
            ]}
          >
            <Input className="round" size="large" placeholder="John Doe" />
          </Form.Item>

          <Form.Item
            label="Complete Property Address (Physical address of the property receiving the LOMA)"
            name="address"
            rules={[
              {
                required: true,
                message: 'Please input the full property address.'
              }
            ]}
          >
            <Input
              className="round"
              size="large"
              placeholder="123 Main St, Tampa, FL 33624"
              onInput={e =>
                (e.target.value = ('' + e.target.value).toUpperCase())
              }
            />
          </Form.Item>

          <Form.Item label="Year Built" name="year">
            <Input className="round" size="large" placeholder="2005" />
          </Form.Item>

          <Form.Item
            label="Single Family Home"
            name="singleFamily"
            help="We are currently taking requests of single family homes only."
          >
            <Select>
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Foundation Type" name="foundation">
            <Select defaultValue="slab on grade">
              <Option value="slab on grade">Slab on Grade</Option>
              <Option value="basement">Basement</Option>
              <Option value="stem wall">Stem Wall</Option>
              <Option value="stilts">Stilts</Option>
              <Option value="piers">Piers</Option>
              <Option value="crawl space">Crawl Space</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="elevationCertificate"
            label={
              <div>
                <span>If you already have an </span>
                <Link to={'/elevation-certificate'}>
                  {' '}
                  Elevation Certificate
                </Link>
                <span> then upload it here (JPG or PDF):</span>
              </div>
            }
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              name="elevationCertificate"
              // action="/upload.do"
              listType="picture"
              multiple={false}
              // onChange={handleOnUploadChange}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            normalize={(value, prevValue, prevValues) =>
              normalizePhoneNumberInput(value, prevValue)
            }
            label="Phone"
            name="phone"
            rules={[
              {
                required: true,
                message: 'Please input a valid email.'
              }
            ]}
          >
            <Input
              className="round"
              size="large"
              placeholder="(813) 213-0641"
            />
          </Form.Item>

          <Form.Item
            label="Your Email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input a valid email.'
              }
            ]}
          >
            <Input
              className="round"
              size="large"
              placeholder="johndoe@email.com"
              onInput={e =>
                (e.target.value = ('' + e.target.value).toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item
            label="Confirm Your Email"
            name="emailConfirm"
            dependencies={['email']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please enter a valid email!',
                type: 'email'
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('email') === value) {
                    return Promise.resolve()
                  }
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject('Emails must match!')
                }
              })
            ]}
          >
            <Input
              className="round"
              size="large"
              placeholder="johndoe@email.com"
              onInput={e =>
                (e.target.value = ('' + e.target.value).toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item
            label={
              <a
                target="_blank"
                href="/flood-insurance-quote"
              >
                Interested in Flood Insurance Quotes? (Click to Learn More)
              </a>
            }
            name="insuranceQuote"
            rules={[{ required: true, message: 'Please select Yes or No.' }]}
          >
            <Select>
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="How did you hear about us?" name="referral">
            <Radio.Group>
              <Radio value="social media">Social Media</Radio>
              <Radio value="friends">Friends</Radio>
              <Radio value="realtor">Realtor</Radio>
              <Radio value="tv ads">TV Ads</Radio>
              <Radio value="radio ads">Radio Ads</Radio>
              <Radio value="other">Other</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" shape="round">
              Submit
            </Button>
          </Form.Item>
        </Form>

        <Divider />

        <p>
          Please note: Structures which are considered by FEMA to be built on
          &quot;Fill&quot; and &quot;Post-Firm&quot; construction have
          additional fees imposed by FEMA. We can help you determine if your
          property falls within this category. Contact us today.
        </p>
        <div style={{ marginBottom: '50px' }} />
      </Container>
    </Wrapper>
  )
}

export default OrderForm
