import styled from 'styled-components'

export const BulletLabel = styled.span`
  font-weight: 700;
  font-size: 18px;
  margin-right: 20px;
  margin-left: 5px;
`

export const ResultsContainer = styled.div`
  padding: 30px 0 30px 0;
`

export const ImgContainer = styled.div`
  padding-top: 20px;
  @media only screen and (max-width: 970px) {
    padding-top: 50px;
  }
`

export const Wrapper = styled.div`
  line-height: 1.6em;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  overflow: inherit !important;
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1440px;
`

export const LoadingText = styled.h2`
  color: white;
  font-size: 27px;
  align-self: center;
  margin-top: 10px;
`

export const LoadingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: 0 auto;
  height: 420px;
`

/* FAQ */
export const FaqTitle = styled.div`
  border: none;
  padding: 4px 20px 4px 20px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.5em;
`

export const FaqWrapper = styled.div`
  border: none;
`

export const FaqContainer = styled.div``

export const FaqHeader = styled.div`
  /* background-color: rgba(234, 234, 234, 0.90); */
`

export const FaqBody = styled.div`
  /* background-color: rgba(234, 234, 234, 0.90); */
  margin-bottom: 20px;
  padding: 20px 20px;
`

export const FaqFooter = styled.div`
  /* background-color: rgba(234, 234, 234, 0.90); */
  padding: 20px 20px 10px 20px;
`

export const FaqList = styled.ul`
  padding: 0;
  margin: 0;
`

export const FaqListItem = styled.li`
  list-style-type: none;
  margin-bottom: 10px;
`

/**
 * Styles for Category pills.
 */

export const HighBlock = styled.div`
  transform: ${props => (props.rating == true ? 'scale(1.1)' : 'none')};
  box-shadow: ${props =>
    props.rating == true
      ? '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)'
      : '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'};
  border: ${props => (props.rating ? '3px solid #ff4d4f' : 'none')};
  background-color: #52c41a;
  padding: 5px 20px 5px 20px;
  width: 100%;
  color: #000;
`

export const MedBlock = styled.div`
  transform: ${props => (props.rating == true ? 'scale(1.1)' : 'none')};
  box-shadow: ${props =>
    props.rating == true
      ? '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)'
      : '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'};
  border: ${props => (props.rating ? '3px solid #ff4d4f' : 'none')};
  background-color: #ffec3d;
  padding: 5px 20px 5px 20px;
  width: 100%;
  color: #000;
`

export const LowBlock = styled.div`
  transform: ${props => (props.rating == true ? 'scale(1.1)' : 'none')};
  box-shadow: ${props =>
    props.rating == true
      ? '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)'
      : '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'};
  border: ${props => (props.rating ? '4px solid #a8071a' : 'none')};
  background-color: #ff4d4f;
  padding: 5px 20px 5px 20px;
  width: 100%;
  color: #000;
`
