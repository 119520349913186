import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100% !important;
  padding: 20px 20px 20px 20px;
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1080px;
  // width: 1080px;
`

export const ContainerImg = styled.div`
  margin: 0 auto;
  max-width: 800px;

  // width: 1080px;
`
