import React from 'react'
import { FaCheck } from 'react-icons/fa'
import styled from 'styled-components'
import LikelihoodAnalysis from '../LikelihoodAnalysis/LikelihoodAnalysis.js'
import { BulletLabel } from '../StyledComponents'
import { Row, Col, Typography } from 'antd'

const { Link, Text, Paragraph } = Typography

const InfoBox = props => {
  const {
    LOMARating,
    selectedAddress,
    propertyData,
    suggestion,
    basisOfDetermination
  } = props

  return (
    <Row
      gutter={[0, 20]}
      style={{ marginTop: '20px', padding: '0 10px 0 10px' }}
    >
      <Col span={24}>
        <FaCheck />
        <BulletLabel>Property Address:</BulletLabel>
        <span style={{ fontWeight: '500' }}>{selectedAddress}</span>
      </Col>
      <Col span={24}>
        <FaCheck />
        <BulletLabel>FEMA Flood Zone:</BulletLabel>
        <span style={{ fontWeight: '500' }}>{propertyData.FEMA_ZONE}</span>
      </Col>
      <Col span={24}>
        <FaCheck />
        <LikelihoodAnalysis
          propertyData={propertyData}
          LOMARating={LOMARating}
          suggestion={suggestion}
        />
      </Col>
      <Col span={24}>
        <Row style={{ marginBottom: '20px' }}>
          <FaCheck />
          <BulletLabel>Basis of this determination:</BulletLabel>
        </Row>
        <Row gutter={[0, 10]}>
          {basisOfDetermination.map((item, idx) => (
            <Col key={idx} span={24} offset={1}>
              <FaCheck /> <span style={{ fontWeight: '400' }}>{item.info}</span>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default InfoBox
