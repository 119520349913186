import React, { useState, useEffect, createContext } from 'react'
// const React = require('react')
import firebase from 'gatsby-plugin-firebase'

export const AuthContext = createContext({})
// exports.AuthContext = AuthContext

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState()

  // console.log('current user', user)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => setUser(user))
    // console.log('did auth state change occur?', user)
  }, [])

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  )
}

// export const useAuthState = () => {
//   const auth = useContext(AuthContext)
//   return { ...auth, isAuthenticated: auth.user != null }
// }

export default AuthProvider
