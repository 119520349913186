import React from 'react'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import { Footer } from '../../components/Footer/Footer'
import SectionHeader from './components/SectionHeader'
import FaqSection from './components/FaqSection'

const Loma = () => {
  return (
    <LandingLayout>
      <SectionHeader />
      <FaqSection />
      <Footer />
    </LandingLayout>
  )
}

export default Loma
