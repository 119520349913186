import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../../context/auth'
import { Typography, Row, Col, Descriptions } from 'antd'
import firebase from 'gatsby-plugin-firebase'
import BarGraphHydrologic from '../Soils/SoilCharts/BarGraphHydrologic'
import BarGraphFloodFrequency from '../Soils/SoilCharts/BarGraphFloodFrequency'
import BarGraphDrainageClass from '../Soils/SoilCharts/BarGraphDrainageClass'

const { Title, Paragraph, Text } = Typography

const SoilProcess = props => {
  // console.log(props.nffID)
  // console.log(props.countyCurrent)

  const [soilData, setSoilData] = useState({
    data: {},
    loading: true
  })

  // console.log(soilData.loading)
  // soilData.data = {}
  // soilData.loading = true

  const { user } = useContext(AuthContext)

  /**
   * Fetch the soil data associated with the NFF_ID for the property of interest
   */
  useEffect(() => {
    if (user.uid) {
      firebase
        .firestore()
        .collection(`properties/Florida/counties/${props.countyCurrent}/soils`)
        .where('NFF_ID', '==', props.nffID)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            if (doc) {
              const data = doc.data()
              setSoilData({
                data: data,
                loading: false
              })
            }
          })
        })
    }
  }, [props.nffID])

  // console.log(soilData.data)
  // console.log(soilData.loading)

  // extract the hydrologic group designation

  // console.log(soilData.data.HydroGroup)
  let HYGrpA_pct = parseFloat(soilData.data.HYD_A).toFixed(0)
  let HYGrpA_D_pct = parseFloat(soilData.data.HYD_A_D).toFixed(0)
  let HYGrpB_pct = parseFloat(soilData.data.HYD_B).toFixed(0)
  let HYGrpB_D_pct = parseFloat(soilData.data.HYD_B_D).toFixed(0)
  let HYGrpC_pct = parseFloat(soilData.data.HYD_C).toFixed(0)
  let HYGrpC_D_pct = parseFloat(soilData.data.HYD_C_D).toFixed(0)
  let HYGrpD_pct = parseFloat(soilData.data.HYD_D).toFixed(0)
  let HYGrpWTR_pct = parseFloat(soilData.data.HYD_WTR).toFixed(0)

  let FreqFlood = parseFloat(soilData.data.FREQ)
  let NoneFlood = parseFloat(soilData.data.NONE)
  let OccFlood = parseFloat(soilData.data.OCC)
  let RareFlood = parseFloat(soilData.data.RARE)
  let VeryFreqFlood = parseFloat(soilData.data.VERY_FREQ)
  let VeryRare = parseFloat(soilData.data.VERY_RARE)

  let ExcDrain = parseFloat(soilData.data.EX_DRAIN)
  let ModWellDrain = parseFloat(soilData.data.MOD_WELL_DRAIN)
  let PoorDrain = parseFloat(soilData.data.POOR_DRAIN)
  let SomeExcDrain = parseFloat(soilData.data.SOME_EX_DRAIN)
  let SomePoorDrain = parseFloat(soilData.data.SOME_POOR_DRAIN)
  let VeryPoorDrain = parseFloat(soilData.data.VERY_POOR_DRAIN)
  let WellDrain = parseFloat(soilData.data.WELL_DRAIN)

  return (
    <>
      {soilData.data.NFF_ID == props.nffID ? (
        <>
          <Row textAlign="left" gutter={[5, 10]} align="middle">
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <BarGraphHydrologic
                HydA={HYGrpA_pct}
                HydA_D={HYGrpA_D_pct}
                HydB={HYGrpB_pct}
                HydB_D={HYGrpB_D_pct}
                HydC={HYGrpC_pct}
                HydC_D={HYGrpC_D_pct}
                HydD={HYGrpD_pct}
                HydWTR={HYGrpWTR_pct}
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={24}
              align="center"
              style={{ padding: '0px 20px 0px 20px' }}
            >
              <Choose>
                <When
                  condition={
                    parseFloat(HYGrpA_pct) + parseFloat(HYGrpA_D_pct) >= 50
                  }
                >
                  <Title level={4} style={{ color: '#388E8E' }}>
                    Good Hydrologic Soil Group
                  </Title>
                  <Paragraph>
                    Majority of the soils are type A which are characterized as
                    low runoff potential and high infiltration rates.
                  </Paragraph>
                </When>
                <When
                  condition={
                    parseFloat(HYGrpB_pct) + parseFloat(HYGrpB_D_pct) >= 50
                  }
                >
                  <Title level={4} style={{ color: '#388E8E' }}>
                    Moderate Hydrologic Soil Group
                  </Title>
                  <Paragraph>
                    Majority of the soils are associated with Group B, soils
                    with moderate runoff potential and good infiltration rates.
                  </Paragraph>
                </When>
                <When
                  condition={
                    parseFloat(HYGrpC_pct) +
                      parseFloat(HYGrpC_D_pct) +
                      parseFloat(HYGrpD_pct) >=
                    50
                  }
                >
                  <Title level={4} style={{ color: '#388E8E' }}>
                    Poor Hydrologic Soil Group
                  </Title>
                  <Paragraph>
                    Majority of the soils are associated with Group C and D,
                    soils with moderately high and high runoff potential; lower
                    infiltration rates.
                  </Paragraph>
                </When>
              </Choose>
              <Choose>
                <When condition={parseFloat(HYGrpWTR_pct) > 0}>
                  <Title level={4} style={{ color: '#388E8E' }}>
                    Water Feature near Property
                  </Title>
                  <Paragraph>
                    A water feature may exist on in close proximity to this
                    property.
                  </Paragraph>
                </When>
              </Choose>
            </Col>
          </Row>

          <Row textAlign="left" gutter={[5, 10]} align="middle">
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <BarGraphFloodFrequency
                FreqFlood={FreqFlood}
                NoneFlood={NoneFlood}
                OccFlood={OccFlood}
                RareFlood={RareFlood}
                VeryFreqFlood={VeryFreqFlood}
                VeryRare={VeryRare}
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={24}
              align="center"
              style={{ padding: '0px 20px 0px 20px' }}
            >
              <Choose>
                <When condition={parseFloat(NoneFlood) == 100}>
                  <Title level={4} style={{ color: '#A7A9AB' }}>
                    Flooding Frequency may not be defined for the soils in this
                    area.
                  </Title>
                </When>
                <When condition={parseFloat(NoneFlood) >= 50}>
                  <Title level={4} style={{ color: '#A7A9AB' }}>
                    Flooding Frequency may not be defined for some soils in this
                    area.
                  </Title>
                  <If
                    condition={parseFloat(VeryRare) + parseFloat(RareFlood) > 0}
                  >
                    <Paragraph>
                      The remainder of the property shows low flood frequency.
                    </Paragraph>
                  </If>
                  <If
                    condition={
                      parseFloat(VeryRare) + parseFloat(RareFlood) == 0
                    }
                  >
                    <Paragraph>
                      The remainder of the property could experience some
                      frequency of flooding or standing water.
                    </Paragraph>
                  </If>
                </When>
                <When
                  condition={
                    parseFloat(VeryRare) +
                      parseFloat(RareFlood) +
                      parseFloat(NoneFlood) >=
                    60
                  }
                >
                  <Title level={4} style={{ color: '#388E8E' }}>
                    Low Flood Frequency
                  </Title>
                  <Paragraph>
                    The soils associated with this property are classified with
                    a low flood frequency, indicating a lower likilihood to
                    experience seasonal inundation.
                  </Paragraph>
                </When>
                <When
                  condition={
                    parseFloat(FreqFlood) +
                      parseFloat(OccFlood) +
                      parseFloat(VeryFreqFlood) >=
                    60
                  }
                >
                  <Title level={4} style={{ color: '#d10000' }}>
                    High Flood Frequency
                  </Title>
                  <Paragraph>
                    The soils associated with this property are classified with
                    a higher flood frequency, indicating a possible likilihood
                    to experience seasonal inundation.
                  </Paragraph>
                </When>
              </Choose>
            </Col>
          </Row>

          <Row textAlign="left" gutter={[5, 10]} align="middle">
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <BarGraphDrainageClass
                ExcDrain={ExcDrain}
                ModWellDrain={ModWellDrain}
                PoorDrain={PoorDrain}
                SomeExcDrain={SomeExcDrain}
                SomePoorDrain={SomePoorDrain}
                VeryPoorDrain={VeryPoorDrain}
                WellDrain={WellDrain}
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={24}
              align="center"
              style={{ padding: '0px 20px 0px 20px' }}
            >
              <Choose>
                <When
                  condition={
                    parseFloat(ExcDrain) + parseFloat(SomeExcDrain) >= 50
                  }
                >
                  <Title level={4} style={{ color: '#388E8E' }}>
                    Good Drainage
                  </Title>
                  <Paragraph>
                    Majority of the soils are characterized with excessive
                    drainage capacity which is good for reducing flood risk.
                  </Paragraph>
                </When>
                <When
                  condition={
                    parseFloat(WellDrain) +
                      parseFloat(ModWellDrain) +
                      parseFloat(SomePoorDrain) >=
                    50
                  }
                >
                  <Title level={4} style={{ color: '#388E8E' }}>
                    Fair Drainage
                  </Title>
                  <Paragraph>
                    Majority of the soils are characterized with moderate to
                    poor drainage capacity.
                  </Paragraph>
                </When>
                <When
                  condition={
                    parseFloat(PoorDrain) + parseFloat(VeryPoorDrain) >= 50
                  }
                >
                  <Title level={4} style={{ color: '#d10000' }}>
                    Poor Drainage
                  </Title>
                  <Paragraph>
                    Majority of the soils are characterized with poor drainage
                    capacity which could increase flood risk.
                  </Paragraph>
                </When>
              </Choose>
            </Col>
          </Row>

          {/* <Row textAlign="left" gutter={[5, 10]}>
            <Descriptions
              title="Hydrologic Group"
              labelStyle={{
                // backgroundColor: '#388E8E',
                backgroundColor: '#E8E8E8',
                // color: '#fff',
                fontWeight: 500
                // borderColor: '#fff',
                // borderStyle: 'solid'
              }}
              contentStyle={
                {
                  // backgroundColor: 'PapayaWhip'
                  // borderColor: '#fff',
                  // borderStyle: 'solid'
                }
              }
              bordered
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="A">{HYGrpA_pct}</Descriptions.Item>
              <Descriptions.Item label="A/D">{HYGrpA_D_pct}</Descriptions.Item>
              <Descriptions.Item label="B">{HYGrpB_pct}</Descriptions.Item>
              <Descriptions.Item label="B/D">{HYGrpB_D_pct}</Descriptions.Item>
              <Descriptions.Item label="C">{HYGrpC_pct}</Descriptions.Item>
              <Descriptions.Item label="C/D">{HYGrpC_D_pct}</Descriptions.Item>
              <Descriptions.Item label="D">{HYGrpD_pct}</Descriptions.Item>
              <Descriptions.Item label="Water">
                {HYGrpWTR_pct}
              </Descriptions.Item>
            </Descriptions>
            <br />
            <br />

            <Descriptions
              title="Flooding Frequency"
              labelStyle={{
                // backgroundColor: '#388E8E',
                backgroundColor: '#E8E8E8',
                // color: '#fff',
                fontWeight: 500
                // borderColor: '#fff',
                // borderStyle: 'solid'
              }}
              contentStyle={
                {
                  // backgroundColor: 'PapayaWhip'
                  // borderColor: '#fff',
                  // borderStyle: 'solid'
                }
              }
              bordered
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Frequent">
                {FreqFlood}
              </Descriptions.Item>
              <Descriptions.Item label="None">{NoneFlood}</Descriptions.Item>
              <Descriptions.Item label="Occasional">
                {OccFlood}
              </Descriptions.Item>
              <Descriptions.Item label="Rare">{RareFlood}</Descriptions.Item>
              <Descriptions.Item label="Very frequent">
                {VeryFreqFlood}
              </Descriptions.Item>
              <Descriptions.Item label="Very rare">
                {VeryRare}
              </Descriptions.Item>
            </Descriptions>
            <br />

            <Descriptions
              title="Drainage Class"
              labelStyle={{
                // backgroundColor: '#388E8E',
                backgroundColor: '#E8E8E8',
                // color: '#fff',
                fontWeight: 500
                // borderColor: '#fff',
                // borderStyle: 'solid'
              }}
              contentStyle={
                {
                  // backgroundColor: 'PapayaWhip'
                  // borderColor: '#fff',
                  // borderStyle: 'solid'
                }
              }
              bordered
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Excessively drained">
                {ExcDrain}
              </Descriptions.Item>
              <Descriptions.Item label="Moderately well drained">
                {ModWellDrain}
              </Descriptions.Item>
              <Descriptions.Item label="Poorly drained">
                {PoorDrain}
              </Descriptions.Item>
              <Descriptions.Item label="Somewhat excessively drained">
                {SomeExcDrain}
              </Descriptions.Item>
              <Descriptions.Item label="Somewhat poorly drained">
                {SomePoorDrain}
              </Descriptions.Item>
              <Descriptions.Item label="Very poorly drained">
                {VeryPoorDrain}
              </Descriptions.Item>
              <Descriptions.Item label="Well drained">
                {WellDrain}
              </Descriptions.Item>
            </Descriptions>
            <br />
          </Row> */}
        </>
      ) : (
        <h3>Soil processing pending... updates to come.</h3>
      )}
    </>
  )
}

export default SoilProcess
