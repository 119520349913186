import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Row, Col, Button, Typography } from 'antd'
import surveyIncentiveSvg from '../../../../assets/images/surveyincentive01.svg'

const { Title, Paragraph } = Typography

const HighRiskLearnMore = ({ disclaimer, img }) => {
  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <Row>
          <Col xxl={14} xl={14} lg={24} md={24} sm={24} xs={24}>
            <div>
              <Title level={3} style={{ color: '#fff' }}>
                Want to Know More About your Property’s Flood Risk?
              </Title>
              <div>
                <Paragraph style={{ color: 'white', fontWeight: '400' }}>
                  Learn more about your detailed flood risk, floodplain maps,
                  building and structure impacts, and flood insurance premium
                  estimates. Order your customized <B>Flood Analysis Memo</B>{' '}
                  today!
                </Paragraph>
              </div>
              <div>
                <p>
                  <em style={{ fontWeight: 500 }}>{disclaimer}</em>
                </p>
              </div>
            </div>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ textAlign: 'center' }}
          >
            <div style={{ textAlign: 'center' }}>
              <img src={img} style={{ height: '120px', width: '100px' }} />
            </div>
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Link to="/fam">
                  <Button
                    className="round secondary-btn"
                    style={{ width: '180px' }}
                  >
                    Learn More
                  </Button>
                </Link>
              </Col>
              <Col span={24}>
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/flood-score.appspot.com/o/sample%20reports%2F709_Whitehall_St.-1.pdf?alt=media&token=8248e807-ef39-4af7-af40-0792aa6d7d3e"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    type="primary"
                    className="round secondary-btn"
                    style={{ width: '180px' }}
                  >
                    Sample
                  </Button>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        {/* What is an Elevation Certificate? */}
        <Row style={{ padding: '0 0 20px 0' }} gutter={[10, 0]}>
          <Col xxl={14} xl={14} lg={24} md={24} sm={24} xs={24}>
            <Title level={3} style={{ color: '#fff' }}>
              What is an Elevation Certificate?
            </Title>
            <Paragraph style={{ color: 'white', fontWeight: '400' }}>
              An Elevation Certificate is used to define key elevations around
              your property. If you do not have an Elevation Certificate, No
              Flood Florida can help you obtain one through one of our Survey
              partners. An Elevation Certificate can further define floodplain
              impacts and flood insurance premiums.
            </Paragraph>
            <p>
              <em style={{ fontWeight: 500 }}>{disclaimer}</em>
            </p>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ textAlign: 'center' }}
          >
            <Row>
              <Col span={24}>
                <img
                  src={surveyIncentiveSvg}
                  style={{ height: '140px', width: '200px' }}
                />
              </Col>
              <Col span={24}>
                <Link to="/elevation-certificate">
                  <Button
                    type="primary"
                    className="round secondary-btn"
                    style={{ width: '180px' }}
                  >
                    Learn More
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default HighRiskLearnMore

const B = styled.b`
  font-weight: 600;
  color: #fff;
`
