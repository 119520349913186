import React, { useState } from 'react'
import styled from 'styled-components'
import { FaCheck } from 'react-icons/fa'
import { Row, Col, Typography } from 'antd'
import ContactUsForm from '../../../Eligibilty/ContactUsForm'
import { BulletLabel } from '../StyledComponents'

const { Link, Text, Paragraph } = Typography

const InfoBox = props => {
  const { LOMARating, selectedAddress, propertyData, suggestion } = props
  const [showContactModal, setShowContactModal] = useState(false)

  return (
    <Row
      gutter={[0, 20]}
      style={{ marginTop: '20px', padding: '0 10px 0 10px' }}
    >
      <Col span={24}>
        <FaCheck />
        <BulletLabel>Property Address:</BulletLabel>
        <span style={{ fontWeight: '500' }}>{selectedAddress}</span>
      </Col>
      <Col span={24}>
        <FaCheck />
        <BulletLabel>FEMA Flood Zone:</BulletLabel>
        <span style={{ fontWeight: '500' }}>{propertyData.FEMA_ZONE}</span>
      </Col>
      <Col span={24}>
        <Row style={{ marginBottom: '20px' }}>
          <FaCheck />
          <BulletLabel>Basis of this determination:</BulletLabel>
        </Row>
        <Row gutter={[0, 10]}>
          <Col offset={1} span={24}>
            <FaCheck />
            <span style={{ fontWeight: '500', marginLeft: '5px' }}>
              FEMA considers this property to be in a{' '}
              <B>high risk flood zone</B>.
            </span>
          </Col>
          <Col offset={1} span={24}>
            <FaCheck />{' '}
            <span style={{ fontWeight: '400' }}>
              The elevation of your property appears to be <B>very close</B> to
              the floodplain elevation,{' '}
              <B>
                an Elevation Certificate will be required to determine if you
                are eligible for the LOMA. Already have an Elevation
                Certificate,{' '}
                <Link
                  underline
                  onClick={() => setShowContactModal(true)}
                  className="primary-link"
                >
                  Contact Us
                </Link>{' '}
                and we will review it for free!
              </B>
            </span>
          </Col>
        </Row>
      </Col>

      <ContactUsForm
        show={showContactModal}
        handleClose={() => setShowContactModal(false)}
      />
    </Row>
  )
}

export default InfoBox

const B = styled.b`
  font-weight: 600;
  color: #fff;
`

const InfoBoxWrapper = styled.div`
  padding: 20px 0 20px 40px;
  display: grid;
  grid-gap: 20px;
`

const BasisContainer = styled.div`
  display: grid;
  grid-gap: 4px;
  padding-left: 40px;
`
