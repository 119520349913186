import React from 'react'
import { Typography, Button, Row, Col, Modal } from 'antd'
import { CheckCircleOutlined, MailOutlined } from '@ant-design/icons'

const { Title } = Typography

const SuccessModal = ({ content, isModalVisible, handleOk, handleCancel }) => {
  return (
    <Modal
      style={{ marginTop: '25vh' }}
      // closable={true}
      title=""
      // maskClosable={true}
      visible={isModalVisible}
      destroyOnClose={true}
      handleOk={handleOk}
      handleCancel={handleCancel}
      // mask={false}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={handleCancel}
          shape="round"
          size="large"
        >
          Close
        </Button>
      ]}
    >
      <Row gutter={[0, 20]}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <CheckCircleOutlined style={{ fontSize: 48, color: '#55B96A' }} />
        </Col>
        <Col span={24} style={{ paddingTop: '4px', textAlign: 'center' }}>
          <Title level={4}>{content}</Title>
        </Col>
      </Row>
    </Modal>
  )
}

export default SuccessModal
