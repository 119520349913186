import React, { useState, useEffect, useContext } from 'react'
import { Button, Typography, Row, Col, Grid } from 'antd'
import { AuthContext } from '../../context/auth'
import firebase from 'gatsby-plugin-firebase'

const { Title, Paragraph } = Typography
const { useBreakpoint } = Grid

const CreateNewAPIKey = () => {
  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '34px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '34px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  const [loading, setLoading] = useState(false)

  const { user } = useContext(AuthContext)
  // const [userData, setUserData] = useState(null)

  const [apiUserData, setApiUserData] = useState(null)

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  var date = new Date()
  var year = date.getFullYear()
  var month = date.getMonth()
  var monthName = monthNames[date.getMonth()]
  var currentMonth = month + 1

  //   const onSubmit = async () => {
  //     const authUser = user
  //     const collection = 'apiUsers'
  //     const doc = authUser
  //     const setObj = {
  //       uid: authUser.uid,
  //       website: apiUserData.websiteName
  //     }
  //     firebase
  //       .firestore()
  //       .collection(collection)
  //       .doc(doc)
  //       .set(setObj)
  //       .then(function () {
  //         console.log('Document successfully written!')
  //         // callback()
  //       })
  //       .catch(function (error) {
  //         console.error('Error writing document: ', error)
  //       })
  //   }

  useEffect(() => {
    if (user.uid) {
      firebase
        .firestore()
        .collection('apiUsers')
        .where('uid', '==', user.uid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            if (doc) {
              const data = doc.data()
              setApiUserData(data)
            }
          })
        })
    }
  }, [])

  if (!apiUserData?.monthCount[year]) {
    var sectionName = 'No requests made yet'
    var monthRequest = 0
    var monthCost = monthRequest * 0.25
  } else {
    var sectionName = 'API Detailed Summary'
    var monthRequest = apiUserData?.monthCount[year][currentMonth]
    var monthCost = monthRequest * 0.25
  }

  return (
    <>
      {/* <Row justify="left" gutter={[0, 10]}>
        <Title level={3}>Create an API Key</Title>
      </Row> */}
      <Row gutter={[0, 10]}>
        <Col flex={3}>
          Create an API Key and deploy the screening tool widget on your own
          website! The API Key can also be associated with a specific website if
          depolying the widget across unique websites. The overall account will
          be tracked by email on a monthly basis, summarizing activity on all
          websites.
        </Col>
        <Col flex={2}>
          <div>
            <label style={{ fontWeight: 'bold' }} htmlFor="defaultAPIKey">
              Default API Key
            </label>
            <br />
            <p>{apiUserData?.apiKey}</p>
          </div>
          <div>
            <Paragraph>
              <strong> {sectionName}</strong>{' '}
            </Paragraph>
          </div>
          <div>
            <strong>API Month Limit: </strong> {apiUserData?.monthLimit}
          </div>
          <div>
            <strong>Requests for {monthName}: </strong> {monthRequest}
          </div>
          <div>
            <strong>Current Month Cost: </strong> $ {monthCost}
          </div>
          {/* <div>
              <label style={{ fontWeight: 'bold' }} htmlFor="websiteName">
                Website Name
              </label>
              <br />
              <input
                type="text"
                name="websiteName"
                placeholder="www.surveyor.com"
              />
              <br />
              <br />
            </div>
            <input type="submit" value="Generate API Key" /> */}
          <br />
          {/* <br /> */}
        </Col>
      </Row>
    </>
  )
}
export default CreateNewAPIKey
