import React, { useState, useEffect } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Drawer, Button } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import MenuItems from './MenuItems'
import './menu.css'

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query {
      nff: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fixed(width: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      nfe: file(relativePath: { eq: "nfe_logo_navbar.png" }) {
        childImageSharp {
          fixed(width: 106, height: 21, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  return (
    <nav className="menuBar">
      <div class="logoStack">
        <Link to="/">
          <Img
            fixed={data.nff.childImageSharp.fixed}
            alt="Logo"
            loading="eager"
            fadeIn={false}
          />
        </Link>

        <Link to="https://nationalfloodexperts.com" className="nfeLogoContainer">
          <div className="nfeDivisionText">A division of</div>
          <Img
            fixed={data.nfe.childImageSharp.fixed}
            alt="Logo"
            loading="eager"
            fadeIn={false}
          />
        </Link>
      </div>

      <div className="menu">
        <div className="menuItems">
          <MenuItems mode="horizontal" />
        </div>

        <Button
          size="large"
          type="ghost"
          onClick={showDrawer}
          className="hamburger"
        >
          <MenuOutlined style={{ color: '#000', fontSize: '16px' }} />
        </Button>

        <Drawer
          title=""
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <MenuItems mode="vertical-right" />
        </Drawer>
      </div>
    </nav>
  )
}
export default Navbar
