import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Button, Row, Col, Typography, Card } from 'antd'
import {
  RightCircleOutlined,
  FormOutlined,
  CheckCircleOutlined
} from '@ant-design/icons'
import styled from 'styled-components'
import { Parallax } from 'react-parallax'
import {
  Wrapper,
  Container
} from '../../../styled-components/styled-components'

const { Title, Paragraph, Text } = Typography

const FaqSection = () => {
  const data = useStaticQuery(graphql`
    query {
      lomaImg: file(relativePath: { eq: "LOMA_HILLS_A.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const bgImage = require('../../../assets/images/housesAerial.jpg')
  return (
    <>
      <Wrapper>
        <Container>
          <Row justify="center" span={24} style={{ padding: '10px 0 10px 0' }}>
            <Title level={1}>
              <div style={{ textAlign: 'center' }}>
                Common Questions & Answers
              </div>
            </Title>
          </Row>
          <Row
            gutter={[20, 10]}
            style={{ justifyContent: 'space-around', padding: '0px 0 20px 0' }}
          >
            <Link to="/key-terms">
              <Button
                block
                size="medium"
                type="primary"
                shape="round"
                // icon={<RightCircleOutlined />}
              >
                Review Key Terms
                <RightCircleOutlined />
              </Button>
            </Link>
          </Row>

          <Row gutter={[0, 40]}>
            <Col>
              <CardLayout className="box-shadow round">
                <Row>
                  <Col span={24}>
                    <Title level={3}>What Is A LOMA?</Title>
                  </Col>
                  <Col>
                    <Paragraph>
                      LOMA stands for “Letter of Map Amendment”. If your house has been designated as being in the
                      <b> High-Risk Flood Zone</b>, a LOMA is FEMA’s official method to determine if the
                      designation is correct. This process will require elevation data from a Professional Surveyor.
                    </Paragraph>
                  </Col>
                  <Col>
                    <Paragraph>
                      The example below shows the results of our screening
                      process. We can see that, based on the surrounding terrain
                      (<b>LiDAR Data</b>), the structure is higher than the
                      effective FEMA floodplain. This property would most likely
                      qualify for a LOMA. We also look at the best available
                      watershed models to help refine our risk understanding.
                      See the purple “Best Available Data” floodplains below.
                      This further confirms our confidence in this property’s
                      ability to obtain a LOMA. Get your screening results
                      today!
                    </Paragraph>
                  </Col>
                  <div
                    style={{
                      margin: '0 auto',
                      maxWidth: '480px',
                      padding: '0 0 20px 0',
                      display: 'contents'
                    }}
                  >
                    <Img
                      fluid={data.lomaImg.childImageSharp.fluid}
                      style={{ width: '80%', height: '80%', margin: '0 auto' }}
                    />
                  </div>
                  <Col>
                    <Paragraph>
                      If it is determined that your house was indeed wrongly
                      categorized, FEMA will issue a letter stating you are
                      actually in an area with a lower flood risk. This letter
                      can be submitted to your insurance company and your
                      mortgage company to save on your premium.
                    </Paragraph>
                  </Col>
                </Row>
              </CardLayout>
            </Col>
            <Col>
              <CardLayout className="box-shadow round">
                <Row>
                  <Col span={24} id="screening-results">
                    <Title level={3}>
                      What Is the LOMA Screening Recommendation?
                    </Title>
                  </Col>
                  <Col>
                    <Paragraph>
                      No Flood Florida has processed single-family homes
                      throughout the State of Florida. We analyze your building
                      structure in relation to the FEMA effective floodplains.
                      In addition to highly accurate digital terrain
                      information, we can quantify the likelihood your property
                      would qualify for a Letter of Map Amendment or Letter of
                      Map Change. The screening tool will produce four different
                      recommendation categories: Not Applicable, Low, Medium,
                      and High.
                    </Paragraph>
                  </Col>
                  <Col span={24}>
                    <Title level={4} style={{ color: '#585858' }}>
                      Not Applicable
                    </Title>
                  </Col>
                  <Col>
                    <Paragraph>
                      The <i>Not Applicable</i> or Not Recommended screening
                      result typically indicates one of two things:
                      <ul>
                        <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                          The building structure does not intersect the high
                          risk flood zone -{' '}
                          <b style={{ color: '#55b96a' }}>Good News!</b>
                        </li>
                        <li>
                          The FEMA floodplain impacts the building structure and
                          the estimated Base Flood Elevation (BFE) is greater
                          than the surrounding structure elevation - Not
                          eligible for the Letter of Map Amendment process,
                          typically flood insurance will be required.
                        </li>
                      </ul>
                    </Paragraph>
                  </Col>
                  <Col span={24}>
                    <Title level={4} style={{ color: '#FFD700' }}>
                      Low Likelihood
                    </Title>
                  </Col>
                  <Col>
                    <Paragraph>
                      The <i>Low Likelihood</i> screening result indicates that
                      there is a <b>small</b> chance that the property of
                      interest would qualify for a Letter of Map Amendment.
                      Depending on the final, surveyed structure elevation, the
                      property may or may not qualify for a LOMA.
                    </Paragraph>
                  </Col>
                  <Col span={24}>
                    <Title level={4} style={{ color: '#F28C28' }}>
                      Medium Likelihood
                    </Title>
                  </Col>
                  <Col>
                    <Paragraph>
                      The <i>Medium Likelihood</i> screening result indicates
                      that there is a <b>good chance</b> that the property of
                      interest would qualify for a Letter of Map Amendment -
                      depending on the final, surveyed structure elevation.
                    </Paragraph>
                  </Col>
                  <Col span={24}>
                    <Title level={4} style={{ color: '#C41E3A' }}>
                      High Likelihood
                    </Title>
                  </Col>
                  <Col>
                    <Paragraph>
                      The <i>High Likelihood</i> screening result indicates that
                      there is an <b>excellent chance</b> that the property of
                      interest would qualify for a Letter of Map Amendment -
                      depending on the final, surveyed structure elevation.
                    </Paragraph>
                  </Col>
                  <Col
                    style={{
                      padding: '40',
                      margin: '10px 20px 10px 20px',
                      textAlign: 'center'
                    }}
                  >
                    <Row
                      gutter={[20, 10]}
                      style={{ justifyContent: 'space-around' }}
                    >
                      <Link to="/order-loma">
                        <Button
                          block
                          size="medium"
                          type="primary"
                          shape="round"
                          icon={<FormOutlined />}
                        >
                          Order LOMA Today
                        </Button>
                      </Link>
                    </Row>
                  </Col>
                  <Col
                    style={{
                      padding: '40',
                      margin: '10px 20px 10px 20px',
                      textAlign: 'center'
                    }}
                  >
                    <Row
                      gutter={[20, 10]}
                      style={{ justifyContent: 'space-around' }}
                    >
                      <Link to="/elevation-certificate">
                        <Button
                          block
                          size="medium"
                          type="primary"
                          shape="round"
                          icon={<FormOutlined />}
                        >
                          Order Elevation Certificate
                        </Button>
                      </Link>
                    </Row>
                  </Col>
                </Row>
              </CardLayout>
            </Col>
            <Col>
              <CardLayout className="box-shadow round">
                <Row>
                  <Col span={24}>
                    <Title level={3}>
                      Can I Terminate My Flood Insurance?{' '}
                    </Title>
                  </Col>
                  <Col>
                    No Flood Florida is all for helping its clients save money. Going through the LOMA
                    process will allow FEMA and ultimately flood insurance companies to have a better
                    understanding of the flood risk your property faces. Your mortgage lender may also
                    drop your flood insurance requirement all together. However, you should learn more
                    about your flood risk before making such a decision. We will consult you as to how
                    our services can affect your insurance requirements and rates.
                  </Col>
                </Row>
              </CardLayout>
            </Col>
            <Col>
              <CardLayout className="box-shadow round">
                <Row>
                  <Col span={24}>
                    <Title level={3}>How Long Will The Process Take?</Title>
                  </Col>
                  <Col>
                    Typically our LOMA projects are completed in 4-6 weeks, pending the complexity of
                    the case. In some cases, expedited LOMA processing and approval can be in as fast
                    as five business days. In terms of flood policy refunds, once the lender has updated
                    their Flood Zone Determination Form, the cancellation/refund timeline with your
                    broker should only be a few weeks.
                  </Col>
                </Row>
              </CardLayout>
            </Col>

            <Col>
              <CardLayout className="box-shadow round">
                <Row>
                  <Col span={24}>
                    <Title level={3}>
                      Is It Possible That FEMA Will Reject My LOMA Request?
                    </Title>
                  </Col>
                  <Col>
                    No Flood Florida screens every request prior to beginning the process. Each property
                    is ranked as either having a low, medium, or high likelihood of receiving a LOMA
                    from FEMA. We will recommend the medium and high likelihood properties to move forward.
                    If through our screening process we find that you are almost certainly not going to be
                    able to get a LOMA, we will notify you.
                  </Col>
                </Row>
              </CardLayout>
            </Col>

            <Col id="money-back-guarantee">
              <Row>
                <Col span={24}>
                  <Title level={5} style={{ padding: '0 0 0 26px' }}>
                    Risk Free
                  </Title>
                </Col>
                <Col span={24}>
                  <Title
                    level={1}
                    style={{
                      borderLeft: '4px solid #d3b574',
                      padding: '0 0 0 20px'
                    }}
                  >
                    Money Back Guarantee
                  </Title>
                </Col>
                <Col>
                  We are confident we are able to identify and avoid situations where a LOMA
                  application will not be approved by FEMA. If we fail to obtain a LOMA from
                  FEMA, we will refund you the cost of those services, less any surveying or
                  requirement fees.
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Wrapper>

      <Parallax blur={0} bgImage={bgImage} bgImageAlt="houses" strength={300}>
        <BgOverlay />
        <Container style={{ paddingTop: '40px' }}>
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <Row>
                <Title level={1} style={{ textAlign: 'center', color: '#fff' }}>
                  Thousands Of People Are Paying Unnecessarily High Flood
                  Insurance Premiums.
                </Title>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Title level={1} style={{ color: '#fff' }}>
                  Find Out If You Qualify For A LOMA.
                </Title>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Link to="/order-loma">
                  <Button
                    size="large"
                    shape="round"
                    className="box-shadow"
                    type="primary"
                  >
                    Find Out Now <RightCircleOutlined />
                  </Button>
                </Link>
              </Row>
              <br></br>
            </Col>
          </Row>
        </Container>
      </Parallax>
    </>
  )
}

const CardLayout = styled(Card)`
  border-left: 4px solid #d3b574;
`

const BgOverlay = styled.div`
  padding: 0;
  overflow: hidden;
  height: 340px;
  opacity: 0.8;
  position: absolute;
  background-color: #16163f;
  width: 100%;
`

export default FaqSection
