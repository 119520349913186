import React, { useState } from 'react'
import * as S from '../StyledComponents'
import styled from 'styled-components'
import { Row, Col, Typography } from 'antd'
import CategoryPills from '../CategoryPills'
import InfoBox from './InfoBox'
import ImgLightbox from '../ImgLightbox'
import BottomMenu from '../BottomMenu/BottomMenu'

const { Title } = Typography

const Medium = props => {
  const {
    LOMARating,
    LOMACategory,
    femaZone,
    selectedAddress,
    propertyData,
    imgUrl,
    setShowRecommendation,
    setShowScreening
  } = props

  const [modalShow, setModalShow] = useState(false)
  const [basisOfDetermination, setBasisOfDetermination] = useState([
    {
      id: 0,
      info: ' FEMA considers this property to be in a high risk flood zone.'
    },
    {
      id: 2,
      info:
        'The elevation of your property appears to be above the flood elevation.'
    }
  ])

  return (
    <>
      <ImgLightbox
        imgUrl={imgUrl}
        visible={modalShow}
        onCancel={() => setModalShow(false)}
      />
      <div style={{ paddingBottom: '20px' }}>
        <Title level={2} style={{ color: '#fff' }}>
          CONGRATULATIONS!
          <br />
          This property has a “medium” likelihood of benefiting from a Letter of
          Map Amendment.
        </Title>
      </div>
      <Row gutter={[100, 0]}>
        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
          <hr
            style={{
              border: '3px solid #C7AE4A',
              backgroundColor: '#C7AE4A'
            }}
          />
          <Row>
            <Col span={24} style={{ margin: '20px 0 20px 0' }}>
              <CategoryPills LOMARating={LOMARating} />
            </Col>
            <InfoBox
              LOMARating={LOMARating}
              selectedAddress={selectedAddress}
              propertyData={propertyData}
              suggestion="Medium"
              basisOfDetermination={basisOfDetermination}
            />
          </Row>
          <hr
            style={{
              border: '3px solid #C7AE4A',
              backgroundColor: '#C7AE4A'
            }}
          />
        </Col>
        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
          {imgUrl ? (
            <img
              src={imgUrl}
              style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              onClick={() => setModalShow(true)}
            />
          ) : (
            <>Pending...</>
          )}
        </Col>
      </Row>
      <BottomMenu
        setShowRecommendation={setShowRecommendation}
        setShowScreening={setShowScreening}
      />
    </>
  )
}

export default Medium
