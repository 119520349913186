import React from 'react'
import { Helmet } from 'react-helmet'

const SeoNoIndex = () => {
    return (
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
    );
};

export default SeoNoIndex
