import React from 'react'
import { Collapse, Divider } from 'antd'
import {
  Wrapper,
  Container
} from '../../../styled-components/styled-components'
import './collapseInfo.css'

const { Panel } = Collapse

const text1 = `
  If you already have an Elevation Certificate, one of our Engineers will review it to determine if you qualify for a Letter of Map Amendment. If you do, we will let you know and move forward. If you do not qualify for a LOMA based on the elevation of your property, we will contact you to let you know if any other solutions may exist.
`
const text2 = `
  If you do not already have an Elevation Certificate, no problem. We will use the best available LiDAR Technology to estimate the elevation of your property. We will use this information to let you know the likelihood that you will qualify. If you have a high likelihood of qualifying, we will quote you for a Flood Expert Survey (FES). With the data obtained in FES, we can determine with complete certainty whether to proceed with the LOMA process.
`
const text3 = `
  We are confident we are able to identify and avoid situations where a LOMA application will not be approved by FEMA. If we fail to obtain a LOMA from FEMA, we will refund you the cost of those services, less any surveying or requirement fees.
`

const CollapseInfo = () => {
  return (
    <Wrapper>
      <Container>
        <Divider />
        <Collapse defaultActiveKey={[]} ghost>
          <Panel header="Situation #1: Existing Elevation Certificate Review" key="1">
            <p>{text1}</p>
          </Panel>
          <Panel header="Situation #2: No Existing Elevation Certificate" key="2">
            <p>{text2}</p>
          </Panel>
          <Panel header="Money Back Guarantee" key="3">
            <p>{text3}</p>
          </Panel>
        </Collapse>
        <Divider />
      </Container>
    </Wrapper>
  )
}

export default CollapseInfo
