import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import firebase from 'gatsby-plugin-firebase'
import axios from 'axios'
import { AuthContext } from '../../context/auth'
import Loader from '../Eligibilty/Recommendation/Loader'

/* Styles */
import { Row, Col, Descriptions } from 'antd'

/* Components */
import AutoSuggestInternal from '../AutoSuggestInternal/AutoSuggestInternal'

/**
 * Controls the 'flow' of displaying the screeening and various recommendations
 */
const PropertySearchInternal = () => {
  const [selectedAddress, setSelectedAddress] = useState('')
  const [selectedCounty, setSelectedCounty] = useState(null)

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setSelectedAddress(suggestion)
  }

  function updateParentCountyState(county) {
    setSelectedCounty(county)
  }

  const handleProceedButton = address => {
    setSelectedAddress(address)
  }

  /* Render IFrame Landing */
  return (
    <IFrameLanding
      onSuggestionSelected={onSuggestionSelected}
      updateParentCountyState={updateParentCountyState}
      firebase={firebase}
      handleProceedButton={handleProceedButton}
      selectedCounty={selectedCounty}
      selectedAddress={selectedAddress}
    />
  )
}

const IFrameLanding = props => {
  const { user } = useContext(AuthContext)

  const [apiUserData, setAPIUserData] = useState(null);
  const [dataSourceRequest, setDataSourceRequest] = useState();
  const [loadingPropertyData, setLoadingPropertyData] = useState(false);
  const [propertyData, setPropertyData] = useState({
    data: '',
    loading: true
  });

  /**
   * Fetch the full firebase user data when the component mounts
   */
  useEffect(() => {
    if (user.uid) {
      firebase
        .firestore()
        .collection('apiUsers')
        .where('uid', '==', user.uid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            if (doc) {
              const data = doc.data()
              setAPIUserData(data)
            }
          })
        })
    }
  }, [])

  IFrameLanding.propTypes = {
    onSuggestionSelected: PropTypes.func,
    updateParentCountyState: PropTypes.func,
    firebase: PropTypes.object,
    handleProceedButton: PropTypes.func,
    selectedCounty: PropTypes.string,
    selectedAddress: PropTypes.string
  }
  const {
    onSuggestionSelected,
    updateParentCountyState,
    firebase,
    selectedCounty,
    selectedAddress
  } = props

  const zipCode = selectedAddress.split(' ').slice(-1)
  const firstAddress = selectedAddress.substr(0, selectedAddress.indexOf(','))
  const apiKey = apiUserData?.apiKey

  const handleClick = async () => {
    setLoadingPropertyData(true);
    const data = await axios.get(
      `https://us-central1-flood-score.cloudfunctions.net/getProperty?state=Florida&county=${selectedCounty}&zip=${zipCode}&streetAddress=${firstAddress}&apiKey=${apiKey}`
    )
    setPropertyData({
      data: data,
      loading: false
    })

    const dataSource = [
      {
        key: '1',
        address: data.data.properties[0].FULL_ADD,
        femazone: data.data.properties[0].FEMA_ZONE,
        femabfe: data.data.properties[0].FEMA_BFE,
        strucPct: data.data.properties[0].STRUC_PCT,
        sge: data.data.properties[0].SGE,
        loma: data.data.properties[0].LOMA,
        femaDate: data.data.properties[0].FEMA_EFF_DT,
        community: data.data.properties[0].Community,
        crs: data.data.properties[0].CRS
      }
    ]

    setDataSourceRequest(dataSource)
    setLoadingPropertyData(false);
  }

  return (
    <>
      <Row textAlign="left" gutter={[0, 10]}>
        <Col flex={3}>
          Select your County of interest and then start typing in the address.
          The tool will auto-complete our available addresses after the fourth
          character is typed. The tool returns a basic table of the available
          flood risk information from our database.
        </Col>
      </Row>
      <br />

      <AutoSuggestRow span={24}>
        <Col span={24} style={{ padding: '0', margin: '0 auto' }}>
          <AutoSuggestInternal
            autocomplete="off"
            theme={autosuggestTheme}
            countySelectStyles={countySelectStyles}
            updateParentCountyState={updateParentCountyState}
            onSuggestionSelected={onSuggestionSelected}
            inputProps={{ id: 'eligibilityAddressSuggest' }}
            firebase={firebase}
          />
        </Col>
        <Row
          span={24}
          style={{ padding: '0', margin: '0 auto', textAlign: 'center' }}
        >
          <div>
            <br />
            <PropSearchButton onClick={handleClick}>Proceed</PropSearchButton>
            <br />
            <br />
          </div>
        </Row>
      </AutoSuggestRow>

      <Col
        span={24}
        style={{ padding: '40', margin: '0 auto', textAlign: 'center' }}
      >
        <div>
          {dataSourceRequest ? (
            <>
              <Descriptions
                title="Property Results"
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                <Descriptions.Item label="Address">
                  {dataSourceRequest[0]?.address}
                </Descriptions.Item>
                <Descriptions.Item label="FEMA Zone">
                  {dataSourceRequest[0]?.femazone}
                </Descriptions.Item>
                <Descriptions.Item label="FEMA BFE (NAVD88-ft)">
                  {dataSourceRequest[0]?.femabfe}
                </Descriptions.Item>
                <Descriptions.Item label="Structure % Impact">
                  {dataSourceRequest[0]?.strucPct}
                </Descriptions.Item>
                <Descriptions.Item label="Structure Ground Elev (NAVD88-ft)">
                  {dataSourceRequest[0]?.sge}
                </Descriptions.Item>
                <Descriptions.Item label="LOMA Recommendation">
                  {dataSourceRequest[0]?.loma}
                </Descriptions.Item>
                <Descriptions.Item label="FEMA Effective Date">
                  {dataSourceRequest[0]?.femaDate}
                </Descriptions.Item>
                <Descriptions.Item label="NFIP Community">
                  {dataSourceRequest[0]?.community}
                </Descriptions.Item>
                <Descriptions.Item label="CRS Score">
                  {dataSourceRequest[0]?.crs}
                </Descriptions.Item>
              </Descriptions>
            </>
          ) : loadingPropertyData ? (
            <Row justify="center" gutter={[0, 10]}>
              <Loader />
            </Row>
          ) : (<></>)}
          <br />
          <br />
        </div>
      </Col>
    </>
  )
}

export default PropertySearchInternal

const AutoSuggestRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  max-width: 1080px;
`

const autosuggestTheme = {
  container: {
    width: '100%',
    color: '#666666',
    position: 'relative',
    top: '6px'
  },

  containerOpen: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    zIndex: '999',
    position: 'relative',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '0px',
    borderBottomRadius: '0px'
  },

  input: {
    borderRadius: '4px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    color: '#666666',
    width: '100%',
    padding: '10px 15px 10px 15px',
    height: '3rem',
    border: 'none'
  },

  inputOpen: {
    fontWeight: '500',
    color: '#666666',
    padding: '10px 15px 10px 15px',
    border: 'none'
  },

  inputFocused: {
    outline: 'none'
  },

  suggestionsContainer: {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    maxHeight: '300px',
    overflow: 'auto',
    margin: '0',
    padding: '0',
    zIndex: '999',
    textAlign: 'left',
    background: '#FAFAFA'
  },

  /**
   * Position absolute here will not push the containers underneath down.
   */
  suggestionsContainerOpen: {
    width: '100%',
    zIndex: '999',
    backgroundColor: '#fff',
    position: 'absolute'
  },

  suggestionsList: {
    listStyleType: 'none',
    borderTop: '1px solid #eee',
    paddingRight: '10px',
    paddingBottom: '10px',
    marginBottom: '0'
  },

  suggestion: {
    paddingTop: '4px',
    paddingBottom: '4px',
    cursor: 'pointer'
  },

  suggestionHighlighted: {
    backgroundColor: '#eeeeee'
  },

  suggestionFirst: {},
  sectionContainer: {},
  sectionContainerFirst: {},
  sectionTitle: {}
}

const countySelectStyles = {
  borderRadius: '4px !important',
  boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  position: 'relative',
  margin: '0 auto',
  width: '100%',
  maxWidth: '250px',
  textAlign: 'left'
}

export const PropSearchButton = styled.button`
  &,
  &:link,
  &:visited {
    width: 100px;
    height: 40px;
    text-align: center !important;
    color: white !important;
    cursor: pointer;
    background-color: #55b96a;
    font-size: 0.94rem;
    font-weight: 700;
    border: 1px solid #55b96a;
    border-radius: 4px;
    text-decoration: none;
    text-transform: uppercase;
    background-size: 230%;
    transition: all 0.4s;
    z-index: 999;
  }

  &:hover {
    background-color: #4ca25e;
    border: 1px solid #4ca25e;
    background-position: 100%;
    color: white;
  }

  &:active {
    outline: none;
    transform: scale(0.9);
  }

  &:disabled {
    border: 1px solid #d4d4d4;
    background-color: #d4d4d4;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }
`
