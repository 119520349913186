import React from 'react'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import { Footer } from '../../components/Footer/Footer'
import CollapseInfo from './components/CollapseInfo'
import OrderForm from './components/OrderForm'
import PageHeader from '../../components/PageHeader'

const OrderLoma = () => {
  return (
    <LandingLayout>
      <PageHeader title="Order a LOMA" />
      <CollapseInfo />
      <OrderForm />
      <Footer />
    </LandingLayout>
  )
}

export default OrderLoma
