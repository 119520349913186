import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import './PhoneCallForm.css'
import firebase from 'gatsby-plugin-firebase'
import {
  Form,
  Input,
  Modal,
  Button,
  Row,
  Col,
  message,
  Divider,
  Select
} from 'antd'
import SuccessModal from '../SuccessModal'
import { normalizePhoneNumberInput } from '../../utils/form/normalize'

const PhoneCallForm = props => {
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  PhoneCallForm.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func
  }

  const { show, handleClose, userEmail } = props

  const handleOnSubmit = values => {
    values.timestamp = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York'
    })
    const phoneCallFormMessageObj = {
      to: userEmail,
      cc: [
        'info@nofloodflorida.com',
        'sophia@nofloodflorida.com',
        'meghana@nofloodflorida.com'
      ],
      template: {
        name: 'phoneCallFormTemplate',
        data: {
          ...values,
          email: 'None'
        }
      }
    }

    firebase
      .firestore()
      .collection('phoneCallForm')
      .add(phoneCallFormMessageObj)
      .then(() => {
        formRef.current.resetFields() // Clear input fields
        handleClose() // close the modal
        setIsModalVisible(true) // Show success modal
      })
      .catch(error => console.log('Error adding document:', error))
  }

  const handleOk = () => setIsModalVisible(false)
  const handleCancel = () => setIsModalVisible(false)

  return (
    <>
      <SuccessModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        content="New Phone Call Added! Check your e-mail for confirmation."
      />
      <Modal visible={show} title="New Phone Call" footer={null}>
        <Form
          ref={formRef}
          action="javascript:void(0);"
          onFinish={handleOnSubmit}
          id="new-phone-call-form"
          layout="vertical"
        >
          <Form.Item
            label="Caller's Name"
            name="name"
            rules={[{ required: true, message: `Please caller's name.` }]}
          >
            <Input placeholder="Jane Doe" name="name" />
          </Form.Item>

          <Form.Item
            normalize={(value, prevValue, prevValues) =>
              normalizePhoneNumberInput(value, prevValue)
            }
            label="Caller's Phone Number"
            name="phone"
            rules={[
              { required: true, message: 'Please input a valid phone number.' }
            ]}
          >
            <Input placeholder="(813)213-0641" name="phone" />
          </Form.Item>

          <Form.Item
            label="Address of Interest (include City and Zip Code)"
            name="address"
            rules={[
              { required: true, message: 'Please input your street address.' }
            ]}
          >
            <Input
              placeholder="123 Main St, Tampa, FL 33624"
              name="address"
              onInput={e =>
                (e.target.value = ('' + e.target.value).toUpperCase())
              }
            />
          </Form.Item>

          <Form.Item
            label={
              <a
                target="_blank"
                href="/flood-insurance-quote"
              >
                Interested in Flood Insurance Quotes? (Click to Learn More)
              </a>
            }
            name="insuranceQuote"
            rules={[{ required: true, message: 'Please select Yes or No.' }]}
          >
            <Select>
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Caller's Message" name="message" initialValue="">
            <Input.TextArea rows={3} name="message" />
          </Form.Item>

          <Row gutter={[8, 0]} justify="end">
            <Col>
              <Form.Item>
                <Button
                  type="secondary"
                  onClick={handleClose}
                  shape="round"
                  // loading={loading}
                  style={{
                    background: '#f9f9f9',
                    borderColor: '#f9f9f9'
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  loading={loading}
                  style={{
                    background: '#1c79c7',
                    borderColor: '#f9f9f9'
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default PhoneCallForm
