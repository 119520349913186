import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import './contactUsForm.css'
import firebase from 'gatsby-plugin-firebase'
import { Form, Input, Modal, Button, Row, Col, message, Select } from 'antd'
import SuccessModal from '../SuccessModal'
import { normalizePhoneNumberInput } from '../../utils/form/normalize'

const ContactUsForm = props => {
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  ContactUsForm.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func
  }

  const { show, handleClose } = props

  const handleOnSubmit = values => {
    values.timestamp = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York'
    })
    const contactFormMessageObj = {
      to: values.email,
      bcc: [
        'info@nofloodflorida.com',
        'sophia@nofloodflorida.com',
        'nofloodflorida+7t5d67e@nofloodflorida-org.myfreshworks.com'
      ],
      template: {
        name: 'contactFormTemplate',
        data: {
          ...values
        }
      }
    }

    firebase
      .firestore()
      .collection('contactForm')
      .add(contactFormMessageObj)
      .then(() => {
        formRef.current.resetFields() // Clear input fields
        handleClose() // close the modal
        setIsModalVisible(true) // Show success modal
      })
      .catch(error => console.log('Error adding document:', error))
  }

  const handleOk = () => setIsModalVisible(false)
  const handleCancel = () => setIsModalVisible(false)

  return (
    <>
      <SuccessModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        content="Form Submitted Successfully! Please check your email and/or spam folder for a confirmation"
      />
      <Modal visible={show} title="Contact Us" footer={null}>
        <Form
          ref={formRef}
          action="javascript:void(0);"
          onFinish={handleOnSubmit}
          id="contact-us-form"
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input your name.' }]}
          >
            <Input placeholder="Jane Doe" name="name" />
          </Form.Item>

          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input a valid email address.'
              }
            ]}
          >
            <Input
              placeholder="info@nofloodflorida.com"
              name="email"
              onInput={e =>
                (e.target.value = ('' + e.target.value).toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item
            normalize={(value, prevValue, prevValues) =>
              normalizePhoneNumberInput(value, prevValue)
            }
            label="Phone Number"
            name="phone"
            rules={[
              { required: true, message: 'Please input a valid phone number.' }
            ]}
          >
            <Input placeholder="(813) 213-0641" name="phone" />
          </Form.Item>

          <Form.Item
            label="Address (include City and Zip Code)"
            name="address"
            rules={[
              { required: true, message: 'Please input your street address.' }
            ]}
          >
            <Input
              placeholder="123 Main St, Tampa, FL 33624"
              name="address"
              onInput={e =>
                (e.target.value = ('' + e.target.value).toUpperCase())
              }
            />
          </Form.Item>

          <Form.Item
            label={
              <a
                target="_blank"
                href="/flood-insurance-quote"
              >
                Interested in Flood Insurance Quotes? (Click to Learn More)
              </a>
            }
            name="insuranceQuote"
            rules={[{ required: true, message: 'Please select Yes or No.' }]}
          >
            <Select>
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Message" name="message" initialValue="">
            <Input.TextArea rows={3} name="message" />
          </Form.Item>

          <Row gutter={[8, 0]} justify="end">
            <Col>
              <Form.Item>
                <Button type="secondary" onClick={handleClose} shape="round">
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  loading={loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default ContactUsForm
