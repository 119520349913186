import React from 'react'
import { Row, Col, Typography } from 'antd'
import ExamineSvg from '../../../../assets/images/Examine.svg'
import styled from 'styled-components'
import { FaCheck } from 'react-icons/fa'
import LowRiskLearnMore from './LowRiskLearnMore'
import { BulletLabel } from '../StyledComponents'
import SearchAnotherPropertyBtn from '../BottomMenu/SearchAnotherPropertyBtn'

const { Title } = Typography

const NotApplicableLowRisk = ({
  selectedAddress,
  propertyData,
  setShowRecommendation,
  setShowScreening
}) => {
  const colSizes = {
    xxl: 12,
    xl: 12,
    lg: 12,
    md: 24,
    sm: 24,
    xs: 24
  }

  return (
    <>
      <div style={{ paddingBottom: '20px' }}>
        <Title level={2} style={{ color: '#fff' }}>
          Good News! It is not recommended to pursue a Letter of Map Amendment
          for this property.
        </Title>
      </div>

      <Row gutter={[40, 20]}>
        <Col {...colSizes}>
          <hr
            style={{
              border: '3px solid #C7AE4A'
            }}
          />
          <Row gutter={[0, 20]} style={{ margin: '30px 0 30px 0'}}>
            <Col span={24}>
              <Title level={4} style={{ color: '#fff' }}>
                A Letter of Map Amendment is not applicable for this property
              </Title>
            </Col>
            <Col span={24}>
              <FaCheck />
              <BulletLabel>Property Address:</BulletLabel>
              <span style={{ fontWeight: '500' }}>{selectedAddress}</span>
            </Col>
            <Col span={24}>
              <FaCheck />
              <BulletLabel>FEMA Flood Zone:</BulletLabel>
              <span style={{ fontWeight: '500' }}>
                {propertyData.FEMA_ZONE}
              </span>
            </Col>
            <Col span={24}>
              <FaCheck />
              <span
                style={{
                  fontWeight: '500',
                  marginRight: '20px',
                  marginLeft: '5px'
                }}
              >
                FEMA considers this property to be in a{' '}
                <B>low risk flood zone</B>, an area of minimal flooding.
              </span>
            </Col>
            <Col span={24}>
              <FaCheck />
              <span
                style={{
                  fontWeight: '500',
                  marginRight: '20px',
                  marginLeft: '5px'
                }}
              >
                Flood insurance for this property would most likely{' '}
                <b style={{ fontWeight: '600', color: '#fff' }}>NOT</b> be
                required.
              </span>
            </Col>
          </Row>
          <hr
            style={{
              border: '3px solid #C7AE4A'
            }}
          />
        </Col>

        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
          <LowRiskLearnMore
            title="Request Your Flood Analysis Memo"
            img={ExamineSvg}
            setShowRecommendation={setShowRecommendation}
            setShowScreening={setShowScreening}
          />
          <Row align="center" style={{ marginTop: '40px' }}>
            <SearchAnotherPropertyBtn
              setShowRecommendation={setShowRecommendation}
              setShowScreening={setShowScreening}
            />
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default NotApplicableLowRisk

const B = styled.b`
  font-weight: 600;
  color: #fff;
`

const InfoBoxWrapper = styled.div`
  padding: 20px 0 20px 40px;
  display: grid;
  grid-gap: 20px;
`
