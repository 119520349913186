import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link as ScrollLink } from 'react-scroll'
import { Button, Typography, Row, Col, Grid } from 'antd'
import {
  FormOutlined,
  FileOutlined,
  RightCircleOutlined
} from '@ant-design/icons'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import { Wrapper, Container } from '../../styled-components/styled-components'
import { Footer } from '../../components/Footer/Footer'
import PageHeader from '../../components/PageHeader'

const { Title, Paragraph } = Typography
const { useBreakpoint } = Grid

const KeyTerms = () => {
  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '30px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '50px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  return (
    <LandingLayout>
      <PageHeader title="Flood Risk Key Terms" />
      <Wrapper>
        <Container style={{ padding: '20px 10px 10px 10px' }}>
          <Row>
            <Col span={24}>
              <Title level={3}>
                Some of the typical language and terms to know...
              </Title>
            </Col>
            <Col>
              <Paragraph>
                It is important to understand the typical vocabulary and key
                terms associated with flood risk. This page is dedicated to
                defining key terms used in the flood risk/flood insurance
                industry.
              </Paragraph>
            </Col>
            <Col span={24}>
              <Title level={5}>
                FEMA - Federal Emergency Management Agency
              </Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                FEMA is a federal agency that is tasked with defining flood risk
                throughout the United States. Floodplains are communicated in
                digital form called Digitial Flood Insurance Rate Maps or
                DFIRMs. Each community participating in the National Flood
                Insurance Program (NFIP) has a defined initial DFRIM date. This
                date is used to compare with the building structure date (when
                the building/home was constructed). From this comparison, we
                often define the following terms: Pre and Post FIRM.
                <ul>
                  <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                    <b>Pre FIRM</b> - Defines a building that was constructed{' '}
                    <i>prior</i> to the initial development of a Federal
                    Insurance Rate Map, often before around 1975 for most
                    communities.
                  </li>
                  <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                    <b>Post FIRM</b> – Defines a building that was constructed{' '}
                    <i>after</i> the initial development of a Federal Insurance
                    Rate Map, often after around 1975 for most communities. For
                    example, if your home was built in the year 2005 it would
                    most likely be considered Post FIRM.
                  </li>
                </ul>
              </Paragraph>
            </Col>
            <Col span={24}>
              <Title level={5}>NFIP - National Flood Insurance Program</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                The NFIP is responsible for providing federally backed flood
                insurance policies and regulations. Within the past 10 years or
                so, many private flood insurance providers have been
                established. Check out the following links for more information:
              </Paragraph>
              <Col
                xl={8}
                lg={12}
                md={12}
                sm={12}
                xs={24}
                align="center"
                style={{ padding: '0px 0px 20px 0px' }}
              >
                <InsuranceProcessBoxes>
                  <Link
                    to="https://www.fema.gov/flood-insurance"
                    target="_blank"
                    style={{ color: '#425466' }}
                    title="NFIP Information"
                  >
                    NFIP Flood Providers
                  </Link>{' '}
                </InsuranceProcessBoxes>
                <InsuranceProcessBoxes level={2} style={{ color: '#425466' }}>
                  <Link
                    to="https://www.floir.com/sections/pandc/floodinsurance/floodinsurancewritersfl.aspx"
                    target="_blank"
                    style={{ color: '#425466' }}
                    title="Private Flood Insurance Information"
                  >
                    Private Flood Providers
                  </Link>{' '}
                </InsuranceProcessBoxes>
                {/* <br /> */}
              </Col>
              <Col span={24}>
                <Title level={5}>LOMA - Letter of Map Amendment</Title>
              </Col>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                A <b>LOMA</b> – or{' '}
                <a href="/loma" target="_blank" style={{ color: '#55b96a' }}>
                  Letter of Map Amendment{' '}
                </a>
                is an official document from FEMA that confirms your building
                structure is outside of the Special Flood Hazard Area (SFHA).
                These include the High Risk Zone designations defined in the
                section below.
              </Paragraph>
            </Col>
            <Col span={24}>
              <Title level={5}>General Flood Events and Terms</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                <ul>
                  <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                    <b>100 Year Storm</b> - A storm that on average has a 1%
                    chance of happening in any given year. This does not mean it
                    will only happen once a year. For example, in 2004 there
                    were several “100 year storms” within a few months. In
                    Florida, this event can equate to around 10-14 inches of
                    rain over a 24-hr period.
                  </li>
                  <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                    <b>100 Year Floodplain</b> – Often referred to as the{' '}
                    <i>Special Flood Hazard Area (SFHA)</i> or the area of land
                    that is expected to be impacted with water after a 100 year
                    storm.
                  </li>
                  <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                    <b>Base Flood Elevation (BFE)</b> – the elevation of the
                    water associated with the 100 year floodplain. This
                    elevation is established using engineering models. Only High
                    Risk Zones have defined BFEs.
                    <ul>
                      <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                        <b>Note:</b> No Flood Florida will report a BFE value of{' '}
                        <i>None</i> for any Moderate Risk or Low Risk Zone.
                      </li>
                    </ul>
                  </li>
                  <li style={{ paddingBottom: '5px', paddingTop: '0px' }}>
                    <b>BFE Lines</b> – BFE Lines are used to characterize the
                    elevations of riverine or creek systems. Since flowing
                    systems experience resistance (friction) to flow due to
                    debris, vegetation, and stormwater infrastructure, the
                    extent or elevation of the floodplain also changes.
                    Typically, as the creek or river flows downstream, the
                    floodplain elevation or BFE Lines will decrease in
                    magnitude.
                  </li>
                </ul>
              </Paragraph>
            </Col>
            <Col span={24} id="flood-risk-zones">
              <Title level={5}>FEMA Flood Zones</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                <ul>
                  <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                    <b style={{ color: '#C41E3A' }}>High Risk Zones</b>
                    <ul>
                      <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                        <b>Zone A</b> – These areas are similar to the 100 year
                        floodplain, with the exception of not having an
                        engineering model establishing the Base Flood Elevation.
                        Homes in these areas are typically required to purchase
                        flood insurance.
                      </li>
                      <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                        <b>Zone AE</b> – These areas are subject to inundation
                        by the 100 year storm event. Base Flood Elevations
                        (BFEs) derived from detailed hydraulic analyses are
                        established. Mandatory flood insurance purchase
                        requirements and floodplain management standards apply.
                        No Flood Florida further breaks down the Zone AE
                        floodplains into <b>AE, LEVEL POOL</b> and{' '}
                        <b>AE, RIVERINE</b>. An AE, LEVEL POOL flood zone
                        indicates a none-flowing system, while the AE, RIVERINE
                        designation defines flood zones that are associated with
                        flowing systems.
                      </li>
                      <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                        <b>Zone VE</b> – These areas are subject to inundation
                        by the 100 year storm event with additional hazards due
                        to storm-induced <b>velocity wave action</b>. Base Flood
                        Elevations (BFEs) derived from detailed hydraulic
                        analyses are established. Mandatory flood insurance
                        purchase requirements and floodplain management
                        standards apply.
                      </li>
                    </ul>
                  </li>
                  <li style={{ paddingBottom: '5px', paddingTop: '0px' }}>
                    <b style={{ color: '#F28C28' }}>Moderate Risk Zones</b>
                    <ul>
                      <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                        <b>Zone X, shaded</b> – These areas are subject to
                        inundation during what is called the 500 year storm. The
                        500 year storm has a 0.2 % chance of happening in any
                        given year. This happens less often than the 100 year
                        storm. Homes in these areas typically are not required
                        to purchase flood insurance. No Flood Florida defines
                        this zone as{' '}
                        <b>X, 0.2 PCT ANNUAL CHANCE FLOOD HAZARD</b> with a BFE
                        of None.
                      </li>
                    </ul>
                  </li>
                  <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                    <b style={{ color: '#388E8E' }}>Low Risk Zones</b>
                    <ul>
                      <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                        <b>Zone X</b> – The areas of minimal flood hazard, which
                        are the areas outside the SFHA and higher than the
                        elevation of the 0.2-percent-annual-chance flood (500
                        year flood), are labeled Zone C or Zone X (unshaded). No
                        Flood Florida defines this zone as{' '}
                        <b>X, AREA OF MINIMAL FLOOD HAZARD</b> with a BFE of
                        None.
                      </li>
                    </ul>
                  </li>
                </ul>
              </Paragraph>
            </Col>
            <Col span={24}>
              <Title level={5}>Building Structure and Elevation Terms</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                <ul>
                  <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                    <b>Elevation Certificate</b> – An{' '}
                    <a
                      href="/elevation-certificate"
                      target="_blank"
                      style={{ color: '#55b96a' }}
                    >
                      official document{' '}
                    </a>
                    completed by a Professional Surveyor that helps you verify a
                    house’s Finished Floor Elevation (FFE). You may want to get
                    this to reduce flood insurance cost if a house is elevated.
                  </li>
                  <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                    <b>Finished Floor Elevation (FFE)</b> - This is the
                    elevation of the lowest floor of your home. It is typically
                    determined through a survey by a Professional Survey. No
                    Flood Florida uses LiDAR technology to provide FFE
                    approximations for flood insurance estimation purposes.
                  </li>
                  <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                    <b>Structure Ground Elevation (SGE)</b> - An approximation
                    of the surrounding terrain near the building structure. This
                    is officially defined as the Lowest Adjacent Grade (LAG) and
                    is determined through a survey by a Professional Surveyor.
                    No Flood Florida uses LiDAR technology to provide SGE values
                    and use them for LOMA qualification purposes.
                  </li>
                  <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                    <b>LiDAR</b> - Light Detection and Ranging - a remote
                    sensing method that uses pulsed light to measure ranges
                    (variable distances) to the Earth. This technology is
                    critical for developing highly accurate Digitial Elevation
                    Models or DEMs of the Earth's surface.
                  </li>
                  <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                    <b>Vertical Datums</b> - a surface of zero elevation to
                    which heights of various points are referenced. Working with
                    multiple datums is similar to working with various units.
                    For example, one could state the length of piece of paper is
                    1 foot or 12 inches.
                    <ul>
                      <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                        <b style={{ color: '#388E8E' }}>NGVD29-ft</b> – The Sea
                        Level Datum of 1929 was named the National Geodetic
                        Vertical Datum of 1929 on May 10, 1973. The datum is
                        defined by the observed heights of mean sea level at the
                        26 tide gauges and by the set of elevations of all bench
                        marks resulting from the adjustment. This vertical datum
                        was replaced by the NAVD88 datum and is not typically
                        seen in floodplain mapping efforts these days.
                      </li>
                      <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                        <b style={{ color: '#388E8E' }}>NAVD88-ft</b> – The
                        North American Vertical Datum of 1988 (NAVD 88) is the
                        vertical control datum established in 1991 by the
                        minimum-constraint adjustment of the
                        Canadian-Mexican-United States leveling observations. In
                        1993 NAVD 88 was affirmed as the official vertical datum
                        in the National Spatial Reference System (NSRS).{' '}
                        <b>
                          The NAVD88 datum is currently used in elevation
                          studies and floodplain mapping efforts.
                        </b>
                      </li>
                    </ul>
                  </li>
                </ul>
              </Paragraph>
              <Paragraph style={{ padding: '0px 0px 0px 50px' }}>
                <Link to="/vertical-datum-conversion">
                  <Button
                    type="primary"
                    className="box-shadow"
                    shape="round"
                    size="Small"
                    // icon={<RightCircleOutlined />}
                  >
                    Convert Datums
                    <RightCircleOutlined />
                  </Button>
                </Link>
              </Paragraph>
            </Col>
            <Col span={24}>
              <Title level={5}>General Real Estate Terms</Title>
            </Col>
            <Col>
              <Paragraph style={{ padding: '0px 0px 0px 20px' }}>
                <ul>
                  <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                    <b>Mortgage</b> - An agreement between you and a lender
                    (often a bank or other financial institution) that allows
                    you to borrow money purchase or maintain a home, land, or
                    other types of real estate.
                  </li>
                  <li style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                    <b>Title Company</b> – An entity that is responsible for
                    verifying that the title to the property is given to the
                    home buyer. The title company also ensures the seller has
                    the right to sell the property. Title companies often
                    provide insurance in the event that someone protests the
                    sale or declares the rights to the property in the future.
                  </li>
                  <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                    <b>Closing Documents</b> – A variety of documents associated
                    with the purchase/ownership of a home. Some examples are
                    closing disclosure, loan application, mortgage note, title
                    documents, deed, and initial escrow disclosure. Some
                    localities often have additional requirements and documents
                    needed for real estate closing.
                  </li>
                </ul>
              </Paragraph>
            </Col>
          </Row>
        </Container>
      </Wrapper>

      <Footer />
    </LandingLayout>
  )
}

export default KeyTerms

const InsuranceProcessBoxes = styled.h3`
  position: relative;
  z-index: 2;
  // font-size: 1.5rem;
  margin-right: 20px;
  margin-left: 20px;
  //   margin-top: 6px;
  padding: 2px;
  // padding-top: 50px;
  background: PapayaWhip;
  border: none;
  border-radius: 3px;
`
