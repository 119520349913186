import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link as ScrollLink } from 'react-scroll'
import { Button, Typography, Row, Grid } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import {
  Wrapper,
  Container,
} from '../../styled-components/styled-components'
import OrderSection from './components/OrderSectionInsuranceQuote'
import { Footer } from '../../components/Footer/Footer'
import PageHeader from '../../components/PageHeader'

const { Title, Paragraph } = Typography
const { useBreakpoint } = Grid

const FloodQuote = () => {
  const data = useStaticQuery(graphql`
    query {
      EC_Full: file(relativePath: { eq: "ElevationCertificate.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Section_B: file(relativePath: { eq: "Section_B_EC.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Section_C: file(relativePath: { eq: "Section_C_EC.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '30px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '50px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  return (
    <LandingLayout>
      <PageHeader title="Flood Insurance Quote" />

      <Wrapper>
        <Container style={{ padding: '20px 10px 10px 10px' }}>
          <Row gutter={[25, 10]}>
            <Paragraph>
              No Flood Florida has partnered with local flood insurance agents
              to provide comprehensive and competitive policy rates. A variety
              of components go into creating a flood insurance quote and it is
              important to discuss directly with a knowledgeable agent. Use the
              button below to fill out a flood insurance quote request to learn
              about what goes into your flood insurance policy.
            </Paragraph>
          </Row>
        </Container>
      </Wrapper>

      <OrderSection />
      <Footer />
    </LandingLayout>
  )
}

export default FloodQuote
