import React from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'

/**
 * Loader for going from screening form to recommendations.
 */
const Loader = () => {
  return (
    <SpinnerWrapper>
      <SpinnerContainer>
        <Spin size="large" style={{ color: '#fff' }}>
          <SpinnerMsg className="sr-only" style={{ color: '#fff' }}>
            Loading...
          </SpinnerMsg>
        </Spin>
      </SpinnerContainer>
    </SpinnerWrapper>
  )
}

export default Loader

const SpinnerMsg = styled.span`
  color: #666666;
`

const SpinnerContainer = styled.div`
  position: absolute;
  margin: '0 auto',
  display: flex;
  max-width: 1080px;
  align-items: center;
  text-align: center !important;
  left: 50%;
  right: 50%;
`

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
`
