import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import '../../UserInfo/PhoneCallForm.css'
import firebase from 'gatsby-plugin-firebase'
import {
  Form,
  Input,
  Modal,
  Button,
  Row,
  Col,
  message,
  Divider,
  Select,
  Upload,
  Typography
} from 'antd'
// import SuccessModal from '../SuccessModal'
import SuccessModal from '../../SuccessModal'
import './uploadQuoteForm.css'
import { UploadOutlined } from '@ant-design/icons'

const { Title, Paragraph, Text } = Typography

const doUploadFile = (fileToUpload, folder) => {
  return new Promise((resolve, reject) => {
    let progress = 0

    // File or Blob named nameOfFile.jpg
    const { originFileObj } = fileToUpload
    const file = originFileObj

    // Create the file metadata with the given file type
    const { type } = file
    const metadata = { contentType: type }

    function formatted_date() {
      var result = ''
      var d = new Date()
      result +=
        d.getFullYear() +
        '_' +
        (d.getMonth() + 1) +
        '_' +
        d.getDate() +
        '_' +
        d.getHours() +
        '_' +
        d.getMinutes() +
        '_' +
        d.getSeconds() +
        '_' +
        d.getMilliseconds()
      return result
    }

    // console.log(formatted_date())

    // console.log(metadata)

    // Create a reference to 'images/nameOfFile.jpg'
    const storageRef = firebase.storage().ref()

    // Upload file and metadata to the object 'images/nameOfFile.jpg'
    const path = folder + '/'
    storageRef
      .child(path + `${file.name}_${formatted_date()}`)
      .put(file, metadata)
      .then(uploadTaskSnapshot => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        progress =
          (uploadTaskSnapshot.bytesTransferred /
            uploadTaskSnapshot.totalBytes) *
          100
        console.log('Upload is ' + progress + '% done')

        // console.log('uploadTaskSnapshot: ', uploadTaskSnapshot);
        // Upload completed successfully, now we can get the download URL
        uploadTaskSnapshot.ref.getDownloadURL().then(downloadUrl => {
          resolve(downloadUrl)
          // downloadURL = downloadUrl
          // resolve({ downloadURL, progress })
        })
      })
  })
}

const InsuranceUploadForm = props => {
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  InsuranceUploadForm.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    propertyAddress: PropTypes.string
  }

  const { show, handleClose, userEmail, allData } = props

  // // try to sort all data so the list is in address order
  // const allDataSort = allData?.sort(function (a, b) {
  //   return b.template.data.address - a.template.data.address
  // })

  // // console.log('address:', propertyAddress)
  // console.log('all data:', allData)

  const handleOnSubmit = values => {
    values.timestamp = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York'
    })
    const { quote1, quote2, quote3 } = values

    if (quote1 || quote2 || quote3) {
      const promises = []
      if (quote1) {
        quote1.forEach(certificate1 => {
          promises.push(doUploadFile(certificate1, 'insuranceQuotes'))
        })
      }
      if (quote2) {
        quote2.forEach(certificate2 => {
          promises.push(doUploadFile(certificate2, 'insuranceQuotes'))
        })
      }
      if (quote3) {
        quote3.forEach(certificate3 => {
          promises.push(doUploadFile(certificate3, 'insuranceQuotes'))
        })
      }
      Promise.all(promises).then(downloadUrls => {
        console.log('downloadUrls:', downloadUrls)
        delete values.quote1 // replacing with downloadUrls
        delete values.quote2
        delete values.quote3
        values.downloadUrls = downloadUrls
        submitDataToFirestore(values)
      })
    } else {
      delete values.quote1 // replacing with downloadUrls
      delete values.quote2
      delete values.quote3
      values.downloadUrls = []
      submitDataToFirestore(values)
    }
  }

  const submitDataToFirestore = values => {
    // replace all undefined values with '' to make firestore happy
    const convertedString = JSON.stringify(values, function (key, value) {
      return value === undefined ? '' : value
    })
    const updatedValues = JSON.parse(convertedString)
    const insuranceQuoteObj = {
      // to: userEmail,
      // bcc: [
      //   'info@nofloodflorida.com',
      //   'sophia@nofloodflorida.com',
      //   'nofloodflorida+7t5d67e@nofloodflorida-org.myfreshworks.com'
      // ],

      insuranceQuotes: {
        ...updatedValues,
        status: 'Quote Submitted'
      }
    }
    console.log('insuranceQuoteObj:', insuranceQuoteObj)
    firebase
      .firestore()
      .collection('insuranceQuoteRequest')
      .where('template.data.address', '==', values.clientAddress)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(documentSnapshot => {
          // console.log('documentID:', documentSnapshot.id)
          documentSnapshot.ref.update(insuranceQuoteObj)
        })
      })
      .then(() => {
        setIsModalVisible(true)
        formRef.current.resetFields()
        // setLoading(true)
      })
      .catch(error => console.log('Error adding document:', error))
  }

  const normFile = e => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const handleOk = () => setIsModalVisible(false)

  const handleCancel = () => {
    setIsModalVisible(false)
    formRef.current.resetFields()
  }

  return (
    <>
      <SuccessModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        content="Insurance Quote Uploaded! Check the status of the quote by hovering over the Status field."
      />
      <Modal
        visible={show}
        onCancel={handleClose}
        title="New Flood Insurance Quote"
        footer={null}
        width={750}
        style={{ top: 20 }}
      >
        <Form
          ref={formRef}
          action="javascript:void(0);"
          onFinish={handleOnSubmit}
          id="new-insurance-quote-form"
          layout="vertical"
        >
          <p>
            <i>Welcome {userEmail}</i>
          </p>
          <p>
            Enter up to three flood insurance quotes for the following property,
            use drop down to select:
          </p>
          {/* <b>
            <p>{propertyAddress}</p>
            <p>{clientEmail}</p>
          </b> */}

          <Form.Item
            // label="Carrier Name"
            name="clientAddress"
            id="clientAddress"
            // list="available-addresses"
            rules={[{ required: true, message: `Please select an address.` }]}
          >
            <Select allowClear style={{ width: '85%' }}>
              {allData.map((product, index) => {
                return (
                  <Option key={index} value={product.template.data.address}>
                    <Text
                      style={{
                        backgroundColor: '#ffefd5',
                        padding: '4px 4px 4px 10px'
                      }}
                    >
                      {product.fullAddress}
                    </Text>
                    , Submitted on {product.template.data.timestamp}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          {/* <datalist id="available-addresses">
            {allData.map((product, index) => {
              return (
                <option key={index} value={product.template.data.address}>
                  <Text
                    style={{
                      backgroundColor: '#ffefd5',
                      padding: '4px 4px 4px 10px'
                    }}
                  >
                    {product.fullAddress}
                  </Text>
                  , Submitted on {product.template.data.timestamp}
                </option>
              )
            })}
          </datalist> */}
          <p>
            If a carrier <b>declined</b> coverage select Declined from the
            dropdown and enter a cost of 0.
          </p>
          <p>Populate quotes that contain PDFs first.</p>
          {/* <Divider /> */}
          <Row gutter={[30, 10]}>
            <Col
              xl={6}
              lg={6}
              md={8}
              sm={8}
              xs={8}
              style={{
                padding: '10px 10px 10px 10px'
              }}
            >
              <p
                style={{
                  backgroundColor: 'lightblue',
                  padding: '4px 4px 4px 10px'
                }}
              >
                Carrier #1
              </p>
            </Col>
          </Row>

          <Row gutter={[30, 10]}>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                label="Carrier Name"
                name="carrierName1"
                rules={[
                  { required: true, message: `Please choose a carrier.` }
                ]}
              >
                {/* <Input placeholder="Neptune" name="carrier_1_name" /> */}
                <Select>
                  <Select.Option value="hiscox">Hiscox</Select.Option>
                  <Select.Option value="incline">Incline</Select.Option>
                  <Select.Option value="neptune">Neptune</Select.Option>
                  <Select.Option value="typtap">Typ Tap</Select.Option>
                  <Select.Option value="wrightflood">
                    Wright Flood
                  </Select.Option>
                  <Select.Option value="declined">Declined</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                label="Total Cost $/year"
                name="carrierCost1"
                rules={[{ required: true, message: `Please enter a value.` }]}
              >
                <Input placeholder="1200" name="carrierCost1" />
              </Form.Item>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                name="quote1"
                label={
                  <div>
                    <span>Upload First Quote</span>
                  </div>
                }
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  name="quote1"
                  // action="/upload.do"
                  listType="images"
                  multiple={false}
                  // onChange={handleOnUploadChange}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[30, 10]}>
            <Col
              xl={6}
              lg={6}
              md={8}
              sm={8}
              xs={8}
              style={{
                padding: '10px 10px 10px 10px'
              }}
            >
              <p
                style={{
                  backgroundColor: 'lightblue',
                  padding: '4px 4px 4px 10px'
                }}
              >
                Carrier #2
              </p>
            </Col>
          </Row>

          <Row gutter={[30, 10]}>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                label="Carrier Name"
                name="carrierName2"
                rules={[
                  { required: true, message: `Please choose a carrier.` }
                ]}
              >
                <Select>
                  <Select.Option value="hiscox">Hiscox</Select.Option>
                  <Select.Option value="incline">Incline</Select.Option>
                  <Select.Option value="neptune">Neptune</Select.Option>
                  <Select.Option value="typtap">Typ Tap</Select.Option>
                  <Select.Option value="wrightflood">
                    Wright Flood
                  </Select.Option>
                  <Select.Option value="declined">Declined</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                label="Total Cost $/year"
                name="carrierCost2"
                rules={[{ required: true, message: `Please enter a value.` }]}
              >
                <Input placeholder="1200" name="carrierCost2" />
              </Form.Item>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                name="quote2"
                label={
                  <div>
                    <span>Upload Second Quote</span>
                  </div>
                }
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  name="quote2"
                  // action="/upload.do"
                  listType="images"
                  multiple={false}
                  // onChange={handleOnUploadChange}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[30, 10]}>
            <Col
              xl={6}
              lg={6}
              md={8}
              sm={8}
              xs={8}
              style={{
                padding: '10px 10px 10px 10px'
              }}
            >
              <p
                style={{
                  backgroundColor: 'lightblue',
                  padding: '4px 4px 4px 10px'
                }}
              >
                Carrier #3
              </p>
            </Col>
          </Row>
          <Row gutter={[30, 10]}>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                label="Carrier Name"
                name="carrierName3"
                rules={[
                  { required: true, message: `Please choose a carrier.` }
                ]}
              >
                <Select>
                  <Select.Option value="hiscox">Hiscox</Select.Option>
                  <Select.Option value="incline">Incline</Select.Option>
                  <Select.Option value="neptune">Neptune</Select.Option>
                  <Select.Option value="typtap">Typ Tap</Select.Option>
                  <Select.Option value="wrightflood">
                    Wright Flood
                  </Select.Option>
                  <Select.Option value="declined">Declined</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                label="Total Cost $/year"
                name="carrierCost3"
                rules={[{ required: true, message: `Please enter a value.` }]}
              >
                <Input placeholder="1200" name="carrierCost3" />
              </Form.Item>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                name="quote3"
                label={
                  <div>
                    <span>Upload Third Quote</span>
                  </div>
                }
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  name="quote3"
                  // action="/upload.do"
                  listType="images"
                  multiple={false}
                  // onChange={handleOnUploadChange}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Any additional details?" name="quoteMessage">
            <Input.TextArea
              placeholder="Unique insights or action items related to this quote?"
              rows={2}
              name="quoteMessage"
            />
          </Form.Item>

          <Row gutter={[8, 0]} justify="end">
            <Col>
              <Form.Item>
                <Button
                  type="secondary"
                  onClick={handleClose}
                  shape="round"
                  // loading={loading}
                  style={{
                    background: '#f9f9f9',
                    borderColor: '#f9f9f9'
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  loading={loading}
                  style={{
                    background: '#1c79c7',
                    borderColor: '#f9f9f9'
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default InsuranceUploadForm
