import React from 'react'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import { Wrapper, Container } from '../../styled-components/styled-components'
import { Row, Col, Typography, Divider } from 'antd'
import PageHeader from '../../components/PageHeader'
import { Footer } from '../../components/Footer/Footer'
import { useStaticQuery, graphql } from 'gatsby'
const { Title, Paragraph } = Typography
import Img from 'gatsby-image'

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      nfe: file(relativePath: { eq: "nfe_logo_full_text.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nff: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <LandingLayout>
      <PageHeader title="About Us" />
      <Wrapper>
        <Container>
          <Row justify="center">
            <Title level={2}>Our Promise to You!</Title>
          </Row>
          <Row>
            <Paragraph>
              No Flood Florida aims to provide its clients with the most
              accurate, up-to-date floodplain information. Data processing and
              final summarized information is confirmed through a thorough
              quality control process. We support the residents of Florida by
              providing reliable and comprehensive information pertaining to
              flood risk. Residents should be fully informed of all the related
              risks prior to purchasing a home. Our promise is to strive to
              provide the most accurate, user friendly mapping and analysis of
              flood risk throughout Florida. During this development phase we
              are eager to build strong relationships with all our potential
              partners.
            </Paragraph>
          </Row>

          <Divider style={{ border: '2px solid' }} className="nff-indigo" />

          <Row justify="center">
            <Title style={{ textAlign: 'center'}} level={3}>National Flood Experts Announces Strategic Acquisition of No Flood Florida</Title>
          </Row>

          <Row align='middle' gutter={[72, 16]} style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
            <Col span={12}>
              <Img fluid={data.nfe.childImageSharp.fluid} alt="National Flood Experts Logo" />
            </Col>

            {/**
              * The NFF logo is a tad larger with less "whitespace" padding around it than the NFE logo.
              * So we'll shrink it by having the span be a bit smaller than the NFE logo span and add an offset to space them apart.
             **/}
            <Col offset={2} span={8}>
              <Img fluid={data.nff.childImageSharp.fluid} alt="No Flood Florida Logo" />
            </Col>
          </Row>

          <Row>
            <Paragraph>
                <strong>TAMPA, November 1, 2023</strong> –  National Flood Experts (NFE), the leading specialized engineering firm focused on flood zone solutions nationwide, is thrilled to announce its recent strategic acquisition of No Flood Florida. This acquisition represents a significant milestone in NFE's ongoing growth and expansion strategy.
                The acquisition of No Flood Florida further solidifies NFE's commitment to excellence and innovation in the flood zone consulting industry. This strategic move aligns with NFE’s mission to continually enhance our product and service offerings to better serve our clients and partners.
            </Paragraph>
            <Paragraph>
                National Flood Experts would like to thank everyone involved in making this acquisition a reality. We look forward to working with No Flood Florida’s team, partners, and customers to expand our solutions and help as many people as possible save money and improve their property values.
            </Paragraph>
          </Row>

          <Divider style={{ border: '2px solid' }} className="nff-indigo" />

          <Row justify="center">
            <Title level={3}>About National Flood Experts</Title>
            <Paragraph>
                <strong>National Flood Experts</strong> (NFE) is a three-time Inc 5000 honoree (2021, 2022, 2023) and made Tampa Bay Business Journals' 2022 Fast 50 list of the fastest-growing companies in the Tampa Bay Region. National Flood Experts' mission is to advocate for the community, create financial opportunities that empower, and create a lasting legacy for our clients. A specialized engineering firm, NFE identifies niche solutions that increase NOI and add significant property value to assets in flood zones. NFE solutions have resulted in $300M+ in added property value and $20M+ in annual insurance premium savings for clients nationwide.
            </Paragraph>
          </Row>

          <Divider style={{ border: '2px solid' }} className="nff-indigo" />

          <Row justify="center">
            <Title level={3}>About No Flood Florida</Title>
            <Paragraph>
                <strong>No Flood Florida</strong> is a technology startup from the Tampa Bay area founded by two water resource engineers. The mission of No Flood Florida is to give the people of Florida the power to make informed decisions by organizing Florida’s latest flood data and making it readily available to all its home buyers.
            </Paragraph>
          </Row>

          <Divider style={{ border: '2px solid' }} className="nff-indigo" />
        </Container>
      </Wrapper>
      <Footer />
    </LandingLayout>
  )
}

export default About
