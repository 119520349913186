import React from 'react'
import { Row, Col, Typography } from 'antd'
import ExamineSvg from '../../../../assets/images/Examine.svg'
import styled from 'styled-components'
import { FaCheck } from 'react-icons/fa'
import HighRiskLearnMore from './HighRiskLearnMore'
import { BulletLabel } from '../StyledComponents'
import SearchAnotherPropertyBtn from '../BottomMenu/SearchAnotherPropertyBtn'

const { Title } = Typography

const NotApplicableHighRisk = ({
  selectedAddress,
  propertyData,
  setShowRecommendation,
  setShowScreening
}) => {
  const colSizes = {
    xxl: 12,
    xl: 12,
    lg: 24,
    md: 24,
    sm: 24,
    xs: 24
  }

  return (
    <>
      <Row style={{ marginBottom: '20px' }}>
        <Title level={2} style={{ color: '#fff' }}>
          It is not recommended to pursue a Letter of Map Amendment for this
          property.
        </Title>
      </Row>
      <Row gutter={[40, 20]}>
        <Col {...colSizes}>
          <hr
            style={{
              border: '3px solid #C7AE4A'
            }}
          />
          <Row gutter={[0, 30]} style={{ paddingBottom: '70px' }}>
            <Col span={24}>
              <Title level={4} style={{ color: '#fff', marginTop: '20px' }}>
                A Letter of Map Amendment is not applicable for this property.
              </Title>
            </Col>
            <Col span={24}>
              <FaCheck />
              <BulletLabel>Property Address:</BulletLabel>
              <span>{selectedAddress}</span>
            </Col>
            <Col span={24}>
              <FaCheck />
              <BulletLabel>FEMA Flood Zone:</BulletLabel>
              <span>{propertyData.FEMA_ZONE}</span>
            </Col>
            <Col span={24}>
              <FaCheck />
              <InfoText>
                FEMA considers this property to be in a high-risk flood zone, a
                special flood hazard area.
              </InfoText>
            </Col>
            <Col span={24}>
              <FaCheck />
              <InfoText>
                Flood insurance for this property <B>WILL</B> most likely be
                required.
              </InfoText>
            </Col>
          </Row>
          <hr
            style={{
              border: '3px solid #C7AE4A'
            }}
          />
        </Col>
        <Col {...colSizes}>
          <HighRiskLearnMore
            img={ExamineSvg}
            setShowRecommendation={setShowRecommendation}
            setShowScreening={setShowScreening}
          />
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <SearchAnotherPropertyBtn
              setShowRecommendation={setShowRecommendation}
              setShowScreening={setShowScreening}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default NotApplicableHighRisk

const InfoHeader = styled.div`
  font-size: 18px;
  font-weight: 600;
`

const InfoText = styled.span`
  font-weight: 500;
  margin-right: 20px;
  margin-left: 5px;
`

const ResultsContainer = styled.div`
  border-top: 6px solid #c7ae4a;
  border-bottom: 6px solid #c7ae4a;
  padding: 50px 0 130px 0;
`

const B = styled.b`
  font-weight: 600;
  color: #fff;
`

const InfoBoxWrapper = styled.div`
  /* padding: 20px 0 20px 60px; */
  padding: 20px 0 20px 40px;
  display: grid;
  grid-gap: 20px;
`
