import React, { useState, useEffect } from 'react'
import { Wrapper, Container } from '../../styled-components/styled-components'
import { Divider, Row, Col, Typography } from 'antd'
import { Link } from 'gatsby'
import {
  FaFacebookF,
  FaLinkedin,
  FaYoutube,
  AiOutlineBulb
} from 'react-icons/fa'
import './Footer.css'
import ContactUsForm from '../Eligibilty/ContactUsForm'

const { Title, Text } = Typography

export const Footer = () => {
  const [showContactModal, setShowContactModal] = useState(false)
  const titleStyles = {
    textAlign: 'left'
  }

  return (
    <Wrapper style={{ backgroundColor: '#f9f9f9' }}>
      <Container style={{ padding: '40px 0 40px 0' }}>
        {/* <button
          id="scroll-btn"
          className="sidebar-btns"
          title="Scroll to top"
        ></button> */}
        <Row span={24} gutter={[30, 30]}>
          <Col xl={6}>
            <Title level={4} style={titleStyles}>
              {/* ABOUT US */}
              <Link to="/about" activeStyle={{ color: '#16163f' }}>
                ABOUT US
              </Link>
            </Title>
            <Text>
                A Tampa Bay area based company providing engineering and insurance consulting solutions for flood zone properties across the state of Florida.
            </Text>
          </Col>
          <Col xl={6}>
            <Title level={4} style={titleStyles}>
              OUR MISSION
            </Title>
            To give the people of Florida the power to make informed decisions
            by organizing Florida’s latest flood data and making it readily
            available to all its home buyers.
          </Col>
          <Col xl={6}>
            <Title level={4} style={titleStyles}>
              100% RISK FREE
            </Title>
            Our complimentary reviews ensure you get the best information possible, risk free.
          </Col>
          <Col xl={6}>
            <Title level={4} style={titleStyles}>
              CONTACT US
            </Title>
            <a href="mailto:info@nofloodflorida.com">info@nofloodflorida.com</a>
            <div>
              <a href="tel:800-561-0396">Call (800) 561-0396</a>
            </div>
            <span
              style={{ color: '#55B96A', cursor: 'pointer' }}
              onClick={() => setShowContactModal(true)}
            >
              Submit Comment
            </span>
          </Col>
        </Row>
        <Divider />
        <Row
          justify="space-between"
          style={{ width: '85px', margin: '5px auto' }}
        >
          <a
            href="https://www.facebook.com/NationalFloodExperts/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookF size={18} className="socialIcon" />
          </a>
          <a
            href="https://www.linkedin.com/company/nationalfloodexperts/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin size={18} className="socialIcon" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCIiWG92VrVPbqqzpXbTfajw"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube size={18} className="socialIcon" />
          </a>
        </Row>
        <Row justify="center">© 2023 All rights reserved</Row>
        <ContactUsForm
          show={showContactModal}
          handleClose={() => setShowContactModal(false)}
        />
      </Container>
    </Wrapper>
  )
}
