import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Input,
  Modal,
  Button,
  Row,
  Col,
  message,
  Divider,
  Select,
  Typography
} from 'antd'
import firebase from 'gatsby-plugin-firebase'
import { normalizePhoneNumberInput } from '../../../utils/form/normalize'
import SuccessModal from '../../../components/SuccessModal'
import './InsuranceContactFormInternal.css'

const { Title, Paragraph } = Typography

const InsuranceQuoteFormInternal = props => {
  const formRef = useRef(null)
  const [loading2, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  InsuranceQuoteFormInternal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    userEmail: PropTypes.string
  }

  const { show, handleClose, userEmail } = props

  const handleInsuranceSubmit = values => {
    setLoading(true)
    values.timestamp = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York'
    })
    // Replace all undefined values with empty strings
    const convertedString = JSON.stringify(values, function (key, value) {
      return value === undefined ? '' : value
    })
    const updatedValues = JSON.parse(convertedString)

    // // comment out for testing purposes only
    // setLoading(false)
    // setIsModalVisible(true)

    const insuranceFormMessageObj = {
      to: userEmail,
      bcc: [
        'info@nofloodflorida.com',
        'sophia@nofloodflorida.com',
        'nofloodflorida+7t5d67e@nofloodflorida-org.myfreshworks.com'
      ],

      template: {
        name: 'insuranceQuoteFormTemplate',
        data: {
          ...updatedValues
        }
      }
    }

    firebase
      .firestore()
      .collection('insuranceQuoteForm')
      .add(insuranceFormMessageObj)
      .then(() => {
        setLoading(false)
        setIsModalVisible(true)
        formRef.current.resetFields()
      })
      .catch(error => console.log('Error adding document:', error))

    const insuranceQuoteMessageObj = {
      to: 'ruth.barrios@veritas-financial.com', //fill in with partner insurance company email
      cc: ['sophia@nofloodflorida.com', 'sheena@veritas-financial.com'],
      // to: 'taylordlankford@gmail.com',
      bcc: [
        'info@nofloodflorida.com',
        'nofloodflorida+7t5d67e@nofloodflorida-org.myfreshworks.com'
      ],
      insuranceQuotes: {
        carrierName1: '',
        carrierName1: '',
        carrierName1: '',
        clientAddress: updatedValues.address,
        carrierCost1: '',
        carrierCost2: '',
        carrierCost3: '',
        downloadUrls: '',
        quoteMessage: '',
        status: 'New',
        timestamp: ''
      },
      template: {
        name: 'insuranceQuoteRequestTemplate',
        data: {
          ...updatedValues,
          status: 'New'
        }
      }
    }

    firebase
      .firestore()
      .collection('insuranceQuoteRequest')
      .add(insuranceQuoteMessageObj)
      .then(() => {
        setLoading(false)
        setIsModalVisible(true)
        formRef.current.resetFields()
      })
      .catch(error => console.log('Error adding document:', error))
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  }

  const handleOk = () => setIsModalVisible(false)
  const handleCancel = () => setIsModalVisible(false)

  return (
    <>
      <SuccessModal
        content="Quote Request Submitted Successfully! Please check your email and/or spam folder for a confirmation."
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
      />
      <Modal
        visible={show}
        title="New Insurance Contact"
        footer={null}
        width={650}
      >
        <Form
          {...layout}
          ref={formRef}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleInsuranceSubmit}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={[20, 0]}>
            <Paragraph style={{ padding: '0px 10px 0px 10px' }}>
              <b>Note:</b> New contact will be added and sent to flood insurance
              partners.
            </Paragraph>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <Input
                  className="round"
                  size="large"
                  allowClear={true}
                  placeholder="John Doe"
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please input your email!'
                  }
                ]}
              >
                <Input
                  className="round"
                  size="large"
                  allowClear={true}
                  placeholder="info@nofloodflorida.com"
                  onInput={e =>
                    (e.target.value = ('' + e.target.value).toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                normalize={(value, prevValue, prevValues) =>
                  normalizePhoneNumberInput(value, prevValue)
                }
                label="Phone"
                name="phone"
                rules={[
                  { required: true, message: 'Please input your phone number.' }
                ]}
              >
                <Input
                  className="round"
                  size="large"
                  allowClear={true}
                  placeholder="(813) 213-0641"
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Address (for Flood Insurance Quote)"
                name="address"
                rules={[
                  {
                    required: true,
                    message: 'Please input your street address.'
                  }
                ]}
              >
                <Input
                  placeholder="123 Main St"
                  className="round"
                  size="large"
                  onInput={e =>
                    (e.target.value = ('' + e.target.value).toUpperCase())
                  }
                  allowClear={true}
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="City"
                name="city"
                rules={[{ required: true, message: 'Please input your city.' }]}
              >
                <Input
                  placeholder="Tampa"
                  className="round"
                  size="large"
                  onInput={e =>
                    (e.target.value = ('' + e.target.value).toUpperCase())
                  }
                  allowClear={true}
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  { required: true, message: 'Please input your state.' }
                ]}
              >
                <Input
                  placeholder="FL"
                  className="round"
                  size="large"
                  onInput={e =>
                    (e.target.value = ('' + e.target.value).toUpperCase())
                  }
                  allowClear={true}
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Zip Code"
                name="zipcode"
                rules={[
                  { required: true, message: 'Please input your zip code.' }
                ]}
              >
                <Input
                  placeholder="33624"
                  className="round"
                  size="large"
                  allowClear={true}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Message" name="message">
                <Input.TextArea
                  className="round"
                  placeholder="Provide additional information about your current insurance policy..."
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[8, 0]} justify="end">
            <Col>
              <Form.Item>
                <Button
                  type="secondary"
                  onClick={handleClose}
                  shape="round"
                  // loading={loading}
                  style={{
                    background: '#f9f9f9',
                    borderColor: '#f9f9f9'
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  loading={loading2}
                  style={{
                    background: '#1c79c7',
                    borderColor: '#f9f9f9'
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default InsuranceQuoteFormInternal
