import React from 'react'
import 'antd/dist/antd.less'
import './index.css'
import Landing from './landing'

const Home = () => {
  return <Landing />
}

export default Home
