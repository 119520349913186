import React from 'react'
import {
  Wrapper,
  Container
} from '../../../styled-components/styled-components'
import { Row, Col, Card, Typography, Grid } from 'antd'
import ContactUsForm from './ContactUsForm'

const { useBreakpoint } = Grid
const { Title } = Typography

const ContactUs = () => {
  const screens = useBreakpoint()

  const isMobile =
    !screens.xs && screens.sm && screens.md && !screens.lg && !screens.xl

  return (
    <Wrapper
      className="nff-indigo"
      style={{
        minHeight: '550px',
        padding: '40px 20px 40px 20px'
      }}
    >
      <Container>
        <Row span={24} gutter={[50, 0]}>
          <Col
            xl={10}
            lg={10}
            md={24}
            sm={24}
            xs={24}
            style={{
              margin: 'auto'
            }}
          >
            <Row
              justify="center"
              style={{
                borderTop: '1px solid #fff',
                borderBottom: '1px solid #fff',
                marginBottom: '20px'
              }}
            >
              <Title level={1} style={{ color: '#fff', paddingTop: '20px' }}>
                Contact Us
              </Title>
            </Row>
            <Row justify="center">
              <Title level={4} style={{ color: '#fff' }}>
                Get Started Today!
              </Title>
            </Row>
          </Col>
          <Col xl={14} lg={14} md={24} sm={24} xs={24}>
            <div style={{ margin: '0 auto' }}>
              <div style={{ margin: '0 auto', maxWidth: '720px' }}>
                <Card className="box-shadow round">
                  <ContactUsForm />
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default ContactUs
