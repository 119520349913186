import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link as ScrollLink } from 'react-scroll'
import { Button, Typography, Row, Col, Grid, Divider } from 'antd'
import { FormOutlined, FileOutlined } from '@ant-design/icons'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import {
  Wrapper,
  Container,
  ContainerImg
} from '../../styled-components/styled-components'
import { Footer } from '../../components/Footer/Footer'
import PageHeader from '../../components/PageHeader'
import ConversionForm from './components/sectionDatum'

const { Title, Paragraph } = Typography
const { useBreakpoint } = Grid

const vertDatumPage = () => {
  const data = useStaticQuery(graphql`
    query {
      NGS_Input: file(relativePath: { eq: "NGS_Example.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      NGS_Output: file(relativePath: { eq: "NGS_Result.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '30px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '50px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  return (
    <LandingLayout>
      <PageHeader title="Convert Vertical Datums" />
      <Wrapper>
        <Container style={{ padding: '20px 10px 0px 10px' }}>
          <Row>
            <Paragraph style={{ padding: '0px 0px 0px 5px' }}>
              <b>Vertical Datums</b> represent a surface of zero elevation to
              which heights of various points are referenced. Working with
              multiple datums is similar to working with various units. For
              example, one could state the length of piece of paper is 1 foot or
              12 inches.
              <ul>
                <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                  <b style={{ color: '#388E8E' }}>NGVD29-ft</b> – The Sea Level
                  Datum of 1929 was named the National Geodetic Vertical Datum
                  of 1929 on May 10, 1973. The datum is defined by the observed
                  heights of mean sea level at the 26 tide gauges and by the set
                  of elevations of all bench marks resulting from the
                  adjustment. This vertical datum was replaced by the NAVD88
                  datum and is not typically seen in floodplain mapping efforts
                  these days.
                </li>
                <li style={{ paddingBottom: '0px', paddingTop: '5px' }}>
                  <b style={{ color: '#388E8E' }}>NAVD88-ft</b> – The North
                  American Vertical Datum of 1988 (NAVD 88) is the vertical
                  control datum established in 1991 by the minimum-constraint
                  adjustment of the Canadian-Mexican-United States leveling
                  observations. In 1993 NAVD 88 was affirmed as the official
                  vertical datum in the National Spatial Reference System
                  (NSRS).{' '}
                  <b>
                    The NAVD88 datum is currently used in elevation studies and
                    floodplain mapping efforts.
                  </b>
                </li>
              </ul>
            </Paragraph>
          </Row>
          <Divider />
        </Container>
      </Wrapper>
      <ConversionForm />
      <Wrapper>
        <Container style={{ padding: '20px 10px 10px 10px' }}>
          <Title
            level={4}
            style={{ textAlign: 'left', padding: '0px 0px 0px 5px' }}
          >
            NGS Website Instructions
          </Title>
          <Row>
            <Paragraph style={{ padding: '0px 0px 0px 5px' }}>
              There are several sections that need to be configured to convert a
              vertical datum with the NGS Coordinate Conversion and
              Transformation Tool (NCAT). The following sections will help
              define the parameters for a typical vertical datum conversion
              between the NGVD29 and NAVD88 datums.
            </Paragraph>
          </Row>
          <Row>
            <Paragraph style={{ padding: '0px 0px 0px 5px' }}>
              <Button
                type="primary"
                className="box-shadow"
                shape="round"
                size="Small"
              >
                Step 1 - Configuration
              </Button>
            </Paragraph>
          </Row>
          <Row>
            <Paragraph style={{ padding: '0px 0px 0px 10px' }}>
              Configure the radio buttons based on the following selections:
              <ul>
                <li style={{ paddingBottom: '0px', paddingTop: '10px' }}>
                  Select <strong>Horizontal+height</strong> for
                  Convert/Transform from
                </li>
                <li style={{ paddingBottom: '0px', paddingTop: '10px' }}>
                  Select <strong>Geodetic lat-long</strong> for Select the type
                  of horizontal coordinate
                </li>
                <li style={{ paddingBottom: '0px', paddingTop: '10px' }}>
                  Select <strong>Orthometric</strong> for Select a height
                </li>
              </ul>
            </Paragraph>
          </Row>
          <Row>
            <Paragraph style={{ padding: '0px 0px 0px 5px' }}>
              <Button
                type="primary"
                className="box-shadow"
                shape="round"
                size="Small"
              >
                Step 2 - Location
              </Button>
            </Paragraph>
          </Row>
          <Row>
            <Paragraph style={{ padding: '0px 0px 0px 5px' }}>
              Next, copy and paste the <b>x, y coordinates</b> from the above
              form into the Lat Lon input (Enter lat-lon in decimal degrees).
              The copied coordinates are in decimal degrees. You can also use
              the degrees-minutes-seconds format if needed; there are many ways
              to configure the NGS tool.
            </Paragraph>
          </Row>
          <Row>
            <Paragraph style={{ padding: '0px 0px 0px 5px' }}>
              <Button
                type="primary"
                className="box-shadow"
                shape="round"
                size="Small"
              >
                Step 3 - Horizontal Datum
              </Button>
            </Paragraph>
          </Row>
          <Row>
            <Paragraph style={{ padding: '0px 0px 0px 5px' }}>
              Select the appropriate input/output reference frames. Typically,
              we refer to these as the <b>horizontal datum</b>. For Florida, we
              can select the <strong>NAD83(2011)</strong> horizontal datum
              option for both the input and output reference frame.
            </Paragraph>
          </Row>
          <Row>
            <Paragraph style={{ padding: '0px 0px 0px 5px' }}>
              <Button
                type="primary"
                className="box-shadow"
                shape="round"
                size="Small"
              >
                Step 4 - Height
              </Button>
            </Paragraph>
          </Row>
          <Row>
            <Paragraph style={{ padding: '0px 0px 0px 5px' }}>
              Enter an <b>orthometric height</b> in the next input field.{' '}
              <strong>Note:</strong> this tool utilizes units of meters not
              feet. It will be important to convert the final datum conversion
              value to feet in the final step. Using a value of 1 m is a good
              way to get a sense for how the elevation is changing from one
              datum to another.
            </Paragraph>
          </Row>
          <Row>
            <Paragraph style={{ padding: '0px 0px 0px 5px' }}>
              <Button
                type="primary"
                className="box-shadow"
                shape="round"
                size="Small"
              >
                Step 5 - Vertical Datum
              </Button>
            </Paragraph>
          </Row>
          <Row>
            <Paragraph style={{ padding: '0px 0px 0px 5px' }}>
              Use the drop downs to select the input and output{' '}
              <b>vertical datums</b>. For this example, we will be converting 1
              meter from <i>NGVD29</i> to <i>NAVD88</i>. Leave the SPC Zone to
              default.
            </Paragraph>
          </Row>
          <Title
            level={5}
            style={{ textAlign: 'left', padding: '0px 0px 0px 5px' }}
          >
            NGS Input Example
          </Title>
          <Row>
            <Paragraph style={{ padding: '0px 0px 0px 5px' }}>
              The screenshot below shows an example input form for the NGS datum
              conversion tool.
            </Paragraph>
          </Row>
          <ContainerImg>
            <Img fluid={data.NGS_Input.childImageSharp.fluid} />
          </ContainerImg>
          <Title
            level={5}
            style={{ textAlign: 'left', padding: '20px 0px 0px 5px' }}
          >
            Output Information
          </Title>
          <Row>
            <Paragraph style={{ padding: '0px 0px 0px 5px' }}>
              The screenshot below shows the output information from the NGS
              tool. We can see the input and output coordinates, orthometric
              heights, vertical datums and the Total Change observed during the
              conversion. In this example, an area near downtown Tampa, FL would
              use a conversion factor (going from NGVD29 to NAVD88) of around
              -0.262 m or <strong>-0.86 ft</strong> (multiply meters by 3.281 to
              get to feet). As another example, if the NGVD29-ft elevation was
              defined as 50.0 ft, the NAVD88-ft value would be 50.0 ft - 0.86 ft
              = 49.14 ft.
            </Paragraph>
          </Row>
          <ContainerImg>
            <Img fluid={data.NGS_Output.childImageSharp.fluid} />
          </ContainerImg>
        </Container>
      </Wrapper>

      <Footer />
    </LandingLayout>
  )
}

export default vertDatumPage
