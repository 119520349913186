import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Typography, Card, Row, Col, Grid } from 'antd'
import Img from 'gatsby-image'

const { Title } = Typography

const TeamCard = props => {
  const { member } = props
  const { useBreakpoint } = Grid
  const [hideBg, setHideBg] = useState(false)
  const screens = useBreakpoint()

  return (
    <Link to={`/about/profile`} state={{ member: member }}>
      <Card
        onMouseEnter={() => setHideBg(true)}
        onMouseLeave={() => setHideBg(false)}
        style={{ height: screens.xs ? '500px' : '100%', overflow: 'hidden' }}
        cover={<ProfileImg member={member} />}
        className="round"
        bodyStyle={{
          padding: '0',
          height: '100%'
        }}
      >
        <CardOverlay hideBg={hideBg} />
        <TextOverlay hideBg={hideBg}>
          <Row
            justify="center"
            style={{ paddingTop: '85%', textAlign: 'center' }}
          >
            <Col span={24}>
              <Row justify="center">
                <Title
                  level={3}
                  style={{
                    color: '#fff',
                    opacity: hideBg ? '0' : '1',
                    transition: '0.3s ease-in-out'
                  }}
                >
                  {member.name}
                </Title>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Title
                  level={5}
                  style={{
                    color: '#fff',
                    opacity: hideBg ? '0' : '1',
                    transition: '0.3s ease-in-out'
                  }}
                >
                  {member.title}
                </Title>
              </Row>
            </Col>
          </Row>
        </TextOverlay>
      </Card>
    </Link>
  )
}

const ProfileImg = ({ member }) => {
  return (
    <Img
      fluid={member.profileImg}
      style={{
        position: 'relative',
        objectFit: 'cover',
        height: '100%'
      }}
    />
  )
}

const CardOverlay = props => {
  return (
    <div
      style={{
        position: 'absolute',
        backgroundColor: '#16163f',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        opacity: props.hideBg ? 0 : 0.6,
        transition: '0.3s ease-in-out'
      }}
    ></div>
  )
}

const TextOverlay = props => (
  <div
    style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0
    }}
  >
    {props.children}
  </div>
)

export default TeamCard
