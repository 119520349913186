import React, { useContext } from 'react'
import { Router } from '@reach/router'
import { LandingLayout } from '../components/LandingLayout/LandingLayout'
import PrivateRoute from '../components/privateRoute'
import Profile from '../components/profile'
import Contacts from '../components/contacts'
import Login from '../pages/sign-in/login'
import { AuthProvider } from '../context/auth'

const App = () => {
  return (
    <AuthProvider>
      <Router basepath="/app">
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/contacts" component={Contacts} />
        <Login path="/login" />
      </Router>
    </AuthProvider>
  )
}
export default App
