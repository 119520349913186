import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/functions'
import React from 'react'
import AuthProvider from './src/context/auth'
import 'charts.css'

// require('prismjs/themes/prism-solarizedlight.css')
// require('./src/assets/prismThemes/prism-material-light.css')
require('./src/assets/prismThemes/prism-dracula.css')
// require('./src/assets/prismThemes/prism-darcula.css')

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
)
