import React, { useState, useContext } from 'react'
import { Button, Typography, Row, Col, Grid, Form } from 'antd'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import { Wrapper, Container } from '../../styled-components/styled-components'
import { Footer } from '../../components/Footer/Footer'
import PageHeader from '../../components/PageHeader'
import firebase from 'gatsby-plugin-firebase'
import { AuthContext } from '../../context/auth'
import { Link, navigate } from 'gatsby'

const { Title, Paragraph } = Typography
const { useBreakpoint } = Grid

const Login = () => {
  const [data, setData] = useState({
    name: '',
    email: '',
    password: '',
    error: null
  })

  const { setUser } = useContext(AuthContext)

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setData({ ...data, error: null })
    try {
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password)
      setUser(result)
      // console.log('user information', result)
      navigate('/app/profile')
    } catch (err) {
      setData({ ...data, error: err.message })
    }
  }

  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '30px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '50px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  return (
    <LandingLayout>
      <PageHeader title="Login" />
      <Wrapper>
        <Container style={{ padding: '20px 0 20px 0' }}>
          <Row span={24}>
            <Col span={12} xl={12} lg={12} md={12} sm={12} xs={24}>
              <Title level={4}>
                Welcome to the No Flood Florida Login Page
              </Title>
              <Paragraph style={{ padding: '0px 40px 0px 0px' }}>
                Through our dashboard and user profile you can access flood risk
                tools and other resources. Access our property, specific flood
                risk data as well as interact with our Flood Report module. More
                features to come!
              </Paragraph>
              <Paragraph style={{ padding: '0px 40px 0px 0px' }}>
                Not registered? Click{' '}
                <Link to="/sign-in/register" style={{ color: '#55B96A' }}>
                  Register
                </Link>{' '}
                to get started.
              </Paragraph>
            </Col>
            <Col span={12} xl={12} lg={12} md={12} sm={12} xs={24}>
              <form onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="email">Email</label>
                  <br />
                  <input
                    type="text"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                  />
                  <br />
                  <br />
                </div>
                <div>
                  <label htmlFor="password">Password</label>
                  <br />
                  <input
                    type="password"
                    name="password"
                    value={data.password}
                    onChange={handleChange}
                  />
                  <br />
                  <br />
                </div>

                {data.error ? (
                  <p style={{ color: 'red' }}>{data.error}</p>
                ) : null}
                <input type="submit" value="Login" />
              </form>
            </Col>
          </Row>
        </Container>
      </Wrapper>
      <Footer />
    </LandingLayout>
  )
}

export default Login
