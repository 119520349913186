import React from 'react'
import { PayPalButton } from 'react-paypal-button-v2'

export default class PayPalButtonComponent extends React.Component {
  render() {
    return (
      <PayPalButton
        style={{
          size: 'responsive',
          shape: 'pill',
          color: 'blue',
          label: 'checkout'
        }}
        options={{ vault: true }}
        createSubscription={(data, actions) => {
          return actions.subscription.create({
            plan_id: 'P-4X874984N6943791GMEEDREY'
          })
        }}
        onApprove={(data, actions) => {
          // Capture the funds from the transaction
          return actions.subscription.get().then(function (details) {
            // Show a success message to your buyer
            alert('Subscription completed')

            // OPTIONAL: Call your server to save the subscription
            return fetch('/paypal-subscription-complete', {
              method: 'post',
              body: JSON.stringify({
                orderID: data.orderID,
                subscriptionID: data.subscriptionID
              })
            })
          })
        }}
      />
    )
  }
}
