import React from 'react'
import styled from 'styled-components'
import { Button, Typography, Row, Col } from 'antd'
import { FileSearchOutlined } from '@ant-design/icons'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import { Wrapper, Container } from '../../styled-components/styled-components'
import { Footer } from '../../components/Footer/Footer'
import PageHeader from '../../components/PageHeader'
import partnershipGraphic from '../../assets/images/PartnershipGraphic.svg'
import realtorGraphic from '../../assets/images/RealtorGraphic.svg'
import insuranceGraphic from '../../assets/images/InsuranceGraphic.svg'
import homeInspectorGraphic from '../../assets/images/HomeInspector.svg'
import surveyGraphic from '../../assets/images/SurveyGraphic.svg'
import floodXpertLogo from '../../assets/images/FloodXpert_V2_update.png'
import PartnershipContactForm from './PartnershipContactForm'

const { Title, Paragraph, Text } = Typography

const Partners = () => {
  const colSizes = {
    xxl: 12,
    xl: 12,
    lg: 12,
    md: 12,
    sm: 24,
    xs: 24
  }
  return (
    <LandingLayout>
      <PageHeader title="Partners" />

      <Wrapper>
        <Container style={{ padding: '40px 0 40px 0' }}>
          <Row gutter={[40, 20]}>
            <Col
              xxl={6}
              xl={6}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              style={{ textAlign: 'center' }}
            >
              <img
                src={partnershipGraphic}
                style={{ maxHeight: '200px', maxWidth: '200px' }}
                draggable={false}
              />
            </Col>
            <Col xxl={18} xl={18} lg={18} md={24} sm={24} xs={24}>
              <Title level={1}>Partner Advantage</Title>
              <Paragraph>
                Get the data, technology and support to make the best business
                decisions - with No Flood Florida at your side.
              </Paragraph>
              <a href="#partnershipContactForm">
                <Button
                  shape="round"
                  type="primary"
                  className="box-shadow"
                  size="large"
                >
                  Enroll today
                </Button>
              </a>
            </Col>
          </Row>

          <Row align="left" style={{ marginTop: '60px' }}>
            <Title level={4}>Data system built with our users in mind.</Title>
          </Row>
          <Row align="center">
            <Paragraph>
              We believe making our data readily available to our professional
              partners will help us achieve our goal of helping Florida
              residents make informed decisions and save money. Find out how we
              can help you!
            </Paragraph>
          </Row>

          <Row style={{ marginTop: '60px' }} gutter={[40, 40]}>
            <Col {...colSizes}>
              <StyledGraphic src={realtorGraphic} draggable={false} />
              <Title level={4}>Realtors</Title>
              <Paragraph>
                Using our best available flood risk data, you will be able to
                provide your clients with the peace of mind of knowing the due
                diligence has been done for them. Get access to brief
                information for every property and for those unique situations
                get a detailed Flood Analysis Memo.
              </Paragraph>
            </Col>
            <Col {...colSizes}>
              <StyledGraphic src={insuranceGraphic} draggable={false} />
              <Title level={4}>Insurance Agents</Title>
              <Paragraph>
                Finding accurate structure and flood elevation data quickly will
                help your agents provide fast and accurate flood insurance
                quotes. Requesting elevation certificates to provide a quote is
                time consuming for you and your client.
              </Paragraph>
              {/* <Row gutter={[100, 20]}> */}
              {/* <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                  <img
                    src={floodXpertLogo}
                    draggable={false}
                    style={{ height: 40, width: 180 }}
                  />
                </Col> */}
              {/* <Col xxl={14} xl={14} lg={14} md={24} sm={24} xs={24}>
                  <a href="">
                    <Button
                      shape="round"
                      type="primary"
                      className="box-shadow"
                      size="large"
                      icon={<FileSearchOutlined />}
                    >
                      Learn More
                    </Button>
                  </a>
                </Col> */}
              {/* </Row> */}
            </Col>
            <Col {...colSizes}>
              <StyledGraphic src={homeInspectorGraphic} draggable={false} />
              <Title level={4}>Home Inspectors</Title>
              <Paragraph>
                Providing your client with a detailed Flood Analysis Memo with
                information on how they can save on their flood insurance is a
                great way to help you differentiate yourself from the
                competition. The best part is we are currently offering
                incentives to home inspectors looking to partner with us.
              </Paragraph>
            </Col>
            <Col {...colSizes}>
              <StyledGraphic src={surveyGraphic} draggable={false} />
              <Title level={4}>Surveyors</Title>
              <Paragraph>
                Most surveyors are sitting on a gold mine in the form of
                Elevation Certificates. Notifying your clients when they are
                eligible for a flood zone change and letting us work with them
                through the process will provide you with an additional stream
                of revenue.
              </Paragraph>
            </Col>
          </Row>

          <PartnershipContactForm />
        </Container>
      </Wrapper>
      <Footer />
    </LandingLayout>
  )
}

export default Partners

const StyledGraphic = styled.img`
  height: 90px;
  width: 90px;
  margin-bottom: 20px;
`
