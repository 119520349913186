import React, { useState, useEffect, useContext } from 'react'
import { Collapse, Typography, Row, Col, Grid, Divider, Button } from 'antd'
import { LandingLayout } from '../components/LandingLayout/LandingLayout'
import { Wrapper, Container } from '../styled-components/styled-components'
import { Footer } from '../components/Footer/Footer'
import PageHeader from '../components/PageHeader'
import { AuthContext } from '../context/auth'
import firebase from 'gatsby-plugin-firebase'
import '../components/collapseInfoProfile.css'
import { Link } from 'gatsby'
import { RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons'
import PhoneCallForm from './UserInfo/PhoneCallForm'
import InsuranceQuoteFormInternal from './Eligibilty/Insurance/InsuranceContactFormInternal'

//insert components
import PropertySearchInternal from './Eligibilty/PropertySearchInternal'
import PayPalButtonComponent from './BillingInfo/PayPalCheckOut'
import CreateNewAPIKey from '../components/UserInfo/CreateNewAPIKey'
import FloodReport from './Eligibilty/FloodReport'
import InsuranceEstimator from './Eligibilty/InsuranceEstimator'

const { Title, Paragraph, Text } = Typography
const { useBreakpoint } = Grid
const { Panel } = Collapse

const Profile = () => {
  useEffect(() => {
    if (typeof window === 'undefined' || !window.document) {
      // console.log(`bailing out of the useeffect. Going to continue to render??`)
      return
    }
  }, [])

  // console.log(`reached before render`)

  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '34px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '34px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  const { user } = useContext(AuthContext)

  const [userData, setUserData] = useState(null)
  const [showContactModal1, setShowContactModal1] = useState(false)
  const [showContactModal2, setShowContactModal2] = useState(false)

  const emailList = [
    'taylordlankford@gmail.com',
    // 'sophia@nofloodflorida.com',
    'ahmed@nofloodflorida.com',
    // 'taylor@nofloodflorida.com',
    'dmlankford@gmail.com',
    'sophia.hower@gmail.com'
  ]

  const insuranceList = [
    'ruth.barrios@veritas-financial.com',
    'sheena@veritas-financial.com',
    // 'taylordlankford@gmail.com'
    'taylor@nofloodflorida.com',
    'sophia@nofloodflorida.com'
  ]

  function handleCloseContactForm1() {
    setShowContactModal1(false)
  }
  function handleShowContactForm1() {
    setShowContactModal1(true)
  }

  function handleCloseContactForm2() {
    setShowContactModal2(false)
  }
  function handleShowContactForm2() {
    setShowContactModal2(true)
  }

  // const handleChange = e => {
  //   setApiUserData({ ...apiUserData, [e.target.name]: e.target.value })
  // }

  /**
   * Fetch the full firebase user data when the component mounts
   */
  useEffect(() => {
    if (user?.uid) {
      firebase
        .firestore()
        .collection('users')
        .where('uid', '==', user.uid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            if (doc) {
              const data = doc.data()
              setUserData(data)
            }
          })
        })
    }
  }, [])

  // used for grabbing all the contact form records, where the submission date is older than some user defined date

  return (
    <LandingLayout>
      {typeof window !== 'undefined' ? (
        <>
          <PageHeader title="Welcome to No Flood Florida" />
          <Wrapper>
            <Container style={{ padding: '20px 0px 20px 0px' }}>
              <Row span={24}>
                <Paragraph>
                  Manage your user profile information, billing details, and API
                  Keys. Interact with a streamlined version of the screening
                  tool, and develop more detailed reports in the Flood Report
                  module.
                </Paragraph>
              </Row>
              <Row justify="left" gutter={[0, 10]}>
                <Title level={3}>Profile Information</Title>
              </Row>
              <Row justify="left" gutter={[0, 10]}>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ padding: '0px 20px 0px 0px' }}
                >
                  <ul>
                    <li style={{ textTransform: 'capitalize' }}>
                      <strong>First Name:</strong> {userData?.firstName}
                    </li>
                    <li style={{ textTransform: 'capitalize' }}>
                      <strong>Last Name:</strong> {userData?.lastName}
                    </li>
                    <li style={{ textTransform: 'capitalize' }}>
                      <strong>Company Name:</strong> {userData?.companyName}
                    </li>
                    {userData?.industryName ? (
                      <>
                        <li style={{ textTransform: 'capitalize' }}>
                          <strong>Industry:</strong> {userData?.industryName}
                        </li>
                      </>
                    ) : (
                      <>
                        {' '}
                        <Col
                          xl={7}
                          lg={7}
                          md={7}
                          sm={7}
                          xs={20}
                          style={{ padding: '0px 20px 0px 20px' }}
                        ></Col>
                      </>
                    )}
                    <li>
                      <strong>E-mail:</strong> {user?.email}
                    </li>
                    {/* <li>
                <strong>API Month Limit:</strong> {apiUserData?.monthLimit},{' '}
                {test}
              </li> */}
                  </ul>
                </Col>
                {emailList.includes(user?.email) ? (
                  <>
                    <Col
                      xl={7}
                      lg={7}
                      md={7}
                      sm={7}
                      xs={20}
                      style={{ padding: '0px 20px 0px 20px' }}
                    >
                      <div style={{ padding: '10px 20px 10px 20px' }}>
                        <Link to="/app/contacts">
                          <Button
                            block
                            size="medium"
                            type="primary"
                            shape="round"
                            // icon={<RightCircleOutlined />}
                          >
                            Manage Contacts
                            <RightCircleOutlined />
                          </Button>
                        </Link>
                      </div>

                      <div style={{ padding: '10px 20px 10px 20px' }}>
                        <Button
                          block
                          size="medium"
                          type="primary"
                          shape="round"
                          // icon={<LeftCircleOutlined />}
                          style={{
                            background: '#1c79c7',
                            borderColor: '#f9f9f9'
                          }}
                          onClick={handleShowContactForm1}
                        >
                          New Phone Call
                          <RightCircleOutlined />
                        </Button>
                      </div>
                      <PhoneCallForm
                        show={showContactModal1}
                        handleClose={handleCloseContactForm1}
                        userEmail={user?.email}
                      />

                      <div style={{ padding: '10px 20px 10px 20px' }}>
                        <Button
                          block
                          size="medium"
                          type="primary"
                          shape="round"
                          // icon={<LeftCircleOutlined />}
                          style={{
                            background: '#6F8FAF',
                            borderColor: '#f9f9f9'
                          }}
                          onClick={handleShowContactForm2}
                        >
                          New Insurance Quote
                          <RightCircleOutlined />
                        </Button>
                      </div>
                      <InsuranceQuoteFormInternal
                        show={showContactModal2}
                        handleClose={handleCloseContactForm2}
                        userEmail={user?.email}
                      />
                    </Col>
                  </>
                ) : insuranceList.includes(user?.email) ? (
                  <>
                    {' '}
                    <Col
                      xl={7}
                      lg={7}
                      md={7}
                      sm={7}
                      xs={20}
                      style={{ padding: '0px 20px 0px 20px' }}
                    >
                      <div style={{ padding: '10px 20px 10px 20px' }}>
                        <Link to="/app/contacts">
                          <Button
                            block
                            size="medium"
                            type="primary"
                            shape="round"
                            style={{
                              background: '#1c79c7',
                              borderColor: '#f9f9f9'
                            }}
                            // icon={<RightCircleOutlined />}
                          >
                            Manage Quotes
                            <RightCircleOutlined />
                          </Button>
                        </Link>
                      </div>

                      {/* <div style={{ padding: '10px 20px 10px 20px' }}>
                        <Button
                          block
                          size="medium"
                          type="primary"
                          shape="round"
                          // icon={<LeftCircleOutlined />}
                          style={{
                            background: '#1c79c7',
                            borderColor: '#f9f9f9'
                          }}
                          onClick={handleShowContactForm}
                        >
                          New Phone Call
                          <RightCircleOutlined />
                        </Button>
                      </div>
                      <PhoneCallForm
                        show={showContactModal}
                        handleClose={handleCloseContactForm}
                        userEmail={user?.email}
                      /> */}
                    </Col>
                  </>
                ) : (
                  <Row justify="center" gutter={[0, 10]}>
                    <Paragraph>
                      Welcome to NFF Dashboard. Use the drop downs below to
                      explore flood risk tools.
                    </Paragraph>
                  </Row>
                )}
              </Row>
              <Divider />
              <Collapse
                defaultActiveKey={[]}
                ghost
                style={{ padding: '0px 0px 0px 0px' }}
              >
                <Panel header="Billing Details" key="1">
                  {/* <Row justify="left" gutter={[0, 10]}>
                    <Title level={3}> Billing Information</Title>
                  </Row> */}
                  <Row gutter={[0, 10]}>
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={24}
                      style={{ padding: '0px 20px 0px 0px' }}
                    >
                      <Paragraph>
                        Manage your billing information related to API access.
                        And learn about the different pricing options related to
                        use of the API.
                      </Paragraph>
                      <Text>
                        Standard monthly fees are required for the connection to
                        the API. All subsequent use of the API will be billed in
                        addition to this every month.
                      </Text>
                    </Col>
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={24}
                      style={{ padding: '0px 40px 0px 40px' }}
                    >
                      <PayPalButtonComponent />
                    </Col>
                    {/* <div>
                      <br />
                    </div> */}
                  </Row>
                </Panel>
                <Panel header="API Information" key="2">
                  <CreateNewAPIKey />
                </Panel>
                <Panel header="Basic Property Search" key="3">
                  <PropertySearchInternal />
                </Panel>
                <Panel header="Flood Report" key="4">
                  <FloodReport />
                </Panel>
                <Panel header="Flood Insurance Estimator" key="5">
                  <InsuranceEstimator />
                </Panel>
              </Collapse>
            </Container>
          </Wrapper>
          <Footer />
        </>
      ) : (
        <Row justify="center" gutter={[0, 10]}>
          <Loader />
        </Row>
      )}
    </LandingLayout>
  )
}

export default Profile
