import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { Container } from '../../styled-components/styled-components'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import firebase from 'gatsby-plugin-firebase'
import axios from 'axios'
import Map from '../esri/map/Map'
import SoilProcess from './Soils/SoilProcess'
import { mapLoaded } from '../../redux/reducers/map'
import { AuthContext } from '../../context/auth'
import {
  AiFillBulb,
  AiFillWarning,
  AiFillPieChart,
  AiFillTool,
  AiFillShop,
  AiFillCheckCircle,
  AiFillEnvironment
} from 'react-icons/ai'

import Loader from '../Eligibilty/Recommendation/Loader'

/* Styles */
import { Row, Col, Typography, Grid } from 'antd'

/* Components */
import AutoSuggestInternal from '../AutoSuggestInternal/AutoSuggestInternal'

import Coastal from '../../assets/images/Coastal.svg'
import Riverine from '../../assets/images/Riverine.svg'
import LowTerrain from '../../assets/images/LowTerrain.svg'

import noImpactBuilding from '../../assets/images/noImpactBuilding.svg'
import CRS_01 from '../../assets/images/CRS_01_new.svg'
import CRS_02 from '../../assets/images/CRS_02_new.svg'
import CRS_03 from '../../assets/images/CRS_03_new.svg'
import CRS_04 from '../../assets/images/CRS_04_new.svg'
import CRS_05 from '../../assets/images/CRS_05_new.svg'
import CRS_06 from '../../assets/images/CRS_06_new.svg'
import CRS_07 from '../../assets/images/CRS_07_new.svg'
import CRS_08 from '../../assets/images/CRS_08_new.svg'
import CRS_09 from '../../assets/images/CRS_09_new.svg'
import CRS_10 from '../../assets/images/CRS_10_new.svg'

import Impact_0_1 from '../../assets/images/Impact_0_1.svg'
import Impact_1_2 from '../../assets/images/Impact_1_2.svg'
import Impact_2_3 from '../../assets/images/Impact_2_3.svg'
import Impact_3_Up from '../../assets/images/Impact_3_Up.svg'

const { Title, Paragraph } = Typography
const { useBreakpoint } = Grid

/**
 * Controls the 'flow' of displaying the screeening and various recommendations
 */
const FloodReport = () => {
  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '34px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '34px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  const [selectedAddress, setSelectedAddress] = useState('')
  const [selectedCounty, setSelectedCounty] = useState(null)

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setSelectedAddress(suggestion)
  }

  function updateParentCountyState(county) {
    setSelectedCounty(county)
  }

  const handleProceedButton = address => {
    setSelectedAddress(address)
  }

  /* Render IFrame Landing */
  return (
    <IFrameLanding
      onSuggestionSelected={onSuggestionSelected}
      updateParentCountyState={updateParentCountyState}
      firebase={firebase}
      handleProceedButton={handleProceedButton}
      selectedCounty={selectedCounty}
      selectedAddress={selectedAddress}
    />
  )
}

const IFrameLanding = props => {
  const { user } = useContext(AuthContext)

  const [apiUserData, setAPIUserData] = useState(null)
  const [dataSourceRequest, setDataSourceRequest] = useState()
  const [loadingFloodReport, setLoadingFloodReport] = useState(false);
  const [locationSourceRequest, setLocation] = useState()
  const [propertyData, setPropertyData] = useState({
    data: '',
    loading: true
  })
  const [xyData, setXYData] = useState({
    data: '',
    loading: true
  })

  /**
   * Fetch the full firebase user data when the component mounts
   */
  useEffect(() => {
    if (user?.uid) {
      firebase
        .firestore()
        .collection('apiUsers')
        .where('uid', '==', user.uid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            if (doc) {
              const data = doc.data()
              setAPIUserData(data)
            }
          })
        })
    }
  }, [])

  IFrameLanding.propTypes = {
    onSuggestionSelected: PropTypes.func,
    updateParentCountyState: PropTypes.func,
    firebase: PropTypes.object,
    handleProceedButton: PropTypes.func,
    selectedCounty: PropTypes.string,
    selectedAddress: PropTypes.string
  }
  const {
    onSuggestionSelected,
    updateParentCountyState,
    firebase,
    selectedCounty,
    selectedAddress
  } = props

  const zipCode = selectedAddress.split(' ').slice(-1)
  const firstAddress = selectedAddress.substr(0, selectedAddress.indexOf(','))
  const apiKey = apiUserData?.apiKey

  const handleClick = async () => {
    setLoadingFloodReport(true);
    const data = await axios.get(
      `https://us-central1-flood-score.cloudfunctions.net/getProperty?state=Florida&county=${selectedCounty}&zip=${zipCode}&streetAddress=${firstAddress}&apiKey=${apiKey}`
    )
    setPropertyData({
      data: data,
      loading: false
    })

    const dataSource = [
      {
        key: '1',
        NFFID: data.data.properties[0].NFF_ID,
        address: data.data.properties[0].FULL_ADD,
        femazone: data.data.properties[0].FEMA_ZONE,
        femabfe: parseFloat(data.data.properties[0].FEMA_BFE).toFixed(2),
        sge: parseFloat(data.data.properties[0].SGE).toFixed(1),
        loma: data.data.properties[0].LOMA,
        femaDate: data.data.properties[0].FEMA_EFF_DT,
        community: data.data.properties[0].Community,
        crs: data.data.properties[0].CRS,
        strucPct: data.data.properties[0].STRUC_PCT,
        fldDepth: data.data.properties[0].FEMA_BFE - data.data.properties[0].SGE
      }
    ]

    setDataSourceRequest(dataSource)

    // using the esri geocode to get x,y location
    const xyData = await axios.get(
      `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?singleLine=${data.data.properties[0].FULL_ADD}&outFields=Addr_type&f=pjson`
    )
    setXYData({
      data: xyData,
      loading: false
    })

    const locationSource = [
      {
        key: '1',
        locHome: xyData.data.candidates[0],
        countyName: selectedCounty
      }
    ]

    setLocation(locationSource)
    setLoadingFloodReport(false);
  }

  return (
    <>
      <Row textAlign="left" gutter={[0, 10]}>
        <Paragraph>
          The Flood Report module provides a more user-friendly understanding of
          flood risk, starting with a basic definition of flood risk within
          Florida and various locations across the world. The unique
          characteristics for the property of interest will also be explored.
          This provides a similar example as our Flood Analysis Memo (FAM).
        </Paragraph>
      </Row>
      <AutoSuggestRow span={24}>
        <Col span={24} style={{ padding: '0', margin: '0 auto' }}>
          <AutoSuggestInternal
            autocomplete="off"
            theme={autosuggestTheme}
            countySelectStyles={countySelectStyles}
            updateParentCountyState={updateParentCountyState}
            onSuggestionSelected={onSuggestionSelected}
            inputProps={{ id: 'eligibilityAddressSuggest' }}
            firebase={firebase}
          />
        </Col>
        <Row
          span={24}
          style={{ padding: '0', margin: '0 auto', textAlign: 'center' }}
        >
          <div>
            <br />
            <FloodReportButton onClick={handleClick}>Proceed</FloodReportButton>
            <br />
            <br />
          </div>
        </Row>
      </AutoSuggestRow>
      <Container id="fullReport" style={{ padding: '20px 0 20px 0' }}>
        <Col
          span={24}
        >
          {dataSourceRequest ? (
            <>
              <Row justify="left" gutter={[0, 10]}>
                <Title level={4}>What is Flood Risk?</Title>

                <a
                  href="/blog/RiskRating2.0"
                  target="_blank"
                  rel="noreferrer"
                >
                  <AiFillBulb
                    size={28}
                    style={{
                      margin: '0 5px',
                      color: '#f4ca16',
                      textAlign: 'center'
                    }}
                  />
                </a>
              </Row>
              <Row textAlign="left" gutter={[5, 10]}>
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  Flood risk is influenced by a variety of environmental, local,
                  and developmental pressures. Flooding can be caused by high
                  volumes or intensities of rainfall during a short period of
                  time or an accumulation of rainfall over a longer wet season
                  period. In Florida, we typically focus of three main sources
                  of flooding:{' '}
                  <strong>Coastal, Riverine, and Low-Lying Areas.</strong>
                </Col>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={24}
                  flex={8}
                  align="center"
                >
                  <a
                    href="/flooding-concerns"
                    target="_blank"
                    title="Learn More about Coastal Flood Risk"
                  >
                    <StyledGraphic src={Coastal} draggable={false} />
                  </a>
                  <a
                    href="/flooding-concerns"
                    target="_blank"
                    title="Learn More about Riverine Flooding"
                  >
                    <StyledGraphic src={Riverine} draggable={false} />
                  </a>
                  <a
                    href="/flooding-concerns"
                    target="_blank"
                    title="Learn More about Depressional Flooding"
                  >
                    <StyledGraphic src={LowTerrain} draggable={false} />
                  </a>
                </Col>
              </Row>

              <br />
              <Row justify="left" gutter={[0, 10]}>
                <Title level={4}>FEMA Floodplain Data </Title>

                <AiFillWarning
                  size={28}
                  style={{
                    margin: '0 5px',
                    color: '#ff4040',
                    textAlign: 'center'
                  }}
                />
              </Row>

              <Row justify="left" gutter={[0, 10]}>
                <Choose>
                  <When condition={dataSourceRequest[0]?.femabfe != -9999}>
                    <Title level={4} style={{ color: '#d10000 ' }}>
                      HIGH RISK FLOOD ZONE
                    </Title>
                  </When>
                  <When condition={dataSourceRequest[0]?.femabfe == -9999}>
                    <Title level={4} style={{ color: '#388E8E' }}>
                      LOW RISK ZONE
                    </Title>
                  </When>
                </Choose>
              </Row>

              <Row textAlign="left" gutter={[25, 10]} align="middle">
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  The property at{' '}
                  <b>
                    <Link
                      to={
                        'https://www.google.com/maps/place/' +
                        dataSourceRequest[0]?.address
                      }
                      target="_blank"
                      style={{ color: '#425466' }}
                      title="Go Go Google Maps!"
                    >
                      {dataSourceRequest[0]?.address
                        .toLowerCase()
                        .split(' ')
                        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' ')}{' '}
                    </Link>
                  </b>
                  is located in the FEMA Zone{' '}
                  <b>{dataSourceRequest[0]?.femazone}</b>. This property is
                  considered to be in the{' '}
                  <If condition={dataSourceRequest[0]?.femabfe == -9999}>
                    FEMA <b>Low Risk</b> Zone. Typically, properties located in
                    the Low Risk floodplain are <b>NOT</b> required to purchase
                    mandatory flood insurance. However, all properties can flood
                    and it is important to know your propery's specific risk. We
                    can help!
                  </If>
                  <If condition={dataSourceRequest[0]?.femabfe != -9999}>
                    FEMA <b>High Risk</b> Zone. Typically, properties located in
                    the High Risk floodplain <b>are</b> required to purchase
                    mandatory flood insurance. All properties can flood and it
                    is important to know your propery's specific risk. We can
                    help!
                  </If>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24} align="center">
                  <Choose>
                    <When condition={dataSourceRequest[0]?.femabfe != -9999}>
                      <FEMAZoneStyle level={2} style={{ color: '#d10000 ' }}>
                        Zone {dataSourceRequest[0]?.femazone.split(',')[0]}
                      </FEMAZoneStyle>
                    </When>
                    <When condition={dataSourceRequest[0]?.femabfe == -9999}>
                      <FEMAZoneStyle level={2} style={{ color: '#388E8E' }}>
                        Zone {dataSourceRequest[0]?.femazone.split(',')[0]}
                      </FEMAZoneStyle>
                    </When>
                  </Choose>
                </Col>
              </Row>
              <br />

              <Row gutter={[0, 10]} align="center">
                {locationSourceRequest ? (
                  <Map
                    onMapLoaded={mapLoaded}
                    position={locationSourceRequest[0]?.locHome}
                    countyName={locationSourceRequest[0]?.countyName}
                    zoomLevel={19}
                    fixedZoom={false}
                    searchFunction={true}
                  />
                ) : (
                  <Map
                    onMapLoaded={mapLoaded}
                  />
                )}
              </Row>
              <br />
              <br />

              <Row justify="left" gutter={[0, 10]}>
                <Title level={4}>Structure Impacts</Title>

                <AiFillTool
                  size={28}
                  style={{
                    margin: '0 5px',
                    color: '#acacac',
                    textAlign: 'center'
                  }}
                />
              </Row>

              <Row textAlign="left" gutter={[5, 10]} align="middle">
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Choose>
                    <When condition={dataSourceRequest[0]?.femabfe != -9999}>
                      <If condition={dataSourceRequest[0]?.fldDepth < 0}>
                        <Title level={4} style={{ color: '#d10000' }}>
                          Floodplain Area Impact Only
                        </Title>
                        <strong>
                          Potential for Letter of Map Amendment (LOMA)
                        </strong>
                      </If>
                      <If condition={dataSourceRequest[0]?.fldDepth > 0}>
                        <Title level={4} style={{ color: '#d10000' }}>
                          Impacts to Structure
                        </Title>
                      </If>
                    </When>

                    <When condition={dataSourceRequest[0]?.femabfe == -9999}>
                      <Title level={4} style={{ color: '#388E8E' }}>
                        No Structure Impacts
                      </Title>
                    </When>
                  </Choose>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24} align="center">
                  <Choose>
                    <When condition={dataSourceRequest[0]?.femabfe != -9999}>
                      <If condition={dataSourceRequest[0]?.loma == 1}>
                        <Title level={4} style={{ color: '#388E8E' }}>
                          LOMA Eligibility Low
                        </Title>
                        <a
                          href="/order-loma"
                          target="_blank"
                          rel="noreferrer"
                          title="Start the LOMA Process Today"
                        >
                          <AiFillCheckCircle
                            size={45}
                            style={{
                              margin: '0 5px',
                              color: '#50C878',
                              textAlign: 'center',
                              justify: 'center'
                            }}
                          />
                        </a>
                      </If>
                      <If condition={dataSourceRequest[0]?.loma == 2}>
                        <Title level={3} style={{ color: '#388E8E' }}>
                          LOMA Eligibility Medium
                        </Title>
                        <a
                          href="/order-loma"
                          target="_blank"
                          rel="noreferrer"
                          title="Start the LOMA Process Today"
                        >
                          <AiFillCheckCircle
                            size={45}
                            style={{
                              margin: '0 5px',
                              color: '#50C878',
                              textAlign: 'center',
                              justify: 'center'
                            }}
                          />
                        </a>
                      </If>
                      <If condition={dataSourceRequest[0]?.loma == 3}>
                        <Title level={3} style={{ color: '#388E8E' }}>
                          LOMA Eligibility High
                        </Title>

                        <a
                          href="/order-loma"
                          target="_blank"
                          rel="noreferrer"
                          title="Start the LOMA Process Today"
                        >
                          <AiFillCheckCircle
                            size={45}
                            style={{
                              margin: '0 5px',
                              color: '#50C878',
                              textAlign: 'center',
                              justify: 'center'
                            }}
                          />
                        </a>
                      </If>
                    </When>
                  </Choose>
                </Col>
              </Row>

              <Row textAlign="left" gutter={[5, 10]}>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ padding: '0px 20px 0px 0px' }}
                >
                  <Choose>
                    <When condition={dataSourceRequest[0]?.femabfe != -9999}>
                      <If condition={dataSourceRequest[0]?.fldDepth < 0}>
                        There are <strong>no</strong> anticipated impacts to the
                        building structure at this location. It appears that the
                        FEMA Base Flood Elevation (BFE) is actually below the
                        Structure Ground Elevation (SGE) estimated by No Flood
                        Florida. <strong>See LOMA Eligibility Status!</strong>
                      </If>
                      <If condition={dataSourceRequest[0]?.fldDepth > 0}>
                        <If condition={dataSourceRequest[0]?.fldDepth <= 1}>
                          Property could have up to{' '}
                          <strong>
                            {parseFloat(dataSourceRequest[0]?.fldDepth).toFixed(
                              2
                            )}{' '}
                            foot
                          </strong>{' '}
                          of flood impact to the building structure! See
                          detailed values and figures.
                        </If>
                      </If>
                      <If condition={dataSourceRequest[0]?.fldDepth > 1}>
                        <If condition={dataSourceRequest[0]?.fldDepth <= 2}>
                          Property could have up to{' '}
                          <strong>
                            {parseFloat(dataSourceRequest[0]?.fldDepth).toFixed(
                              2
                            )}{' '}
                            feet
                          </strong>{' '}
                          of flood impact to the building structure! See
                          detailed values and figures.
                        </If>
                      </If>
                      <If condition={dataSourceRequest[0]?.fldDepth > 2}>
                        <If condition={dataSourceRequest[0]?.fldDepth <= 3}>
                          Property could have up to{' '}
                          <strong>
                            {parseFloat(dataSourceRequest[0]?.fldDepth).toFixed(
                              2
                            )}{' '}
                            feet
                          </strong>{' '}
                          of flood impact to the building structure! See
                          detailed values and figures.
                        </If>
                      </If>
                      <If condition={dataSourceRequest[0]?.fldDepth > 3}>
                        Property could have <strong>greater than 3 feet</strong>{' '}
                        of flood impact to the building structure! See detailed
                        values and figures.
                      </If>
                      <br />
                      <br />
                      <strong>FEMA BFE</strong> ={' '}
                      {parseFloat(dataSourceRequest[0]?.femabfe).toFixed(2)}{' '}
                      <i>NAVD88-ft</i>
                      <br />
                      <strong>Ground Elevation</strong> ={' '}
                      {parseFloat(dataSourceRequest[0]?.sge).toFixed(2)}{' '}
                      <i>NAVD88-ft</i>
                    </When>
                    <When condition={dataSourceRequest[0]?.femabfe == -9999}>
                      There are <strong>no</strong> anticipated impacts to the
                      building structure at this location. Although this
                      property is located in the FEMA low-risk floodplain, all
                      homes can flood and it is important to know your true
                      risk.
                      <br />
                      <br />
                      The elevation around the building structure is estimated
                      from LiDAR data to be approximately:
                      <br />
                      <strong>Structure Ground Elevation</strong> ={' '}
                      {parseFloat(dataSourceRequest[0]?.sge).toFixed(2)}{' '}
                      <i>NAVD88-ft</i>
                    </When>
                  </Choose>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Choose>
                    <When condition={dataSourceRequest[0]?.femabfe != -9999}>
                      <If condition={dataSourceRequest[0]?.fldDepth < 0}>
                        Based on the Effective FEMA floodplain, the Base Flood
                        Elevation (BFE) is estimated to be below the building
                        structure, about{' '}
                        <strong>
                          {' '}
                          {Math.abs(
                            dataSourceRequest[0]?.fldDepth.toFixed(2)
                          )}{' '}
                          feet below
                        </strong>{' '}
                        the building structure.
                        <br />
                        <br />
                        <Link
                          to="/order-loma"
                          target="_blank"
                          style={{ color: '#55B96A' }}
                        >
                          <b>Click here</b>
                        </Link>{' '}
                        to learn more about the Letter of Map Amendment process
                        and how it could save you thousands on flood insurance!
                      </If>
                      <If condition={dataSourceRequest[0]?.fldDepth > 0}>
                        <If condition={dataSourceRequest[0]?.fldDepth <= 1}>
                          <StyledGraphicLarger
                            src={Impact_0_1}
                            draggable={false}
                          />
                        </If>
                      </If>
                      <If condition={dataSourceRequest[0]?.fldDepth > 1}>
                        <If condition={dataSourceRequest[0]?.fldDepth <= 2}>
                          <StyledGraphicLarger
                            src={Impact_1_2}
                            draggable={false}
                          />
                        </If>
                      </If>
                      <If condition={dataSourceRequest[0]?.fldDepth > 2}>
                        <If condition={dataSourceRequest[0]?.fldDepth <= 3}>
                          <StyledGraphicLarger
                            src={Impact_2_3}
                            draggable={false}
                          />
                        </If>
                      </If>
                      <If condition={dataSourceRequest[0]?.fldDepth > 3}>
                        <StyledGraphicLarger
                          src={Impact_3_Up}
                          draggable={false}
                        />
                      </If>
                    </When>
                    <When condition={dataSourceRequest[0]?.femabfe == -9999}>
                      <StyledGraphicLarger
                        src={noImpactBuilding}
                        draggable={false}
                      />
                    </When>
                  </Choose>
                </Col>
              </Row>
              <br />

              <Row justify="left" gutter={[0, 10]}>
                <Title level={4}>Review of Soils</Title>

                <AiFillEnvironment
                  size={28}
                  style={{
                    margin: '0 5px',
                    color: '#C46200',
                    textAlign: 'center'
                  }}
                />
                <Paragraph>
                  Brief Review of the various soils throught this area based on
                  a 500 acre buffer around the building structure. The charts
                  provide a summary of the Hydrologic Soil Group, Flooding
                  Frequency, and Drainage Class of the surrounding soils. All
                  values represented as a percent area of the 500 acre buffer.
                </Paragraph>
              </Row>

              <Row gutter={[0, 10]} align="left">
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  style={{ padding: '0px 20px 0px 0px' }}
                >
                  <SoilProcess
                    nffID={dataSourceRequest[0]?.NFFID}
                    countyCurrent={selectedCounty}
                  />
                </Col>
              </Row>
              <br />

              <Row justify="left" gutter={[0, 10]}>
                <Title level={4}>Flood Insurance</Title>
                <a
                  href="/blog/RiskRating2.0"
                  target="_blank"
                  rel="noreferrer"
                  title="Learn more about Flood Insurance"
                >
                  <AiFillPieChart
                    size={28}
                    style={{
                      margin: '0 5px',
                      color: '#0069C1',
                      textAlign: 'center'
                    }}
                  />
                </a>
              </Row>
              <Row justify="left" gutter={[0, 10]}>
                {' '}
                <Choose>
                  <When condition={dataSourceRequest[0]?.femabfe != -9999}>
                    <Title level={4} style={{ color: '#d10000 ' }}>
                      Mandatory Insurance Requirement
                    </Title>
                  </When>
                  <When condition={dataSourceRequest[0]?.femabfe == -9999}>
                    <Title level={4} style={{ color: '#388E8E' }}>
                      Insurance NOT Required
                    </Title>
                  </When>
                </Choose>
              </Row>

              <Row textAlign="left" gutter={[5, 10]}>
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Choose>
                    <When condition={dataSourceRequest[0]?.femabfe != -9999}>
                      <i>
                        Future flood insurance estimations will be provided here
                        when there are mandatory requriements.
                      </i>{' '}
                      The insurance process typically requires a few different
                      documents and people of interest, including:
                      <ul>
                        <br />
                        <li style={{ textTransform: 'capitalize' }}>
                          Surveyors for Elevation Certificates
                        </li>
                        <li style={{ textTransform: 'capitalize' }}>
                          National Flood Insurance Program (NFIP) Insurers
                        </li>
                        <li style={{ textTransform: 'capitalize' }}>
                          Private Flood Insurers
                        </li>
                      </ul>
                    </When>
                    <When condition={dataSourceRequest[0]?.femabfe == -9999}>
                      Although mandatory flood insurance is not required, one
                      should look into the avaialble, low-risk rates from
                      federal and private insurers. The typical flood insurance
                      process requires coordinating with:
                      <ul>
                        <br />
                        <li style={{ textTransform: 'capitalize' }}>
                          Surveyors for Elevation Certificates
                        </li>
                        <li style={{ textTransform: 'capitalize' }}>
                          National Flood Insurance Program (NFIP) Insurers
                        </li>
                        <li style={{ textTransform: 'capitalize' }}>
                          Private Flood Insurers
                        </li>
                      </ul>
                    </When>
                  </Choose>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24} align="center">
                  <Choose>
                    <When condition={dataSourceRequest[0]?.femabfe != -9999}>
                      <strong>
                        Click to learn more about flood insurance and our
                        partners.
                      </strong>

                      <InsuranceProcessBoxes>
                        <Link
                          to="/blog/riskrating2.0/"
                          rel="nofollow"
                          target="_blank"
                          style={{ color: '#425466' }}
                          title="Blog post about Risk Rating 2.0"
                        >
                          Risk Rating 2.0 and the NFIP
                        </Link>{' '}
                      </InsuranceProcessBoxes>
                      <InsuranceProcessBoxes>
                        <Link
                          to="/elevation-certificate"
                          target="_blank"
                          style={{ color: '#425466' }}
                          title="Learn about Elevation Certificates"
                        >
                          Local Surveyors
                        </Link>{' '}
                      </InsuranceProcessBoxes>
                      <InsuranceProcessBoxes>
                        <Link
                          to="https://www.fema.gov/flood-insurance"
                          target="_blank"
                          style={{ color: '#425466' }}
                          title="NFIP Information"
                        >
                          NFIP Flood Providers
                        </Link>{' '}
                      </InsuranceProcessBoxes>
                      <InsuranceProcessBoxes
                        level={2}
                        style={{ color: '#425466' }}
                      >
                        <Link
                          to="https://www.floir.com/sections/pandc/floodinsurance/floodinsurancewritersfl.aspx"
                          target="_blank"
                          style={{ color: '#425466' }}
                          title="Private Flood Insurance Information"
                        >
                          Private Flood Providers
                        </Link>{' '}
                      </InsuranceProcessBoxes>
                    </When>
                    <When condition={dataSourceRequest[0]?.femabfe == -9999}>
                      <strong>
                        Click to learn more about flood insurance and our
                        partners.
                      </strong>

                      <InsuranceProcessBoxes>
                        <Link
                          to="/blog/riskrating2.0/"
                          rel="nofollow"
                          target="_blank"
                          style={{ color: '#425466' }}
                          title="Blog post about Risk Rating 2.0"
                        >
                          Risk Rating 2.0 and the NFIP
                        </Link>{' '}
                      </InsuranceProcessBoxes>
                      <InsuranceProcessBoxes>
                        <Link
                          to="/elevation-certificate"
                          target="_blank"
                          style={{ color: '#425466' }}
                          title="Learn about Elevation Certificates"
                        >
                          Local Surveyors
                        </Link>{' '}
                      </InsuranceProcessBoxes>
                      <InsuranceProcessBoxes>
                        <Link
                          to="https://www.fema.gov/flood-insurance"
                          target="_blank"
                          style={{ color: '#425466' }}
                          title="NFIP Information"
                        >
                          NFIP Flood Providers
                        </Link>{' '}
                      </InsuranceProcessBoxes>
                      <InsuranceProcessBoxes
                        level={2}
                        style={{ color: '#425466' }}
                      >
                        <Link
                          to="https://www.floir.com/sections/pandc/floodinsurance/floodinsurancewritersfl.aspx"
                          target="_blank"
                          style={{ color: '#425466' }}
                          title="Private Flood Insurance Information"
                        >
                          Private Flood Providers
                        </Link>{' '}
                      </InsuranceProcessBoxes>
                    </When>
                  </Choose>
                </Col>
              </Row>
              <br />

              <Row justify="left" gutter={[0, 10]}>
                <Title level={4}>Community Support</Title>

                <AiFillShop
                  size={28}
                  style={{
                    margin: '0 5px',
                    color: '#55b96a'
                  }}
                />
              </Row>
              <Row textAlign="left" gutter={[5, 10]}>
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Choose>
                    <When condition={dataSourceRequest[0]?.crs <= 6}>
                      The community of{' '}
                      <strong>{dataSourceRequest[0]?.community}</strong>{' '}
                      currently holds a Community Rating System class or{' '}
                      <strong>CRS score of {dataSourceRequest[0]?.crs}</strong>.
                      This is considered a relatively high-score. This community
                      is participating in various floodplain managment
                      activities related to mapping and regulation, flood damage
                      reduction, as well as providing public information and
                      resources.
                    </When>
                    <When condition={dataSourceRequest[0]?.crs >= 7}>
                      The community of{' '}
                      <strong>{dataSourceRequest[0]?.community}</strong>{' '}
                      currently holds a Community Rating System class or{' '}
                      <strong>CRS score of {dataSourceRequest[0]?.crs}</strong>.
                      This is considered a relatively normal score. Most
                      communities have not completed enough projects or
                      components related to the CRS program to earn lower score
                      designations.
                    </When>
                  </Choose>
                  <br />
                  <br />A lower score equates to a{' '}
                  <strong>greater discount</strong> on flood insurance premiums.{' '}
                  <Link
                    to="https://www.fema.gov/floodplain-management/community-rating-system"
                    target="_blank"
                    style={{ color: '#55B96A' }}
                  >
                    <b>Click here</b>
                  </Link>{' '}
                  to learn more about the Community Rating System from the NFIP.
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24} align="center">
                  <Choose>
                    <When condition={dataSourceRequest[0]?.crs == 1}>
                      <StyledGraphicCRS src={CRS_01} draggable={false} />
                    </When>
                    <When condition={dataSourceRequest[0]?.crs == 2}>
                      <StyledGraphicCRS src={CRS_02} draggable={false} />
                    </When>
                    <When condition={dataSourceRequest[0]?.crs == 3}>
                      <StyledGraphicCRS src={CRS_03} draggable={false} />
                    </When>
                    <When condition={dataSourceRequest[0]?.crs == 4}>
                      <StyledGraphicCRS src={CRS_04} draggable={false} />
                    </When>
                    <When condition={dataSourceRequest[0]?.crs == 5}>
                      <StyledGraphicCRS src={CRS_05} draggable={false} />
                    </When>
                    <When condition={dataSourceRequest[0]?.crs == 6}>
                      <StyledGraphicCRS src={CRS_06} draggable={false} />
                    </When>
                    <When condition={dataSourceRequest[0]?.crs == 7}>
                      <StyledGraphicCRS src={CRS_07} draggable={false} />
                    </When>
                    <When condition={dataSourceRequest[0]?.crs == 8}>
                      <StyledGraphicCRS src={CRS_08} draggable={false} />
                    </When>
                    <When condition={dataSourceRequest[0]?.crs == 9}>
                      <StyledGraphicCRS src={CRS_09} draggable={false} />
                    </When>
                    <When condition={dataSourceRequest[0]?.crs == 10}>
                      <StyledGraphicCRS src={CRS_10} draggable={false} />
                    </When>
                  </Choose>
                  * for SFHA policies
                </Col>
              </Row>
            </>
          ) : loadingFloodReport ? (
            <Row justify="center" gutter={[0, 10]}>
              <Loader />
            </Row>
          ) : (<></>)}
        </Col>
      </Container>
    </>
  )
}

export default FloodReport

const AutoSuggestRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  max-width: 1080px;
`

const autosuggestTheme = {
  container: {
    width: '100%',
    color: '#666666',
    position: 'relative',
    top: '6px'
  },

  containerOpen: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    zIndex: '999',
    position: 'relative',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '0px',
    borderBottomRadius: '0px'
  },

  input: {
    borderRadius: '4px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    color: '#666666',
    width: '100%',
    padding: '10px 15px 10px 15px',
    height: '3rem',
    border: 'none'
  },

  inputOpen: {
    fontWeight: '500',
    color: '#666666',
    padding: '10px 15px 10px 15px',
    border: 'none'
  },

  inputFocused: {
    outline: 'none'
  },

  suggestionsContainer: {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    maxHeight: '300px',
    overflow: 'auto',
    margin: '0',
    padding: '0',
    zIndex: '999',
    textAlign: 'left',
    background: '#FAFAFA'
  },

  /**
   * Position absolute here will not push the containers underneath down.
   */
  suggestionsContainerOpen: {
    width: '100%',
    zIndex: '999',
    backgroundColor: '#fff',
    position: 'absolute'
  },

  suggestionsList: {
    listStyleType: 'none',
    borderTop: '1px solid #eee',
    paddingRight: '10px',
    paddingBottom: '10px',
    marginBottom: '0'
  },

  suggestion: {
    paddingTop: '4px',
    paddingBottom: '4px',
    cursor: 'pointer'
  },

  suggestionHighlighted: {
    backgroundColor: '#eeeeee'
  },

  suggestionFirst: {},
  sectionContainer: {},
  sectionContainerFirst: {},
  sectionTitle: {}
}

const countySelectStyles = {
  borderRadius: '4px !important',
  boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  position: 'relative',
  margin: '0 auto',
  width: '100%',
  maxWidth: '250px',
  textAlign: 'left'
}

export const FloodReportButton = styled.button`
  &,
  &:link,
  &:visited {
    width: 100px;
    height: 40px;
    text-align: center !important;
    color: white !important;
    cursor: pointer;
    background-color: #55b96a;
    font-size: 0.94rem;
    font-weight: 700;
    border: 1px solid #55b96a;
    border-radius: 4px;
    text-decoration: none;
    text-transform: uppercase;
    background-size: 230%;
    transition: all 0.4s;
    z-index: 999;
  }

  &:hover {
    background-color: #4ca25e;
    border: 1px solid #4ca25e;
    background-position: 100%;
    color: white;
  }

  &:active {
    outline: none;
    transform: scale(0.9);
  }

  &:disabled {
    border: 1px solid #d4d4d4;
    background-color: #d4d4d4;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }
`
const StyledGraphic = styled.img`
  height: 160px;
  width: 160px;
  padding: 2px;
  cursor: pointer;
  text-align: center;
`
const MapWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  z-index: 0;
  overflow: hidden;
`

const StyledGraphicCRS = styled.img`
  height: 225px;
  width: 100%;
  padding: 6px;
`

const StyledGraphicLarger = styled.img`
  height: 300px;
  width: 100%;
  padding: 2px;
`
const FEMAZoneStyle = styled.h1`
  position: relative;
  z-index: 2;
  font-size: 3em;
  margin-right: 40px;
  margin-left: 40px;
  background: PapayaWhip;
  border: none;
  border-radius: 3px;
  letter-spacing: 8px;
  text-transform: uppercase;
  transform: rotate(0deg);
  text-shadow: 1px 1px hsl(0, 100%, 0%), 2px 2px hsl(0, 100%, 0%),
    3px 3px hsl(288, 6%, 21%), 4px 4px hsl(0, 100%, 0%),
    5px 5px hsl(288, 0%, 53%), 8px 8px 5px rgba(0, 0, 0, 0.2);
`

const InsuranceProcessBoxes = styled.h3`
  position: relative;
  z-index: 2;
  margin-right: 50px;
  margin-left: 50px;
  margin-top: 6px;
  padding: 2px;
  background: PapayaWhip;
  border: none;
  border-radius: 3px;
`
