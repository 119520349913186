import React from 'react'
import {
  Wrapper,
  Container
} from '../../../styled-components/styled-components'
import { Typography, Card, Col } from 'antd'
import OrderForm from './LandDueDiligenceOrderForm'

const { Title } = Typography

const OrderSectionLand = () => {
  return (
    <Wrapper className="nff-indigo" id="order-land-report">
      <Container style={{ padding: '0px' }}>
        <Title
          level={1}
          style={{ color: 'white', textAlign: 'center', fontSize: '40px' }}
        >
          Order Land Due Diligence Report
        </Title>

        <Col
          xl={14}
          lg={14}
          md={24}
          sm={24}
          xs={24}
          style={{ margin: '0 auto' }}
        >
          <Card className="box-shadow round">
            <OrderForm />
          </Card>
        </Col>
      </Container>
    </Wrapper>
  )
}

export default OrderSectionLand
