import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Typography, Grid } from 'antd'
import CategoryPills from '../CategoryPills'
import InfoBox from './InfoBox'
import ImgLightbox from '../ImgLightbox'
import FloodRiskMap from '../FloodRiskMap'
import BottomMenu from '../BottomMenu/BottomMenu'

const { useBreakpoint } = Grid
const { Title } = Typography

const High = props => {
  const {
    LOMARating,
    selectedAddress,
    propertyData,
    imgUrl,
    setShowRecommendation,
    setShowScreening
  } = props
  const screens = useBreakpoint()
  console.log('screens: ', screens)

  const [modalShow, setModalShow] = useState(false)
  const [basisOfDetermination] = useState([
    {
      id: 1,
      info: 'FEMA considers this property to be in a high risk flood zone.'
    },
    {
      id: 2,
      info:
        'The elevation of your property appears to be above the flood elevation.'
    }
  ])

  return (
    <>
      <ImgLightbox
        imgUrl={imgUrl}
        visible={modalShow}
        onCancel={() => setModalShow(false)}
      />
      <HighWrapper>
        <div style={{ paddingBottom: '20px' }}>
          <Title level={2} style={{ color: '#fff' }}>
            CONGRATULATIONS! <br />
            This property has a “High” likelihood of benefiting from a Letter of
            Map Amendment.
          </Title>
        </div>
        <Row gutter={[50, 20]}>
          <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <hr
              style={{
                border: '3px solid #C7AE4A',
                backgroundColor: '#C7AE4A'
              }}
            />
            <div style={{ margin: '20px 0 20px 0' }}>
              <CategoryPills LOMARating={LOMARating} />
              <InfoBox
                LOMARating={LOMARating}
                selectedAddress={selectedAddress}
                propertyData={propertyData}
                suggestion="High"
                basisOfDetermination={basisOfDetermination}
              />
            </div>
            <hr
              style={{
                border: '3px solid #C7AE4A',
                backgroundColor: '#C7AE4A'
              }}
            />
          </Col>
          <Col xxl={12} xl={12} lg={24}>
            <FloodRiskMap imgUrl={imgUrl} setModalShow={setModalShow} />
          </Col>
        </Row>
        <BottomMenu
          setShowRecommendation={setShowRecommendation}
          setShowScreening={setShowScreening}
        />
      </HighWrapper>
    </>
  )
}

const HighWrapper = styled.div`
  font-size: 16px;
  line-height: 1.5em;
`

export default High
