import React from 'react'
import { Divider, Button, Typography, Row, Col, Card } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import {
  Wrapper,
  Container
} from '../../../styled-components/styled-components'
import { Link } from 'gatsby'

const { Title } = Typography

const priceCardStyles = {
  padding: '40px 0 40px 0',
  cursor: 'auto'
}

const Pricing = () => {
  return (
    <Wrapper
      style={{ backgroundColor: '#F7FAFC', padding: '40px 20px 40px 20px' }}
    >
      <Container>
        <Row justify="center">
          <Title level={2}>Choose your plan</Title>
        </Row>
        <Row span={24} gutter={[20, 20]}>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <Product
              title="LOMA Flood Risk Package"
              price="$499*"
              priceAdditional=""
              checkMarks={['FEMA LOMA', 'Single Family Home']}
              to="/loma"
            />
          </Col>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <Product
              title="Flood Analysis Memo (FAM)"
              price="$49"
              priceAdditional=""
              checkMarks={['Full Memo', 'Flood Score']}
              to="/fam"
              nofollow={true}
            />
          </Col>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <Product
              title={'Detailed Flood Risk APIs' + '\n' + 'structure address'}
              price="Varies"
              // priceAdditional="per call"
              checkMarks={['36 Counties', '4 Million Properties']}
              to="/api-doc"
              nofollow={true}
            />
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Title level={4} style={{ color: '#000' }}>
              {/* *First report is $49. Each report thereafter is only $8. Perfect
              for individuals in the market to buy a home! */}
              * Multi-unit buildings, condos, or commercial properties may
              require additional fees
            </Title>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const Product = ({ title, price, priceAdditional, checkMarks, to, nofollow = false }) => {
  return (
    <Card className="box-shadow round" style={priceCardStyles} hoverable>
      <Row span={24} gutter={[0, 30]} style={{ minHeight: '76px' }}>
        <Title level={3} style={{ textAlign: 'center', width: '100%' }}>
          {title}
        </Title>
      </Row>
      <Divider />
      <Row gutter={[0, 10]} style={{ textAlign: 'center' }}>
        <Col span={24}>
          <div>
            <div
              style={{
                display: 'inline-block',
                fontWeight: 'bold',
                fontSize: '56px'
              }}
            >
              {price}
            </div>
            <div style={{ display: 'inline-block' }}>{priceAdditional}</div>
          </div>
        </Col>
        {checkMarks.map((item, index) => (
          <Col span={24} key={`${title}-${item}`}>
            <Title level={4}>
              <CheckOutlined /> {item}
            </Title>
          </Col>
        ))}
      </Row>
      <Row justify="center">
        <Link to={to} rel={ nofollow ? 'nofollow' : ''} >
          <Button
            type="primary"
            shape="round"
            size="large"
            className="box-shadow"
          >
            LEARN MORE
          </Button>
        </Link>
      </Row>
    </Card>
  )
}

export default Pricing
