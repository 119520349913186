import React, { useState, useRef } from 'react'
import { Form, Input, Button, Row, Col, Select } from 'antd'
import firebase from 'gatsby-plugin-firebase'
import { normalizePhoneNumberInput } from '../../../utils/form/normalize'
import SuccessModal from '../../../components/SuccessModal'

const ContactUsForm = () => {
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const onFinish = values => {
    setLoading(true)
    values.timestamp = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York'
    })

    const contactFormMessageObj = {
      to: values.email,
      bcc: [
        'info@nofloodflorida.com',
        'sophia@nofloodflorida.com',
        'nofloodflorida+7t5d67e@nofloodflorida-org.myfreshworks.com'
      ],
      template: {
        name: 'contactFormTemplate',
        data: {
          ...values
        }
      }
    }

    firebase
      .firestore()
      .collection('contactForm')
      .add(contactFormMessageObj)
      .then(() => {
        setLoading(false)
        setIsModalVisible(true)
        formRef.current.resetFields()
      })
      .catch(error => console.log('Error adding document:', error))
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  }

  const handleOk = () => setIsModalVisible(false)
  const handleCancel = () => setIsModalVisible(false)

  return (
    <>
      <SuccessModal
        content="Form Submitted Successfully! Please check your email and/or spam folder for a confirmation."
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <Form
        {...layout}
        ref={formRef}
        name="basic"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={[20, 0]}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input your name!' }]}
              initialValue=""
              preserve={false}
            >
              <Input
                className="round"
                size="large"
                name="name"
                placeholder="John Doe"
                allowClear={true}
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please input your email!'
                }
              ]}
              initialValue=""
            >
              <Input
                className="round"
                size="large"
                name="email"
                placeholder="info@nofloodflorida.com"
                allowClear={true}
                onInput={e =>
                  (e.target.value = ('' + e.target.value).toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              normalize={(value, prevValue, prevValues) =>
                normalizePhoneNumberInput(value, prevValue)
              }
              label="Phone"
              name="phone"
              rules={[
                { required: true, message: 'Please input your phone number.' }
              ]}
              initialValue=""
            >
              <Input
                className="round"
                size="large"
                name="phone"
                placeholder="(813) 213-0641"
                allowClear={true}
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Address"
              name="address"
              rules={[
                { required: true, message: 'Please input your address!' }
              ]}
              initialValue=""
            >
              <Input
                className="round"
                size="large"
                name="address"
                placeholder="123 Main St, Tampa, FL 33624"
                allowClear={true}
                id="contactAddress"
                onInput={e =>
                  (e.target.value = ('' + e.target.value).toUpperCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <a
                  target="_blank"
                  href="/flood-insurance-quote"
                >
                  Interested in Flood Insurance Quotes? (Click to Learn More)
                </a>
              }
              name="insuranceQuote"
              rules={[{ required: true, message: 'Please select Yes or No.' }]}
            >
              <Select>
                <Select.Option value="yes">Yes</Select.Option>
                <Select.Option value="no">No</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Message" name="message" initialValue="">
              <Input.TextArea
                className="round"
                name="message"
                allowClear={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="box-shadow"
              shape="round"
              size="large"
              loading={loading}
            >
              {loading ? '' : 'Send Message'}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </>
  )
}

export default ContactUsForm
