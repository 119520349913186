import React from 'react'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import { Wrapper, Container } from '../../styled-components/styled-components'
import { Row, Col, Typography } from 'antd'

import Eligibility from '../../components/Eligibilty/Eligibility'
import Testimonials from './components/Testimonials'
import ContactUs from './components/ContactUs'
import { Footer } from '../../components/Footer/Footer'

const { Title, Text } = Typography

const Landing = () => {
  return (
    <LandingLayout>
      <Eligibility />
      <Content1 />
      <Testimonials />
      <ContactUs />
      <Footer />
    </LandingLayout>
  )
}

const Content1 = () => {
  return (
    <Wrapper>
      <Container style={{ padding: '20px 0 20px 0' }}>
        <Row span={24}>
          <Col xl={8}>
            <div style={{ borderLeft: '4px solid #d3b574' }}>
              <Title style={{ fontSize: '44px', marginLeft: '20px' }}>
                Our Promise To You
              </Title>
            </div>
          </Col>
          <Col xl={8}>
            <Text>
              We support the residents of Florida by providing reliable and
              comprehensive information pertaining to flood risk. We also help
              residents find out if their home is wrongly mapped in the FEMA
              flood maps. Oftentimes, FEMA data is outdated. Your flood risk may
              be higher or lower than officially stated. Our promise is to
              strive to provide the most accurate analysis
            </Text>
          </Col>
          <Col xl={8}>
            <Text>
              and user friendly reporting throughout Florida. If you are
              incorrectly mapped as being in the high risk flood zone, we will
              provide FEMA with the necessary documents, on your behalf, to
              change the flood zone designation through the process referred to
              as the “Letter of Map Amendment”. If you don’t qualify for a LOMA,
              we’ll ensure you’re getting the best flood insurance rates available
              based on your data.
            </Text>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}
export default Landing
