import React from 'react'
import './styles.css'
import * as S from './StyledComponents'
import { Row, Col, Typography } from 'antd'

const { Text } = Typography

const CategoryPills = ({ LOMARating }) => {
  const getLOMARecommendation = LOMARating => {
    switch (LOMARating) {
      case '0':
        return 'n/a'
      case '1':
        return 'Low'
      case '2':
        return 'Medium'
      case '3':
        return 'High'
      default: {
        return 'n/a'
      }
    }
  }

  const colSizes = {
    xxl: 8,
    xl: 8,
    lg: 8,
    md: 8,
    sm: 24,
    xs: 24
  }

  return (
    <Row gutter={[50, 20]} style={{ margin: '0 10px 0 10px' }}>
      <Col {...colSizes} className="category-pill">
        <S.HighBlock rating={getLOMARecommendation(LOMARating) === 'High'}>
          HIGH
        </S.HighBlock>
      </Col>
      <Col {...colSizes} className="category-pill">
        <S.MedBlock rating={getLOMARecommendation(LOMARating) === 'Medium'}>
          MED
        </S.MedBlock>
      </Col>
      <Col {...colSizes} className="category-pill">
        <S.LowBlock rating={getLOMARecommendation(LOMARating) === 'Low'}>
          LOW
        </S.LowBlock>
      </Col>
    </Row>
  )
}

export default CategoryPills
