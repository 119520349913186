import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col, Typography } from 'antd'
import styled from 'styled-components'

const { Title, Paragraph } = Typography

const TheInfo = () => {
  const data = useStaticQuery(graphql`
    query {
      HeaderScore: file(relativePath: { eq: "HeaderScore.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ZoomOut1: file(relativePath: { eq: "ZoomOut1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ZoomIn: file(relativePath: { eq: "ZoomIn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      PieChartsNew: file(relativePath: { eq: "PieChartsNew.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FloodPlainSummary: file(relativePath: { eq: "FloodplainSummary.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      PotentialStrucImpact: file(
        relativePath: { eq: "PotentialStrucImpact.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      LOMARecommendation: file(relativePath: { eq: "LOMARecommendation.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FloodInsuranceSetup: file(
        relativePath: { eq: "FloodInsuranceSetup.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      InsuranceEstimate: file(relativePath: { eq: "InsuranceEstimate.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Row>
        <Col span={6} xs={24} sm={24} md={8} lg={6} style={{ padding: '20px' }}>
          <Paragraph>
            The top section of the{' '}
            <a
              href="https://firebasestorage.googleapis.com/v0/b/flood-score.appspot.com/o/sample%20reports%2F709_Whitehall_St.-1.pdf?alt=media&token=8248e807-ef39-4af7-af40-0792aa6d7d3e"
              target="_blank"
              rel="noreferrer"
            >
              report
            </a>{' '}
            identifies the property address and a direct statement concerning
            high-risk zone impact. A quick flood score indicator is also
            included ranging from low-risk (10) to highest-risk (95).
          </Paragraph>
        </Col>
        <Col
          span={18}
          xs={24}
          sm={24}
          md={16}
          lg={18}
          style={{ padding: '20px' }}
        >
          <Img fluid={data.HeaderScore.childImageSharp.fluid} />
        </Col>
      </Row>
      <Row style={{ padding: '20px' }}>
        <Paragraph>
          The <strong>first</strong> visual representation of the property of
          interest is displayed at an extent that allows the user to gain a
          better understanding of the flooding situation in general. The
          property is identified by the parcel boundary, which is symbolized as
          a red polygon with a bold, white border. Local streets along with the
          current, effective FEMA floodplains are displayed and symbolized with
          understanding and visual appeal in mind. Best-available watershed
          modeling floodplains are also displayed with a light-pink symbology.
        </Paragraph>
        <Img
          fluid={data.ZoomOut1.childImageSharp.fluid}
          style={{ width: '80%', height: '80%', margin: '0 auto' }}
        />
        <Caption>General Map of Property and Surrounding Area</Caption>
      </Row>
      <Row style={{ padding: '20px' }}>
        <Paragraph>
          The <strong>second</strong> visual representation of the property
          provides additional details and potentially a more localized
          understanding of the flood risk. In the image below, all parcel
          boundaries for the surrounding area are displayed while the property
          of interest is symbolized with a bold, red border. At this extent, the
          structural footprint of the property is displayed with a dark blue
          border. In this situation, the property is located in the high-risk,
          FEMA flood zone, Zone AE. The best-available data, however, depicts a
          more desirable situation. See the remainder of the report for more
          information.
        </Paragraph>
        <Img
          fluid={data.ZoomIn.childImageSharp.fluid}
          style={{ width: '80%', height: '80%', margin: '0 auto' }}
        />
        <Caption>Detailed Map of Property</Caption>
      </Row>

      <Row style={{ padding: '40px 20px' }}>
        <Title level={3}>
          Floodplain Area of Impact – Parcel and Structure
        </Title>
        <Paragraph>
          Two pie charts are used to represent the percent of area impacted by
          the FEMA effective floodplain. In the example below, 100% of the
          parcel and building structure are impacted by the floodplain. This
          graphic appears directly below the detailed aerial view of the
          property, allowing the user to gain a precise understanding of the
          potential floodplain impact from an area standpoint.
        </Paragraph>
        <Img
          fluid={data.PieChartsNew.childImageSharp.fluid}
          style={{ width: '80%', height: '80%', margin: '0 auto' }}
        />
      </Row>

      <Row style={{ padding: '40px 20px' }}>
        <Title level={3}>Floodplain Summary</Title>
        <Paragraph>
          The Floodplain Summary table provides an overview of the important
          floodplain information, including the flood zone designation, the
          established Base Flood Elevation (BFE), an estimated Finished Floor
          Elevation (FFE) of the building structure, and the date for which the
          represented floodplains became effective under FEMA regulations. In
          the example below, the property is located in the high-risk
          floodplain, Zone AE; however, we can see that the estimated Finished
          Floor Elevation (FFE) is actually higher than the BFE. This property
          may qualify for a{' '}
          <Link to="/loma">Letter of Map Amendment or LOMA</Link>.
        </Paragraph>
        <Img
          fluid={data.FloodPlainSummary.childImageSharp.fluid}
          style={{ width: '80%', height: '80%', margin: '0 auto' }}
        />
      </Row>

      <Row style={{ padding: '40px 20px' }}>
        <Title level={3}>Potential Structural Impact</Title>
        <Paragraph>
          A graphic is provided to help the user visualize the potential
          flooding impact on the building structure. The chart compares the
          elevation of the structure’s estimated Finished Floor Elevation (FFE)
          to the Base Flood Elevation (BFE) of the FEMA floodplain. In the
          example below, the building structure is actually estimated to be
          higher than the effective BFE. This often occurs due to the accuracy
          of the underlying terrain the floodplains were mapped at the time of
          delineation. When the structure elevation is above the floodplain, a
          Letter of Map Amendment (LOMA) can be submitted to FEMA to officially
          adjust the flood zone designation.
        </Paragraph>
        <Img
          fluid={data.PotentialStrucImpact.childImageSharp.fluid}
          style={{ width: '80%', height: '80%', margin: '0 auto' }}
        />
      </Row>

      <Row style={{ padding: '40px 20px' }}>
        <Title level={3}>LOMA Recommendation</Title>
        <Paragraph>
          A <Link to="/loma">Letter of Map Amendment (LOMA)</Link> is an
          official amendment, by letter, to an effective National Flood
          Insurance Program (NFIP) map. In situations where the estimated
          Finished Floor Elevation (FFE) is within one foot of the established
          Base Flood Elevation (BFE), No Flood Florida will recommend for the
          homeowner to look into the LOMA process. If the BFE is substantially
          higher than the estimated FFE, no recommendation will be made.
        </Paragraph>
        <Img
          fluid={data.LOMARecommendation.childImageSharp.fluid}
          style={{ width: '80%', height: '80%', margin: '0 auto' }}
        />
      </Row>

      <Row style={{ padding: '40px 20px' }}>
        <Title level={3}>Flood Insurance Estimation</Title>
        <Paragraph>
          Flood Insurance estimations are provided based on the interpretation
          of the National Flood Insurance Program (NFIP). The program
          establishes rates for building and content coverage. Overall insurance
          premiums are most influenced by the flood zone designation (X, AE, VE,
          etc.) as well as the floodplain elevation impact – how many feet of
          water are expected to impact the building structure? Typical
          residential insurance premiums are quoted based on a maximum building
          coverage of $250,000. Additional coverage must be handled through
          private insurance where rates may not reflect that of the NFIP
          program.
        </Paragraph>
        <Img
          fluid={data.FloodInsuranceSetup.childImageSharp.fluid}
          style={{ width: '80%', height: '80%', margin: '0 auto' }}
        />
        <Paragraph style={{ padding: '15px 0' }}>
          Although the example property was impacted by the high-risk
          floodplain, Zone AE, the flood insurance premium remains relatively
          low, ~$450/year. This is directly related to the fact that the
          building structure is approximately 2.9 feet higher than the
          established FEMA Base Flood Elevation (BFE). Insurance rates through
          the NFIP for this property are quite low and affordable policies are
          available.
        </Paragraph>
        <Img
          fluid={data.InsuranceEstimate.childImageSharp.fluid}
          style={{ width: '80%', height: '80%', margin: '0 auto' }}
        />
      </Row>
    </>
  )
}

export default TheInfo

const Caption = styled.p`
  color: #7a7a7a;
  font-family: 'Roboto', Sans-serif;
  font-weight: 400;
  margin: 5px auto;
  font-size: 12px;
  width: 100%;
  text-align: center;
`
