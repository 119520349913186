import React, { useState, useRef } from 'react'
import { Form, Row, Col, Input, Button, Select } from 'antd'
import axios from 'axios'
import { Link } from 'gatsby'
// import { FormOutlined } from '@ant-design/icons'
import {
  RightCircleOutlined,
  FormOutlined,
  CheckCircleOutlined
} from '@ant-design/icons'

const DatumConversionForm = () => {
  const formRef = useRef(null)
  const [loading2, setLoading] = useState(false)

  const [xyData, setXYData] = useState({
    data: '',
    loading: true
  })

  const [locationSourceDatum, setLocation1] = useState()

  const handleConversion = async values => {
    setLoading(true)
    values.timestamp = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York'
    })
    // Replace all undefined values with empty strings
    const convertedString = JSON.stringify(values, function (key, value) {
      return value === undefined ? '' : value
    })
    const updatedValues = JSON.parse(convertedString)

    const fullAddress =
      updatedValues.address +
      ', ' +
      updatedValues.city +
      ', ' +
      updatedValues.state +
      ' ' +
      updatedValues.zipcode

    // console.log('full address:', fullAddress)

    const coordinateData = await axios.get(
      `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?singleLine=${fullAddress}&outFields=Addr_type&f=pjson`
    )
    setXYData({
      data: coordinateData,
      loading: false
    })
    //   console.log('x, y:', coordinateData)
    console.log('x, y:', coordinateData?.data.candidates[0].location)

    const locationSource1 = [
      {
        key: '1',
        locHome: coordinateData.data.candidates[0].location
      }
    ]

    setLocation1(locationSource1)
    setLoading(false)

    // const defaultHeight = 100
    // const inDatum_Horizontal = 'nad83(2011)'
    // const outDatum_Horizontal = 'nad83(2011)'
    // const lat_y = coordinateData.data.candidates[0].location.y
    // const long_x = coordinateData.data.candidates[0].location.x

    // const options = {
    //   headers: { 'Access-Control-Allow-Origin': true }
    // } // Required for CORS support to work}}

    // axios
    //   .get(
    //     `https://geodesy.noaa.gov/api/ncat/llh?lat=${lat_y}&lon=${long_x}&inDatum=${inDatum_Horizontal}&outDatum=${outDatum_Horizontal}&inVertDatum=${updatedValues.inputDatum}&outVertDatum=${updatedValues.outputDatum}&orthoHt=${defaultHeight}`,
    //     options
    //   )
    //   .then(response => {
    //     console.log(response.data)
    //     console.log(response.status)
    //     console.log(response.statusText)
    //     console.log(response.headers)
    //     console.log(response.config)
    //   })

    // const datumData = axios.get(
    //   `https://geodesy.noaa.gov/api/ncat/llh?lat=${lat_y}&lon=${long_x}&inDatum=${inDatum_Horizontal}&outDatum=${outDatum_Horizontal}&inVertDatum=${updatedValues.inputDatum}&outVertDatum=${updatedValues.outputDatum}&orthoHt=${defaultHeight}`,
    //   {
    //     headers: {
    //       'access-control-allow-origin': '*', // Required for CORS support to work
    //       'access-control-allow-credentials': true, // Required for cookies, authorization headers with HTTPS
    //       // 'Access-Control-Allow-Methods': 'GET', // Required for cookies, authorization headers with HTTPS
    //       crossorigin: true
    //     }
    //   }
    // )
    // setDatumConversionInfo({
    //   data: datumData,
    //   loading: false,
    //   loading2: false
    // })

    // console.log('headers:', datumData.data.headers)
    // console.log('datum response:', datumData)

    // setLoading(false)
  }

  // const { datumConversion, loading } = useGetConversion(dataSourceValues)

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  }

  return (
    <>
      <Form
        {...layout}
        ref={formRef}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleConversion}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={[20, 0]}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Address (for Datum Conversion)"
              name="address"
              rules={[
                { required: true, message: 'Please input your street address.' }
              ]}
            >
              <Input
                placeholder="123 Main St"
                className="round"
                size="large"
                allowClear={true}
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: 'Please input your city.' }]}
            >
              <Input
                placeholder="Tampa"
                className="round"
                size="large"
                allowClear={true}
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="State"
              name="state"
              rules={[{ required: true, message: 'Please input your state.' }]}
            >
              <Input
                placeholder="FL"
                className="round"
                size="large"
                allowClear={true}
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Zip Code"
              name="zipcode"
              rules={[
                { required: true, message: 'Please input your zip code.' }
              ]}
            >
              <Input
                placeholder="33624"
                className="round"
                size="large"
                allowClear={true}
              />
            </Form.Item>
          </Col>

          <Row justify="end">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="box-shadow"
                shape="round"
                size="large"
                loading={loading2}
              >
                Generate Lat/Long
              </Button>
            </Form.Item>
          </Row>
        </Row>
      </Form>
      <Col>
        {locationSourceDatum ? (
          <>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <p>x, longitude</p>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <p>y, latitude</p>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} id="x-coord">
                <strong>
                  {parseFloat(locationSourceDatum[0]?.locHome.x).toFixed(6)}
                </strong>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} id="y-coord">
                <strong>
                  {parseFloat(locationSourceDatum[0]?.locHome.y).toFixed(6)}
                </strong>
              </Col>
            </Row>
            <Row style={{ padding: '20px 0px 0px 0px' }}>
              Click the following button and copy the x, y coordinates and paste
              into the NGS Website form.
            </Row>
            <Row style={{ padding: '20px 0px 0px 0px' }}>
              {' '}
              <Link to="https://geodesy.noaa.gov/NCAT/" target="_blank">
                <Button
                  block
                  size="medium"
                  type="primary"
                  shape="round"
                  //   icon={<RightCircleOutlined />}
                >
                  NGS Website
                  <RightCircleOutlined />
                </Button>
              </Link>
            </Row>
          </>
        ) : (
          <Row justify="center" gutter={[0, 10]}></Row>
        )}
      </Col>
    </>
  )
}

export default DatumConversionForm
