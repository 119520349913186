import React, { useEffect, useState, useContext } from 'react'
import Prism from 'prismjs'
import firebase from 'gatsby-plugin-firebase'
import '../../assets/prismThemes/prism-dracula.css'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import PageHeader from '../../components/PageHeader'
import { Wrapper, Container } from '../../styled-components/styled-components'
import { Card, Typography, Row, Col,  Grid } from 'antd'
import { Footer } from '../../components/Footer/Footer'
import ContactUsForm from '../../components/Eligibilty/ContactUsForm'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { AuthContext } from '../../context/auth'
import SeoNoIndex from '../../components/SeoNoIndex'

const { Title, Paragraph, Text } = Typography

const { useBreakpoint } = Grid

const ApiDoc = () => {
  const [apiUserData, setAPIUserData] = useState(null)
  const { user } = useContext(AuthContext)

  const [showContactModal, setShowContactModal] = useState(false)

  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '30px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '50px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  useEffect(() => {
    Prism.highlightAll()

    if (user?.uid) {
      firebase
        .firestore()
        .collection('apiUsers')
        .where('uid', '==', user.uid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            if (doc) {
              const data = doc.data()
              setAPIUserData(data)
            }
          })
        })
    }
  })

  const apiKey = apiUserData?.apiKey ?? "<Your API Key Here>";

  return (
    <LandingLayout>
      <SeoNoIndex />
      <PageHeader title="API" />

      <Wrapper>
        <Container>
          <Title level={3}>
            Welcome to the No Flood Florida API Documentation
          </Title>

          <Paragraph>
            <Link to="/sign-in/register" style={{ color: '#55B96A' }}>
              Register
            </Link>{' '}
            or{' '}
            <Link to="/sign-in/login" style={{ color: '#55B96A' }}>
              Login
            </Link>{' '}
            to explore our Internal Tools today!
          </Paragraph>
          <Col
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={24}
            align="center"
            style={{ padding: '0px 0px 15px 0px' }}
          >
            <InsuranceProcessBoxes>
              <Link
                to="/sign-in/login"
                target="_blank"
                style={{ color: '#425466' }}
                title="Login"
              >
                Login
              </Link>{' '}
            </InsuranceProcessBoxes>
          </Col>

          <Col
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={24}
            align="center"
            style={{ padding: '0px 0px 15px 0px' }}
          >
            <InsuranceProcessBoxes>
              <Link
                to="/sign-in/register"
                target="_blank"
                style={{ color: '#425466' }}
                title="Register"
              >
                Register
              </Link>{' '}
            </InsuranceProcessBoxes>
          </Col>

          <Paragraph>
            You can use the Flood Data API to access our best available flood
            risk data for over 4.0 million Florida properties. Integrating is
            quick and easy. We provide an API Client as well as sample code in
            popular languages. Our API contains essential FEMA Regulatory data
            for Single Family homes. These data include{' '}
            <Link href="#fema-bfe">Base Flood Elevations (BFEs)</Link>,{' '}
            <Link href="#firm">Pre/Post FIRM designation</Link>, as well as
            estimated <Link href="#sge-lag">Lowest Adjacent Grade (LAG)</Link>{' '}
            values{' '}
            <Link href="#sge-lag">(Structure Ground Elevation or SGE)</Link> at
            the building structure level. See the full list of available data
            below in the fields section.
          </Paragraph>

          <Row gutter={[0, 20]}>
            <Col span={24}>
              <Title level={4}>URL</Title>
            </Col>
            <Col span={24}>
              <Card>
                <Link
                  href="https://us-central1-flood-score.cloudfunctions.net/"
                  target="_blank"
                >
                  https://us-central1-flood-score.cloudfunctions.net/
                </Link>
              </Card>
            </Col>

            <Col span={24}>
              <Title level={4}>Endpoint</Title>
              <Card>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <Text code>getProperty</Text>
                  </Col>
                  <Col span={24}>
                    <Text strong>Type: </Text>
                    <Text>GET</Text>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col span={24}>
              <Title level={4}>Params</Title>
            </Col>
            <Col span={24}>
              <Card>
                <Param
                  name="state"
                  description="The state in which the property is located. Capitlized. For example, Florida"
                  paramType="String"
                  required="true"
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Param
                  name="county"
                  description="The county in which the property is located. Capitalized. For example, Hillsborough."
                  paramType="String"
                  required="true"
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Param
                  name="zip"
                  description="The zip in which the property is located, five character convention, 33452."
                  paramType="String"
                  required="true"
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <ParamStreetAddress
                  name="streetAddress"
                  description="The street address in which the property is located. Address matching can be difficult depending on the adopted convention. No Flood Florida has standardized our database with the following conventions. All characters are capitalized. Some words exist in the names of streets that also happen to match the street type. For example 9225 NW 32 COURT RD, where COURT is the name of the road (RD) in this case. Some Counties specify the cardinal direction at the end of the address. For example, 4913 28TH AVE S where the address is read as 28th Avenue South."
                  streetDirections="N, S, E, W and combinations of these directions"
                  streetSuffix="AVE, ST, RD, WAY, PL, CIR, etc. These are the typical abbreviations for types of streets"
                  paramType="String"
                  required="true"
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <Text code>apiKey</Text>
                  </Col>
                  <Col span={24}>
                    Your personal API Key given to you by No Flood Florida
                  </Col>
                  <Col span={24}>
                    <Text strong>Do NOT share this with anyone.</Text>
                  </Col>
                  <Col span={24}>
                    <Text strong>Type: </Text>
                    String
                  </Col>
                  <Col span={24}>
                    <Text strong>Required: </Text>
                    true
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col span={24}>
              <Card>
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <Text code>fields</Text>
                  </Col>
                  <Col span={24}>
                    <Paragraph>
                      Specify the fields you would like to get back. Separate
                      each field with a comma.
                    </Paragraph>
                  </Col>
                  <Col span={24}>
                    <Paragraph>
                      If no value is given, all property data will be returned.
                    </Paragraph>
                  </Col>

                  <Col span={24}>
                    <Paragraph>
                      i.e. fields=FEMA_ZONE, FEMA_BFE, PRE_POST_1
                    </Paragraph>
                  </Col>
                </Row>

                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <Title level={4}>Full Available Fields</Title>
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>NFF_ID </Text>- internal ID, unique for each
                    property
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>PROP_ADD </Text>- address of property
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>PROP_CITY </Text>- city of property
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>PROP_STATE </Text>- property state
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>PROP_ZIP </Text>- property zip code
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>FULL_ADD </Text>- concatenated full address for
                    the property
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>SQ_FT </Text>- building square footage (in
                    square feet)
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>BLD_DATE </Text>- build year of property
                  </Col>
                  <Col span={24} offset={1} id="sge-lag">
                    <Text strong>SGE </Text>- Structure Ground Elevation
                    (NAVD88-ft), No Flood Florida estimation for the Lowest
                    Adjacent Grade (LAG) of the structure
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>STRUCT_PCT </Text>- percent of structure
                    impacted by the high-risk FEMA floodplain (percent area)
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>FEMA_ZONE </Text>- FEMA effective flood zone
                    designation based on intersection with building structure
                  </Col>
                  <Col span={24} id="fema-bfe" offset={1}>
                    <Text strong>FEMA_BFE </Text>- FEMA effective Base Flood
                    Elevation (BFE), 100-Yr floodplain elevation, NAVD88-ft
                  </Col>
                  <Col span={24} offset={1} id="firm">
                    <Text strong>FEMA_EFF_DT </Text>- effective date of the
                    underlying FIRM panel
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>Community </Text>- NFIP community name the
                    property is located in
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>Init_FIRM </Text>- inital FIRM date for the
                    community
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>PRE_POST_1 </Text>- designation of Pre/Post
                    FIRM property based on the comparison between the BLDDATE
                    and InitFIRM date
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>CRS </Text>- Community Rating System score,
                    1-10, 1 being the best, highest discount on NFIP flood
                    insurance
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>LOMA </Text>- likelihood for the property to be
                    eligible for a Letter of Map Amendment (LOMA); 0, 1, 2, 3
                    (N/A, Low, Medium, High Likelihood)
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>Type: </Text>
                    String
                  </Col>
                  <Col span={24} offset={1}>
                    <Text strong>Required: </Text>
                    false
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col span={24}>
              <Title level={4}>Error Codes</Title>
            </Col>
            <Col span={24}>
              <Card>
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <Text code>NOCOUNTY</Text>
                  </Col>
                  <Col span={24}>
                    <Paragraph>County could not be found</Paragraph>
                  </Col>
                  <Col span={24}>
                    <Text code>NOADDR</Text>
                  </Col>
                  <Col span={24}>
                    <Paragraph>
                      County could be found, but no property with requested
                      address and zip code could be found in that county. Refer
                      to address naming conventions as described in the
                      streetaddress section above to ensure accurate response
                      from API. Some properties may not exist in the database,
                      we focus mainly on Single Family residential homes.
                    </Paragraph>
                  </Col>
                  <Col span={24}>
                    <Text code>USAGELIMIT</Text>
                  </Col>
                  <Col span={24}>
                    <Paragraph>
                      Monthly usage limit for specified apiKey has been met.
                    </Paragraph>
                  </Col>
                  <Col span={24}>
                    <Text code>UNAUTHORIZED</Text>
                  </Col>
                  <Col span={24}>
                    <Paragraph>Invalid apiKey.</Paragraph>
                  </Col>
                </Row>
              </Card>

              <Col span={24}>
                <Title level={4}>Examples</Title>
              </Col>
              <Card>
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <Paragraph>Plain URL</Paragraph>
                  </Col>
                  <Col span={24}>
                    <Link
                      href={`https://us-central1-flood-score.cloudfunctions.net/getProperty?state=Florida&county=Alachua&zip=32605&streetAddress=1616 NW 39TH AVE&apiKey=${apiKey}&fields=LOMA,FULL_ADD`}
                    >
                      https://us-central1-flood-score.cloudfunctions.net/getProperty?state=Florida&county=Alachua&zip=32605&streetAddress=1616
                      NW 39TH
                      AVE&apiKey={apiKey}&fields=LOMA,FULL_ADD
                    </Link>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col>
                        <Title level={4}>JS - Fetch</Title>
                      </Col>
                      <Col>
                        <pre>
                          <code className="language-js">
                            {`
var requestOptions = {
  method: 'GET',
  redirect: 'follow'
};

fetch("https://us-central1-flood-score.cloudfunctions.net/getProperty?state=Florida&county=Alachua&zip=32605&streetAddress=1616 NW 39TH AVE&apiKey=${apiKey}", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
    `}
                          </code>
                        </pre>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24}>
                    <Row>
                      <Col>
                        <Title level={4}>JS - jQuery</Title>
                      </Col>
                      <Col>
                        <pre>
                          <code className="language-js">
                            {`
var settings = {
  "url": "https://us-central1-flood-score.cloudfunctions.net/getProperty?state=Florida&county=Alachua&zip=32605&streetAddress=1616 NW 39TH AVE&apiKey=${apiKey}",
  "method": "GET",
  "timeout": 0,
};

$.ajax(settings).done(function (response) {
  console.log(response);
});
    `}
                          </code>
                        </pre>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24}>
                    <Row>
                      <Col>
                        <Title level={4}>Python - Requests</Title>
                      </Col>
                      <Col>
                        <pre>
                          <code className="language-js">
                            {`
import requests

url = "https://us-central1-flood-score.cloudfunctions.net/getProperty?state=Florida&county=Alachua&zip=32605&streetAddress=1616 NW 39TH AVE&apiKey=${apiKey}"

payload = {}
headers= {}

response = requests.request("GET", url, headers=headers, data = payload)

print(response.text.encode('utf8'))
    `}
                          </code>
                        </pre>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Title level={4}>Contact</Title>
            </Col>
            <Col span={24}>Taylor Lankford, CTO</Col>
            <Col span={24}>taylor@nofloodflorida.com</Col>
            <Col span={24}>
              <span
                style={{ color: '#1DA57A', cursor: 'pointer' }}
                onClick={() => setShowContactModal(true)}
              >
                Contact
              </span>
            </Col>
          </Row>

          <ContactUsForm
            show={showContactModal}
            handleClose={() => setShowContactModal(false)}
          />
        </Container>
      </Wrapper>
      <Footer />
    </LandingLayout>
  )
}
const ParamStreetAddress = ({
  name,
  description,
  streetDirections,
  streetSuffix,
  paramType,
  required
}) => {
  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <Text code>{name}</Text>
      </Col>
      <Col span={24}>{description}</Col>
      <Col span={24}>
        <Text strong>Street Directions: </Text>
        {streetDirections}
      </Col>
      <Col span={24}>
        <Text strong>Street Suffix: </Text>
        {streetSuffix}
      </Col>
      <Col span={24}>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Text strong>Examples</Text>
          </Col>
          <Col span={24}>2149 NW 77TH AVE</Col>
          <Col span={24}>9225 NW 32 COURT RD</Col>
          <Col span={24}>3415 NW 81 TER</Col>
          <Col span={24}>18423 VAN NUYS CIR</Col>
          <Col span={24}>4913 28TH AVE S</Col>
        </Row>
      </Col>
      <Col span={24}>
        If using <Text strong>Google Maps API</Text> to obtain base address from
        a previous user input, here are a few examples of the text string
        manipulations in Python that can be executed to take the full address
        response from Google and convert it to the streetAddress component of
        the URL request:
      </Col>
      <Col span={24}>
        <pre className="language-js">
          <code className="language-js">
            {`
# Example 1
GoogleAdd1 = "2149 N 77th St, Tampa, FL 33619"
AddFont = GoogleAdd1.split(",")[0].upper()
print (AddFront)
--> "2149 N 77TH ST"

# Example 2
GoogleAdd1 = "2149 77th Ave N, St. Petersburg, FL 33702"
AddFront = GoogleAdd1.split(",")[0].upper()
print (AddFront)
--> "2149 77TH AVE N"

# Example 3
GoogleAdd1 = "1564 SW Jasmine Trce, Palm City, FL 34990"
AddFront = GoogleAdd1.split(",")[0].upper()
print (AddFront)
--> "1564 SW JASMINE TRCE"

# Example 4
GoogleAdd1 = "18423 Van Nuys Cir, Port Charlotte, FL 33948"
AddFront = GoogleAdd1.split(",")[0].upper()
print (AddFront)
--> "18423 VAN NUYS CIR"

# Example 5
GoogleAdd1 = "4913 28th Ave S, Gulfport, FL 33707"
AddFront = GoogleAdd1.split(",")[0].upper()
print (AddFront)
--> "4913 28TH AVE S"
            `}
          </code>
        </pre>
      </Col>
      <Col span={24}>
        <Text strong>Type: </Text>
        {paramType}
      </Col>
      <Col span={24}>
        <Text strong>Required: </Text>
        {required}
      </Col>
    </Row>
  )
}

const Param = ({ name, description, paramType, required }) => {
  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <Text code>{name}</Text>
      </Col>
      <Col span={24}>{description}</Col>
      <Col span={24}>
        <Text strong>Type: </Text>
        {paramType}
      </Col>
      <Col span={24}>
        <Text strong>Required: </Text>
        {required}
      </Col>
    </Row>
  )
}

export default ApiDoc

const InsuranceProcessBoxes = styled.h3`
  position: relative;
  z-index: 2;
  // font-size: 1.5rem;
  margin-right: 20px;
  margin-left: 20px;
  //   margin-top: 6px;
  padding: 2px;
  // padding-top: 50px;
  background: PapayaWhip;
  border: none;
  border-radius: 3px;
`
