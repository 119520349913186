import React, { useState } from 'react'
import {
  Typography,
  Divider,
  Button,
  DatePicker,
  Row,
  Col,
  Card,
  Input
} from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import { Wrapper, Container } from '../../styled-components/styled-components'
import { Footer } from '../../components/Footer/Footer'
import SeoNoIndex from '../../components/SeoNoIndex'
import PageHeader from '../../components/PageHeader'
import moment from 'moment'

const { Title, Paragraph } = Typography
const { Meta } = Card
const { Search } = Input

const { RangePicker } = DatePicker

const DATE_FORMAT = 'YYYY/MM/DD'

const Blog = () => {
  const [searchValue, setSearchValue] = useState('')
  const [dateStrings, setDateStrings] = useState(['', ''])
  const [postsData, setPostsData] = useState(
    useStaticQuery(graphql`
      query {
        posts: allMarkdownRemark(
          filter: { frontmatter: { template: { eq: "BlogTemplate" } } }
        ) {
          edges {
            node {
              id
              rawMarkdownBody
              frontmatter {
                title
                date
                thumbnail
                slug
              }
            }
          }
        }
      }
    `)
  )

  const onChange = (dates, dateStrings) => {
    setDateStrings(dateStrings)
  }

  const onSearch = value => {
    setSearchValue(value)
  }

  const handleSearchChange = e => {
    const { value } = e.target
    setSearchValue(value)
  }

  const resetFilters = () => {
    setSearchValue('')
    setDateStrings(['', ''])
  }

  // filter for search bar
  const searchFilteredPosts = postsData.posts.edges.filter(e =>
    e.node.frontmatter.title.toLowerCase().includes(searchValue.toLowerCase())
  )
  // filter for dates
  let finalFilteredPosts
  if (dateStrings[0] === '' && dateStrings[1] === '') {
    finalFilteredPosts = searchFilteredPosts
  } else {
    finalFilteredPosts = searchFilteredPosts.filter((post, idx) => {
      const publishedDate = moment(post.node.frontmatter.date).format(
        DATE_FORMAT
      )
      return (
        moment(publishedDate).isBetween(dateStrings[0], dateStrings[1]) ||
        moment(publishedDate).isSame(dateStrings[0]) ||
        moment(publishedDate).isSame(dateStrings[1])
      )
    })
  }
  const showingAllPosts = !!(
    searchValue === '' &&
    dateStrings[0] === '' &&
    dateStrings[1] === ''
  )

  return (
    <LandingLayout>
      <SeoNoIndex />

      <PageHeader title="Blog" />

      <Wrapper>
        <Container style={{ padding: '40px 0 40px 0' }}>
          <Row>
            <Col xxl={16} xl={16} lg={16} md={16} sm={24} xs={24}>
              {showingAllPosts ? (
                <Title level={1}>All Posts</Title>
              ) : (
                <Button
                  icon={<LeftOutlined />}
                  shape="round"
                  onClick={() => resetFilters()}
                >
                  Back to all posts
                </Button>
              )}
            </Col>
          </Row>
          <Row justify="end" gutter={[10, 0]}>
            <Col xxl={6} xl={6} lg={6} md={7} sm={9} xs={24}>
              <Title level={4}>Filter by date</Title>
              <RangePicker
                size="medium"
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [
                    moment().startOf('month'),
                    moment().endOf('month')
                  ]
                }}
                format={DATE_FORMAT}
                onChange={onChange}
              />
            </Col>
            <Col xxl={6} xl={6} lg={6} md={7} sm={9} xs={24}>
              <Title level={4}>Search</Title>
              <Search
                key="blogSearchBar"
                size="medium"
                placeholder="Search Blog Posts"
                allowClear
                onSearch={onSearch}
                onChange={handleSearchChange}
                value={searchValue}
                style={{ width: 200 }}
              />
            </Col>
          </Row>
          <Divider />
          <Row span={24} gutter={[20, 20]}>
            {finalFilteredPosts.map((item, index) => (
              <Col key={item.node.id} xxl={8} xl={8} lg={8}>
                <Link to={item.node.frontmatter.slug}>
                  <Card
                    hoverable
                    style={{ width: '100%' }}
                    cover={
                      <img
                        alt={item.node.frontmatter.title}
                        style={{ height: '300px', objectFit: 'cover' }}
                        src={item.node.frontmatter.thumbnail}
                      />
                    }
                  >
                    <Row gutter={[0, 10]}>
                      <Col span={24}>
                        <Meta title={item.node.frontmatter.title} />
                      </Col>
                      <Col span={24}>
                        {moment(item.node.frontmatter.date).format('LL')}
                      </Col>
                      <Col span={24}>
                        <Paragraph
                          ellipsis={{
                            rows: 3,
                            expandable: true,
                            symbol: (
                              <Link to={item.node.frontmatter.slug}>More</Link>
                            )
                          }}
                        >
                          {item.node.rawMarkdownBody.substring(0, 300)}
                        </Paragraph>
                      </Col>
                    </Row>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </Wrapper>
      <Footer />
    </LandingLayout>
  )
}

export default Blog
