import React from 'react'
import PropTypes from 'prop-types'
import { Link as ScrollLink } from 'react-scroll'
import { Typography, Card, Row, Col } from 'antd'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import {
  Wrapper,
  Container
} from '../../../styled-components/styled-components'

const { Title, Paragraph } = Typography

const OneTwoThree = () => {
  const data = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "submit.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      two: file(relativePath: { eq: "research.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      three: file(relativePath: { eq: "pdf.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const cardsData = [
    {
      number: '01',
      text: (
        <>
          Submit property address through the{' '}
          <ScrollLink
            activeClass="active"
            to="order-fam-section"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            form
          </ScrollLink>{' '}
          at the bottom of this page.
        </>
      ),
      image: data.one.childImageSharp.fluid
    },
    {
      number: '02',
      text:
        'Property Of Interest Will Be Researched And A Report Will Be Developed.',
      image: data.two.childImageSharp.fluid
    },
    {
      number: '03',
      text: 'Your FAM Will Be Emailed Within 48 Hours As A PDF.',
      image: data.three.childImageSharp.fluid
    }
  ]

  return (
    <Wrapper>
      <Container>
        <Row span={24} gutter={[20, 0]}>
          {cardsData.map((item, idx) => (
            <Col
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
              key={idx}
              style={{ display: 'flex' }}
            >
              <MyCard item={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </Wrapper>
  )
}

const MyCard = ({ item }) => {
  MyCard.propTypes = {
    item: PropTypes.object
  }
  return (
    <StyledCard style={{ maxWidth: 400 }}>
      <Title level={1}>{item.number}</Title>
      <Text>{item.text}</Text>
      <Img fluid={item.image} />
    </StyledCard>
  )
}

export default OneTwoThree

const StyledCard = styled(Card)`
  padding: 0px 10px;
  flex: 1;
  margin: 0 auto;
`

const Text = styled(Paragraph)`
  color: #4a4a4a;
  font-size: 20px;
`
