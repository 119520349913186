import React from 'react'
import { Link } from 'gatsby'
import { Row, Col, Button, Typography } from 'antd'
import surveyIncentiveSvg from '../../../../assets/images/surveyincentive01.svg'

const { Title, Paragraph } = Typography

const LearnMore = ({ disclaimer, img }) => {
  return (
    <>
      {/* Elevation Certificate */}
      <Row>
        <Col lg={16}>
          <Title level={3} style={{ color: '#fff', fontWeight: '500' }}>
            Elevation Certificate
          </Title>
          <Paragraph style={{ color: 'white', fontWeight: '400' }}>
            An Elevation Certificate is used to define key elevations around
            your property and can confirm your eligibility for a Letter of Map
            Amendment (LOMA). If you do not have an Elevation Certificate, No
            Flood Florida can help you obtain one through one of our Survey
            partners. An Elevation Certificate can further define floodplain
            impacts and flood insurance premiums.
          </Paragraph>
          <p>
            <em style={{ fontWeight: 500 }}>{disclaimer}</em>
          </p>
        </Col>
        <Col lg={8} md={24} sm={24} xs={24} style={{ textAlign: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <img
              src={surveyIncentiveSvg}
              style={{ height: '140px', width: '200px' }}
              draggable={false}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <Link to="/loma">
              <Button type="primary" className="round secondary-btn">
                Learn More
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      {/* Want to Know More About your Property’s Flood Risk? */}
      <Row style={{ marginTop: '40px' }}>
        <Col lg={16}>
          <Title level={3} style={{ color: '#fff', fontWeight: '500' }}>
            Want to Know More About your Property’s Flood Risk?
          </Title>
          <Paragraph style={{ color: 'white', fontWeight: '400' }}>
            Learn more about your detailed flood risk, floodplain maps, building
            and structure impacts, and flood insurance premium estimates. Order
            your customized <b style={{ color: '#fff' }}>Flood Analysis Memo</b>{' '}
            today!
          </Paragraph>
          <p>
            <em style={{ fontWeight: 500 }}>{disclaimer}</em>
          </p>
        </Col>
        <Col lg={8} md={24} sm={24} xs={24} style={{ textAlign: 'center' }}>
          <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
            <img
              src={img}
              style={{ height: '100px', width: '100px' }}
              draggable={false}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <Link rel="nofollow" to="/fam">
              <Button type="primary" className="round secondary-btn">
                Learn More
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default LearnMore
