import React from 'react'
import { Row, Col } from 'antd'
import SearchAnotherPropertyBtn from './SearchAnotherPropertyBtn.js'
import FinalizeLomaBtn from './FinalizeLomaBtn.js'

const BottomMenu = ({ setShowRecommendation, setShowScreening }) => {
  const colSizes = {
    xxl: 12,
    xl: 12,
    lg: 24,
    md: 24,
    sm: 24,
    xs: 24
  }

  return (
    <Row gutter={[0, 20]} style={{ marginTop: '20px' }}>
      <Col {...colSizes} style={{ textAlign: 'center' }}>
        <FinalizeLomaBtn />
      </Col>
      <Col {...colSizes}>
        <Row align="center">
          <SearchAnotherPropertyBtn
            setShowRecommendation={setShowRecommendation}
            setShowScreening={setShowScreening}
          />
        </Row>
      </Col>
    </Row>
  )
}

export default BottomMenu
