import React from 'react'
import { Typography, Row, Col } from 'antd'
import { useStaticQuery, graphql } from 'gatsby'
import {
  Wrapper,
  Container
} from '../../../styled-components/styled-components'
import './team.css'
import TeamCard from '../../../components/TeamCard'

const { Title } = Typography

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      ahmed: file(relativePath: { eq: "Ahmed.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      taylor: file(relativePath: { eq: "taylornew1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chris: file(relativePath: { eq: "Chris.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sophia: file(relativePath: { eq: "SophiaHower.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      meghana: file(relativePath: { eq: "Meghana.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const teamMembers = [
    {
      id: 1,
      profileImg: data.ahmed.childImageSharp.fluid,
      name: 'Ahmed Hamed',
      title: 'Co-Founder & CEO',
      bio:
        'Ahmed Hamed is a Professional Engineer as well as a Certified Floodplain Manager. Ahmed has worked on projects spanning hundreds of square miles of Florida watersheds. The combination of being an engineer and working closely with real estate professionals allowed him to envision a product which uses the best available technical data, and analysis methodology and present it to the users in a way they will understand and value. Ahmed’s diverse career experience makes him uniquely qualified to lead No Flood Florida now and as it continues to grow.'
    },
    {
      id: 2,
      profileImg: data.taylor.childImageSharp.fluid,
      name: 'Taylor Lankford',
      title: 'Co-Founder & CTO',
      bio:
        'Taylor Lankford was recognized as Summa Cum Laude as he graduated top of his class from the University of Florida with a bachelors in Biological Engineering. Taylor’s immense passion for problem solving through programming efforts is the engine of No Flood Florida. Taylor is recognized by his colleagues for his efficient work and his ability to find effective solutions. This is demonstrated by his role as technical lead on several large-scale projects. As No Flood Florida continues to grow, Taylor’s role will be improving the program used and to bring on technical staff to continue to expand the service provided.'
    },
    {
      id: 3,
      profileImg: data.chris.childImageSharp.fluid,
      name: 'Chris Needham',
      title: 'Chairman',
      bio:
        'Chris Needham is a business leader and entrepreneur, having created, managed and built award winning companies from start-up though to corporate sale. His expertise is transforming companies through technical innovation and change management. Chris’ experience in Sales and Marketing and as a CEO - coupled with his I.T. background - gives him a unique skill set, which can be applied to any business.  He has: Built small company of six to 150 professionals by transforming business through introduction of new technology then sold organization for $15m and in process delivering substantial value for shareholders; Created and developed multi-award winning software used worldwide by industry giants, such as EDS, HP, BBC, UK Government, and British Telecom; Delivered new consumer app to market starting from idea that attracted host of investors.'
    },
    {
      id: 4,
      profileImg: data.sophia.childImageSharp.fluid,
      name: 'Sophia Hower',
      title: 'Marketing Specialist',
      bio:
        'Sophia Hower is a student at the University of South Florida. She is studying Global Business with a concentration in Business Analytics and Marketing. Sophia is a member of the Business Honors Program and is a Muma College of Business Student Ambassador. Sophia has a passion for providing exceptional customer service, and she loves connecting Florida residents with the No Flood Florida team.'
    },
    {
      id: 5,
      profileImg: data.meghana.childImageSharp.fluid,
      name: 'Meghana Aduri',
      title: ' Business Anaytics Specialist',
      bio:
        'Meghana Aduri is a student at the University of South Florida. She is studying Business Analytics and Information Systems with a concentration in Cybersecurity. Meghana is a member of Women in Business and The Association of Information Systems. Meghana enjoys her role of compiling and analyzing data to help No Flood Florida make informed decisions, develop business plans and implement new strategies. She further values connecting Florida residents with the No Flood Florida team and has a commitment to delivering outstanding customer service.'
    }
  ]

  return (
    <Wrapper>
      <Container>
        <Row justify="center">
          <Title level={1}>Meet The Team</Title>
        </Row>
        <Row span={24} gutter={[20, 5]}>
          {teamMembers.map((member, idx) => (
            <Col xl={12} lg={12} md={12} sm={12} xs={24} key={idx}>
              <TeamCard member={member} />
            </Col>
          ))}
        </Row>
      </Container>
    </Wrapper>
  )
}

export default Team
