import React from 'react'
import {
  Wrapper,
  Container
} from '../../../styled-components/styled-components'
import { Typography, Card, Col } from 'antd'
import ConversionForm from './datumConversionForm'
import { Link } from 'gatsby'

const { Title, Paragraph } = Typography

const sectionDatum = () => {
  return (
    // <Wrapper className="nff-indigo" id="datum-conversion-section">
    <Wrapper id="datum-conversion-section">
      <Container style={{ padding: '0px' }}>
        <Title level={4} style={{ textAlign: 'center' }}>
          Conversion Form
        </Title>

        <Col
          xl={14}
          lg={14}
          md={24}
          sm={24}
          xs={24}
          style={{ margin: '0 auto' }}
        >
          <Card className="box-shadow round">
            <Paragraph>
              Fill out the form below to generate the x, y coordinates needed
              for the{' '}
              <a
                href="https://geodesy.noaa.gov/NCAT/"
                target="_blank"
                style={{ color: '#55b96a' }}
              >
                NGS Website
              </a>
              . The external site tool will ask for a few other parameters, see
              instructions below form.
            </Paragraph>
            <ConversionForm />
          </Card>
        </Col>
      </Container>
    </Wrapper>
  )
}

export default sectionDatum
