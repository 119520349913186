import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { Container } from '../../styled-components/styled-components'
import PropTypes from 'prop-types'
import firebase from 'gatsby-plugin-firebase'
import axios from 'axios'
import { AuthContext } from '../../context/auth'
import LineGraphLowRisk from './Insurance/LineGraphLowRisk'
import LineGraphHighRisk from './Insurance/LineGraphHighRisk'

import './Insurance/styles.css'

import MapInsurance from '../esri/map/MapInsurance'
import { mapLoaded } from '../../redux/reducers/map'

import Loader from './Recommendation/Loader'

/* Styles */
import {
  Row,
  Col,
  Typography,
  Grid,
  Descriptions
} from 'antd'

/* Components */
import AutoSuggestInternal from '../AutoSuggestInternal/AutoSuggestInternal'

const { Title, Paragraph } = Typography
const { useBreakpoint } = Grid

/**
 * Controls the 'flow' of displaying the screeening and various recommendations
 */
const InsuranceEstimator = () => {
  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '34px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '34px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  const [selectedAddress, setSelectedAddress] = useState('')
  const [selectedCounty, setSelectedCounty] = useState(null)

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setSelectedAddress(suggestion)
  }

  function updateParentCountyState(county) {
    setSelectedCounty(county)
  }

  const handleProceedButton = address => {
    setSelectedAddress(address)
  }

  /* Render IFrame Landing */
  return (
    <IFrameLanding
      onSuggestionSelected={onSuggestionSelected}
      updateParentCountyState={updateParentCountyState}
      firebase={firebase}
      handleProceedButton={handleProceedButton}
      selectedCounty={selectedCounty}
      selectedAddress={selectedAddress}
    />
  )
}

const IFrameLanding = props => {
  const { user } = useContext(AuthContext)

  const [apiUserData, setAPIUserData] = useState(null)
  const [dataSourceRequest, setDataSourceRequest] = useState()
  const [loadingInsuranceEstimate, setLoadingInsuranceEstimate] = useState(false);
  const [locationSourceRequest, setLocation] = useState()
  const [propertyData, setPropertyData] = useState({
    data: '',
    loading: true
  })

  const [xyData, setXYData] = useState({
    data: '',
    loading: true
  })

  /**
   * Fetch the full firebase user data when the component mounts
   */
  useEffect(() => {
    if (user.uid) {
      firebase
        .firestore()
        .collection('apiUsers')
        .where('uid', '==', user.uid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            if (doc) {
              const data = doc.data()
              setAPIUserData(data)
            }
          })
        })
    }
  }, [])

  IFrameLanding.propTypes = {
    onSuggestionSelected: PropTypes.func,
    updateParentCountyState: PropTypes.func,
    firebase: PropTypes.object,
    handleProceedButton: PropTypes.func,
    selectedCounty: PropTypes.string,
    selectedAddress: PropTypes.string
  }
  const {
    onSuggestionSelected,
    updateParentCountyState,
    firebase,
    selectedCounty,
    selectedAddress
  } = props

  const zipCode = selectedAddress.split(' ').slice(-1)
  const firstAddress = selectedAddress.substr(0, selectedAddress.indexOf(','))
  const apiKey = apiUserData?.apiKey

  const handleClick = async () => {
    setLoadingInsuranceEstimate(true);

    const data = await axios.get(
      `https://us-central1-flood-score.cloudfunctions.net/getProperty?state=Florida&county=${selectedCounty}&zip=${zipCode}&streetAddress=${firstAddress}&apiKey=${apiKey}`
    )
    setPropertyData({
      data: data,
      loading: false
    })

    // using the esri geocode to get x,y location
    const xyData = await axios.get(
      `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?singleLine=${data.data.properties[0].FULL_ADD}&outFields=Addr_type&f=pjson`
    )
    setXYData({
      data: xyData,
      loading: false
    })

    const locationSource = [
      {
        key: '1',
        locHome: xyData.data.candidates[0],
        countyName: selectedCounty
      }
    ]

    setLocation(locationSource)

    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
    })

    const dataSource = [
      {
        key: '1',
        address: data.data.properties[0].FULL_ADD,
        femazone: data.data.properties[0].FEMA_ZONE,
        femabfe: parseFloat(data.data.properties[0].FEMA_BFE).toFixed(2),
        sge: parseFloat(data.data.properties[0].SGE).toFixed(2),
        loma: data.data.properties[0].LOMA,
        bldsqft: data.data.properties[0].SQ_FT,
        femaDate: data.data.properties[0].FEMA_EFF_DT,
        community: data.data.properties[0].Community,
        crs: data.data.properties[0].CRS,
        strucPct: data.data.properties[0].STRUC_PCT,
        fldDepth:
          data.data.properties[0].FEMA_BFE - data.data.properties[0].SGE,
        buildingCost: formatter.format(100 * data.data.properties[0].SQ_FT),
        contentsCost: formatter.format(
          0.25 * 100 * data.data.properties[0].SQ_FT
        ),

        prePost: data.data.properties[0].PRE_POST_1,
        buildingCostRaw: 100 * data.data.properties[0].SQ_FT,
        fldInsImpact: Math.ceil(
          parseFloat(data.data.properties[0].SGE).toFixed(3) -
            parseFloat(data.data.properties[0].FEMA_BFE).toFixed(3) +
            1.5
        )
        // fldInsImpact: Math.round(sge + 1.5 - femabfe)
      }
    ]

    setDataSourceRequest(dataSource)
    setLoadingInsuranceEstimate(false);
  }

  return (
    <>
      <Row textAlign="left" gutter={[0, 10]}>
        <Paragraph>
          The Flood Insurance Estimator was bulit upon the latest NFIP Flood
          Insurance Rating methodology. This will soon be replaced by the Risk
          Rating 2.0 system; however, in many instances the yearly cost
          increases or decreases are only changing by a few 10s or 100s of
          dollars per year. These estimates should provide some insight as to
          true insurance costs.
        </Paragraph>
      </Row>
      <AutoSuggestRow span={24}>
        <Col span={24} style={{ padding: '0', margin: '0 auto' }}>
          <AutoSuggestInternal
            autocomplete="off"
            theme={autosuggestTheme}
            countySelectStyles={countySelectStyles}
            updateParentCountyState={updateParentCountyState}
            onSuggestionSelected={onSuggestionSelected}
            inputProps={{ id: 'eligibilityAddressSuggest' }}
            firebase={firebase}
          />
        </Col>
        <Row
          span={24}
          style={{ padding: '0', margin: '0 auto', textAlign: 'center' }}
        >
          <div>
            <br />
            <FloodReportButton onClick={handleClick}>Proceed</FloodReportButton>
            <br />
            <br />
          </div>
        </Row>
      </AutoSuggestRow>

      <Container id="insuranceReport" style={{ padding: '20px 0 20px 0' }}>
        <Col
          span={24}
        >
          {dataSourceRequest ? (
            <>
              <Row textAlign="left" gutter={[5, 10]}>
                <Paragraph>
                  Insurance estimate provided for{'  '}
                  <b>
                    {dataSourceRequest[0]?.address
                      .toLowerCase()
                      .split(' ')
                      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(' ')}
                    {'  '}
                  </b>
                  using the latest NFIP Rating Tables. Finished Floor Elevations
                  (FFE) are estimated as 1.5 ft higher than the LiDAR derived
                  Structure Ground Elevation (SGE).
                </Paragraph>
              </Row>
              <Row textAlign="left" gutter={[5, 10]}>
                <Paragraph>
                  <Choose>
                    <When condition={dataSourceRequest[0]?.femabfe != -9999}>
                      <Title level={4} style={{ color: '#d10000 ' }}>
                        HIGH RISK FLOOD ZONE
                      </Title>
                      <If condition={dataSourceRequest[0]?.prePost == 0}>
                        Pre FIRM, Any High-Risk Zone (A, AE, VE), Table 2A,
                        Insurance Flood Depth{' '}
                        {dataSourceRequest[0]?.fldInsImpact} ft
                      </If>
                      <If
                        condition={
                          dataSourceRequest[0]?.prePost == 1 &&
                          dataSourceRequest[0]?.femazone == 'A'
                        }
                      >
                        Post FIRM, Zone A Unnumbered, Estimate BFE and FFE
                        Values, Table 3C, Insurance Flood Depth{' '}
                        {dataSourceRequest[0]?.fldInsImpact} ft
                      </If>
                      <If
                        condition={
                          dataSourceRequest[0]?.prePost == 1 &&
                          dataSourceRequest[0]?.femazone == 'AE, LEVEL POOL'
                        }
                      >
                        Post FIRM, Zone AE, Estimate FFE Values, Table 3B
                        Building and Table 3B Contents, Insurance Flood Depth{' '}
                        {dataSourceRequest[0]?.fldInsImpact} ft
                      </If>
                      <If
                        condition={
                          dataSourceRequest[0]?.prePost == 1 &&
                          dataSourceRequest[0]?.femazone == 'AE, RIVERINE'
                        }
                      >
                        Post FIRM, Zone AE, Estimate FFE Values, Table 3B
                        Building and Table 3B Contents, Insurance Flood Depth{' '}
                        {dataSourceRequest[0]?.fldInsImpact} ft
                      </If>
                    </When>
                    <When condition={dataSourceRequest[0]?.femabfe == -9999}>
                      <Title level={4} style={{ color: '#388E8E' }}>
                        LOW RISK ZONE
                      </Title>
                      <Paragraph>
                        Low-risk policies are assumed to qualify for the
                        Preferred Risk Policy (PRP) rate. Fixed
                        building/contents replacement costs are used with fixed
                        deductibles.
                      </Paragraph>
                    </When>
                  </Choose>
                </Paragraph>
              </Row>
              <Row textAlign="left" gutter={[5, 10]}>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ padding: '0px 20px 0px 0px' }}
                >
                  <Descriptions
                    title="Building and Contents"
                    labelStyle={{
                      backgroundColor: '#E8E8E8',
                      fontWeight: 500
                    }}
                    contentStyle={{}}
                    bordered
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  >
                    <Descriptions.Item label="Building Coverage">
                      {dataSourceRequest[0]?.buildingCost}
                    </Descriptions.Item>
                    <Descriptions.Item label="Contents Coverage">
                      {dataSourceRequest[0]?.contentsCost}
                    </Descriptions.Item>
                  </Descriptions>
                  <br />

                  <Descriptions
                    title="NFIP Key Parameters"
                    bordered="true"
                    contentStyle={{}}
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    labelStyle={{
                      fontWeight: 500,
                      backgroundColor: '#E8E8E8'
                    }}
                  >
                    <Descriptions.Item label="Program">
                      Regular
                    </Descriptions.Item>
                    <Descriptions.Item label="Residence">
                      Primary
                    </Descriptions.Item>
                    <Descriptions.Item label="Flood Zone">
                      {dataSourceRequest[0]?.femazone}
                    </Descriptions.Item>
                    <Descriptions.Item label="Occupancy">
                      No Basement/Enclosure
                    </Descriptions.Item>
                    <Descriptions.Item label="Contents Location">
                      Lowest Floor Only - Above Ground Level
                    </Descriptions.Item>
                    <Descriptions.Item label="Construction Date">
                      <Choose>
                        <When condition={dataSourceRequest[0]?.prePost == 0}>
                          Pre FIRM
                        </When>
                        <When condition={dataSourceRequest[0]?.prePost == 1}>
                          Post FIRM
                        </When>
                      </Choose>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24} align="center">
                  {locationSourceRequest ? (
                    <MapInsurance
                      onMapLoaded={mapLoaded}
                      position={locationSourceRequest[0]?.locHome}
                      countyName={locationSourceRequest[0]?.countyName}
                      zoomLevel={17}
                    />
                  ) : (
                    <MapInsurance
                      onMapLoaded={mapLoaded}
                    />
                  )}
                  <br />
                  <Choose>
                    <When condition={dataSourceRequest[0]?.femabfe != -9999}>
                      <LineGraphHighRisk
                        femaBFE={dataSourceRequest[0]?.femabfe}
                        replacementCost={dataSourceRequest[0]?.buildingCostRaw}
                        insuranceImpact={dataSourceRequest[0]?.fldInsImpact}
                        fldZone={dataSourceRequest[0]?.femazone}
                        prePost={dataSourceRequest[0]?.prePost}
                        crsValue={dataSourceRequest[0]?.crs}
                      />
                    </When>
                    <When condition={dataSourceRequest[0]?.femabfe == -9999}>
                      <LineGraphLowRisk
                        femaZone={dataSourceRequest[0]?.femabfe}
                        replacementCost={dataSourceRequest[0]?.buildingCostRaw}
                      />
                    </When>
                  </Choose>
                </Col>
              </Row>
              <br />
              <Row textAlign="left" gutter={[5, 10]}>
                <Title level={5} style={{ color: '#00000' }}>
                  Basis of Insurance Calculations
                </Title>
                <Paragraph>
                  The replacement cost for this property was estimated by
                  assuming a replacement cost of $100/sqft. Contents replacement
                  was estimated as 25% of the replacement cost. Premiums are
                  calculated as full risk policies defined by the NFIP Regular
                  Program. Premiums are only an estimate and should be used as a
                  first approximation. Actual insurance policies may vary from
                  the estimates provided above.
                </Paragraph>
                <Paragraph>
                  The Regular Program from the NFIP sets{' '}
                  <strong>maximum</strong> replacement cost values at $250,000
                  and contents coverage at $100,000. If the building/contents
                  replacement cost exceeds these values, additional insurance
                  may be required.
                </Paragraph>
              </Row>
              <Row textAlign="left" gutter={[5, 10]}>
                <Title level={5} style={{ color: '#00000' }}>
                  Private Flood Providers
                </Title>
              </Row>
              <Row textAlign="left" gutter={[5, 10]}>
                <Paragraph>
                  Pass through connections from Private Flood Insurance
                  companies, does Neptune support something like this?
                </Paragraph>
              </Row>
            </>
          ) : loadingInsuranceEstimate ?(
            <Row justify="center" gutter={[0, 10]}>
              <Loader />
            </Row>
          ) : (<></>)}
          <br />
        </Col>
      </Container>
    </>
  )
}

export default InsuranceEstimator

const AutoSuggestRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  max-width: 1080px;
`

const autosuggestTheme = {
  container: {
    width: '100%',
    color: '#666666',
    position: 'relative',
    top: '6px'
  },

  containerOpen: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    zIndex: '999',
    position: 'relative',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '0px',
    borderBottomRadius: '0px'
  },

  input: {
    borderRadius: '4px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    color: '#666666',
    width: '100%',
    padding: '10px 15px 10px 15px',
    height: '3rem',
    border: 'none'
  },

  inputOpen: {
    fontWeight: '500',
    color: '#666666',
    padding: '10px 15px 10px 15px',
    border: 'none'
  },

  inputFocused: {
    outline: 'none'
  },

  suggestionsContainer: {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    maxHeight: '300px',
    overflow: 'auto',
    margin: '0',
    padding: '0',
    zIndex: '999',
    textAlign: 'left',
    background: '#FAFAFA'
  },

  /**
   * Position absolute here will not push the containers underneath down.
   */
  suggestionsContainerOpen: {
    width: '100%',
    zIndex: '999',
    backgroundColor: '#fff',
    position: 'absolute'
  },

  suggestionsList: {
    listStyleType: 'none',
    borderTop: '1px solid #eee',
    paddingRight: '10px',
    paddingBottom: '10px',
    marginBottom: '0'
  },

  suggestion: {
    paddingTop: '4px',
    paddingBottom: '4px',
    cursor: 'pointer'
  },

  suggestionHighlighted: {
    backgroundColor: '#eeeeee'
  },

  suggestionFirst: {},
  sectionContainer: {},
  sectionContainerFirst: {},
  sectionTitle: {}
}

const countySelectStyles = {
  borderRadius: '4px !important',
  boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  position: 'relative',
  margin: '0 auto',
  width: '100%',
  maxWidth: '250px',
  textAlign: 'left'
}

export const FloodReportButton = styled.button`
  &,
  &:link,
  &:visited {
    width: 100px;
    height: 40px;
    text-align: center !important;
    color: white !important;
    cursor: pointer;
    background-color: #55b96a;
    font-size: 0.94rem;
    font-weight: 700;
    border: 1px solid #55b96a;
    border-radius: 4px;
    text-decoration: none;
    text-transform: uppercase;
    background-size: 230%;
    transition: all 0.4s;
    z-index: 999;
  }

  &:hover {
    background-color: #4ca25e;
    border: 1px solid #4ca25e;
    background-position: 100%;
    color: white;
  }

  &:active {
    outline: none;
    transform: scale(0.9);
  }

  &:disabled {
    border: 1px solid #d4d4d4;
    background-color: #d4d4d4;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }
`
