import React from 'react'
import styled from 'styled-components'
import { Grid, Typography, Row, Col, Card } from 'antd'
import {
  Wrapper,
  Container
} from '../../../styled-components/styled-components'
import { Link } from 'gatsby'

const { useBreakpoint } = Grid
const { Title, Text } = Typography

const Services = () => {
  const screens = useBreakpoint()

  const isMobile = screens.xs

  const Services = () => {
    return (
      <Row gutter={[20, 40]} style={{ padding: '20px 0px 20px 0px' }}>
        <Col xl={12} lg={12} md={12} sm={24}>
          <Link to="/loma">
            <ServiceCard hoverable>
              <Row>
                <Col>
                  <Title level={4}>Letter Of Map Amendment (LOMA)</Title>
                </Col>
                <Col>
                  <Text>
                    If a property is wrongly designated as being in the high
                    risk flood zone, a Letter of Map Amendment is needed to
                    correct this issue.
                  </Text>
                </Col>
              </Row>
            </ServiceCard>
          </Link>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24}>
          <Link rel="nofollow" to="/fam">
            <ServiceCard hoverable>
              <Row>
                <Col>
                  <Title level={4}>Flood Analysis Memo (FAM)</Title>
                </Col>
                <Col>
                  <Text>
                    A Flood Analysis Memo (FAM) will help you answer questions
                    about your property's related flood risk. Ask your Realtor
                    for a FAM or order one today.
                  </Text>
                </Col>
              </Row>
            </ServiceCard>
          </Link>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24}>
          <Link to="/api-doc">
            <ServiceCard>
              <Row>
                <Col>
                  <Title level={4}>Flood Risk APIs</Title>
                </Col>
                <Col>
                  <Text>
                    Our Flood Risk API is perfect for real estate websites,
                    insurance companies, investors, and app developers looking
                    to access structure specific FEMA Flood Zones, Base Flood
                    Elevations, Structure Elevations, Parcel Data and Flood Risk
                    Insights.
                  </Text>
                </Col>
              </Row>
            </ServiceCard>
          </Link>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24}>
          <Link to="/partners">
            <ServiceCard hoverable>
              <Row>
                <Col>
                  <Title level={4}>Partnership</Title>
                </Col>
                <Col>
                  <Text>
                    No Flood Florida ensures that its partners have the latest
                    flood risk data to accurately identify flood risk issues to
                    convey to their clients and members. Our partners have flood
                    risk insights to assist you in making the right decision.
                  </Text>
                </Col>
              </Row>
            </ServiceCard>
          </Link>
        </Col>
      </Row>
    )
  }

  return (
    <Wrapper>
      <Container style={{ padding: '20px 20px 20px 20px' }}>
        <Row xl={24} lg={24} gutter={[50, 90]} className="nff-indigo round">
          <Col xl={12} lg={12} md={24} sm={24}>
            <Row style={{ paddingTop: isMobile ? '20px' : '190px' }}>
              <Col span={24}>
                <Text style={{ color: '#fff' }}>
                  Full Flood Risk Information Approach
                </Text>
              </Col>
              <Col span={24}>
                <Title style={{ fontSize: '44px', color: '#fff' }}>
                  Our Services
                </Title>
              </Col>
              <Col span={24}>
                <Text style={{ color: '#fff' }}>
                  Through the use of LiDAR technology, best available flood risk
                  models and analysis, we will help you better understand your
                  risk of flooding, and help you reduce the cost of your flood
                  insurance premiums.
                </Text>
              </Col>
            </Row>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24}>
            <Services />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const ServiceCard = styled(Card)`
  border-radius: 10px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
  min-height: 300px;
  border-top: 6px solid #757575;
  transition: 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    transition: 0.3s ease-in-out;
    border-top: 6px solid #d3b574;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`

export default Services
