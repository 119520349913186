import React from 'react'
import { Button, Grid } from 'antd'

const { useBreakpoint } = Grid

const SearchAnotherPropertyBtn = ({
  setShowRecommendation,
  setShowScreening
}) => {
  const screen = useBreakpoint()

  return (
    <Button
      // block={screen.lg || screen.xl || screen.xxl ? false : true}
      style={{ fontWeight: '600', textTransform: 'uppercase', width: '260px' }}
      size="large"
      type="primary"
      className="round box-shadow-3"
      onClick={() => {
        setShowRecommendation(false)
        setShowScreening(false)
      }}
    >
      SEARCH ANOTHER PROPERTY
    </Button>
  )
}

export default SearchAnotherPropertyBtn
