import React from 'react'
import styled from 'styled-components'
import {
  Wrapper,
  Container
} from '../../../styled-components/styled-components'
import { Row, Col, Typography, Button } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Link } from 'gatsby'

const { Title } = Typography

const SectionHeader = () => {
  return (
    <Wrapper className="nff-indigo">
      <Container>
        <SectionRow justify="center" span={24}>
          <Col>
            <Title level={1} style={{ color: '#fff' }}>
              <div style={{ textAlign: 'center' }}>What Is A LOMA &</div>
              <div style={{ textAlign: 'center' }}>
                How Can It Save Me Money?
              </div>
            </Title>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Link to="/order-loma">
                <Button
                  size="large"
                  type="primary"
                  className="box-shadow"
                  shape="round"
                >
                  Already Know? Let's Get Started
                  <CheckCircleOutlined />
                </Button>
              </Link>
            </Row>
          </Col>
        </SectionRow>
      </Container>
    </Wrapper>
  )
}

const SectionRow = styled(Row)`
  min-height: 200px;
  padding-top: 20px;
`

export default SectionHeader
