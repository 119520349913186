import { useState, useEffect } from 'react'
import firebase from 'gatsby-plugin-firebase'

export const useGetContacts = numDays => {
  console.log('days:', numDays)
  const [contactForm, setContactFormData] = useState(null)
  const [loading, setLoading] = useState(true)

  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate())

  const previousDate = new Date()
  previousDate.setDate(previousDate.getDate() - numDays)

  let postData = []

  useEffect(() => {
    firebase
      .firestore()
      .collection('contactForm')
      .where('delivery.startTime', '>', previousDate)
      .orderBy('delivery.startTime', 'desc')
      .get()
      .then(querySnapshot => {
        console.log('querySnapshot:', querySnapshot.size)

        querySnapshot.forEach(documentSnapshot => {
          if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              0
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Contact',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName: 'No Name'
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              1
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Contact',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName: documentSnapshot
                .data()
                .template.data.name.toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              2
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Contact',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName:
                documentSnapshot
                  .data()
                  .template.data.name.charAt(0)
                  .toLowerCase() +
                ' ' +
                documentSnapshot
                  .data()
                  .template.data.name.match(/\S+/gi)[1]
                  .toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length >=
              3
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Contact',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName:
                documentSnapshot
                  .data()
                  .template.data.name.charAt(0)
                  .toLowerCase() +
                ' ' +
                documentSnapshot
                  .data()
                  .template.data.name.match(/\S+/gi)[2]
                  .toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          }

          setLoading(false)
          // console.log(postData)
        })
      })
  }, [])

  useEffect(() => {
    firebase
      .firestore()
      .collection('eCertForm')
      .where('delivery.startTime', '>', previousDate)
      .orderBy('delivery.startTime', 'desc')
      .get()
      .then(querySnapshot => {
        console.log('querySnapshot:', querySnapshot.size)

        querySnapshot.forEach(documentSnapshot => {
          if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              0
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'E Cert',
              fullAddress:
                documentSnapshot.data().template.data.address +
                ', ' +
                documentSnapshot.data().template.data.city +
                ', ' +
                documentSnapshot.data().template.data.state +
                ' ' +
                documentSnapshot.data().template.data.zipcode,
              reportName: 'No Name'
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              1
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'E Cert',
              fullAddress:
                documentSnapshot.data().template.data.address +
                ', ' +
                documentSnapshot.data().template.data.city +
                ', ' +
                documentSnapshot.data().template.data.state +
                ' ' +
                documentSnapshot.data().template.data.zipcode,
              reportName: documentSnapshot
                .data()
                .template.data.name.toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              2
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'E Cert',
              fullAddress:
                documentSnapshot.data().template.data.address +
                ', ' +
                documentSnapshot.data().template.data.city +
                ', ' +
                documentSnapshot.data().template.data.state +
                ' ' +
                documentSnapshot.data().template.data.zipcode,
              reportName:
                documentSnapshot
                  .data()
                  .template.data.name.charAt(0)
                  .toLowerCase() +
                ' ' +
                documentSnapshot
                  .data()
                  .template.data.name.match(/\S+/gi)[1]
                  .toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length >=
              3
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'E Cert',
              fullAddress:
                documentSnapshot.data().template.data.address +
                ', ' +
                documentSnapshot.data().template.data.city +
                ', ' +
                documentSnapshot.data().template.data.state +
                ' ' +
                documentSnapshot.data().template.data.zipcode,
              reportName:
                documentSnapshot
                  .data()
                  .template.data.name.charAt(0)
                  .toLowerCase() +
                ' ' +
                documentSnapshot
                  .data()
                  .template.data.name.match(/\S+/gi)[2]
                  .toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          }

          // setLoading(false)
          // console.log(postData)
        })
      })
  }, [])

  useEffect(() => {
    firebase
      .firestore()
      .collection('famOrderForm')
      .where('delivery.startTime', '>', previousDate)
      .orderBy('delivery.startTime', 'desc')
      .get()
      .then(querySnapshot => {
        console.log('querySnapshot:', querySnapshot.size)

        querySnapshot.forEach(documentSnapshot => {
          if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              0
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'FAM',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName: 'No Name'
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              1
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'FAM',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName: documentSnapshot
                .data()
                .template.data.name.toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              2
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'FAM',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName:
                documentSnapshot
                  .data()
                  .template.data.name.charAt(0)
                  .toLowerCase() +
                ' ' +
                documentSnapshot
                  .data()
                  .template.data.name.match(/\S+/gi)[1]
                  .toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length >=
              3
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'FAM',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName:
                documentSnapshot
                  .data()
                  .template.data.name.charAt(0)
                  .toLowerCase() +
                ' ' +
                documentSnapshot
                  .data()
                  .template.data.name.match(/\S+/gi)[2]
                  .toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          }

          // setLoading(false)
          // console.log(postData)
        })
      })
  }, [])

  useEffect(() => {
    firebase
      .firestore()
      .collection('orderForm')
      .where('delivery.startTime', '>', previousDate)
      .orderBy('delivery.startTime', 'desc')
      .get()
      .then(querySnapshot => {
        console.log('querySnapshot:', querySnapshot.size)

        querySnapshot.forEach(documentSnapshot => {
          if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              0
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'LOMA Order',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName: 'No Name'
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              1
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'LOMA Order',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName: documentSnapshot
                .data()
                .template.data.name.toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              2
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'LOMA Order',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName:
                documentSnapshot
                  .data()
                  .template.data.name.charAt(0)
                  .toLowerCase() +
                ' ' +
                documentSnapshot
                  .data()
                  .template.data.name.match(/\S+/gi)[1]
                  .toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length >=
              3
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'LOMA Order',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName:
                documentSnapshot
                  .data()
                  .template.data.name.charAt(0)
                  .toLowerCase() +
                ' ' +
                documentSnapshot
                  .data()
                  .template.data.name.match(/\S+/gi)[2]
                  .toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          }

          // setLoading(false)
          // console.log(postData)
        })
      })
  }, [])

  useEffect(() => {
    firebase
      .firestore()
      .collection('screeningForm')
      .where('delivery.startTime', '>', previousDate)
      .orderBy('delivery.startTime', 'desc')
      .get()
      .then(querySnapshot => {
        console.log('querySnapshot:', querySnapshot.size)

        querySnapshot.forEach(documentSnapshot => {
          if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              0
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Screening',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName: 'No Name'
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              1
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Screening',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName: documentSnapshot
                .data()
                .template.data.name.toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              2
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Screening',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName:
                documentSnapshot
                  .data()
                  .template.data.name.charAt(0)
                  .toLowerCase() +
                ' ' +
                documentSnapshot
                  .data()
                  .template.data.name.match(/\S+/gi)[1]
                  .toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length >=
              3
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Screening',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName:
                documentSnapshot
                  .data()
                  .template.data.name.charAt(0)
                  .toLowerCase() +
                ' ' +
                documentSnapshot
                  .data()
                  .template.data.name.match(/\S+/gi)[2]
                  .toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          }

          // setLoading(false)
          // console.log(postData)
        })
      })
  }, [])

  useEffect(() => {
    firebase
      .firestore()
      .collection('phoneCallForm')
      .where('delivery.startTime', '>', previousDate)
      .orderBy('delivery.startTime', 'desc')
      .get()
      .then(querySnapshot => {
        console.log('querySnapshot:', querySnapshot.size)

        querySnapshot.forEach(documentSnapshot => {
          if (
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
            0
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Phone',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName: 'No Name'
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
            1
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Phone',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName: documentSnapshot
                .data()
                .template.data.name.toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
            2
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Phone',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName:
                documentSnapshot
                  .data()
                  .template.data.name.charAt(0)
                  .toLowerCase() +
                ' ' +
                documentSnapshot
                  .data()
                  .template.data.name.match(/\S+/gi)[1]
                  .toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.name.match(/\S+/gi).length >=
            3
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Phone',
              fullAddress: documentSnapshot.data().template.data.address,
              reportName:
                documentSnapshot
                  .data()
                  .template.data.name.charAt(0)
                  .toLowerCase() +
                ' ' +
                documentSnapshot
                  .data()
                  .template.data.name.match(/\S+/gi)[2]
                  .toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          }

          // setLoading(false)
          // console.log(postData)
        })
      })
  }, [])

  useEffect(() => {
    firebase
      .firestore()
      .collection('insuranceQuoteRequest')
      .where('delivery.startTime', '>', previousDate)
      .orderBy('delivery.startTime', 'desc')
      .get()
      .then(querySnapshot => {
        console.log('querySnapshot:', querySnapshot.size)

        querySnapshot.forEach(documentSnapshot => {
          if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              0
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Insurance Quote',
              quoteStatus: documentSnapshot.data().insuranceQuotes,
              fullAddress:
                documentSnapshot.data().template.data.address +
                ', ' +
                documentSnapshot.data().template.data.city +
                ', ' +
                documentSnapshot.data().template.data.state +
                ' ' +
                documentSnapshot.data().template.data.zipcode,
              reportName: 'No Name'
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              1
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Insurance Quote',
              quoteStatus: documentSnapshot.data().insuranceQuotes,
              fullAddress:
                documentSnapshot.data().template.data.address +
                ', ' +
                documentSnapshot.data().template.data.city +
                ', ' +
                documentSnapshot.data().template.data.state +
                ' ' +
                documentSnapshot.data().template.data.zipcode,
              reportName: documentSnapshot
                .data()
                .template.data.name.toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length ==
              2
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Insurance Quote',
              quoteStatus: documentSnapshot.data().insuranceQuotes,
              fullAddress:
                documentSnapshot.data().template.data.address +
                ', ' +
                documentSnapshot.data().template.data.city +
                ', ' +
                documentSnapshot.data().template.data.state +
                ' ' +
                documentSnapshot.data().template.data.zipcode,
              reportName: documentSnapshot
                .data()
                .template.data.name.toLowerCase()
              // ' ' +
              // documentSnapshot
              //   .data()
              //   .template.data.name.match(/\S+/gi)[1]
              //   .toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          } else if (
            documentSnapshot.data().template.data.email !=
              'taylordlankford@gmail.com' &&
            documentSnapshot.data().template.data.name.match(/\S+/gi).length >=
              3
          ) {
            postData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              formType: 'Insurance Quote',
              quoteStatus: documentSnapshot.data().insuranceQuotes,
              fullAddress:
                documentSnapshot.data().template.data.address +
                ', ' +
                documentSnapshot.data().template.data.city +
                ', ' +
                documentSnapshot.data().template.data.state +
                ' ' +
                documentSnapshot.data().template.data.zipcode,
              reportName: documentSnapshot
                .data()
                // .template.data.name.charAt(0)
                .template.data.name.toLowerCase()
              // ' ' +
              // documentSnapshot
              //   .data()
              //   .template.data.name.match(/\S+/gi)[2]
              //   .toLowerCase()
            })
            // console.log('querySnapshot:', postData)
            setContactFormData(postData)
          }

          setLoading(false)
          // console.log(postData)
        })
      })
  }, [])

  return { contactForm, loading }
}
