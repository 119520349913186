import React, { useContext } from 'react'
import { Link, navigate } from 'gatsby'
import { Menu } from 'antd'
import PropTypes from 'prop-types'
import { AuthContext } from '../../context/auth'
import firebase from 'gatsby-plugin-firebase'

const SubMenu = Menu.SubMenu

const MenuItems = ({ mode }) => {
  MenuItems.propTypes = {
    mode: PropTypes.string
  }

  const { user } = useContext(AuthContext)

  const handleLogout = async () => {
    await firebase.auth().signOut()
    navigate('/app/login')
  }

  return (
    <div>
      {!user ? (
        <>
          <Menu mode={mode}>
            <SubMenu title={<span>Flood Zone Correction</span>}>
              <Menu.Item key="loma:1">
                <Link to="/loma">What is a LOMA?</Link>
              </Menu.Item>
              <Menu.Item key="loma:2">
                <Link to="/order-loma">Order a LOMA</Link>
              </Menu.Item>
              <Menu.Item key="loma:3">
                <Link to="/key-terms">Key Terms</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu title={<span>Elevation Certificate</span>}>
              <Menu.Item key="EC:1">
                <Link to="/elevation-certificate">
                  Order Elevation Certificate
                </Link>
              </Menu.Item>
              <Menu.Item key="EC:2">
                <Link to="/vertical-datum-conversion">
                  Convert Vertical Datum
                </Link>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="flood-insurance">
              <Link to="/flood-insurance">Flood Insurance</Link>
            </Menu.Item>

            <Menu.Item key="about">
              <Link to="/about">About</Link>
            </Menu.Item>

            <Menu.Item key="call">
              <a href="tel:800-561-0396">Call (800) 561-0396</a>
            </Menu.Item>
            <Menu.Item key="email">
              <a href="mailto:info@nofloodflorida.com">
                Email info@nofloodflorida.com
              </a>
            </Menu.Item>
            <Menu.Item key="register">
              <Link to="/sign-in/register">Register</Link>
            </Menu.Item>
            <Menu.Item key="login">
              <Link to="/sign-in/login">Login</Link>
            </Menu.Item>
          </Menu>
        </>
      ) : (
        <Menu mode={mode}>
          <Menu.Item key="profile">
            <Link to="/app/profile">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="API">
            <Link rel="nofollow" to="/api-doc">API</Link>
          </Menu.Item>
          <Menu.Item key="call">
            <a href="tel:800-561-0396">Call (800) 561-0396</a>
          </Menu.Item>
          <Menu.Item key="email">
            <a href="mailto:info@nofloodflorida.com">
              Email info@nofloodflorida.com
            </a>
          </Menu.Item>
          <Menu.Item key="logout" onClick={handleLogout}>
            <Link to="#!">Logout</Link>
          </Menu.Item>
        </Menu>
      )}
    </div>
  )
}

export default MenuItems
