import React, { useState, useContext } from 'react'
import { Button, Typography, Row, Col, Grid, Form } from 'antd'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import { Wrapper, Container } from '../../styled-components/styled-components'
import { Footer } from '../../components/Footer/Footer'
import PageHeader from '../../components/PageHeader'
import firebase from 'gatsby-plugin-firebase'
import { AuthContext } from '../../context/auth'
import { navigate, Link } from 'gatsby'
import SuccessModal from '../../components/SuccessModal'

const { Title, Paragraph } = Typography
const { useBreakpoint } = Grid

const Register = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const modalCloseTimeout = 500
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    industryName: 'Real Estate',
    email: '',
    password: '',
    error: null
  })

  const { setUser } = useContext(AuthContext)

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setData({ ...data, error: null })
    try {
      const result = await firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .then(sample => {
          const authUser = sample.user
          console.log('authUser after sign in', authUser)
          const collection = 'users'
          const doc = authUser.uid
          const setObj = {
            uid: authUser.uid,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            industryName: data.industryName,
            companyName: data.companyName
          }
          const collection1 = 'apiUsers'
          const doc1 = authUser.uid
          const setObj1 = {
            uid: authUser.uid,
            website: 'Home',
            apiKey: authUser.uid,
            email: data.email,
            monthCount: {},
            monthLimit: 10
          }

          //send user registration email thanking for signing up
          const userRegistrationMessageObj = {
            to: data.email,
            bcc: [
              'info@nofloodflorida.com',
              'sophia@nofloodflorida.com',
              'nofloodflorida+7t5d67e@nofloodflorida-org.myfreshworks.com'
            ],
            template: {
              name: 'userRegisterTemplate',
              data: {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                industryName: data.industryName,
                companyName: data.companyName
              }
            }
          }
          // create original user provile
          firebase
            .firestore()
            .collection(collection)
            .doc(doc)
            .set(setObj)
            .then(function () {
              console.log('Document successfully written!')
              // callback()
            })
            .catch(function (error) {
              console.error('Error writing document: ', error)
            })
          // create new API user
          firebase
            .firestore()
            .collection(collection1)
            .doc(doc1)
            .set(setObj1)
            // .collection2('requests')
            .then(function () {
              console.log('Document successfully written!')
              // callback()
            })
            .catch(function (error) {
              console.error('Error writing document: ', error)
            })
          // complete user registration information
          firebase
            .firestore()
            .collection('registeredUsers')
            .add(userRegistrationMessageObj)
            .then(() => {
              setIsModalVisible(true)
              // navigate('/app/profile')
            })
            .catch(error => console.log('Error adding document:', error))
        })
      //entire user object, result; what does this mean?
      setUser(result)
      console.log('registered user object', result)
      // navigate('/app/profile')
    } catch (err) {
      setData({ ...data, error: err.message })
    }
  }

  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '30px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '50px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setTimeout(() => navigate(`/`), modalCloseTimeout)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
    setTimeout(() => navigate(`/app/profile`), modalCloseTimeout)
  }

  return (
    <>
      <SuccessModal
        content="New User Registered Successfully! Please check your email and/or spam folder for a confirmation."
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
      />
      <LandingLayout>
        <PageHeader title="Register" />
        <Wrapper>
          <Container style={{ padding: '20px 0 20px 0' }}>
            <Row span={24}>
              <Col span={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                <Title level={4}>
                  Welcome to No Flood Florida's Registration Page
                </Title>
                <Paragraph style={{ padding: '0px 40px 0px 0px' }}>
                  Please fill in the following registration form to create a
                  user profile and exclusive access to internal flood risk
                  screening tools. A user profile will be created and is used to
                  track interactions with our flood risk data.
                </Paragraph>
                <Paragraph style={{ padding: '0px 40px 0px 0px' }}>
                  Upon registration an automated email will be sent providing
                  confirmation for the creation of an account. Additionally, the
                  initial user registration will create a default API key that
                  is used to query our flood risk data.
                </Paragraph>
                <Paragraph style={{ padding: '0px 40px 0px 0px' }}>
                  Already registered? Click{' '}
                  <Link to="/sign-in/login" style={{ color: '#55B96A' }}>
                    Login
                  </Link>{' '}
                  to access your profile.
                </Paragraph>
              </Col>
              <Col span={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="firstName">First Name</label>
                    <br />
                    <input
                      type="text"
                      name="firstName"
                      value={data.firstName}
                      onChange={handleChange}
                    />
                    <br />
                    <br />
                  </div>
                  <div>
                    <label htmlFor="lastName">Last Name</label>
                    <br />
                    <input
                      type="text"
                      name="lastName"
                      value={data.lastName}
                      onChange={handleChange}
                    />
                    <br />
                    <br />
                  </div>
                  <div>
                    <label htmlFor="companyName">Company Name</label>
                    <br />
                    <input
                      type="text"
                      name="companyName"
                      value={data.companyName}
                      onChange={handleChange}
                    />
                    <br />
                    <br />
                  </div>
                  <div>
                    <label htmlFor="industryName">Industry</label>
                    <br />
                    <select
                      id="industryName"
                      name="industryName"
                      value={data.industryName}
                      onChange={handleChange}
                    >
                      <option value="Real Estate">Real Estate</option>
                      <option value="Flood Insurance">Flood Insurance</option>
                      <option value="Surveyor">Surveyor</option>
                      <option value="Other">Other</option>
                    </select>
                    <br />
                    <br />
                  </div>
                  <div>
                    <label htmlFor="email">Email</label>
                    <br />
                    <input
                      type="text"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                    />
                    <br />
                    <br />
                  </div>
                  <div>
                    <label htmlFor="password">Password</label>
                    <br />
                    <input
                      type="password"
                      name="password"
                      value={data.password}
                      onChange={handleChange}
                    />
                    <br />
                    <br />
                  </div>
                  {data.error ? (
                    <p style={{ color: 'red' }}>{data.error}</p>
                  ) : null}
                  <input type="submit" value="Register" />
                </form>
              </Col>
            </Row>
          </Container>
        </Wrapper>
        <Footer />
      </LandingLayout>
    </>
  )
}

export default Register
