import React from 'react'
import { Modal } from 'antd'

const ImgLightbox = props => {
  const { imgUrl, setModalShow } = props
  return (
    <Modal
      {...props}
      destroyOnClose
      footer={null}
      centered
      width="75%"
      bodyStyle={{ padding: 0 }}
    >
      {imgUrl
        ? (
          <div>
            <img
              src={imgUrl}
              style={{ height: '100%', width: '100%' }}
              // onClick={() => setModalShow(true)}
            />
          </div>
          )
        : (
          <>Loading...</>
          )
      }
    </Modal>
  )
}

export default ImgLightbox
