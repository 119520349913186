import React, { useState, useRef } from 'react'
import { Form, Row, Col, Input, Button, Radio, Rate } from 'antd'
import firebase from 'gatsby-plugin-firebase'
import { normalizePhoneNumberInput } from '../../../utils/form/normalize'
import SuccessModal from '../../../components/SuccessModal'

const ClientReviewForm = () => {
  const formRef = useRef(null)
  const [loading2, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleReviewSubmit = values => {
    setLoading(true)
    values.timestamp = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York'
    })
    // Replace all undefined values with empty strings
    const convertedString = JSON.stringify(values, function (key, value) {
      return value === undefined ? '' : value
    })
    const updatedValues = JSON.parse(convertedString)
    const clientReviewFormMessageObj = {
      to: updatedValues.email,
      bcc: [
        'info@nofloodflorida.com',
        'sophia@nofloodflorida.com',
        'nofloodflorida+7t5d67e@nofloodflorida-org.myfreshworks.com'
      ],
      template: {
        name: 'clientReviewsTemplate',
        data: {
          ...updatedValues
        }
      }
    }

    firebase
      .firestore()
      .collection('clientReviews')
      .add(clientReviewFormMessageObj)
      .then(() => {
        setLoading(false)
        setIsModalVisible(true)
        formRef.current.resetFields()
      })
      .catch(error => console.log('Error adding document:', error))
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  }

  const handleOk = () => setIsModalVisible(false)
  const handleCancel = () => setIsModalVisible(false)

  return (
    <>
      <SuccessModal
        content="Thanks for the Review! Please check your email and/or spam folder for a confirmation."
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
      />
      <Form
        {...layout}
        ref={formRef}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleReviewSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={[20, 0]}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input
                className="round"
                size="large"
                allowClear={true}
                placeholder="John Smith"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please input your email!'
                }
              ]}
            >
              <Input
                className="round"
                size="large"
                allowClear={true}
                placeholder="johnsmith@gmail.com"
                onInput={e =>
                  (e.target.value = ('' + e.target.value).toLowerCase())
                }
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="rate-service"
              label="Quality of Service"
              rules={[
                {
                  required: true,
                  message: 'Please select a rating!'
                }
              ]}
            >
              <Rate style={{ color: '#55B96A' }} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="rate-understanding"
              label="Understanding of Flood Risk"
              rules={[
                {
                  required: true,
                  message: 'Please select a rating!'
                }
              ]}
            >
              <Rate style={{ color: '#55B96A' }} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="rate-staff"
              label="Knowledge and Friendliness of Staff"
              rules={[
                {
                  required: true,
                  message: 'Please select a rating!'
                }
              ]}
            >
              <Rate style={{ color: '#55B96A' }} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Customer Review" name="message">
              <Input.TextArea
                className="round"
                placeholder="Write your review here..."
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="box-shadow"
              shape="round"
              size="large"
              loading={loading2}
            >
              Submit Review
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </>
  )
}

export default ClientReviewForm
