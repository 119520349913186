import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Typography } from 'antd'
import { LandingLayout } from '../components/LandingLayout/LandingLayout'
import SeoNoIndex from '../components/SeoNoIndex'
import PageHeader from '../components/PageHeader'
import { Container, Wrapper } from '../styled-components/styled-components'
import { Footer } from '../components/Footer/Footer'
import './blogTemplate.css'

const { Title } = Typography

export default function BlogDocTemplate ({
  data // this prop will be injected by the GraphQL query below.
}) {
  BlogDocTemplate.propTypes = {
    data: PropTypes.object
  }
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const { title } = frontmatter

  return (
    <LandingLayout>
      <SeoNoIndex />
      <PageHeader title="Blog" />
      <Wrapper>
        <Container className="blogContentContainer">
          <Title style={{ textAlign: 'center' }}>{title}</Title>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
      </Wrapper>
      <Footer />
    </LandingLayout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`
