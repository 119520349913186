import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import { Typography, Row, Col } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import PageHeader from '../../components/PageHeader'
import { Wrapper, Container } from '../../styled-components/styled-components'
import { Footer } from '../../components/Footer/Footer'

const { Title, Paragraph, Text } = Typography

const Profile = props => {
  const [teamMember, setTeamMember] = useState(null)

  useEffect(() => {
    const { location } = props
    const { state } = location

    if (typeof state !== 'undefined') {
      const { member } = state
      setTeamMember(member)
    }
  }, [])

  if (!teamMember) {
    return <LoadingOutlined />
  }

  if (teamMember) {
    return (
      <LandingLayout>
        <PageHeader title="Team" />
        <Wrapper>
          <Container>
            <Row style={{ marginTop: '20px' }} gutter={[40, 20]}>
              <Col xxl={8} xl={8} lg={10} md={10} sm={24} xs={24}>
                <Img
                  fluid={teamMember.profileImg}
                  style={{
                    position: 'relative',
                    objectFit: 'cover',
                    height: '100%'
                  }}
                />
              </Col>
              <Col xxl={14} xl={14} lg={12} md={12} sm={24} xs={24}>
                <Title level={4}>{teamMember.name}</Title>
                <Text strong>{teamMember.title}</Text>
                <Paragraph>{teamMember.bio}</Paragraph>
              </Col>
            </Row>
          </Container>
        </Wrapper>
        <Footer />
      </LandingLayout>
    )
  }
}

export default Profile
