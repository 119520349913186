import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Link as ScrollLink } from 'react-scroll'
import { Button, Typography, Row, Col, Grid } from 'antd'
import { FormOutlined, FileOutlined } from '@ant-design/icons'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import {
  Wrapper,
  Container,
  ContainerImg
} from '../../styled-components/styled-components'
import OrderSection from './components/OrderSectionEC'
import { Footer } from '../../components/Footer/Footer'
import PageHeader from '../../components/PageHeader'

const { Title, Paragraph } = Typography
const { useBreakpoint } = Grid

const Ec = () => {
  const data = useStaticQuery(graphql`
    query {
      EC_Full: file(relativePath: { eq: "ElevationCertificate.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Section_B: file(relativePath: { eq: "Section_B_EC.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Section_C: file(relativePath: { eq: "Section_C_EC.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '30px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '50px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  return (
    <LandingLayout>
      <PageHeader title="FEMA Elevation Certificate" />

      <Wrapper>
        <Container style={{ padding: '20px 10px 10px 10px' }}>
          <Row gutter={[25, 10]}>
            <Paragraph>
              A <b>FEMA Elevation Certificate</b> is a document (
              <a
                href="https://firebasestorage.googleapis.com/v0/b/flood-score.appspot.com/o/sample%20reports%2Ffema_nfip_elevation-certificate.pdf?alt=media&token=ad9f5a29-6444-432f-ab91-ec5f90068ef6"
                target="_blank"
                style={{ color: '#55B96A' }}
              >
                see example
              </a>
              ) completed by a surveyor that informs the homeowner, building
              department, title company and mortgage company or other entities
              requiring the form of relevant elevations on the subject property
              and building. The Elevation Certificate will also include
              floodplain information including Base Flood Elevation and Flood
              Zone.
            </Paragraph>
            <Paragraph>
              <b>Local communities</b> often store Elevation Certificates on
              city or county websites and can be downloaded for free. It is a
              good idea to check with your city or county first. Additionally,
              the Elevation Certificate may not be available online, but the
              community could have a paper copy of the document. Finally, an
              Elevation Certificate may be associated with your{' '}
              <b>mortgage closing documents.</b>
            </Paragraph>
            <Paragraph>
              An additional resource for digital Elevation Certificates is the
              Florida Disaster website. This website is supported through the
              Florida Division of Emergency Management (FDEM). Use the following
              link to access the Elevation Certificate map viewer. Search by
              your address to see if an Elevation Certificate is on file -{' '}
              <a
                href="https://floridadisaster.maps.arcgis.com/apps/webappviewer/index.html?id=31af9cc082c442cea899fb955dc29d02"
                target="_blank"
                style={{
                  backgroundColor: '#ffefd5',
                  padding: '2px 4px 2px 4px',
                  color: '#262626'
                }}
              >
                FDEM Elevation Certificate Map Viewer
              </a>
            </Paragraph>
            <Row gutter={[20, 10]} style={{ justifyContent: 'space-around' }}>
              {/* <Col xl={12} lg={12} md={12} sm={24} xs={24}> */}
              <ScrollLink
                activeClass="active"
                // to="fam-learn-more"
                to="order-elevationcert-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Button
                  block
                  size="large"
                  type="primary"
                  shape="round"
                  icon={<FormOutlined />}
                >
                  Order Certificate
                </Button>
              </ScrollLink>
            </Row>
          </Row>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          id="ec-learn-more"
          style={{ padding: '20px 10px 10px 10px' }}
        >
          <Row gutter={[40, 40]}>
            <Col span={12} xs={24} sm={12}>
              <Img fluid={data.EC_Full.childImageSharp.fluid} />
            </Col>
            <Col span={12} xs={24} sm={12}>
              <Title level={3}>
                Elevation certificates are typically required when:
              </Title>
              <ul>
                <li>Purchasing a property</li>
                <li>Constructing a new building</li>
                <li>Obtaining flood insurance</li>
                <li>
                  Requesting a revision to the flood zone designation also known
                  as a Letter of Map Amendment (LOMA) or Letter of Map Revisions
                  (LOMRs)
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Wrapper>
      <Wrapper>
        <Container style={{ padding: '20px 10px 20px 10px' }}>
          <Row gutter={[25, 10]}>
            <Title level={3}>
              How does an Elevation Certificate help with a flood zone change?
            </Title>
            <Paragraph>
              A Letter of Map Amendment or LOMA is often used to prove the
              building structure is above the Base Flood Elevation (BFE) of the
              FEMA effective floodplain. Using detailed terrain datasets, No
              Flood Florida estimates the building structure elevation to
              determine if there is even a chance of qualifying. If our
              estimates indicate you are likely to qualify, an Elevation
              Certificate would be required to make a final determination
              whether this property would qualify for the flood zone change. If
              you do not have an Elevation Certificate, please take the first
              step by ordering an Elevation Certificate.
            </Paragraph>
            <Paragraph>
              We are typically focused on two main sections when reviewing
              Elevation Certificates.
              <ul>
                <li>
                  <b>SECTION B – FLOOD INSURANCE RATE MAP (FIRM) INFORMATION</b>
                </li>
                <li>
                  <b>
                    SECTION C – BUILDING ELEVATION INFORMATION (SURVEY REQUIRED)
                  </b>
                </li>
              </ul>
            </Paragraph>
          </Row>

          <Row gutter={[25, 10]}>
            <Title level={4}>SECTION B</Title>
          </Row>
          <Row gutter={[25, 10]}>
            <Paragraph>
              Section B of the Elevation Certificate contains various
              information related to the FEMA floodplains. In the example below,
              we can see the floodplain information defined for a property in
              Pasco County, Florida. We can see the FEMA Flood Zone (B8), the
              Base Flood Elevation (BFE) (B9), and the vertical datum used for
              the surveyed data (B11).
            </Paragraph>
          </Row>
          <Col>
            <ContainerImg>
              <Img fluid={data.Section_B.childImageSharp.fluid} />{' '}
            </ContainerImg>
          </Col>
          <br></br>
          <Row gutter={[25, 10]}>
            <Title level={4}>SECTION C</Title>
          </Row>
          <Row gutter={[25, 10]}>
            <Paragraph>
              Section C of the Elevation Certificate contains survey information
              related to your building structure and key elevations surrounding
              the building. For this example, we can see the survey was taken
              for a finished construction building with the vertical datum noted
              as NAVD-88. The key elevation needed for the Letter of Map
              Amendment is the Lowest Adjacent Grade (LAG, letter f). When this
              elevation is at or above the Base Flood Elevation, the property
              will most likely qualify for the Letter of Map Amendment.
            </Paragraph>
          </Row>
          <Col>
            <ContainerImg>
              <Img fluid={data.Section_C.childImageSharp.fluid} />
            </ContainerImg>
          </Col>
          <br></br>
          <Row gutter={[25, 10]}>
            <Title level={3}>
              Do I need an Elevation Certificate for Flood Insurance?
            </Title>
            <Paragraph>
              Under the new,{' '}
              <a
                href="/blog/RiskRating2.0"
                rel="nofollow"
                target="_blank"
                style={{ color: '#55B96A' }}
              >
                Risk Rating 2.0 insurance methodology
              </a>
              , FEMA is <b>not</b> directly requiring the use of Elevation
              Certificates. FEMA has developed a flood insurance rating engine
              that pulls in a variety of flood risk and elevation datasets to
              provide insurance quotes. Within these datasets, elevation
              information is extracted and used to develop the insurance quote.
              The insured <b>may</b> provide an Elevation Certificate to
              override the FEMA developed elevation information if the Elevation
              Certificate results in a better rate for the insured. It is
              recommended to discuss the various options, including private
              flood insurance, with your insurance agent.
            </Paragraph>
          </Row>
          <Row gutter={[25, 10]}>
            <Title level={4}>
              The price of a FEMA Elevation Certificate varies based on the
              location of your property of interest. Submit a request and we
              will contact you with the price and timeline to complete the job.
            </Title>
          </Row>
        </Container>
      </Wrapper>

      <OrderSection />
      <Footer />
    </LandingLayout>
  )
}

export default Ec
