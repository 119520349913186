// React imports
import React from 'react'

// ESRI ArcGIS API
import { loadMap } from '../../../utils/map'

// Styled Components
import styled from 'styled-components'

const Container = styled.div`
  height: 300px;
  width: 100%;
`

// Component
const MapInsurance = props => {
  // set an ID for the map to attach to
  const containerID = 'mapInsurance-view-container'

  // console.log(containerID)
  // console.log(props.position?.location)

  loadMap(
    containerID,
    props.position?.location,
    props.countyName,
    props.zoomLevel,
    props.fixedZoom,
    props.searchFunction
  ).then(() => {
    // call the map loaded event when we get the map view back

    props.onMapLoaded()
  })

  // load map with config properties

  // Component template
  return (
    <>
      <Container id={containerID}></Container>
    </>
  )
}

export default MapInsurance
