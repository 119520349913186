import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ElegibilityContainer = ({ children }) => {
  ElegibilityContainer.propTypes = {
    children: PropTypes.node
  }
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Background1_topo.jpg" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Wrapper>
      <Img
        draggable={false}
        fluid={data.file.childImageSharp.fluid}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          zIndex: '-10',
          opacity: '0.3'
        }}
      />
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #16163e;
  background-size: cover;
  overflow: hidden;
  position: relative;
  z-index: 2;
  /* min-height: calc(100vh - 64px); */
  /* min-height: 540px; */
  padding: 10px;
  display: flex;
  padding: 20px 0 20px 0;
`

export default ElegibilityContainer
