import React, { useState, useEffect } from 'react'
import './styles.css'
import {
  Wrapper,
  Container,
  LoadingText,
  LoadingWrapper
} from './StyledComponents'
import { Spin } from 'antd'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import LOMARecommendations from './LOMARecommendations'
import { useDomains, useGetPropertyData, useGetImg } from '../eligibility-hooks'
import ElegibilityContainer from '../ElegibilityContainer'
import RecommendationFooter from './RecommendationFooter'

const Recommendation = props => {
  const { address, county, setShowRecommendation, setShowScreening } = props
  const { pubDomain, devDomain } = useDomains()
  const { docData, loading } = useGetPropertyData(county, address)
  const { imgUrlData, imgLoading } = useGetImg(county, address)

  const [selectedAddress] = useState(address != null ? address : null)
  const [propertyData, setPropertyData] = useState(null)
  const [LOMARating, setLOMARating] = useState('')
  const [imgUrl, setImgUrl] = useState(null)
  const [femaZone, setFemaZone] = useState('')
  const [fakeLoading1, setFakeLoading1] = useState(true)
  const [fakeLoading2, setFakeLoading2] = useState(true)

  useEffect(() => {
    console.log('in useeffect docData:', docData)
    if (docData !== null || docData !== undefined) {
      const data = { ...docData }
      const { FEMA_ZONE } = data
      const { LOMA } = data
      setFemaZone(FEMA_ZONE)
      setLOMARating(LOMA)
      setPropertyData({ ...docData })
    }
  }, [docData])

  useEffect(() => {
    if (imgUrlData !== null || imgUrlData !== undefined) {
      setImgUrl(imgUrlData)
    }
  }, [imgUrlData, imgLoading])

  if (loading || fakeLoading1) {
    setTimeout(() => {
      setFakeLoading1(false)
    }, 1200)
    return (
      <Wrapper>
        <ElegibilityContainer>
          <Container>
            <LoadingWrapper>
              <Spin
                indicator={<Loading3QuartersOutlined spin />}
                size="large"
                style={{ color: '#55B96A', alignSelf: 'center' }}
              />
              <LoadingText>Getting Property Data...</LoadingText>
            </LoadingWrapper>
          </Container>
        </ElegibilityContainer>
      </Wrapper>
    )
  }

  if (fakeLoading2) {
    setTimeout(() => {
      setFakeLoading2(false)
    }, 2000)
    return (
      <Wrapper>
        <ElegibilityContainer>
          <Container>
            <LoadingWrapper>
              <Spin
                indicator={<Loading3QuartersOutlined spin />}
                size="large"
                style={{ color: '#55B96A', alignSelf: 'center' }}
              />
              <LoadingText>Analyzing Data...</LoadingText>
            </LoadingWrapper>
          </Container>
        </ElegibilityContainer>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <ElegibilityContainer>
        <Container style={{ padding: '0 20px 0 20px' }}>
          <LOMARecommendations
            propertyData={propertyData}
            LOMARating={LOMARating}
            femaZone={femaZone}
            selectedAddress={selectedAddress}
            imgUrl={imgUrl}
            setShowRecommendation={setShowRecommendation}
            setShowScreening={setShowScreening}
          />
          <br />
          <RecommendationFooter />
        </Container>
      </ElegibilityContainer>
    </Wrapper>
  )
}

export default Recommendation
