import React from 'react'
import {
  Wrapper,
  Container
} from '../../../styled-components/styled-components'
import { Typography, Card, Col } from 'antd'
import OrderForm from './FamOrderForm'

const { Title } = Typography

const OrderSection = () => {
  return (
    <Wrapper className="nff-indigo" id="order-fam-section">
      <Container style={{ padding: '0px' }}>
        <Title
          level={1}
          style={{ color: 'white', textAlign: 'center', fontSize: '40px' }}
        >
          Order FAM Now
        </Title>
        {/* <Title
          level={1}
          style={{
            color: 'white',
            textAlign: 'center',
            fontSize: '34px',
            marginTop: '0'
          }}
        >
          - Pay Later - $49
        </Title> */}

        <Col
          xl={14}
          lg={14}
          md={24}
          sm={24}
          xs={24}
          style={{ margin: '0 auto' }}
        >
          <Card className="box-shadow round">
            <OrderForm />
          </Card>
        </Col>
      </Container>
    </Wrapper>
  )
}

export default OrderSection
