import React from 'react'
import styled from 'styled-components'
import { Carousel, Row, Col, Typography } from 'antd'
import './testimonials-styles.css'

const { Title, Text } = Typography

function onChange(a, b, c) {}

const TestimonialsCarousel = () => {
  return (
    <Carousel afterChange={onChange} autoplay>
      <Row>
        <Col offset={2}>
          <TestimonialBubble>
            <P>
              "I first requested a Flood Analysis Memo from No Flood Florida
              where I learned I may be eligible to change the flood zone
              designation of my home to a low risk. I emailed them a 25-year-old
              elevation certificate. They quickly worked to get the documents
              and calculations ready and submit to FEMA. No Flood Florida was
              able to get the flood zone changed in less than a month. I am no
              longer required to have a flood insraunce on my home. You guys did
              an awesome job!"
            </P>
          </TestimonialBubble>
        </Col>
        <Col>
          <Title level={5}>E Corcoran </Title>
          <Text>Tampa, FL</Text>
        </Col>
      </Row>
      <Row>
        <Col offset={2}>
          <TestimonialBubble>
            <P>
              "I cannot thank you enough for this, it gives me great relief of
              mind that my wife and I chose the right place. Thank you!"
            </P>
          </TestimonialBubble>
        </Col>
        <Col>
          <Title level={5}>T Weisman</Title>
          <Text>St. Petersburg, FL</Text>
        </Col>
      </Row>
      <Row>
        <Col offset={2}>
          <TestimonialBubble>
            <P>
              "I'm a Realtor and I like what you're doing. I think this is a
              huge benefit to those clients who have already made a decision to
              purchase a property within a flood zone. Being able to give my
              buyer's a detailed report like this is definitely an added value
              service I can provide for them. Good job guys!!!"
            </P>
          </TestimonialBubble>
        </Col>
        <Col>
          <Title level={5}>M Riverd</Title>
          <Text>Tampa, FL</Text>
        </Col>
      </Row>
      <Row>
        <Col offset={2}>
          <TestimonialBubble>
            <P>
              “I just want to take a moment to share my wonderful experience
              dealing with No Flood Florida. I was particularly impressed with
              how fast they were able to navigate the process for me because I
              had just closed on my property. Meaning they were able to get my
              LOMA paperwork through before my first mortgage payment was due.”
            </P>
          </TestimonialBubble>
        </Col>
        <Col>
          <Title level={5}>J Fullenkamp</Title>
          <Text>Plant City, FL</Text>
        </Col>
      </Row>
      <Row>
        <Col offset={2}>
          <TestimonialBubble>
            <P>
              (Español) "No Flood Florida me explicó el proceso de LOMA y
              respondió a todas mis preguntas en español para ayudarme. Ahmed,
              que no habla español, tradujo toda la correspondencia de y para
              mí, lo que fue útil. También pudieron obtener mi solicitud
              aprobada por FEMA en menos de 2 semanas. Fue agradable trabajar
              con ellos. En realidad estoy muy agradecida y satisfecha con el
              trabajo y responsabilidad con el que resolvieron y ayudaron con mi
              caso... muchas gracias , pueden estar seguros que todo al que
              pueda referirles lo haré."
            </P>
          </TestimonialBubble>
        </Col>
        <Col>
          <Title level={5}>D Valdes</Title>
          <Text>Brandon, FL</Text>
        </Col>
      </Row>
    </Carousel>
  )
}

const TestimonialBubble = props => {
  return (
    <div className="talk-bubble tri-right round btm-left">
      <div className="talktext">{props.children}</div>
    </div>
  )
}

const P = styled.p`
  font-size: 18px;
  line-height: 1.4;
`

export default TestimonialsCarousel
