import React, { useState } from 'react'
// import { Line } from '@ant-design/charts'
import './styles.css'
// import '../index.css'
import { Typography } from 'antd'

const { Title, Paragraph, Text } = Typography

const BarGraphHydrologic = props => {
  // HydA={HYGrpA_pct}
  // HydA_D={HYGrpA_D_pct}
  // HydB={HYGrpB_pct}
  // HydB_D={HYGrpB_D_pct}
  // HydC={HYGrpC_pct}
  // HydC_D={HYGrpC_D_pct}
  // HydD={HYGrpD_pct}
  // HydWTR={HYGrpWTR_pct}

  // use this section to make if/then/else statements related to PRP policies
  let chartSource = []

  chartSource = [
    {
      val1: 'A',
      cost1: props.HydA,
      costNum1: props.HydA,
      color1: '#B3DACB',

      val2: 'A/D',
      cost2: props.HydA_D,
      costNum2: props.HydA_D,
      color2: '#66B596',

      val3: 'B',
      cost3: props.HydB,
      costNum3: props.HydB,
      color3: '#F9E299',

      val4: 'B/D',
      cost4: props.HydB_D,
      costNum4: props.HydB_D,
      color4: '#F5D466',

      val5: 'C',
      cost5: props.HydC,
      costNum5: props.HydC,
      color5: '#F5D466',

      val6: 'C/D',
      cost6: props.HydC_D,
      costNum6: props.HydC_D,
      color6: '#E3A5A1',

      val7: 'D',
      cost7: props.HydD,
      costNum7: props.HydD,
      color7: '#CD615A',

      val8: 'Water',
      cost8: props.HydWTR,
      costNum8: props.HydWTR,
      color8: '#005A8C'
    }
  ]

  // console.log(chartSource)

  return (
    <main>
      <strong>Hydrologic Soil Group</strong>

      <div>
        <br />
        <table
          id="HydroChart"
          class="charts-css column show-primary-axis show-5-secondary-axes data-spacing-5 labels-align-center"
        >
          <thead>
            <tr>
              <th scope="col"> Year </th> <th scope="col"> Progress </th>
            </tr>
          </thead>{' '}
          <tbody>
            <tr>
              <th scope="row"> {chartSource[0]?.val1} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum1} / (100) )`,
                  '--color': `${chartSource[0]?.color1}`
                }}
              >
                <Choose>
                  <When condition={chartSource[0]?.cost1 != 0}>
                    <span class="data"> {chartSource[0]?.cost1} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource[0]?.cost1} % Group A.
                    </span>
                  </When>
                  {/* <When condition={chartSource[0]?.cost1 == 0}>
                    <span class="data"> {chartSource[0]?.cost1} </span>

                    <span class="tooltip">
                      {' '}
                      {chartSource[0]?.cost1} % Group A.
                    </span>
                  </When> */}
                </Choose>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource[0]?.val2} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum2} / (100) )`,
                  '--color': `${chartSource[0]?.color2}`
                }}
              >
                <Choose>
                  <When condition={chartSource[0]?.cost2 != 0}>
                    <span class="data"> {chartSource[0]?.cost2} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource[0]?.cost2} % Group A/D.
                    </span>
                  </When>
                </Choose>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource[0]?.val3} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum3} / (100) )`,
                  '--color': `${chartSource[0]?.color3}`
                }}
              >
                <Choose>
                  <When condition={chartSource[0]?.cost3 != 0}>
                    <span class="data"> {chartSource[0]?.cost3} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource[0]?.cost3} % Group B.
                    </span>
                  </When>
                </Choose>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource[0]?.val4} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum4} / (100) )`,
                  '--color': `${chartSource[0]?.color4}`
                }}
              >
                <Choose>
                  <When condition={chartSource[0]?.cost4 != 0}>
                    <span class="data"> {chartSource[0]?.cost4} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource[0]?.cost4} % Group B/D.
                    </span>
                  </When>
                </Choose>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource[0]?.val5} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum5} / (100) )`,
                  '--color': `${chartSource[0]?.color5}`
                }}
              >
                <Choose>
                  <When condition={chartSource[0]?.cost5 != 0}>
                    <span class="data"> {chartSource[0]?.cost5} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource[0]?.cost5} % Group C.
                    </span>
                  </When>
                </Choose>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource[0]?.val6} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum6} / (100 ) )`,
                  '--color': `${chartSource[0]?.color6}`
                }}
              >
                <Choose>
                  <When condition={chartSource[0]?.cost6 != 0}>
                    <span class="data"> {chartSource[0]?.cost6} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource[0]?.cost6} % Group C/D.
                    </span>
                  </When>
                </Choose>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource[0]?.val7} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum7} / (100) )`,
                  '--color': `${chartSource[0]?.color7}`
                }}
              >
                <Choose>
                  <When condition={chartSource[0]?.cost7 != 0}>
                    <span class="data"> {chartSource[0]?.cost7} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource[0]?.cost7} % Group D.
                    </span>
                  </When>
                </Choose>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource[0]?.val8} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum8} / (100) )`,
                  '--color': `${chartSource[0]?.color8}`
                }}
              >
                <Choose>
                  <When condition={chartSource[0]?.cost8 != 0}>
                    <span class="data"> {chartSource[0]?.cost8} </span>
                    <span class="tooltip">
                      {' '}
                      {chartSource[0]?.cost8} % Group Water.
                    </span>
                  </When>
                </Choose>
              </td>
            </tr>{' '}
          </tbody>
        </table>
        {/* <br /> */}
        <div
          class="primary-axis"
          style={{
            textAlign: 'center',
            padding: '0px 0px 20px 0px'
          }}
        >
          {' '}
          <i>Increasingly Restrictive Soils (A to Water)</i>{' '}
        </div>
      </div>
    </main>
  )
}

export default BarGraphHydrologic
