import React, { useContext } from 'react'
import { navigate } from 'gatsby'
// import { isLoggedIn } from '../services/auth'
import { AuthContext } from '../context/auth'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { user } = useContext(AuthContext)
  // console.log('Private Route user email', user)

  if (!user && location.pathname !== `/app/login`) {
    navigate('/app/profile')
    return null
  }
  return <Component {...rest} />
}

// PrivateRoute.propTypes = {
//   component: PropTypes.any.isRequired
// }

export default PrivateRoute
