import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  Wrapper,
  Container
} from '../../styled-components/styled-components'
import { Row, Col, Typography } from 'antd'

const { Title } = Typography

const PageHeader = ({ title }) => {
  PageHeader.propTypes = {
    title: PropTypes.string
  }
  return (
    <Wrapper className="nff-indigo">
      <Container>
        <SectionRow justify="center" span={24}>
          <Col>
            <Title level={1} style={{ color: '#fff' }}>
              <div style={{ textAlign: 'center' }}>{title}</div>
            </Title>
          </Col>
        </SectionRow>
      </Container>
    </Wrapper>
  )
}

const SectionRow = styled(Row)`
  min-height: 100px;
  padding-top: 20px;
`

export default PageHeader
