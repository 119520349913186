import React, { useState, useEffect, useContext } from 'react'
import { Collapse, Typography, Row, Col, Grid, Button } from 'antd'
import { LandingLayout } from './LandingLayout/LandingLayout'
import { Wrapper, Container } from '../styled-components/styled-components'
import { Footer } from './Footer/Footer'
import PageHeader from './PageHeader'
import { AuthContext } from '../context/auth'
import firebase from 'gatsby-plugin-firebase'
import '../components/collapseInfoProfile.css'
import { getCurrentDate } from '../utils/common'
import { useGetContacts } from '../components/UserInfo/UserInfo-hooks'
import { normalizePhoneNumberInput } from '../utils/form/normalize'
import { Link } from 'gatsby'
import { RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons'
// import FloodMapModal from './FloodMapModal'
import InsuranceUploadForm from './Eligibilty/Insurance/uploadQuoteForm'
import '../components/contactInsurance.css'

//insert components
import PropertySearchInternal from './Eligibilty/PropertySearchInternal'
import CreateNewAPIKey from './UserInfo/CreateNewAPIKey'
import FloodReport from './Eligibilty/FloodReport'
import InsuranceEstimator from './Eligibilty/InsuranceEstimator'

const { Title, Paragraph, Text } = Typography
const { useBreakpoint } = Grid
const { Panel } = Collapse

const Contacts = props => {
  useEffect(() => {
    if (typeof window === 'undefined' || !window.document) {
      // console.log(`bailing out of the useeffect. Going to continue to render??`)
      return
    }
  }, [])

  // general handling for modal
  const handleOk = () => setIsModalVisible(false)
  const handleCancel = () => setIsModalVisible(false)

  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '34px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '34px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  const [showContactModal, setShowContactModal] = useState(false)

  const emailList = [
    'taylordlankford@gmail.com',
    // 'sophia@nofloodflorida.com',
    'ahmed@nofloodflorida.com',
    // 'taylor@nofloodflorida.com',
    'dmlankford@gmail.com',
    'sophia.hower@gmail.com'
  ]

  const insuranceList = [
    'ruth.barrios@veritas-financial.com',
    'sheena@veritas-financial.com',
    // 'taylordlankford@gmail.com'
    'taylor@nofloodflorida.com',
    'sophia@nofloodflorida.com'
  ]

  function handleCloseContactForm() {
    setShowContactModal(false)
  }
  function handleShowContactForm() {
    setShowContactModal(true)
  }

  const { user } = useContext(AuthContext)
  const [userData, setUserData] = useState(null)
  const [stillLoading, setLoading] = useState(true)
  // const [isModalVisible, setIsModalVisible] = useState(false)

  // get all types of contact forms over the past 365 days
  const { contactForm, loading } = useGetContacts(365)

  // weekly information
  const weeklyDate = new Date()
  weeklyDate.setDate(weeklyDate.getDate() - 30)

  var contactFormWeekly = contactForm?.filter(d => {
    return new Date(d.template.data.timestamp).getTime() >= weeklyDate.getTime()
  })

  var contactFormWeekly = contactFormWeekly?.sort(function (a, b) {
    return (
      new Date(b.template.data.timestamp) - new Date(a.template.data.timestamp)
    )
  })

  // for flood insurance only
  var insuranceFormWeekly = contactForm?.filter(d => {
    return d.template.name == 'insuranceQuoteRequestTemplate'
  })

  var insuranceFormWeekly = insuranceFormWeekly?.sort(function (a, b) {
    return (
      new Date(b.template.data.timestamp) - new Date(a.template.data.timestamp)
    )
  })

  // monthly information
  const monthlyDate = new Date()
  monthlyDate.setDate(monthlyDate.getDate() - 30)

  var contactFormMonthly = contactForm?.filter(d => {
    return (
      new Date(d.template.data.timestamp).getTime() >= monthlyDate.getTime()
    )
  })

  var contactFormMonthly = contactFormMonthly?.sort(function (a, b) {
    return (
      new Date(b.template.data.timestamp) - new Date(a.template.data.timestamp)
    )
  })

  // for flood insurance only
  var insuranceFormMonthly = contactForm?.filter(d => {
    return d.template.name == 'insuranceQuoteRequestTemplate'
  })

  var insuranceFormMonthly = insuranceFormMonthly?.sort(function (a, b) {
    return (
      new Date(b.template.data.timestamp) - new Date(a.template.data.timestamp)
    )
  })

  // yearly information
  const yearlyDate = new Date()
  yearlyDate.setDate(yearlyDate.getDate() - 365)

  var contactFormYearly = contactForm?.filter(d => {
    return new Date(d.template.data.timestamp).getTime() >= yearlyDate.getTime()
  })

  var contactFormYearly = contactFormYearly?.sort(function (a, b) {
    return (
      new Date(b.template.data.timestamp) - new Date(a.template.data.timestamp)
    )
  })

  // for flood insurance only
  var insuranceFormYearly = contactForm?.filter(d => {
    return d.template.name == 'insuranceQuoteRequestTemplate'
  })

  var insuranceFormYearly = insuranceFormYearly?.sort(function (a, b) {
    return (
      new Date(b.template.data.timestamp) - new Date(a.template.data.timestamp)
    )
  })

  function sortObject(o) {
    var sorted = {},
      key,
      a = []

    for (key in o) {
      if (o.hasOwnProperty(key)) {
        a.push(key)
      }
    }

    a.sort()

    for (key = 0; key < a.length; key++) {
      sorted[a[key]] = o[a[key]]
    }
    return sorted
  }

  /**
   * Fetch the full firebase user data when the component mounts
   */
  useEffect(() => {
    if (user.uid) {
      firebase
        .firestore()
        .collection('users')
        .where('uid', '==', user.uid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            if (doc) {
              const data = doc.data()
              setUserData(data)
            }
            setLoading(false)
            // loading(false)
          })
        })
    }
  }, [])

  return (
    <LandingLayout>
      {typeof window !== 'undefined' ? (
        <>
          <PageHeader title="Welcome to No Flood Florida" />
          <Wrapper>
            <Container style={{ padding: '20px 0px 20px 0px' }}>
              <Row span={24}>
                <Paragraph>
                  <strong>Contact Management System</strong>
                </Paragraph>
              </Row>
              <Row span={24}>
                <Col span={12}>
                  <Paragraph>
                    Welcome, {userData?.firstName} {userData?.lastName}
                  </Paragraph>
                </Col>
                <Col span={12}>
                  <Paragraph>Today's date is {getCurrentDate()}</Paragraph>
                </Col>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={16}
                  style={{ padding: '0px 10px 0px 10px' }}
                >
                  <Link to="/app/profile">
                    <Button
                      block
                      size="medium"
                      type="primary"
                      shape="round"
                      icon={<LeftCircleOutlined />}
                    >
                      Back to Profile
                      {/* <RightCircleOutlined /> */}
                    </Button>
                  </Link>
                </Col>
              </Row>

              {emailList.includes(user?.email) ? (
                <>
                  <Collapse
                    defaultActiveKey={[]}
                    ghost
                    style={{ padding: '0px 0px 0px 0px' }}
                  >
                    <Panel header="7 Day Summary" key="1">
                      <Row gutter={[0, 10]}>
                        <p>
                          <strong>{contactFormWeekly?.length}</strong> total
                          contacts over the past 7 days
                        </p>
                        <div className="contactTable">
                          {/* <h1>Contact Forms</h1> */}
                          <table
                            style={{
                              height: '300px',
                              overflow: 'scroll',
                              display: 'block',
                              fontSize: '75%'
                            }}
                          >
                            <thead
                              style={{
                                background: '#ffefd5',
                                borderBottom: '1px solid rgb(190, 190, 190)'
                              }}
                            >
                              <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Form Type</th>
                              </tr>
                            </thead>

                            <tbody>
                              {contactFormWeekly?.map(item => (
                                <tr key={item.id}>
                                  <td style={{ padding: '0px 10px' }}>
                                    {new Intl.DateTimeFormat('en-US', {
                                      month: 'long',
                                      day: 'numeric',
                                      year: 'numeric'
                                    }).format(
                                      new Date(item.template.data.timestamp)
                                    )}
                                  </td>

                                  <td
                                    style={{
                                      textTransform: 'capitalize',
                                      padding: '0 px 10px'
                                    }}
                                  >
                                    {' '}
                                    {item.template.data.freshID ? (
                                      <div className="fresh-ID-exists">
                                        <a
                                          target="_blank"
                                          href={`https://nofloodflorida-org.myfreshworks.com/crm/sales/contacts/${item.template.data.freshID}`}
                                          rel="noopener noreferrer"
                                          style={{ color: '#55b96a' }}
                                          title="Freshworks Client"
                                        >
                                          {item.reportName}
                                        </a>
                                      </div>
                                    ) : (
                                      <div className="no-fresh-ID">
                                        <a
                                          target="_blank"
                                          href={`https://nofloodflorida-org.myfreshworks.com/crm/sales/contacts`}
                                          rel="noopener noreferrer"
                                          style={{ color: '#425466' }}
                                          title="Freshworks"
                                        >
                                          {item.reportName}
                                        </a>
                                      </div>
                                    )}
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    {item.fullAddress}
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    <a
                                      target="_blank"
                                      href={`tel:${normalizePhoneNumberInput(
                                        item.template.data.phone,
                                        'old'
                                      )}`}
                                      rel="noopener noreferrer"
                                      title="Call Client"
                                      style={{ color: '#425466' }}
                                    >
                                      {normalizePhoneNumberInput(
                                        item.template.data.phone,
                                        'old'
                                      )}
                                    </a>
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    <a
                                      target="_blank"
                                      href={`mailto:${item.template.data.email}`}
                                      rel="noopener noreferrer"
                                      title="Right Click to Send Email in New Tab"
                                      style={{ color: '#425466' }}
                                    >
                                      {item.template.data.email}
                                    </a>
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    {' '}
                                    {item.template.data.message ? (
                                      <div
                                        className="user-message"
                                        title={`${item.template.data.message}`}
                                      >
                                        {item.formType}
                                      </div>
                                    ) : (
                                      <div
                                        className="no-user-message"
                                        title="No Message"
                                      >
                                        {item.formType}
                                      </div>
                                    )}
                                  </td>

                                  <td />
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Row>
                    </Panel>
                    <Panel header="30 Day Summary" key="2">
                      <Row gutter={[0, 10]}>
                        <p>
                          <strong>{contactFormMonthly?.length}</strong> total
                          contacts over the past 30 days
                        </p>
                        <div className="contactTable">
                          {/* <h1>Contact Forms</h1> */}
                          <table
                            style={{
                              height: '300px',
                              overflow: 'scroll',
                              display: 'block',
                              fontSize: '75%'
                            }}
                          >
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Form Type</th>
                              </tr>
                            </thead>

                            <tbody>
                              {contactFormMonthly?.map(item => (
                                <tr key={item.id}>
                                  <td style={{ padding: '0px 10px' }}>
                                    {new Intl.DateTimeFormat('en-US', {
                                      month: 'long',
                                      day: 'numeric',
                                      year: 'numeric'
                                    }).format(
                                      new Date(item.template.data.timestamp)
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      textTransform: 'capitalize',
                                      padding: '0 px 10px'
                                    }}
                                  >
                                    {item.reportName}
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    <a
                                      target="_blank"
                                      href={`https://www.google.com/maps/place/${item.fullAddress}`}
                                      rel="noopener noreferrer"
                                      style={{ color: '#425466' }}
                                      title="Go Go Google Maps!"
                                    >
                                      {item.fullAddress}
                                    </a>
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    <a
                                      target="_blank"
                                      href={`tel:${normalizePhoneNumberInput(
                                        item.template.data.phone,
                                        'old'
                                      )}`}
                                      rel="noopener noreferrer"
                                      title="Call Client"
                                      style={{ color: '#425466' }}
                                    >
                                      {normalizePhoneNumberInput(
                                        item.template.data.phone,
                                        'old'
                                      )}
                                    </a>
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    <a
                                      target="_blank"
                                      href={`mailto:${item.template.data.email}`}
                                      rel="noopener noreferrer"
                                      title="Right Click to Send Email in New Tab"
                                      style={{ color: '#425466' }}
                                    >
                                      {item.template.data.email}
                                    </a>
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    {item.formType}
                                  </td>

                                  <td />
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Row>
                    </Panel>
                    <Panel header="365 Day Summary" key="3">
                      <Row gutter={[0, 10]}>
                        <p>
                          <strong>{contactFormYearly?.length}</strong> total
                          contacts over the past 365 days
                        </p>
                        <div className="contactTable">
                          {/* <h1>Contact Forms</h1> */}
                          <table
                            style={{
                              height: '300px',
                              overflow: 'scroll',
                              display: 'block',
                              fontSize: '75%'
                            }}
                          >
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Form Type</th>
                              </tr>
                            </thead>

                            <tbody>
                              {contactFormYearly?.map(item => (
                                <tr key={item.id}>
                                  <td style={{ padding: '0px 10px' }}>
                                    {new Intl.DateTimeFormat('en-US', {
                                      month: 'long',
                                      day: 'numeric',
                                      year: 'numeric'
                                    }).format(
                                      new Date(item.template.data.timestamp)
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      textTransform: 'capitalize',
                                      padding: '0 px 10px'
                                    }}
                                  >
                                    {item.reportName}
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    <a
                                      target="_blank"
                                      href={`https://www.google.com/maps/place/${item.fullAddress}`}
                                      rel="noopener noreferrer"
                                      style={{ color: '#425466' }}
                                      title="Go Go Google Maps!"
                                    >
                                      {item.fullAddress}
                                    </a>
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    <a
                                      target="_blank"
                                      href={`tel:${normalizePhoneNumberInput(
                                        item.template.data.phone,
                                        'old'
                                      )}`}
                                      rel="noopener noreferrer"
                                      title="Call Client"
                                      style={{ color: '#425466' }}
                                    >
                                      {normalizePhoneNumberInput(
                                        item.template.data.phone,
                                        'old'
                                      )}
                                    </a>
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    <a
                                      target="_blank"
                                      href={`mailto:${item.template.data.email}`}
                                      rel="noopener noreferrer"
                                      title="Right Click to Send Email in New Tab"
                                      style={{ color: '#425466' }}
                                    >
                                      {item.template.data.email}
                                    </a>
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    {item.formType}
                                  </td>

                                  <td />
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Row>
                    </Panel>
                  </Collapse>
                </>
              ) : insuranceList.includes(user?.email) ? (
                <>
                  {' '}
                  <Collapse
                    defaultActiveKey={[1]}
                    ghost
                    style={{ padding: '0px 0px 0px 0px' }}
                  >
                    <Panel header="Insurance Quotes" key="1">
                      <p>
                        <strong>{insuranceFormWeekly?.length}</strong> total
                        contacts over the past 30 days. Hover over the{' '}
                        <i>Form Type</i> column to see additional messages from
                        the client. Hover over the <i>Status</i> column to see
                        previously submitted quote information.
                      </p>
                      <Row gutter={[0, 10]}>
                        <div className="contactTable">
                          {/* <h1>Contact Forms</h1> */}
                          <table
                            style={{
                              height: '400px',
                              overflow: 'scroll',
                              display: 'block',
                              fontSize: '75%'
                            }}
                            id="insuranceTable"
                          >
                            <thead
                              style={{
                                background: '#E5E4E2',
                                borderBottom: '1px solid rgb(190, 190, 190)'
                              }}
                            >
                              <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Form Type</th>
                                <th>Status</th>
                                <th>Quote</th>
                              </tr>
                            </thead>

                            <tbody>
                              {insuranceFormWeekly?.map(item => (
                                <tr key={item.id} id="insuranceTable">
                                  <td style={{ padding: '0px 10px' }}>
                                    {new Intl.DateTimeFormat('en-US', {
                                      month: 'long',
                                      day: 'numeric',
                                      year: 'numeric',
                                      hour: 'numeric',
                                      minute: 'numeric'
                                    }).format(
                                      new Date(item.template.data.timestamp)
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      textTransform: 'capitalize',
                                      padding: '0 px 10px'
                                    }}
                                  >
                                    {item.reportName}
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    {/* <Link
                                      to={
                                        'https://www.google.com/maps/place/' +
                                        item.fullAddress
                                      }
                                      target="_blank"
                                      style={{ color: '#425466' }}
                                      title="Go, Go, Google Maps!"
                                    >
                                      {item.fullAddress}
                                    </Link> */}
                                    <a
                                      href={
                                        `https://www.google.com/maps/place/` +
                                        item.fullAddress
                                      }
                                      target="_blank"
                                      style={{ color: '#425466' }}
                                      title="Go, Go, Google Maps!"
                                    >
                                      {item.fullAddress}
                                    </a>
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    <a
                                      target="_blank"
                                      href={`tel:${normalizePhoneNumberInput(
                                        item.template.data.phone,
                                        'old'
                                      )}`}
                                      rel="noopener noreferrer"
                                      title="Call Client"
                                      style={{ color: '#425466' }}
                                    >
                                      {normalizePhoneNumberInput(
                                        item.template.data.phone,
                                        'old'
                                      )}
                                    </a>
                                  </td>
                                  <td style={{ padding: '0px 10px' }}>
                                    <a
                                      target="_blank"
                                      href={`mailto:${item.template.data.email}`}
                                      rel="noopener noreferrer"
                                      title="Right Click to Send Email in New Tab"
                                      style={{ color: '#425466' }}
                                    >
                                      {item.template.data.email}
                                    </a>
                                  </td>

                                  <td style={{ padding: '0px 10px' }}>
                                    {' '}
                                    {item.template.data.message ? (
                                      <div
                                        className="user-message"
                                        title={`${item.template.data.message}`}
                                      >
                                        {item.formType}
                                      </div>
                                    ) : (
                                      <div
                                        className="no-user-message"
                                        title="No Message"
                                      >
                                        {item.formType}
                                      </div>
                                    )}
                                  </td>

                                  <td style={{ padding: '4px 20px' }}>
                                    {' '}
                                    {item.quoteStatus.status ==
                                    'Quote Submitted' ? (
                                      <div
                                        className="client-status-submitted"
                                        title={JSON.stringify(
                                          sortObject(item.quoteStatus),
                                          null,
                                          4
                                        )}
                                      >
                                        Quote Submitted
                                      </div>
                                    ) : (
                                      <div
                                        className="new-client-status"
                                        title={`Status ${item.template.data.status}, ${item.fullAddress}`}
                                      >
                                        New
                                      </div>
                                    )}
                                  </td>
                                  <td style={{ padding: '4px 20px' }}>
                                    {' '}
                                    {item.quoteStatus.status ==
                                    'Quote Submitted' ? (
                                      <div
                                        className="client-status-submitted"
                                        title={`Quote Uploaded on ${item.quoteStatus.timestamp}`}
                                      >
                                        <Button
                                          block
                                          size="small"
                                          type="secondary"
                                          shape="round"
                                          style={{
                                            background: '#D3D3D3',
                                            borderColor: '#f9f9f9'
                                          }}
                                          onClick={handleShowContactForm}
                                        >
                                          Update Quote
                                        </Button>

                                        <InsuranceUploadForm
                                          show={showContactModal}
                                          handleClose={handleCloseContactForm}
                                          userEmail={user?.email}
                                          propertyAddress={item.fullAddress}
                                          clientEmail={item.template.data.email}
                                          allData={insuranceFormWeekly}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="client-status"
                                        title={`Status ${item.template.data.status} ${item.fullAddress}`}
                                      >
                                        <Button
                                          block
                                          size="small"
                                          type="primary"
                                          shape="round"
                                          style={{
                                            background: '#1c79c7',
                                            borderColor: '#f9f9f9'
                                          }}
                                          onClick={handleShowContactForm}
                                        >
                                          Upload Quote
                                        </Button>
                                        <InsuranceUploadForm
                                          show={showContactModal}
                                          handleClose={handleCloseContactForm}
                                          userEmail={user?.email}
                                          propertyAddress={item.fullAddress}
                                          clientEmail={item.template.data.email}
                                          allData={insuranceFormWeekly}
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Row>
                    </Panel>
                  </Collapse>
                </>
              ) : (
                <Row justify="center" gutter={[0, 10]}>
                  <Paragraph>Welcome to NFF Dashboard</Paragraph>
                </Row>
              )}
            </Container>
          </Wrapper>
          <Footer />
        </>
      ) : (
        <Row justify="center" gutter={[0, 10]}>
          <Loader />
        </Row>
      )}
    </LandingLayout>
  )
}

export default Contacts
