import React from 'react'
import {
  Wrapper,
  Container
} from '../../../styled-components/styled-components'
import { Row, Col, Typography, Button } from 'antd'
import TestimonialsCarousel from './TestimonialsCarousel'
import { Link } from 'gatsby'
import { RightCircleOutlined } from '@ant-design/icons'

const { Title } = Typography

const Testimonials = () => {
  return (
    <Wrapper>
      <Container>
        <Row span={24} gutter={[10, 0]}>
          <Col xl={8} lg={24} md={24} sm={24} xs={24}>
            <div>
              <Title level={4} style={{ marginLeft: '10px' }}>
                What People Say About Us
              </Title>
            </div>
            <div style={{ borderLeft: '4px solid #d3b574' }}>
              <Title style={{ fontSize: '36px', marginLeft: '20px' }}>
                Client Testimonials
              </Title>
            </div>
            <div style={{ padding: '10px 40px 40px 10px' }}>
              <Link to="/client-reviews">
                <Button
                  block
                  size="medium"
                  type="primary"
                  shape="round"
                  // icon={<RightCircleOutlined />}
                >
                  Leave Us a Review
                  <RightCircleOutlined />
                </Button>
              </Link>
            </div>
          </Col>
          <Col xl={16} lg={24} md={24} sm={24} xs={24}>
            <TestimonialsCarousel />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default Testimonials
