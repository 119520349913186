import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Link as ScrollLink } from 'react-scroll'
import { Button, Typography, Row, Col, Grid } from 'antd'
import { FormOutlined, FileOutlined } from '@ant-design/icons'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import { Wrapper, Container } from '../../styled-components/styled-components'
import OrderSection from './components/OrderSectionClientReview'
import { Footer } from '../../components/Footer/Footer'
import PageHeader from '../../components/PageHeader'

const { Title, Paragraph } = Typography
const { useBreakpoint } = Grid

const ClientReview = () => {
  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '30px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '50px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  return (
    <LandingLayout>
      <PageHeader title="Leave Us a Review" />

      <Wrapper>
        <Container style={{ padding: '20px 10px 10px 10px' }}>
          <Row gutter={[25, 10]}>
            <Paragraph>
              No Flood Florida aims to provide excellent customer service to our
              clients. Please use the following form to submit a review of your
              experience. Thanks!
            </Paragraph>
          </Row>
        </Container>
      </Wrapper>

      <OrderSection />
      <Footer />
    </LandingLayout>
  )
}

export default ClientReview
