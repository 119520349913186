import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import {
  message,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Card
} from 'antd'
import firebase from 'gatsby-plugin-firebase'
import { normalizePhoneNumberInput } from '../../utils/form/normalize'
import SuccessModal from '../../components/SuccessModal'

const { Title } = Typography

const PartnerShipContactForm = () => {
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const colSizes = {
    xxl: 12,
    xl: 12,
    lg: 12,
    md: 12,
    sm: 24,
    xs: 24
  }

  const onFinish = values => {
    const { callDetails, emailDetails } = values

    // values.timestamp = new Date().toLocaleString('en-US', {
    //   timeZone: 'America/New_York'
    // })
    // const contactFormMessageObj = {
    //   to: 'info@nofloodflorida.com',
    //   // to: 'Ahmed89hamed@gmail.com',
    //   template: {
    //     name: 'contactFormTemplate',
    //     data: {
    //       ...values
    //     }
    //   }
    // }
    // const timestamp = firebase.firestore.Timestamp.now()

    const timestamp = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York'
    })

    const partnershipContactObj = {
      to: values.email,
      bcc: ['info@nofloodflorida.com', 'sophia@nofloodflorida.com'],
      template: {
        name: 'partnershipFormTemplate',
        data: {
          ...values,
          timestamp,
          callDetails: callDetails === undefined ? false : true,
          emailDetails: emailDetails === undefined ? false : true
        }
      }
    }

    firebase
      .firestore()
      .collection('partnershipForm')
      .add(partnershipContactObj)
      .then(() => {
        formRef.current.resetFields()
        setIsModalVisible(true)
      })
      .catch(error => console.log('Error adding document:', error))
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed: ', errorInfo)
  }

  const handleCancel = () => setIsModalVisible(false)
  const handleOk = () => setIsModalVisible(false)

  return (
    <>
      <SuccessModal
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        content="Form Submitted Successfully! Please check your email and/or spam folder for a confirmation."
      />
      <TitleContainer>
        <Title level={3}>Partnership Contact Form</Title>
      </TitleContainer>
      <FormContainer>
        <Form
          ref={formRef}
          id="partnershipContactForm"
          name="partnershipContactForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={[20, 0]}>
            <Col {...colSizes}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Please input your name' }]}
              >
                <Input placeholder="Name" size="large" />
              </Form.Item>
            </Col>
            <Col {...colSizes}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please input your email.'
                  }
                ]}
              >
                <Input placeholder="Email" size="large" />
              </Form.Item>
            </Col>
            <Col {...colSizes}>
              <Form.Item
                normalize={(value, prevValue, prevValues) =>
                  normalizePhoneNumberInput(value, prevValue)
                }
                name="phone"
                rules={[
                  { required: true, message: 'Please input your phone number.' }
                ]}
              >
                <Input placeholder="(xxx) xxx-xxxx" size="large" />
              </Form.Item>
            </Col>
            <Col {...colSizes}>
              <Form.Item
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: 'Please input your company name'
                  }
                ]}
              >
                <Input placeholder="Company Name" size="large" />
              </Form.Item>
            </Col>
            <Col {...colSizes}>
              <Row gutter={[0, 0]}>
                <Col span={24}>
                  <Form.Item name="emailDetails" valuePropName="checked">
                    <Checkbox defaultChecked={false}>Email Me Details</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="callDetails" valuePropName="checked">
                    <Checkbox defaultChecked={false}>
                      Call Me With Details
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row align="end">
            <Form.Item>
              <Button
                htmlType="submit"
                size="large"
                shape="round"
                className="box-shadow"
                type="primary"
                loading={loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </FormContainer>
    </>
  )
}

export default PartnerShipContactForm

const TitleContainer = styled.div`
  margin-bottom: 40px;
`

const FormContainer = styled(Card)`
  margin: 0 auto;
  max-width: 980px;
`
