import React from 'react'
import { Link } from 'gatsby'
import { Typography, Row, Button, Grid } from 'antd'

const { Text } = Typography
const { useBreakpoint } = Grid

const FinalizeLomaBtn = () => {
  const screen = useBreakpoint()

  return (
    <Link to="/order-loma" style={{ width: '100%' }}>
      <Button
        // block={screen.lg || screen.xl || screen.xxl ? false : true}
        style={{
          fontWeight: '600',
          textTransform: 'uppercase',
          width: '260px'
        }}
        type="primary"
        className="round box-shadow-3"
        size="large"
      >
        Finalize LOMA Request
      </Button>
    </Link>
  )
}

export default FinalizeLomaBtn
