import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Row, Col, Button, Typography } from 'antd'

const { Title, Paragraph } = Typography

const LowRiskLearnMore = ({
  title,
  disclaimer,
  img,
  setShowRecommendation,
  setShowScreening
}) => {
  return (
    <>
      <Row gutter={[20, 0]}>
        <Col md={14} sm={24} xs={24} style={{ paddingTop: '20px' }}>
          <Title level={3} style={{ color: '#fff' }}>
            {title}
          </Title>
          <Paragraph style={{ color: 'white', fontWeight: '400' }}>
            Good News! This property is identified as being in the low-risk
            flood zone according to FEMA data. Therefore, there is no need to
            pursue a Letter of Map Amendment. However, FEMA data does not always
            show the full picture and properties in this low-risk flood zone
            have flooded in the past. In fact, more than 20 percent of flood
            claims come from properties <B>outside the high-risk flood zone</B>.
            Get a <B>Flood Analysis Memo</B> to learn the full flood risk of
            this property.
          </Paragraph>
          <p>
            <em style={{ fontWeight: 500 }}>{disclaimer}</em>
          </p>
        </Col>
        <Col lg={8} md={24} sm={24} xs={24} style={{ textAlign: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <img src={img} style={{ height: '120px', width: '100px' }} />
          </div>
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <Link to="/fam">
                <Button
                  type="primary"
                  className="round secondary-btn"
                  style={{ width: '120' }}
                >
                  Learn More
                </Button>
              </Link>
            </Col>
            <Col span={24}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/flood-score.appspot.com/o/sample%20reports%2F709_Whitehall_St.-1.pdf?alt=media&token=8248e807-ef39-4af7-af40-0792aa6d7d3e"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  type="primary"
                  className="round secondary-btn"
                  style={{ width: '120' }}
                >
                  Sample
                </Button>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default LowRiskLearnMore

const B = styled.b`
  font-weight: 600;
  color: #fff;
`
