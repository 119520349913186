import React from 'react'

import { Button, Typography, Row, Col, Grid } from 'antd'

import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import { Wrapper, Container } from '../../styled-components/styled-components'
import { Footer } from '../../components/Footer/Footer'
import PageHeader from '../../components/PageHeader'

import styled from 'styled-components'

import Coastal from '../../assets/images/Coastal.svg'
import Riverine from '../../assets/images/Riverine.svg'
import LowTerrain from '../../assets/images/LowTerrain.svg'

const { Title, Paragraph } = Typography
const { useBreakpoint } = Grid

const FloodingConcerns = () => {
  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '30px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '50px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  return (
    <LandingLayout>
      <PageHeader title="Flooding Sources and Concerns" />

      <Wrapper>
        <Container style={{ padding: '20px 10px 10px 10px' }}>
          <Row textAlign="left" gutter={[25, 10]}>
            <div>
              Flood risk is influenced by a variety of environmental, local, and
              developmental pressures. Flooding can be caused by high volumes or
              intensities of rainfall during a short period of time or an
              accumulation of rainfall over a longer wet season period. In
              Florida, we typically focus of three main sources of flooding:{' '}
              <strong>Coastal, Riverine, and Low-Lying Areas.</strong>
            </div>
          </Row>
          <br />
          <Row type="flex" align="middle" gutter={[0, 50]}>
            <Col xl={12} lg={12} md={12} sm={12} xs={24} align="center">
              <StyledGraphic src={Coastal} draggable={false} />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <strong>Coastal</strong> flooding tends to occur, along the
              coast... of course. Various locations along the coast experience
              the impact of flooding in different ways, however. In Florida, we
              rely on sand dune systems and other enviornmental protections to
              arm us during coastal flooding events. Mangrove systems are also
              very beneficial in reducing wave action caused by larger storm
              events.
            </Col>
          </Row>
          <Row type="flex" align="middle" gutter={[0, 50]}>
            <Col xl={12} lg={12} md={12} sm={12} xs={24} align="center">
              <StyledGraphic src={Riverine} draggable={false} />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <strong>Riverine</strong> flooding has occured throughout history
              including on the Nile River and more local rivers to Florida like
              the Hillsborough, St. Lucie, or smaller creeks like Mill Creek in
              Manatee County, FL. Often during the summer months, rivers will be
              quite full. Summer rains and increasing baseflow from the aquifer
              feed rivers during these months. An extreme event like a tropical
              storm, hurricane, or significant rainfall pattern (intensity or
              duration) can overwhelm river and creek systems in Florida causing
              significant flooding.
            </Col>
          </Row>
          <Row type="flex" align="middle" gutter={[0, 50]}>
            <Col xl={12} lg={12} md={12} sm={12} xs={24} align="center">
              <StyledGraphic src={LowTerrain} draggable={false} />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <strong> Low Terrain</strong> can be related to areas like
              wetlands, swamps, and marshes. In general, this type of flooding
              is caused by changes in the landscape where a type of bowl is
              created. As rainfall occurs and runoff develops, the water will
              flow from high areas to low areas, naturally. Many areas
              throughout Florida are considered closed basins, where all the
              rainfall/runoff eventually gets to a low point (a pond or wetland
              typically) - flooding typically occurs here.
            </Col>
          </Row>
        </Container>
      </Wrapper>

      <Footer />
    </LandingLayout>
  )
}

export default FloodingConcerns

const StyledGraphic = styled.img`
  height: 50%;
  width: 50%;
  padding: 2px;
  text-align: center;
  // margin-bottom: 20px;
`
