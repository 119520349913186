import React from 'react'
import {
  Wrapper,
  Container
} from '../../../styled-components/styled-components'
import { Typography, Card, Col } from 'antd'
import OrderForm from './ClientReviewForm'

const OrderSectionEC = () => {
  return (
    <Wrapper className="nff-indigo" id="client-review-section">
      <Container style={{ padding: '0px' }}>
        <Col
          xl={14}
          lg={14}
          md={24}
          sm={24}
          xs={24}
          style={{ margin: '0 auto' }}
        >
          <Card className="box-shadow round">
            <OrderForm />
          </Card>
        </Col>
      </Container>
    </Wrapper>
  )
}

export default OrderSectionEC
