import React, { useState } from 'react'
// import { Line } from '@ant-design/charts'
import './styles.css'
// import '../index.css'
import { Typography } from 'antd'

const { Title, Paragraph, Text } = Typography

const LineGraphHighRisk = props => {
  // console.log(props.femaBFE)
  // console.log(props.replacementCost)
  // console.log(props.insuranceImpact)
  // console.log(props.fldZone)
  // console.log(props.prePost)
  // console.log(props.crsValue)

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
  })

  // need to figure out which table to step into at this point
  let tableName = ''
  if (props.prePost == 0) {
    tableName = 'Table 2A'
  } else if (props.prePost == 1 && props.fldZone == 'A') {
    tableName = 'Table 3C'
  } else if (
    (props.prePost == 1 && props.fldZone == 'AE, LEVEL POOL') ||
    props.fldZone == 'AE, RIVERINE'
  ) {
    tableName = 'Table 3B'
  } else if (props.prePost == 1 && props.fldZone == 'VE') {
    tableName = 'Table 3E'
  }

  // determine the discount due to the CRS program
  let discount = 0

  if (props.crsValue == 10) {
    discount = 0
  } else if (props.crsValue == 9) {
    discount = 0.05
  } else if (props.crsValue == 8) {
    discount = 0.1
  } else if (props.crsValue == 7) {
    discount = 0.15
  } else if (props.crsValue == 6) {
    discount = 0.2
  } else if (props.crsValue == 5) {
    discount = 0.25
  } else if (props.crsValue == 4) {
    discount = 0.3
  } else if (props.crsValue == 3) {
    discount = 0.35
  } else if (props.crsValue == 2) {
    discount = 0.4
  } else if (props.crsValue == 1) {
    discount = 0.45
  }

  // calculate the insurance value for 4 distinct deductibles
  const Deductible = [0.925, 0.85, 0.775, 0.75]

  let basicBldRate = 0
  let addBldRate = 0
  let basicContRate = 0
  let addContRate = 0
  // additional costs associated with the NFIP program, static values
  let HFIAA = 25
  let FPF = 50
  let ICC = 5

  // create the tables and rates assuming no basement/enclosure and typical content location
  // assume 1 Floor/No basement/Enclosure/Crawlspace
  if (tableName == 'Table 2A') {
    if (
      props.fldZone == 'A' ||
      props.fldZone == 'AE, LEVEL POOL' ||
      props.fldZone == 'AE, RIVERINE' ||
      props.fldZone == 'AH'
    ) {
      basicBldRate = 1.18
      addBldRate = 1.08
      basicContRate = 1.48
      addContRate = 1.93
    } else if (props.fldZone == 'VE') {
      basicBldRate = 1.53
      addBldRate = 2.7
      basicContRate = 1.9
      addContRate = 4.6
    }
  } else if (tableName == 'Table 3B') {
    if (props.insuranceImpact >= 4) {
      basicBldRate = 0.31
      addBldRate = 0.08
      basicContRate = 0.38
      addContRate = 0.12
    } else if (props.insuranceImpact == 3) {
      basicBldRate = 0.34
      addBldRate = 0.09
      basicContRate = 0.38
      addContRate = 0.12
    } else if (props.insuranceImpact == 2) {
      basicBldRate = 0.5
      addBldRate = 0.11
      basicContRate = 0.38
      addContRate = 0.12
    } else if (props.insuranceImpact == 1) {
      basicBldRate = 0.94
      addBldRate = 0.17
      basicContRate = 0.53
      addContRate = 0.12
    } else if (props.insuranceImpact == 0) {
      basicBldRate = 2.21
      addBldRate = 0.26
      basicContRate = 1.02
      addContRate = 0.12
    } else if (props.insuranceImpact == -1) {
      basicBldRate = 5.37
      addBldRate = 0.36
      basicContRate = 2.33
      addContRate = 0.12
    } else if (props.insuranceImpact == -2) {
      basicBldRate = 7.93
      addBldRate = 0.7
      basicContRate = 3.69
      addContRate = 0.14
    } else if (props.insuranceImpact == -3) {
      basicBldRate = 9.85
      addBldRate = 1.19
      basicContRate = 5.02
      addContRate = 0.24
    } else if (props.insuranceImpact == -4) {
      basicBldRate = 11.9
      addBldRate = 1.79
      basicContRate = 6.53
      addContRate = 0.41
    } else if (props.insuranceImpact == -5) {
      basicBldRate = 13.45
      addBldRate = 2.4
      basicContRate = 7.84
      addContRate = 0.62
    } else if (props.insuranceImpact == -6) {
      basicBldRate = 13.82
      addBldRate = 2.96
      basicContRate = 8.62
      addContRate = 0.89
    } else if (props.insuranceImpact == -7) {
      basicBldRate = 14.2
      addBldRate = 3.44
      basicContRate = 9.27
      addContRate = 1.14
    } else if (props.insuranceImpact == -8) {
      basicBldRate = 14.25
      addBldRate = 3.95
      basicContRate = 9.75
      addContRate = 1.38
    } else if (props.insuranceImpact == -9) {
      basicBldRate = 14.3
      addBldRate = 4.26
      basicContRate = 9.98
      addContRate = 1.6
    } else if (props.insuranceImpact == -10) {
      basicBldRate = 14.35
      addBldRate = 4.42
      basicContRate = 10.01
      addContRate = 1.79
    }
  } else if (tableName == 'Table 3C') {
    if (props.insuranceImpact >= 2) {
      basicBldRate = 0.57
      addBldRate = 0.1
      basicContRate = 0.32
      addContRate = 0.08
    } else if (props.insuranceImpact == 1) {
      basicBldRate = 2.67
      addBldRate = 0.2
      basicContRate = 1.2
      addContRate = 0.09
    } else if (props.insuranceImpact == 0) {
      basicBldRate = 2.67
      addBldRate = 0.2
      basicContRate = 1.2
      addContRate = 0.09
    } else if (props.insuranceImpact <= -1) {
      basicBldRate = 6.31
      addBldRate = 0.35
      basicContRate = 2.71
      addContRate = 0.16
    }
  }

  // segment the total replacement cost into correct buckets
  let basicBld = 0
  let addBld = 0
  let basicCont = 0
  let addCont = 0

  if (props.replacementCost > 250000 && props.replacementCost * 0.25 > 100000) {
    basicBld = 60000
    addBld = 190000
    basicCont = 25000
    addCont = 75000
  } else if (
    props.replacementCost > 250000 &&
    props.replacementCost * 0.25 < 100000
  ) {
    basicBld = 60000
    addBld = 190000
    basicCont = 25000
    addCont = props.replacementCost * 0.25 - 25000
  } else if (
    props.replacementCost <= 250000 &&
    props.replacementCost * 0.25 <= 100000
  ) {
    basicBld = 60000
    addBld = props.replacementCost - 60000
    basicCont = 25000
    addCont = props.replacementCost * 0.25 - 25000
  }

  // need to partition the total replacement cost into basic and additional values
  const FinalCost = [] // final cost array holds all deductible values
  let i,
    len,
    BldPremium,
    ContPremium,
    Subtotal1,
    Subtotal2,
    ReserveFund,
    FinalTotal
  for (let i = 0, len = Deductible.length; i < len; i++) {
    BldPremium =
      ((basicBld / 100) * basicBldRate + (addBld / 100) * addBldRate) *
      Deductible[i]
    ContPremium =
      ((basicCont / 100) * basicContRate + (addCont / 100) * addContRate) *
      Deductible[i]
    Subtotal1 = BldPremium + ContPremium + ICC
    Subtotal2 = Subtotal1 - Subtotal1 * discount
    ReserveFund = Subtotal2 * 0.15
    FinalTotal = Subtotal2 + ReserveFund + HFIAA + FPF
    FinalCost.push(FinalTotal)
  }

  // use this section to populate the calculated values from the above insurance calcs
  let chartSource = []

  chartSource = [
    {
      val1: '$ 2,000',
      cost1: formatter.format(FinalCost[0]),
      costNum1: FinalCost[0],
      color1: '#ade6d8',

      val2: '$ 3,000',
      cost2: formatter.format(FinalCost[1]),
      costNum2: FinalCost[1],
      color2: 'orange',

      val3: '$ 4,000',
      cost3: formatter.format(FinalCost[2]),
      costNum3: FinalCost[2],
      color3: '#ade6d8',

      val4: '$ 5,000',
      cost4: formatter.format(FinalCost[3]),
      costNum4: FinalCost[3],
      color4: '#ade6d8'
    }
  ]
  // console.log(tableName)

  // console.log(basicBldRate)
  // console.log(addBldRate)
  // console.log(basicContRate)
  // console.log(addContRate)

  // console.log(basicBld)
  // console.log(addBld)
  // console.log(basicCont)
  // console.log(addCont)

  // console.log(FinalCost)

  return (
    <main>
      <h3>Flood Insurance Premium ($/year)</h3>

      <div>
        <table
          id="highRiskChart"
          class="charts-css column show-labels show-primary-axis show-5-secondary-axes data-spacing-10 labels-align-center"
        >
          <thead>
            <tr>
              <th scope="col"> Year </th> <th scope="col"> Progress </th>
            </tr>
          </thead>{' '}
          <tbody>
            <tr>
              <th scope="row"> {chartSource[0]?.val1} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum1}  / (${chartSource[0]?.costNum1} + 100) )`,
                  '--color': `${chartSource[0]?.color1}`
                }}
              >
                <span class="data"> {chartSource[0]?.cost1} </span>
                <span class="tooltip">
                  {' '}
                  Cost for {chartSource[0]?.val1} deductible.
                </span>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource[0]?.val2} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum2} / (${chartSource[0]?.costNum1} + 100 ) )`,
                  '--color': `${chartSource[0]?.color2}`
                }}
              >
                <span class="data"> {chartSource[0]?.cost2} </span>
                <span class="tooltip">
                  {' '}
                  Cost for {chartSource[0]?.val2} deductible.
                </span>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource[0]?.val3} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum3} / (${chartSource[0]?.costNum1} + 100  ) )`,
                  '--color': `${chartSource[0]?.color3}`
                }}
              >
                <span class="data"> {chartSource[0]?.cost3} </span>
                <span class="tooltip">
                  {' '}
                  Cost for {chartSource[0]?.val3} deductible.
                </span>
              </td>
            </tr>{' '}
            <tr>
              <th scope="row"> {chartSource[0]?.val4} </th>{' '}
              <td
                style={{
                  '--size': `calc( ${chartSource[0]?.costNum4} / (${chartSource[0]?.costNum1} + 100 ) )`,
                  '--color': `${chartSource[0]?.color4}`
                }}
              >
                <span class="data"> {chartSource[0]?.cost4} </span>
                <span class="tooltip">
                  {' '}
                  Cost for {chartSource[0]?.val4} deductible.
                </span>
              </td>
            </tr>{' '}
          </tbody>
        </table>
        <div class="primary-axis">
          {' '}
          <i>Insurance Deductible ($)</i>{' '}
        </div>
      </div>
    </main>
  )
}

export default LineGraphHighRisk
