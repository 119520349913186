import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const RecommendationFooter = () => {
  return (
    <FooterWrapper>
      Learn more about the LOMA by visiting our{' '}
      <u>
        <Link to="/loma" className="primary-link">
          LOMA
        </Link>
      </u>{' '}
      page or call{' '}
      <Link href="tel:813-213-0641" className="primary-link">
        813-213-0641
      </Link>
    </FooterWrapper>
  )
}

export default RecommendationFooter

const FooterWrapper = styled.div`
  width: 100%;
  font-size: 20px;
  background-color: #c7ae4a;
  color: #16163f;
  padding: 10px;
  text-align: center;
  height: max-content;
`
