import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link as ScrollLink } from 'react-scroll'
import { Button, Typography, Row, Col, Grid } from 'antd'
import { FormOutlined, FileOutlined } from '@ant-design/icons'
import { LandingLayout } from '../../components/LandingLayout/LandingLayout'
import { Wrapper, Container } from '../../styled-components/styled-components'
import OneTwoThree from './components/OneTwoThree'
import ReportComponentsParallax from './components/ReportComponentsParallax'
import TheInfo from './components/TheInfo'
import OrderSection from './components/OrderSection'
import { Footer } from '../../components/Footer/Footer'
import PageHeader from '../../components/PageHeader'
import SeoNoIndex from '../../components/SeoNoIndex'

const { Title, Paragraph } = Typography
const { useBreakpoint } = Grid

const Fam = () => {
  const data = useStaticQuery(graphql`
    query {
      flood: file(relativePath: { eq: "flooding.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const screens = useBreakpoint()
  let fontSizeObject
  if (screens.xl) {
    fontSizeObject = { fontSize: '50px' }
  } else if (screens.lg) {
    fontSizeObject = { fontSize: '40px' }
  } else if (screens.md) {
    fontSizeObject = { fontSize: '42px' }
  } else if (screens.sm) {
    fontSizeObject = { fontSize: '34px', lineHeight: '44px' }
  } else if (screens.xs) {
    fontSizeObject = { fontSize: '34px' }
  } else {
    fontSizeObject = { fontSize: '50px' }
  }

  return (
    <LandingLayout>
      <SeoNoIndex />
      <PageHeader title="Flood Analysis Memo (FAM)" />

      <Wrapper>
        <Container style={{ padding: '40px 0 40px 0' }}>
          <Row gutter={[25, 0]}>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <p style={{ color: '#4a4a4a' }}>
                For Current And Future Home Buyers
              </p>
              <Title level={1} style={fontSizeObject}>
                Comprehensive
                <br />
                Flood Risk
                <br />
                Analysis
              </Title>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <Paragraph>
                No Flood Florida’s Flood Analysis Memo (FAM) can be developed
                for any residential property. Whether in the process of buying a
                home, selling a home, or generally interested in the real estate
                market, a FAM will answer any questions about the related flood
                risk for the desired property.
              </Paragraph>
              <Row gutter={[20, 20]} style={{ justifyContent: 'space-around' }}>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <ScrollLink
                    activeClass="active"
                    // to="fam-learn-more"
                    to="order-fam-section"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <Button
                      block
                      size="large"
                      type="primary"
                      shape="round"
                      icon={<FormOutlined />}
                    >
                      Order Report
                    </Button>
                  </ScrollLink>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://firebasestorage.googleapis.com/v0/b/flood-score.appspot.com/o/sample%20reports%2F709_Whitehall_St.-1.pdf?alt=media&token=8248e807-ef39-4af7-af40-0792aa6d7d3e"
                  >
                    <Button
                      block
                      size="large"
                      type="primary"
                      shape="round"
                      icon={<FileOutlined />}
                      style={{ width: '100%' }}
                    >
                      Sample Report
                    </Button>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container id="fam-learn-more">
          <Row gutter={[80, 10]}>
            <Col span={12} xs={24} sm={12}>
              <Img fluid={data.flood.childImageSharp.fluid} />
            </Col>
            <Col span={12} xs={24} sm={12}>
              <Title level={3}>FAM Features</Title>
              <ul>
                <li>
                  FEMA Effective Flood Zones (Easy to understand FEMA flood
                  maps)
                </li>
                <li>Best Available Watershed Modeling Floodplains</li>
                <li>
                  Latest Parcel and Structure Boundary with Floodplain Area of
                  Impact
                </li>
                <li>Estimation of Finished Floor Elevation of Structure.</li>
                <li>
                  Clear visual representation of flood impact – Area and
                  Elevation Impact
                </li>
                <li>
                  Overall Flood Risk Meter Flood Insurance Estimation through
                  NFIP (National Flood Insurance Program) rating system
                </li>
              </ul>
              {(screens.xl || screens.lg) && (
                <Paragraph>
                  <div style={{ textAlign: 'center', fontSize: '42px' }}>
                    Best Part Is:
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '42px',
                      lineHeight: 1.2
                    }}
                  >
                    Requesting And Understanding Your FAM Is As Easy As
                  </div>
                  <div style={{ textAlign: 'center', fontSize: '42px' }}>
                    1, 2, 3…
                  </div>
                </Paragraph>
              )}
            </Col>
          </Row>
          <Row>
            {!(screens.xl || screens.lg) && (
              <Paragraph>
                <div style={{ textAlign: 'center', fontSize: '42px' }}>
                  Best Part Is:
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '42px',
                    lineHeight: 1.2
                  }}
                >
                  Requesting And Understanding Your FAM Is As Easy As
                </div>
                <div style={{ textAlign: 'center', fontSize: '42px' }}>
                  1, 2, 3…
                </div>
              </Paragraph>
            )}
          </Row>
          <Row>
            <OneTwoThree />
          </Row>
        </Container>
      </Wrapper>

      <ReportComponentsParallax />

      <Wrapper>
        <Container style={{ padding: '40px 0 40px 0' }}>
          <TheInfo />
        </Container>
      </Wrapper>

      <OrderSection />
      <Footer />
    </LandingLayout>
  )
}

export default Fam
