import React from 'react'
import styled from 'styled-components'
import { Row, Col, Typography } from 'antd'
import ExamineSvg from '../../../../assets/images/Examine.svg'
import CategoryPills from '../CategoryPills'
import InfoBox from './InfoBox'
import LearnMore from './LearnMore'
import RecommendationFooter from '../RecommendationFooter'
import SearchAnotherPropertyBtn from '../BottomMenu/SearchAnotherPropertyBtn'

const { Title } = Typography

const Low = props => {
  const {
    LOMARating,
    LOMACategory,
    selectedAddress,
    propertyData,
    setShowRecommendation,
    setShowScreening
  } = props

  const colSizes = {
    xxl: 12,
    xl: 12,
    lg: 24,
    md: 24,
    sm: 24,
    xs: 24
  }

  return (
    <>
      <div style={{ paddingBottom: '40px' }}>
        <Title level={2} style={{ color: '#fff' }}>
          This property has a “low” likelihood of benefiting from a Letter of
          Map Amendment.
        </Title>
      </div>
      <Row gutter={[40, 20]}>
        <Col {...colSizes}>
          <hr
            style={{
              border: '3px solid #C7AE4A',
              backgroundColor: '#C7AE4A'
            }}
          />
          <div style={{ margin: '20px 0 20px 0' }}>
            <CategoryPills LOMARating={LOMARating} />
            <InfoBox
              LOMARating={LOMARating}
              selectedAddress={selectedAddress}
              propertyData={propertyData}
              suggestion="Low"
            />
          </div>
          <hr
            style={{
              border: '3px solid #C7AE4A',
              backgroundColor: '#C7AE4A'
            }}
          />
        </Col>
        <Col {...colSizes}>
          <LearnMore
            title="Request Your Flood Analysis Memo"
            text="Learn more about your detailed flood risk. Floodplain maps, building and structure impacts, and flood insurance premium estimates"
            img={ExamineSvg}
          />
          <Row align="center" style={{ marginTop: '40px' }}>
            <SearchAnotherPropertyBtn
              setShowRecommendation={setShowRecommendation}
              setShowScreening={setShowScreening}
            />
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Low
