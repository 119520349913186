import React from 'react'
import { Parallax } from 'react-parallax'
import bgImage from '../../../assets/images/nice-house.jpg'

const ReportComponentsParallax = () => {
  return (
    <Parallax blur={0} bgImage={bgImage} bgImageAlt="house" strength={300}>
      <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#16163f', opacity: '0.8' }}>
        <p style={{ fontSize: '50px', color: 'white', textAlign: 'center' }}>Report Components</p>
      </div>
    </Parallax>

  )
}

export default ReportComponentsParallax
